import { all } from 'redux-saga/effects';
import { saga as authSagas } from './auth';
import { saga as activitiesSagas } from './activities';
import { saga as exerciseVideoSagas } from './exerciseVideos';
import { saga as checkoutSaga } from './checkout';
import { saga as adminSaga } from './admin';


export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    activitiesSagas(),
    exerciseVideoSagas(),
    checkoutSaga(),
    adminSaga(),
  ]);
}
