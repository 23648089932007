import React, {useState, useEffect} from "react";
import { Card, CardBody, Button, Row, Label } from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
} from "availity-reactstrap-validation";

import IntlMessages from "../../helpers/IntlMessages";
import { ThemeColors } from '../../helpers/ThemeColors'
import LoaderButton from "../../components/LoaderButton";
import { Colxx } from "../../components/common/CustomBootstrap";
import { WeightAreaChart } from "../../components/charts";


const HomeWeight = ({
  weeklyWeights,
  currentWeight,
  messages,
  updateActivity,
  loading
}) => {
  const [isList, flipIsList] = useState(true);
  const [weight, setWeight] = useState(currentWeight);
  useEffect(() => {
    setWeight(currentWeight);
  }, [currentWeight]);

  const colors = ThemeColors();
  const weightChartData = {
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    datasets: [
      {
        label: "",
        data: weeklyWeights,
        borderColor: colors.themeColor2,
        pointBackgroundColor: colors.foregroundColor,
        pointBorderColor: colors.themeColor2,
        pointHoverBackgroundColor: colors.themeColor2,
        pointHoverBorderColor: colors.foregroundColor,
        pointRadius: 4,
        pointBorderWidth: 2,
        pointHoverRadius: 5,
        fill: true,
        borderWidth: 2,
        backgroundColor: colors.themeColor2_10
      }
    ]
  };

  function submitData(event, errors) {
    if (errors.length === 0) {
      updateActivity(weight, "weight");
    }
    flipIsList(isList => !isList);
  }

  function changeFormState() {
    flipIsList(isList => !isList);
  }

  function handleChange(event) {
    setWeight(parseFloat(event.target.value));
  }

  function renderList() {
    return (
      <Card className="dashboard-filled-line-chart h-100">
        <CardBody>
          <div className="float-left float-none-xs">
            <div className="d-inline-block">
              <h5 className="d-inline">
                <IntlMessages id="activities.weight-title" />
              </h5>
            </div>
          </div>

          <div className="btn-group float-right float-none-xs mt-2 text-muted">
            {`${messages["activities.weight-today"]} ${weight} ${
              messages["activities.weight-kg"]
            }`}
          </div>
        </CardBody>

        <div className="chart card-body pt-0">
          <WeightAreaChart shadow data={weightChartData} />
        </div>
        <div className="card-body pt-0">
          <Button block color="primary" onClick={changeFormState}>
            <IntlMessages id="activities.weight-submit" />
          </Button>
        </div>
      </Card>
    );
  }

  function renderForm() {
    return (
      <div>
        <Card className="mb-5">
          <CardBody>
            <h6 className="mb-4">
              <IntlMessages id="activities.weight-submit" />
            </h6>

            <AvForm
              className="av-tooltip tooltip-label-right"
              onSubmit={submitData}
            >
              <Row>
                <Colxx lg={12}>
                  <AvGroup>
                    <Label>
                      <IntlMessages id="activities.weight-title" />
                    </Label>
                    <AvField
                      name="weight"
                      id="weight"
                      type="number"
                      value={weight}
                      onChange={handleChange}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "โปรดกรอกน้ำหนัก"
                        },
                        min: {
                          value: 0,
                          errorMessage: "ขั้นต่ำต้องเท่ากับ 0"
                        }
                      }}
                    />
                  </AvGroup>
                </Colxx>
              </Row>

              <LoaderButton
                color="primary"
                block
                status={loading ? "processing" : "default"}
              >
                <IntlMessages id="activities.submit" />
              </LoaderButton>
            </AvForm>
          </CardBody>
        </Card>
      </div>
    );
  }

  if (isList) {
    return renderList();
  } else {
    return renderForm();
  }
};

export default HomeWeight;
