import React, { Component, Fragment } from "react";
import { Row, Label, Button, Alert } from "reactstrap";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { connect } from "react-redux";
import { injectIntl } from 'react-intl';
import { registerUser } from "../../redux/auth";
import { changeAuthState, checkUser } from "../../redux/checkout";

import IntlMessages from "../../helpers/IntlMessages";
import { Colxx } from "../../components/common/CustomBootstrap";

class RegisterForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
          email: "",
          password: "",
          firstname: "",
          lastname: "",
        };
        
        this.onUserRegister = this.onUserRegister.bind(this);
        this.customEmailValidation = this.customEmailValidation.bind(this);
        this.checkExistedEmail = this.checkExistedEmail.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.renderErrorMessage = this.renderErrorMessage.bind(this);
        this.renderSubmitButton = this.renderSubmitButton.bind(this);
    }
    onUserRegister(event, errors) {
        if (errors.length === 0) {
          // register and login immediately
          this.props.registerUser(this.state, null, true);
        }
    }

    customEmailValidation() {
        const { messages } = this.props.intl;
        if(this.props.isNewUser) {
            return true;
        } else {
            return messages['error.username-existed']
        }
    }

    async checkExistedEmail(event) {
        const { value } = event.target;
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
            this.props.checkUser(value);
        }
        
    }
    
    handleChange(event) {
        this.setState({
          [event.target.id]: event.target.value
        })
    }
    
    renderErrorMessage(errorMessageID) {
        if (!errorMessageID) {
          return null;
        }
        return (
          <Colxx xxs={12} md={12}>
            <Alert color="danger">
              <IntlMessages id={errorMessageID}/>
            </Alert>
          </Colxx>
        );
    }

    renderSubmitButton() {
        const { isNewUser } = this.props;
        if (isNewUser) {
            return (
                <div className="d-flex justify-content-end align-items-center">
                    <Button
                        color="primary"
                        className="btn-shadow"
                        size="lg"
                    >
                        <span className="text-one text-white">
                            <IntlMessages id="user.register-button" />
                        </span>
                    </Button>
                </div>
            );
        } else {
            return (
                <div className="d-flex justify-content-end align-items-center">
                    <Button
                        color="warning"
                        className="btn-shadow"
                        size="lg"
                        onClick={() => this.props.changeAuthState("login")}
                    >
                        <span className="text-one text-white">
                            <IntlMessages id="label.existing-user-warning" />
                        </span>
                    </Button>
                </div>
            );
        }
    }
    
    render() {
        const { 
            email,
            password,
            firstname,
            lastname
        } = this.state;

        return (
            <Fragment>
                <AvForm onSubmit={this.onUserRegister}>
                    <Row>
                        <Colxx xxs={6} md={6}>
                            <AvGroup>
                                <Label><IntlMessages id="user.firstname" /></Label>
                                <AvField 
                                    name="firstname" 
                                    id="firstname" 
                                    value={firstname} 
                                    onChange={(event) => this.handleChange(event)} 
                                    validate={{
                                        required: { value: true, errorMessage: 'อย่าลืมกรอกชื่อ' }
                                    }}
                                />
                            </AvGroup>
                        </Colxx>
                        <Colxx xxs={6} md={6}>
                            <AvGroup>
                                <Label><IntlMessages id="user.lastname" /></Label>
                                <AvField 
                                    name="lastname" 
                                    id="lastname" 
                                    value={lastname} 
                                    onChange={(event) => this.handleChange(event)} 
                                    validate={{
                                        required: { value: true, errorMessage: 'อย่าลืมกรอกนามสกุล' }
                                    }}
                                />
                            </AvGroup>
                        </Colxx>
                        <Colxx xxs={12} md={12}>
                            <AvGroup>
                                <Label><IntlMessages id="user.email" /></Label>
                                <AvField 
                                    type="email" 
                                    name="email" 
                                    id="email" 
                                    value={email} 
                                    onChange={(event) => this.handleChange(event)} 
                                    onBlur={this.checkExistedEmail}
                                    validate={{
                                        required: { value: true, errorMessage: 'อย่าลืมกรอกอีเมล์' },
                                        email: { value: true, errorMessage: 'รูปแบบอีเมล์ไม่ถูกต้อง' },
                                        custom: this.customEmailValidation
                                    }}
                                />
                            </AvGroup>
                        </Colxx>
                        <Colxx xxs={12} md={12}>
                            <AvGroup>
                                <Label><IntlMessages id="user.password"/></Label>
                                <AvField 
                                    type="password" 
                                    name="password" 
                                    id="password" 
                                    value={password} 
                                    onChange={(event) => this.handleChange(event)} 
                                    validate={{
                                        required: { value: true, errorMessage: 'อย่าลืมกรอกพาสเวิร์ด' },
                                        minLength: { value: 6, errorMessage: 'ขั้นต่ำ 6 ตัวอักษร' }
                                    }}
                                />
                            </AvGroup>
                        </Colxx>
                        <Colxx xxs={12} md={12}>
                            <AvGroup>
                                <Label><IntlMessages id="user.confirm-password"/></Label>
                                <AvField 
                                    type="password" 
                                    name="confirm-password" required 
                                    id="comfirm-password" 
                                    validate={{
                                        required: { value: true, errorMessage: 'อย่าลืมกรอกยืนยันพาสเวิร์ด' },
                                        match:{value:'password', errorMessage: 'พาสเวิร์ดไม่ตรงกัน'}
                                    }}
                                />
                            </AvGroup>
                        </Colxx>
                        {this.renderErrorMessage(this.props.errorMessageID)}
                    </Row>
                    {this.renderSubmitButton()}
                </AvForm>
            </Fragment>
        );
    }
}
const mapStateToProps = ({ authUser, checkout }) => {
    const { user, loading, errorMessageID } = authUser;
    const { authState, isNewUser } = checkout
    return { user, loading, errorMessageID, authState, isNewUser };
};
  
const mapActionsToProps = { registerUser, changeAuthState, checkUser };
  
export default connect(
    mapStateToProps,
    mapActionsToProps
)(injectIntl(RegisterForm));
