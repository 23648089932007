import React, { Component, Fragment } from "react";
import { 
    Row, 
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    Jumbotron,
} from "reactstrap";
import axios from "axios";
import { connect } from "react-redux";
import tus from "tus-js-client";

import Pagination from "../../containers/pages/Pagination";
import ListPageHeading from "../../containers/pages/ListPageHeading";
import ImageListView from "../../containers/pages/ImageListView";
import AddNewVideoModal from "../../containers/pages/AddNewVideoModal";
import { getVideos, addVideo, deleteVideo, selectVideo } from "../../redux/exerciseVideos";
import { updateScore, getActivities } from "../../redux/activities";
import { sortGeneralArray } from "../../helpers/Utils";
import IntlMessages from "../../helpers/IntlMessages";

function collect(props) {
  return { data: props.data };
}

class exerciseVideoPage extends Component {
  constructor(props) {
    super(props);
    this.mouseTrap = require('mousetrap');
    const { allItems, totalItemCount, loading } = props;

    this.state = {
        isScoreModelOpen: false,
        isVideoModalOpen: false,
        isDeleteModelOpen: false,
        selectedVideo: {},
        allItems,
        totalItemCount,
        isLoaded: !loading,
        orderColumn: "title",
        titleFiltered: [],
        durationFiltered: [],
        scoreFiltered: [],
        selectedPageSize: 8,
        pageSizes: [8, 12, 24],
        currentPage: 1,
        totalPage: 1,
        items: [],

        categories: [
            { label: "Cakes", value: "Cakes", key: 0 },
            { label: "Cupcakes", value: "Cupcakes", key: 1 },
            { label: "Desserts", value: "Desserts", key: 2 }
        ],

        modalOpen: false,
        video: {
          file: null,
          size: 0,
          id: "",
          title: "",
          description: "",
          level: "",
          score: 0,
        },
        uploadStatus: "default",
        uploadPercentage: 0.00,
        removeVideoId: "0",
        isVideoUploadMode: true,
    };

    this.dataListRender = this.dataListRender.bind(this);
    this.onRangeFilter = this.onRangeFilter.bind(this);
    this.onClickedItem = this.onClickedItem.bind(this);
    this.onClosedVideoModal = this.onClosedVideoModal.bind(this);
    this.toggle = this.toggle.bind(this);
    this.modalConfirm = this.modalConfirm.bind(this);
    this.onSubmitVideo = this.onSubmitVideo.bind(this);
    this.onVideoFileChange = this.onVideoFileChange.bind(this);
    this.onVideoFieldsChange = this.onVideoFieldsChange.bind(this);
    this.resetUploadModal = this.resetUploadModal.bind(this);
    this.onDeleteVideo = this.onDeleteVideo.bind(this);
    this.deleteConfirm = this.deleteConfirm.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.customLevelValidation = this.customLevelValidation.bind(this);
    this.toggleVideoMode = this.toggleVideoMode.bind(this);
  }

  componentDidMount() {
    if (!this.props.entireData) this.props.getActivities(this.props.user.username);
    if (this.props.allItems.length > 0) {
        const { allItems } = this.props;
        this.setState({
            titleFiltered: allItems,
            durationFiltered: allItems,
            levelFiltered: allItems,
            scoreFiltered: allItems,
            isLoaded: true
        }, () => this.dataListRender());
        return;
    }
    this.props.getVideos();
  }

  componentWillReceiveProps(newProps) {
      const { allItems, loading: isLoading, status } = newProps;
      if (allItems.length <= 0) return;
      this.setState({
        allItems,
        titleFiltered: allItems,
        durationFiltered: allItems,
        levelFiltered: allItems,
        scoreFiltered: allItems,
        isLoaded: !isLoading
      }, () => this.dataListRender());

      const { uploadStatus } = this.state;
      if(uploadStatus === "processing" && status === "success") {
        this.setState({
          uploadStatus: status
        });
        this.props.getVideos();
      }

  }
  
  componentWillUnmount() {
    this.mouseTrap.unbind("ctrl+a");
    this.mouseTrap.unbind("command+a");
    this.mouseTrap.unbind("ctrl+d");
    this.mouseTrap.unbind("command+d");
  }

  toggleModal = async () => {
    this.resetUploadModal();
    this.setState({
      modalOpen: !this.state.modalOpen
    });
  };

  changeOrderBy = orderColumn => {
    this.setState(
      {
        orderColumn
      },
      () => this.dataListRender()
    );
  };
  changePageSize = size => {
    this.setState(
      {
        selectedPageSize: size,
        currentPage: 1
      },
      () => this.dataListRender()
    );
  };
  onChangePage = page => {
    this.setState(
      {
        currentPage: page
      },
      () => this.dataListRender()
    );
  };

  onSearchKey = search => {
    const { allItems } = this.state;
    const titleFiltered = allItems.filter(item => item.title.toLowerCase().includes(search));
    this.setState({ titleFiltered, currentPage: 1 },
        () => this.dataListRender()
    );
    
  };

  onRangeFilter = (valueRange, fieldName) => {
        const { allItems } = this.state;
        const durationFiltered = allItems.filter(item => 
        item[fieldName] >= valueRange[0] && item[fieldName] <= valueRange[1]);
        this.setState({ durationFiltered, currentPage: 1 },
            () => this.dataListRender()
        );
    }

  getIndex(value, arr, prop) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i][prop] === value) {
        return i;
      }
    }
    return -1;
  }

  dataListRender() {
      const { 
            titleFiltered,
            durationFiltered,
            scoreFiltered,
            selectedPageSize,
            currentPage,
            orderColumn,
        } = this.state;
      // Steps of filtering videos 
      // 1. filter by title search
      // 2. filter by duration
      // 3. filter by score
      // Then...
      // 1. intersect all filtered videos
      const intersectItems = titleFiltered
        .filter(title => durationFiltered.some(duration => title.id === duration.id))
        .filter(td => scoreFiltered.some(score => td.id === score.id));
      // 2. sort by column
      const isColumnString = (orderColumn === 'duration' || orderColumn === 'score') 
                ? false : true;
      const sortedItems = sortGeneralArray(intersectItems, orderColumn, true, isColumnString);
      // 3. paging
      const totalItemCount = sortedItems.length;
      const totalPage = Math.ceil(totalItemCount / selectedPageSize);
      const startPosition = (currentPage - 1) * selectedPageSize;
      const endPosition = startPosition + selectedPageSize;
      const items = sortedItems.slice(startPosition, endPosition);

      this.setState({
        totalPage,
        items,
        totalItemCount,
      });

  }

  onClickedItem = (event, selectedVideo) => {
    const { user } = this.props;
    const group = (user || "user").group || "user";
    this.setState(prevState => ({
        selectedVideo,
        isVideoModalOpen: group==="admin" ? false : !prevState.isVideoModalOpen
    }));

    if (group === "admin") {
      this.props.selectVideo(selectedVideo);
      this.props.history.push("/app/admin/videodetail")
    }
  }

  onClosedVideoModal = (event) => {
    this.setState({
        isVideoModalOpen: false,
        isScoreModelOpen: true,
    });
  }

  toggle = () => {
    this.setState(prevState => ({
        isVideoModalOpen: false,
        isScoreModelOpen: !prevState.isScoreModelOpen
    }));
  };

  modalConfirm = () => {
      const { updateScore, entireData, user } = this.props;
      const { selectedVideo } = this.state;
      updateScore(selectedVideo.score, entireData, user);
      this.setState(prevState => ({
          isVideoModalOpen: false,
          isScoreModelOpen: !prevState.isScoreModelOpen
      }));
  }

  async onSubmitVideo (event, errors) {
    if (this.state.isVideoUploadMode) {
      if (errors.length > 0 || !this.state.video.file) return;
      this.setState({ uploadStatus: "preparing" });
      const { file, size, title, description } = this.state.video;
      axios.defaults.headers.common['Authorization'] = 'Bearer bdd5c6bf1d13356615ba27ec22d4b981';
  
      const initHeaders = {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/vnd.vimeo.*+json;version=3.4'
        }
      };
      const response = await axios.post('https://api.vimeo.com/me/videos', {
                              upload: {
                                approach: "tus",
                                size
                              }, 
                              name: title,
                              description,
                              initHeaders 
                            });
      const { uri, upload: vimeoUpload } = response.data;
      this.setState(prevState => ({
        uploadStatus: "uploading",
        video: {
          ...prevState.video,
          id: uri.substr(uri.lastIndexOf('/')+1)
        }
      }));
      const uploadURI = vimeoUpload.upload_link;
      const upload = new tus.Upload(file, {
        uploadUrl: uploadURI,
        endpoint: uploadURI,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        metadata: {
            filename: file.name,
            filetype: file.type
        },
        onError: (error) => {
          this.setState({
            uploadStatus: "error"
          });
          console.log("Failed because: " + error)
        },
        onProgress: (bytesUploaded, bytesTotal) => {
            const percentage = (bytesUploaded / bytesTotal * 100).toFixed(2);
            this.setState({
              uploadPercentage: percentage 
            });
        },
        onSuccess: () => {
          this.setState({
            uploadStatus: "processing"
          });
          this.props.addVideo(this.state.video);
        }
      });
      upload.start();
    } else {
      this.setState({
        uploadStatus: "processing"
      });
      this.props.addVideo(this.state.video);
    }
    
  }

  onVideoFileChange (event) {
    const file = event.target.files[0];
    const size = file.size;
    this.setState(prevState => ({
      video: {
        ...prevState.video,
        file,
        size,
      }
    }));
  }

  onVideoFieldsChange(event) {
    const {id, value} = event.target;

    this.setState(prevState => ({
      video: {
        ...prevState.video,
        [id]: value
      }
    }));
  }

  onDeleteVideo(id) {
    this.setState(prevState => ({
      removeVideoId: id,
      isDeleteModelOpen: !prevState.isDeleteModelOpen
    }));
  }

  deleteConfirm(deleteEntirely) {
    if (this.state.removeVideoId.length < 9) return;
    this.props.deleteVideo(this.state.removeVideoId, deleteEntirely);
    this.toggleDeleteModal();
  }
  toggleDeleteModal() {
    this.setState({
      removeVideoId: "0",
      isDeleteModelOpen: false
    });
  }

  customLevelValidation() {
    if (this.state.video.level === "") {
      return "อย่าลืมกรอกระดับความยาก"
    } else {
      return true;
    }
  }

  toggleVideoMode() {
    this.setState(prevState => ({
      isVideoUploadMode: !prevState.isVideoUploadMode
    }));
  }

  resetUploadModal() {
    this.setState({
      video: {
        file: null,
        size: 0,
        id: "",
        title: "",
        description: "",
        level: "",
        score: 0,
      },
      uploadStatus: "default",
      uploadPercentage: 0.00,
    })
  }

  render() {
    const {
      isLoaded,
      isScoreModelOpen,
      isVideoModalOpen,
      isDeleteModelOpen,
      selectedVideo,
      currentPage,
      selectedPageSize,
      totalItemCount,
      pageSizes,
      modalOpen,
      uploadPercentage,
      uploadStatus,
      isVideoUploadMode
    } = this.state;
    const { match, user } = this.props;
    const group = (user || "user").group || "user";
    const startIndex = (currentPage - 1) * selectedPageSize;
    const endIndex = currentPage * selectedPageSize;

    return !isLoaded ? (
      <div className="loading" />
    ) : (
      <Fragment>
      {
        isVideoModalOpen &&
        <div className="trailer">
          <video controls autoPlay>
            <source src={selectedVideo.url} type="video/mp4"/>
          </video>
          <img src="/assets/img/close.png" alt="Close video" className="close" onClick={this.onClosedVideoModal}/>
        </div>
      }
        <Modal isOpen={isScoreModelOpen} toggle={this.toggle}>
          <ModalBody>
              <Jumbotron>
                <p className="lead text-center">
                  <IntlMessages id="modal.finish-body-message" />
                </p>
                <p className="display-1 card-text text-center">
                  {selectedVideo.score}
                </p>
                <p className="lead text-center">
                    <IntlMessages id="pages.score-label" />
                </p>
              </Jumbotron>
          </ModalBody>
          <ModalFooter>
              <Button color="primary" onClick={this.modalConfirm}>
                  <IntlMessages id="modal.finish-confirm"/>
              </Button>{" "}
              <Button color="secondary" onClick={this.toggle}>
                  <IntlMessages id="modal.finish-cancel"/>
              </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={isDeleteModelOpen} toggle={this.toggleDeleteModal}>
          <ModalBody>
              <Jumbotron>
                <p className="lead text-center">
                  <IntlMessages id="modal.finish-delete-message" />
                </p>
              </Jumbotron>
          </ModalBody>
          <ModalFooter>
              <Button color="warning" onClick={() => this.deleteConfirm(false)}>
                  <IntlMessages id="madal.deldbonly"/>
              </Button>{" "}
              <Button color="danger" onClick={() => this.deleteConfirm(true)}>
                  <IntlMessages id="madal.delete"/>
              </Button>{" "}
              <Button color="secondary" onClick={this.toggleDeleteModal}>
                  <IntlMessages id="modal.finish-cancel"/>
              </Button>
          </ModalFooter>
        </Modal>
        <div className="disable-text-selection">
          <ListPageHeading
            heading="menu.exercise-video"
            changeOrderBy={this.changeOrderBy}
            changePageSize={this.changePageSize}
            selectedPageSize={selectedPageSize}
            totalItemCount={totalItemCount}
            match={match}
            startIndex={startIndex}
            endIndex={endIndex}
            pageSizes={pageSizes}
            toggleModal={this.toggleModal}
            onSearchKey={this.onSearchKey}
            onRangeFilter={this.onRangeFilter}
            isAdmin={group === "admin"}
          />
          <AddNewVideoModal
            modalOpen={modalOpen}
            toggleModal={this.toggleModal}
            onSubmitVideo={this.onSubmitVideo}
            onVideoFileChange={this.onVideoFileChange}
            onVideoFieldsChange={this.onVideoFieldsChange}
            resetUploadModal={this.resetUploadModal}
            status={uploadStatus}
            percentage={uploadPercentage}
            customLevelValidation={this.customLevelValidation}
            isVideoUploadMode={isVideoUploadMode}
            toggleVideoMode={this.toggleVideoMode}
          />
          <Row>
            {this.state.items.map(video => {
                return (
                  <ImageListView
                    key={video.id}
                    video={video}
                    collect={collect}
                    onCheckItem={this.onClickedItem}
                    isAdmin={group === "admin"}
                    onDelete={this.onDeleteVideo}
                  />
                );
            })}{" "}
            <Pagination
              currentPage={this.state.currentPage}
              totalPage={this.state.totalPage}
              onChangePage={i => this.onChangePage(i)}
            />
          </Row>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ exerciseVideos, activities, authUser }) => {
    const { allItems, loading, status } = exerciseVideos;
    const { user } = authUser;
    const { currentScore, entireData, loading: activityLoading } = activities;
    return { allItems, loading, status, user, currentScore, entireData, activityLoading };
  };
  const mapActionsToProps = { getVideos, addVideo, deleteVideo, updateScore, getActivities, selectVideo };
  
  export default connect(
    mapStateToProps,
    mapActionsToProps
  )(exerciseVideoPage);
