/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-sub-hidden";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultDirection = 'ltr';
export const defaultLocale = "th";
export const localeOptions = [
  { id: "th", name: "ไทย" },
  { id: "en", name: "English" },
];

export const searchPath = "/app/pages/search";
export const servicePath = "https://api.planforfit.com/actdev";

/* 
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const isMultiColorActive = false;
export const defaultColor = "light.red";

// Product name/id of this application that refer in DB
export const productName = "ccr";

const dev = {
  Storage: {
    bucket: "ccr-files-dev",
    region: "ap-southeast-1",
    identityPoolId: "ap-southeast-1:b657065f-5756-409d-abde-f854ea587b9c",
  },
  Auth: {
      mandatorySignIn: true,
      region: "ap-southeast-1",
      userPoolId: "ap-southeast-1_c44o0XEbI",
      identityPoolId: "ap-southeast-1:b657065f-5756-409d-abde-f854ea587b9c",
      userPoolWebClientId: "5vm6nnt6fj2raivp1fovlhdd63"
  },
  API: {
      endpoints: [
          {
              name: "user",
              endpoint: process.env.REACT_APP_STAGE === 'dev' 
                ? "https://api.bemoove.co/dev" 
                : "http://localhost:3003",
              region: "ap-southeast-1"
          },
          {
              name: "activity",
              endpoint: process.env.REACT_APP_STAGE === 'dev'
                ? "https://api.bemoove.co/actdev"
                : "http://localhost:3002",
              region: "ap-southeast-1"
          }
      ]
  }
}

const prod = {
  Storage: {
    bucket: "ccr-files",
    region: "ap-southeast-1",
    identityPoolId: "ap-southeast-1:cc1900b3-00e0-4aef-8f1b-c1c6eaa1b7a8"
  },
  Auth: {
      mandatorySignIn: true,
      region: "ap-southeast-1",
      userPoolId: "ap-southeast-1_yVVf1J0zR",
      identityPoolId: "ap-southeast-1:cc1900b3-00e0-4aef-8f1b-c1c6eaa1b7a8",
      userPoolWebClientId: "2i1b1e5sag1hvc2sr008v6hpf"
  },
  API: {
      endpoints: [
          {
              name: "user",
              endpoint: "https://api.bemoove.co/prod",
              region: "ap-southeast-1"
          },
          {
              name: "activity",
              endpoint: "https://api.bemoove.co/actprod",
              region: "ap-southeast-1"
          }
      ]
  }
}

const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export const wsURL = process.env.REACT_APP_STAGE === 'prod'
  ? "wss://dt0oemn5u3.execute-api.ap-southeast-1.amazonaws.com/prod"
  : "wss://bheu0p3293.execute-api.ap-southeast-1.amazonaws.com/dev";

export const awsConfig = {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
