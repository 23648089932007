import React from "react";
import { Card, CardBody, CardTitle, Button } from "reactstrap";

import IntlMessages from "../../helpers/IntlMessages";
import moment from "moment";

const DayInWeekNav = params => {
  const {
    changeDay,
    day,
    weeklyActivities,
    firstLowCarb,
    secondLowCarb,
    goal,
    openLowCarbModal,
  } = params;

  return (
    <Card className="mb-4">
      <CardBody>
        <CardTitle>
            <IntlMessages id="activities.daily-info" />
            {` ${moment(weeklyActivities[day-1].date).format('dddd Do/MM/YY')}`}
        </CardTitle>

        <div className="day-navbar">
          {weeklyActivities.map((activity, index) => {
            let isLowCarbDay = false;
            if ((goal === "bulk") && (index === firstLowCarb || index === secondLowCarb)) {
              isLowCarbDay = true;
            }
            let compId = "";
            switch (index) {
              case 0:
                compId = "monday";
                break;
              case 1:
                compId = "tuesday";
                break;
              case 2:
                compId = "wednesday";
                break;
              case 3:
                compId = "thursday";
                break;
              case 4:
                compId = "friday";
                break;
              case 5:
                compId = "saturday";
                break;
              case 6:
                compId = "sunday";
                break;
              default:
                compId = "monday";
                break;
            }
            return (
              <div className="day-item" key={index}>
                {
                  isLowCarbDay &&
                    <span className="tooltiptext">low-carb</span>
                }
                <Button 
                  outline={day !== index + 1} 
                  color="primary" 
                  className="default btn-block mb-2 button"
                  id={compId} 
                  name={compId}
                  onClick={() => changeDay(index+1)}
                  disabled={moment(activity.date).isAfter(moment(), 'day')}
                >
                  <div>{moment(activity.date).format("dd")}</div>
                  <div className="date">{moment(activity.date).format("DD/MM/YY")}</div>
                </Button>
              </div>
            );
          })}
        </div>
        {
          goal === "bulk" &&
          <div>
            <Button 
              className="link-btn"
              onClick={openLowCarbModal}
            >
              <IntlMessages id="label.what_low_carb" /> 
            </Button>
          </div>
        }
      </CardBody>
    </Card>
  );
}

export default DayInWeekNav;
