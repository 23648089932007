import React, { Component, Fragment } from "react";
import { Alert, Row, Card, CardTitle, Label, Button } from "reactstrap";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { connect } from "react-redux";
import { injectIntl } from 'react-intl';

import { Colxx } from "../components/common/CustomBootstrap";
import LoaderButton from "../components/LoaderButton";
import IntlMessages from "../helpers/IntlMessages";
import { getNewPassword, submitNewPassword } from "../redux/auth";
import { checkUser } from "../redux/checkout";
import { getUrlVars } from "../helpers/Utils";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
      email: "",
      password: "",
      codeSent: false,
      confirmed: false,
      confirmPassword: "",
      isConfirming: false,
      isSendingCode: false,
      urlVars: [],
    };

    this.customEmailValidation = this.customEmailValidation.bind(this);
    this.checkExistedEmail = this.checkExistedEmail.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onSendClick = this.onSendClick.bind(this);
    this.onConfirmClick = this.onConfirmClick.bind(this);
    this.renderRequestCodeForm = this.renderRequestCodeForm.bind(this);
    this.renderConfirmationForm = this.renderConfirmationForm.bind(this);
    this.renderSuccessMessage = this.renderSuccessMessage.bind(this);
  }

  componentDidMount() {
    const urlVars = getUrlVars();
    if(urlVars.length > 0) {
      const [, email, confirm_code] = urlVars;
      this.setState({
        urlVars: getUrlVars(),
        codeSent: true,
        email,
        code: confirm_code
      });
    }
  }

  componentWillReceiveProps(newProps) {
    const { code, email, password } = this.state;
    const { status } = newProps;
    if(code && email && password && status === "success") {
      this.setState({
        confirmed: true
      })
    }
  }

  customEmailValidation() {
    const { messages } = this.props.intl;
    if(this.props.isNewUser) {
      return messages['error.newuser'];
    } else {
      return true;
    }
  }

  async checkExistedEmail(event) {
    const { value } = event.target;
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        this.props.checkUser(value);
    }
    
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  onSendClick(event, errors) {
    if(errors.length === 0){
      this.setState({
        isSendingCode: true
      })
      this.props.getNewPassword(this.state.email);
    }
  }

  onConfirmClick(event, errors) {
    if(errors.length === 0) {
      const { email, code, password } = this.state;
      this.props.submitNewPassword(email, code, password);
    }
  }

  renderRequestCodeForm() {
    const { email } = this.state;
    const { status, loading } = this.props;
    return (
      <AvForm onSubmit={this.onSendClick}>
        <Row>
          <Colxx xxs={12} md={12}>
            {status === "success" 
              ? (
                  <div className="ml-4 mr-4">
                    <Alert color="success">
                      <IntlMessages id="label.reset-password-success" />
                    </Alert>
                  </div>
                )
              : (
                  <div className="mb-4">
                    <AvGroup>
                      <Label><IntlMessages id="user.email" /></Label>
                      <AvField 
                        type="email" 
                        name="email" 
                        id="email" 
                        value={email} 
                        onChange={(event) => this.handleChange(event)}
                        validate={{
                            required: { value: true, errorMessage: 'อย่าลืมกรอกอีเมล์' },
                            email: { value: true, errorMessage: 'รูปแบบอีเมล์ไม่ถูกต้อง' }
                        }}
                      />
                    </AvGroup>
                    <LoaderButton
                      status={loading ? "processing": "default"}
                      id="forgot-password-button"
                      color="primary"
                      className="btn-shadow"
                      size="lg"
                      block
                    >
                      <IntlMessages id="user.reset-password-button"/>
                    </LoaderButton>
                  </div>
                )
              }
          </Colxx>
        </Row>
      </AvForm>
    );
  }

  renderConfirmationForm() {
    const { password } = this.state;
    const { loading } = this.props;
    return (
      <AvForm onSubmit={this.onConfirmClick}>
        <Row>
          <Colxx xxs={12} md={12}>
            <AvGroup>
              <Label><IntlMessages id="user.new-password"/></Label>
              <AvField 
                type="password" 
                name="password" 
                id="password" 
                value={password} 
                onChange={(event) => this.handleChange(event)} 
                validate={{
                    required: { value: true, errorMessage: 'อย่าลืมกรอกพาสเวิร์ด' },
                    minLength: { value: 6, errorMessage: 'ขั้นต่ำ 6 ตัวอักษร' }
                }}
              />
            </AvGroup>
          </Colxx>
          <Colxx xxs={12} md={12}>
              <AvGroup>
                  <Label><IntlMessages id="user.confirm-new-password"/></Label>
                  <AvField 
                      type="password" 
                      name="confirm-password" 
                      id="comfirm-password" 
                      validate={{
                          required: { value: true, errorMessage: 'อย่าลืมกรอกยืนยันพาสเวิร์ด' },
                          match: { value:'password', errorMessage: 'พาสเวิร์ดไม่ตรงกัน' }
                      }}
                  />
              </AvGroup>
          </Colxx>
        </Row>
          
        <div className="d-flex justify-content-end align-items-center mb-3">
          <LoaderButton
            status={loading ? "processing": "default"}
            id="forgot-password-button"
            color="primary"
            className="btn-shadow"
            size="lg"
            block
          >
            <IntlMessages id="user.confirm-button"/>
          </LoaderButton>
        </div>
      </AvForm>
    );
  }

  renderSuccessMessage() {
    return (
      <AvForm onSubmit={this.onSendClick}>
        <Row>
          <Colxx xxs={12} md={12} className="mb-4">
            <Alert color="success">
              <IntlMessages id="user.password-changed" />
            </Alert>
          </Colxx>
        </Row>
        
        <div className="d-flex justify-content-end align-items-center mb-3">
          <Button
            href="/login"
            color="primary"
            className="btn-shadow"
            size="lg"
            block
          >
            <IntlMessages id="user.password-changed-button" />
          </Button>
        </div>
      </AvForm>
    );
  }

  render() {
    const { codeSent, confirmed } = this.state;
    const titleMessageId = !codeSent 
                            ? "user.forgot-password"
                            : !confirmed
                              ? "user.confirmation-code"
                              : "user.password-changed"
    return (
      <Fragment>
        <div className='unauth-nav-container'>
          <img src="/assets/img/p4flogo.svg" className="unauth-nav-logo" alt="logo"/>
          <p className="inner-text">
            CYCLICAL CALORIC RESTRICTION (CCR)
          </p>
        </div>
        <Row className="h-100 login-body">
          <Colxx xxs="12" md="10" className="mx-auto my-auto pr-0">
            <Card className="auth-card">
              <div className="form-side">
                <img className="login-logo" src="/assets/img/login_logo.svg" alt="logo"/>
                <CardTitle className="mb-4">
                  <IntlMessages id={titleMessageId} />
                </CardTitle>
                {!codeSent
                  ? this.renderRequestCodeForm()
                  : !confirmed 
                    ? this.renderConfirmationForm()
                    : this.renderSuccessMessage()}
              </div>
              
              <div className="position-relative image-side ">
                <img className="login-image" src="/assets/img/welcome.jpg" alt="login"/>
              </div>          
            </Card>
          </Colxx>
        </Row>
        <div className='unauth-footer-container'>
          <p className="text-one mb-0">
            ติดต่อสอบถาม
          </p>
          <div className="inner-wrapper">
            <img src="/assets/img/footer-tel.svg" className="logo" alt="tel"/>
            <img src="/assets/img/footer-fb.svg" className="logo" alt="fb"/>
            <img src="/assets/img/footer-line.svg" className="logo" alt="line"/>
            <img src="/assets/img/footer-msg.svg" className="logo" alt="msg"/>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ authUser, checkout }) => {
  const { user, loading, status, errorMessageID } = authUser;
  const { isNewUser } = checkout
  return { user, loading, status, errorMessageID, isNewUser };
};

const mapActionsToProps = { getNewPassword, submitNewPassword, checkUser };

export default connect(
  mapStateToProps,
  mapActionsToProps
)(injectIntl(ForgotPassword));
