import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import ExifOrientationImg from 'react-fix-image-orientation'

export default props => 
  props.images.map((image, i) =>
    <div key={i} className='imgup-fadein'>
      <div 
        onClick={() => props.removeImage(image.public_id)} 
        className='imgup-delete'
      >
        <FontAwesomeIcon icon={faTimesCircle} size='2x' />
      </div>
      <ExifOrientationImg 
        className="imgup-img"
        src={image.secure_url} 
        alt='' 
        onError={() => props.onError(image.public_id)}
      />
    </div>
  )