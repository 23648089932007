import React, { Component, Fragment } from "react";
import { injectIntl } from "react-intl";
import { Row, Label, Button } from "reactstrap";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { connect } from "react-redux";

import IntlMessages from "../../helpers/IntlMessages";
import { Colxx } from "../../components/common/CustomBootstrap";
import { updateProfile } from "../../redux/checkout";

class BillingDetailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_link: "",
      phone: "",
      address_line1: "",
      tambol: "",
      city: "",
      state: "",
      zip: ""
    };

    this.onUpdateProfile = this.onUpdateProfile.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (
      newProps.user &&
      newProps.user.attributes &&
      newProps.user.attributes.hasOwnProperty("address")
    ) {
      const {
        "custom:user_link": user_link = "", // set default for deconstruction in ES6
        "custom:phone": phone_number = ""
      } = newProps.user.attributes;
      this.setState({
        user_link: user_link,
        phone: phone_number
      });
      if (newProps.user.attributes.hasOwnProperty("address")) {
        const address = JSON.parse(newProps.user.attributes.address);
        this.setState({
          address_line1: address.address_line1,
          tambol: address.tambol,
          city: address.city,
          state: address.state,
          zip: address.zip
        });
      }
    }
  }

  onUpdateProfile(event, errors) {
    if (errors.length === 0) {
      const {
        user_link,
        phone,
        address_line1,
        tambol,
        city,
        state,
        zip
      } = this.state;
      const address = {
        address_line1,
        tambol,
        city,
        state,
        zip
      };
      const profile = {
        user_link,
        phone,
        address
      };
      this.props.updateProfile(profile);
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  render() {
    const {
      user_link,
      phone,
      address_line1,
      tambol,
      city,
      state,
      zip
    } = this.state;
    const { messages } = this.props.intl;
    return (
      <Fragment>
        <AvForm onSubmit={this.onUpdateProfile}>
          <Row>
          <Colxx xxs={6} md={6}>
              <AvGroup>
                <Label>
                  <IntlMessages id="user.user_link" />
                </Label>
                <AvField
                  name="user_link"
                  id="user_link"
                  value={user_link}
                  onChange={event => this.handleChange(event)}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: messages["error.user_link"]
                    }
                  }}
                />
              </AvGroup>
            </Colxx>
            <Colxx xxs={6} md={6}>
              <AvGroup>
                <Label>
                  <IntlMessages id="user.phone" />
                </Label>
                <AvField
                  name="phone"
                  id="phone"
                  value={phone}
                  placeholder="###-###-####"
                  onChange={event => this.handleChange(event)}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: messages["error.phone"]
                    },
                    pattern: {
                      value: '^[-+()/0-9]{9,30}$',
                      errorMessage: messages["error.phone-format"]
                    }
                  }}
                />
              </AvGroup>
            </Colxx>
            <Colxx xxs={12} md={12}>
              <AvGroup>
                <Label>
                  <IntlMessages id="user.line1" />
                </Label>
                <AvField
                  name="address_line1"
                  id="address_line1"
                  value={address_line1}
                  onChange={event => this.handleChange(event)}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: messages["error.address"]
                    }
                  }}
                />
              </AvGroup>
            </Colxx>
            <Colxx xxs={6} lg={4}>
              <AvGroup>
                <Label>
                  <IntlMessages id="user.tambol" />
                </Label>
                <AvField
                  name="tambol"
                  id="tambol"
                  value={tambol}
                  onChange={event => this.handleChange(event)}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: messages["error.tambol"]
                    }
                  }}
                />
              </AvGroup>
            </Colxx>
            <Colxx xxs={6} lg={4}>
              <AvGroup>
                <Label>
                  <IntlMessages id="user.city" />
                </Label>
                <AvField
                  name="city"
                  id="city"
                  value={city}
                  onChange={event => this.handleChange(event)}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: messages["error.city"]
                    }
                  }}
                />
              </AvGroup>
            </Colxx>
            <Colxx xxs={6} lg={4}>
              <AvGroup>
                <Label>
                  <IntlMessages id="user.state" />
                </Label>
                <AvField
                  name="state"
                  id="state"
                  value={state}
                  onChange={event => this.handleChange(event)}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: messages["error.state"]
                    }
                  }}
                />
              </AvGroup>
            </Colxx>
            <Colxx xxs={12} lg={4}>
              <AvGroup>
                <Label>
                  <IntlMessages id="user.zip" />
                </Label>
                <AvField
                  name="zip"
                  id="zip"
                  value={zip}
                  onChange={event => this.handleChange(event)}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: messages["error.zip"]
                    }
                  }}
                />
              </AvGroup>
            </Colxx>
          </Row>
          <div className="d-flex justify-content-end align-items-center">
            <Button color="primary" className="btn-shadow" size="lg">
              <span className="text-one text-white">
                <IntlMessages id="user.continue-button" />
              </span>
            </Button>
          </div>
        </AvForm>
      </Fragment>
    );
  }
}
const mapStateToProps = ({ authUser }) => {
  const { user, loading, errorMessageID } = authUser;
  return { user, loading, errorMessageID };
};

const mapActionsToProps = { updateProfile };

export default connect(
  mapStateToProps,
  mapActionsToProps
)(injectIntl(BillingDetailForm));
