import React, { Component, Fragment } from "react";
import { injectIntl } from "react-intl";
import { Row, Label, Button } from "reactstrap";
import { AvForm, AvGroup, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import { connect } from "react-redux";

import IntlMessages from "../../helpers/IntlMessages";
import { Colxx } from "../../components/common/CustomBootstrap";
import { tdeeCalculate } from "../../redux/activities";

class TDEECalForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weight: 50,
      height: 150,
      age: 30,
      gender: "female",
      work_active_level: "passive",
      workout_day: 3,
      goal: "diet"
    };

    this.onUpdateProfile = this.onUpdateProfile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if ( newProps.user.healthProfile ) {
      const baseInfo = JSON.parse(newProps.user.healthProfile.base_info);
      if (Object.keys(baseInfo).length > 0) {
        const {
          weight,
          height,
          age,
          gender,
          work_active_level,
          workout_day,
          goal
        } = baseInfo;
        this.setState({
          weight,
          height,
          age,
          gender,
          work_active_level,
          workout_day,
          goal
        });
      }
    }
  }

  onUpdateProfile(event, errors) {
    if (errors.length === 0) {
      const {
        weight,
        height,
        age,
        gender,
        work_active_level,
        workout_day,
        goal
      } = this.state;
      const { user: {
        username,
        latestSubscription: {
          product_id,
          program_id
        }
      } } = this.props;
      const profile = {
        weight,
        height,
        age,
        gender,
        work_active_level,
        workout_day,
        user_id: username,
        product_id,
        program_id,
        goal
      };
      this.props.tdeeCalculate(profile);
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleNumberTypeChange(event) {
    const value = parseFloat(event.target.value);
    this.setState({
      [event.target.id]: value
    })
  }

  handleOptionChange(event) {
    event.persist();
    const {name, value} = event.target;
    this.setState({
      [name]: value 
    } );
  }


  render() {
    const {
      weight,
      height,
      age,
      gender,
      work_active_level,
      workout_day,
      goal
    } = this.state;
    const { messages } = this.props.intl;
    return (
      <Fragment>
        <AvForm onSubmit={this.onUpdateProfile}>
          <Row>
            <Colxx md={6}>
              <AvGroup>
                <Label>
                  <IntlMessages id="user.weight" />
                </Label>
                <AvField
                  type="number"
                  name="weight"
                  id="weight"
                  value={weight}
                  onChange={event => this.handleNumberTypeChange(event)}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: messages["error.weight"]
                    }
                  }}
                />
              </AvGroup>
            </Colxx>
            <Colxx md={6}>
              <AvGroup>
                <Label>
                  <IntlMessages id="user.height" />
                </Label>
                <AvField
                  type="number"
                  name="height"
                  id="height"
                  value={height}
                  onChange={event => this.handleNumberTypeChange(event)}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: messages["error.height"]
                    }
                  }}
                />
              </AvGroup>
            </Colxx>
            <Colxx md={6}>
              <AvGroup>
                <Label>
                  <IntlMessages id="user.age" />
                </Label>
                <AvField
                  type="number"
                  name="age"
                  id="age"
                  value={age}
                  onChange={event => this.handleNumberTypeChange(event)}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: messages["error.age"]
                    }
                  }}
                />
              </AvGroup>
            </Colxx>
            <Colxx lg={6}>
              <AvGroup>
                <Label>
                  <IntlMessages id="user.workout_day" />
                </Label>
                <AvField
                  type="number"
                  name="workout_day"
                  id="workout_day"
                  value={workout_day}
                  onChange={event => this.handleNumberTypeChange(event)}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: messages["error.workout_day"]
                    }
                  }}
                />
              </AvGroup>
            </Colxx>
            <Colxx lg={6}>
              <AvRadioGroup
                name="gender"
                id="gender"
                label={messages["user.gender"]}
                value={gender}
                required
                errorMessage={messages["error.gender"]}
                onChange={this.handleOptionChange}
              >
                <AvRadio
                  label={messages["label.female"]}
                  value="female"
                />
                <AvRadio
                  label={messages["label.male"]}
                  value="male"
                />
              </AvRadioGroup>
            </Colxx>
            <Colxx lg={6}>
              <AvRadioGroup
                name="work_active_level"
                id="work_active_level"
                label={messages["user.work_active_level"]}
                value={work_active_level}
                required
                errorMessage={messages["error.work_active_level"]}
                onChange={this.handleOptionChange}
              >
                <AvRadio
                  label={messages["label.passive-work"]}
                  value="passive"
                />
                <AvRadio
                  label={messages["label.active-work"]}
                  value="active"
                />
              </AvRadioGroup>
            </Colxx>
            <Colxx lg={6}>
              <AvRadioGroup
                name="goal"
                id="goal"
                label={messages["user.goal"]}
                value={goal}
                required
                errorMessage={messages["error.goal"]}
                onChange={this.handleOptionChange}
              >
                <AvRadio
                  label={messages["label.diet"]}
                  value="diet"
                />
                <AvRadio
                  label={messages["label.bulk"]}
                  value="bulk"
                />
              </AvRadioGroup>
            </Colxx>
          </Row>
          <div className="d-flex justify-content-end align-items-center">
            <Button color="primary" className="btn-shadow" size="lg">
              <span className="text-one text-white">
                <IntlMessages id="label.save" />
              </span>
            </Button>
          </div>
        </AvForm>
      </Fragment>
    );
  }
}
const mapStateToProps = ({ authUser }) => {
  const { user } = authUser;
  return { user };
};

const mapActionsToProps = { tdeeCalculate };

export default connect(
  mapStateToProps,
  mapActionsToProps
)(injectIntl(TDEECalForm));
