import React, {useState, useEffect} from "react";
import { Card, CardBody, CardTitle, Progress, Button, Row, Label } from "reactstrap";
import ReactTable from "react-table";
import {
  AvForm,
  AvField,
  AvGroup,
} from "availity-reactstrap-validation";
import uuidv4 from "uuid/v4";
import { useSelector } from "react-redux";

import IntlMessages from "../../helpers/IntlMessages";
import LoaderButton from "../../components/LoaderButton";
import Pagination from "../../components/DatatablePagination";
import { Colxx } from "../../components/common/CustomBootstrap";

function HomeworkFood({
  cardClass = "h-100",
  foodList,
  weeklyPlan,
  currentNutrients,
  updateActivity,
  loading
}) {
  const [isList, flipIsList] = useState(true);
  const [isNewFood, flipIsNewFood] = useState(true);
  const [food, setFood] = useState({
    id: "",
    name: "",
    carbohydrate: 0,
    protein: 0,
    fat: 0
  });
  const [foods, setFoods] = useState(foodList);
  useEffect(() => {
    setFoods(foodList);
  }, [foodList]);
  const user = useSelector(({authUser}) => authUser.user);
  const currentActivity = useSelector(({activities}) => activities.currentActivity);
  const baseInfo = JSON.parse(user.healthProfile.base_info) 
                      || 
                    {
                      goal: 'diet',
                      low_carb_first_day: 0,
                      low_carb_second_day: 3
                    };
  const {food: foodPlan} = weeklyPlan.plan;

  const useLowCarb = baseInfo.goal === "bulk" && 
                      (
                        (currentActivity.day - 1) === baseInfo.low_carb_first_day
                          ||
                        (currentActivity.day - 1) === baseInfo.low_carb_second_day
                      );

  const nutrients = [
    {
      name: "fat", 
      plan: useLowCarb && foodPlan.low_fat ? foodPlan.low_fat : foodPlan.fat, 
      actual: currentNutrients.fat
    },
    {
      name: "protein", 
      plan: foodPlan.protein, 
      actual: currentNutrients.protein
    },
    {
      name: "carbohydrate", 
      plan: useLowCarb && foodPlan.low_carb ? foodPlan.low_carb : foodPlan.carbohydrate,
      actual: currentNutrients.carbohydrate
    }
  ];

  function changeFormState() {
    flipIsNewFood(true);
    if (isList) {
      setFood({
        id: "",
        name: "",
        carbohydrate: 0,
        protein: 0,
        fat: 0
      });
    }
    flipIsList(isList => !isList);
  }

  function submitData(newFoods) {
    setFoods(newFoods);
    updateActivity(newFoods, "foods");
  }

  function updateFood(event, errors) {
    if (errors.length === 0) {
      if (isNewFood) {
        const newFood = { ...food, id: uuidv4() };
        foods.push(newFood);
        submitData(foods);
      } else {
        const editedFoods = foods.map(item =>
          item.id === food.id ? food : item
        );
        submitData(editedFoods);
      }
      flipIsList(isList => !isList);
    }
  }

  function editFood(param) {
    flipIsNewFood(false);
    setFood({ ...param });
    flipIsList(isList => !isList);
  }

  function removeFood(id) {
    const removedFoods = foods.filter(item => item.id !== id);
    submitData(removedFoods);
  }

  function handleChange(event) {
    const { name, value } = event.target;
    const foodValue = name === "name" ? value : parseFloat(value);
    setFood({ ...food, [name]: foodValue });
  }
  const columns = [
    {
      Header: "Name",
      accessor: "name",
      sortable: false,
      Cell: props => <p className="text-muted">{props.value}</p>
    },
    {
      Header: "C",
      accessor: "carbohydrate",
      width: 25,
      sortable: false,
      Cell: props => <p className="text-muted">{props.value}</p>
    },
    {
      Header: "P",
      accessor: "protein",
      width: 25,
      Cell: props => <p className="text-muted">{props.value}</p>
    },
    {
      Header: "F",
      accessor: "fat",
      width: 25,
      sortable: false,
      Cell: props => <p className="text-muted">{props.value}</p>
    },
    {
      Header: () => <div></div>,
      accessor: "id",
      width: 80,
      sortable: false,
      Cell: props => (
        <div className="text-center align-text-bottom">
          <Button
            color="empty"
            size="xs"
            onClick={() => editFood(props.original)}
          >
            <i className={"simple-icon-note align-text-center"} />
          </Button>
          <Button
            color="empty"
            size="xs"
            onClick={() => removeFood(props.value)}
          >
            <i
              className={"simple-icon-trash text-highlight align-text-center"}
            />
          </Button>
        </div>
      )
    }
  ];

  function renderList() {
    return (
      <div>
        <Card className={cardClass}>
          <CardBody>
            <CardTitle>
              <IntlMessages id="activities.food-title" /> 
              {
                useLowCarb && 
                <span className="goal-badge">(low-carb)</span>
              }
            </CardTitle>
            {nutrients.map((s, index) => {
              return (
                <div key={index} className="mb-4">
                  <p className="mb-2">
                    {s.name}
                    <span className="float-right text-muted">
                      {s.actual.toFixed(2)}/{s.plan}
                    </span>
                  </p>
                  <Progress value={(s.actual / s.plan) * 100} />
                </div>
              );
            })}
          </CardBody>
        </Card>

        <Card className={cardClass}>
          <CardBody>
            <ReactTable
              NoDataComponent={() => null}
              defaultPageSize={6}
              data={foods}
              columns={columns}
              minRows={0}
              showPageJump={false}
              showPageSizeOptions={false}
              PaginationComponent={Pagination}
            />
            <Button block color="primary" onClick={changeFormState}>
              <IntlMessages id="activities.food-submit" />
            </Button>
          </CardBody>
        </Card>
      </div>
    );
  }

  function renderForm() {
    return (
      <div>
        <Card className="mb-5">
          <CardBody>
            <h6 className="mb-4">
              <IntlMessages id="activities.food-submit" />
            </h6>

            <AvForm
              className="av-tooltip tooltip-label-right"
              onSubmit={updateFood}
            >
              <Row>
                <Colxx lg={12}>
                  <AvGroup>
                    <Label>
                      <IntlMessages id="activities.food-name" />
                    </Label>
                    <AvField
                      name="name"
                      id="name"
                      value={food.name}
                      onChange={handleChange}
                    />
                  </AvGroup>
                </Colxx>
                <Colxx lg={4} xs={6}>
                  <AvGroup>
                    <Label>
                      <IntlMessages id="activities.food-carb" />
                    </Label>
                    <AvField
                      name="carbohydrate"
                      id="carbohydrate"
                      type="number"
                      value={food.carbohydrate}
                      onChange={handleChange}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "โปรดกรอกคาร์บ"
                        },
                        min: {
                          value: 0,
                          errorMessage: "ขั้นต่ำต้องเท่ากับ 0"
                        },
                        max: {
                          value: 800,
                          errorMessage: "ห้ามเกิน 800"
                        }
                      }}
                    />
                  </AvGroup>
                </Colxx>
                <Colxx lg={4} xs={6}>
                  <AvGroup>
                    <Label>
                      <IntlMessages id="activities.food-protein" />
                    </Label>
                    <AvField
                      name="protein"
                      id="protein"
                      type="number"
                      value={food.protein}
                      onChange={handleChange}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "โปรดกรอกโปรตีน"
                        },
                        min: {
                          value: 0,
                          errorMessage: "ขั้นต่ำต้องเท่ากับ 0"
                        },
                        max: {
                          value: 500,
                          errorMessage: "ห้ามเกิน 500"
                        }
                      }}
                    />
                  </AvGroup>
                </Colxx>
                <Colxx lg={4} xs={6}>
                  <AvGroup>
                    <Label>
                      <IntlMessages id="activities.food-fat" />
                    </Label>
                    <AvField
                      name="fat"
                      id="fat"
                      type="number"
                      value={food.fat}
                      onChange={handleChange}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "โปรดกรอกไขมัน"
                        },
                        min: {
                          value: 0,
                          errorMessage: "ขั้นต่ำต้องเท่ากับ 0"
                        },
                        max: {
                          value: 200,
                          errorMessage: "ห้ามเกิน 200"
                        }
                      }}
                    />
                  </AvGroup>
                </Colxx>
              </Row>

              <LoaderButton
                color="primary"
                block
                status={loading ? "processing" : "default"}
              >
                <IntlMessages id="activities.submit" />
              </LoaderButton>
            </AvForm>
          </CardBody>
        </Card>
      </div>
    );
  }

  if (isList) {
    return renderList();
  } else {
    return renderForm();
  }
};
export default HomeworkFood;
