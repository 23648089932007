import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Auth, API } from 'aws-amplify';

import { types as authTypes } from './auth';

/* ACTION Section */
export const types = {
    CHANGE_AUTH_STATE: 'CHANGE_AUTH_STATE',
    CHECK_USER: 'CHECK_USER',
    CHECK_USER_EXISTED: 'CHECK_USER_EXISTED',
    CHECK_USER_NEW: 'CHECK_USER_NEW',
    UPDATE_PROFILE: 'UPDATE_PROFILE',
    UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
    UPDATE_PROFILE_ERROR: 'UPDATE_PROFILE_ERROR',
    UPDATE_GENERAL_PROFILE: 'UPDATE_GENERAL_PROFILE',
    UPDATE_GENERAL_PROFILE_SUCCESS: 'UPDATE_GENERAL_PROFILE_SUCCESS',
    UPDATE_GENERAL_PROFILE_ERROR: 'UPDATE_GENERAL_PROFILE_ERROR',
    UPDATE_DB_USER: 'UPDATE_DB_USER',
    UPDATE_DB_USER_SUCCESS: 'UPDATE_DB_USER_SUCCESS',
    UPDATE_DB_USER_ERROR: 'UPDATE_DB_USER_ERROR',
    SUBSCRIPTION: 'SUBSCRIPTION',
    SUBSCRIPTION_SUCCESS: 'SUBSCRIPTION_SUCCESS',
    SUBSCRIPTION_FAIL: 'SUBSCRIPTION_FAIL',
    DIGITAL_PAYING: 'DIGITAL_PAYING',
    DIGITAL_PAYING_SUCCESS: 'DIGITAL_PAYING_SUCCESS',
    DIGITAL_PAYING_FAIL: 'DIGITAL_PAYING_FAIL',
    EXECUTE_PAYMENT: 'EXECUTE_PAYMENT',
    EXECUTE_PAYMENT_SUCCESS: 'EXECUTE_PAYMENT_SUCCESS',
    EXECUTE_PAYMENT_FAIL: 'EXECUTE_PAYMENT_FAIL',
    GET_INVOICES: 'GET_INVOICES',
    GET_INVOICES_SUCCESS: 'GET_INVOICES_SUCCESS',
    GET_INVOICES_FAIL: 'GET_INVOICES_FAIL',
}

export const changeAuthState = (auth_state) => ({
    type: types.CHANGE_AUTH_STATE,
    payload: auth_state
});

export const checkUser = (username) => ({
    type: types.CHECK_USER,
    payload: username.toLowerCase()
});

export const updateProfile = (profile) => ({
    type: types.UPDATE_PROFILE,
    payload: profile
});

export const subscription = (paymentData) => ({
    type: types.SUBSCRIPTION,
    payload: paymentData
});

export const digitalPaying = (items, description, history) => ({
    type: types.DIGITAL_PAYING,
    payload: { 
        paymentItems: {
            base_url: window.location.origin,
            items,
            description
        }, history
    }
});
export const executePayment = (paymentId, execute_json) => ({
    type: types.EXECUTE_PAYMENT,
    payload: { 
      paymentId,
      execute_json: JSON.stringify(execute_json)
    }
});

export const getInvoices = (user_id) => ({
    type: types.GET_INVOICES,
    payload: user_id
});

/* END OF ACTION Section */

/* SAGA Section */

const checkUserSagaAsync = async (username) => {
    try {
        const apiReturn = await API.get("user", "/user", {
            queryStringParameters: {
                username
            }
        });
        return apiReturn
        
    } catch (error) {
        return error;
    }
}

function* checkUserSaga({ payload }) {
    try {
        const apiReturn = yield call(checkUserSagaAsync, payload);
        if(apiReturn.results) {
            yield put({ 
                type: types.CHECK_USER_EXISTED
            });
        } else {
            yield put({
                type: types.CHECK_USER_NEW
            });
        }
    } catch (error) {
        yield put({
            type: types.CHECK_USER_NEW
        });
    }
}

const updateDBUserSagaAsync = async ({
  user_id,
  user_link,
  phone,
  address
}) => {
    try {
      const apiReturn = await API.post("user", "/member", { 
        body: { 
          user_id,
          phone,
          fb_link: escape(unescape(user_link)),
          address: JSON.stringify(address)
        } 
      });
      return apiReturn;
    } catch (error) {
        return error
    }
}

function* updateDBUserSaga({ payload }) {
    try {
        yield call(updateDBUserSagaAsync, payload);

        yield put({
            type: types.UPDATE_DB_USER_SUCCESS
        });
    } catch (error) {
        yield put({
            type: types.UPDATE_DB_USER_ERROR,
            payload: error
        });
    }
}

const updateGeneralProfileSagaAsync = async (payload) => {
  try {
      let user = await Auth.currentAuthenticatedUser(); 
      await Auth.updateUserAttributes(user, {
        ...payload
      });
      user = await Auth.currentAuthenticatedUser({
          bypassCache: true
      });
      return user;
  } catch (error) {
      return error
  }
}

function* updateGeneralProfileSaga({ payload }) {
  try {
      const user = yield call(updateGeneralProfileSagaAsync, payload);
      
      yield put({
          type: authTypes.UPDATE_USER,
          payload: user,
      });

      yield put({
          type: types.UPDATE_GENERAL_PROFILE_SUCCESS
      });

  } catch (error) {
      yield put({
          type: types.UPDATE_PROFILE_ERROR,
          payload: error.message
      });
  }
}

const updateProfileSagaAsync = async ({
  user_link,
  phone,
  address
}) => {
    try {
        let user = await Auth.currentAuthenticatedUser(); 
        await Auth.updateUserAttributes(user, {
          'custom:user_link': user_link,
          'custom:phone': phone,
          'address': JSON.stringify(address)
        });
        user = await Auth.currentAuthenticatedUser({
            bypassCache: true
        });
        return user;
    } catch (error) {
        return error
    }
}

function* updateProfileSaga({ payload }) {
    try {
        const user = yield call(updateProfileSagaAsync, payload);
        yield put({
            type: authTypes.UPDATE_USER,
            payload: user,
        });

        yield put({
            type: types.UPDATE_PROFILE_SUCCESS
        });

        yield put({
          type: types.UPDATE_DB_USER,
          payload: {
            user_id: user.username,
            user_link: payload.user_link,
            phone: payload.phone,
            address: payload.address
          }
        })
    } catch (error) {
        yield put({
            type: types.UPDATE_PROFILE_ERROR,
            payload: error.message
        });
    }
}

const subscriptionSagaAsync = async(payload) => {
    try {
        const apiReturn = await API.post("user", "/subscription", { body: { ...payload } });
        return apiReturn;
    } catch (e) {
        return e;
    }
}

function* subscriptionSaga({ payload }) {
    try {
        yield call(subscriptionSagaAsync, payload);
        yield put({
            type: types.SUBSCRIPTION_SUCCESS
        })
    } catch (error) {
        yield put({
            type: types.SUBSCRIPTION_FAIL
        })
    }
}

function* subscriptionSuccessSaga() {
    yield put({
        type: authTypes.RETRIEVE_USER
    })
}

const digitalPayingSagaAsync = async (payload) => {
    try {
        const apiReturn = await API.post("user", "/user/payment", { body: { ...payload } });
        return apiReturn;
    } catch (e) {
        return e;
    }
}

function* digitalPayingSaga({ payload }) {
    try {
        const apiReturn = yield call(digitalPayingSagaAsync, payload.paymentItems);
        if(apiReturn.results) {
            const approval_url = apiReturn.results.links.find(elem => elem.rel === "approval_url");
            localStorage.setItem('paypal_info', JSON.stringify(apiReturn.results));

            yield put({
                type: types.DIGITAL_PAYING_SUCCESS
            });
            window.location = approval_url.href;
        }
    } catch (error) {
        yield put( {
            type: types.DIGITAL_PAYING_FAIL
        });

    }
}

const executePaymentSagaAsync = async (payload) => {
  try {
      const apiReturn = await API.post("user", "/user/execute_payment", { 
        body: { 
          ...payload } 
      });
      return apiReturn;
  } catch (e) {
      return e;
  }
}

function* executePaymentSaga({ payload }) {
  try {
      const apiReturn = yield call(executePaymentSagaAsync, payload);
      if(apiReturn.results) {
          yield put({
              type: types.EXECUTE_PAYMENT_SUCCESS
          });
      }
  } catch (error) {
      yield put( {
          type: types.EXECUTE_PAYMENT_FAIL
      });

  }
}

const getInvoicesSagaAsync = async (user_id) => {
    try {
        const apiReturn = await API.get("user", "/user/invoices", {
            queryStringParameters: {
                user_id
            }
        });
        return apiReturn;
    } catch (error) {
        return error;
    }
}

function* getInvoicesSaga({ payload }) {
    try {
        const apiReturn = yield call(getInvoicesSagaAsync, payload);
        if (apiReturn.results) {
            yield put({
                type:types.GET_INVOICES_SUCCESS,
                payload: apiReturn.results
            })
        } else {
            yield put({
                type:types.GET_INVOICES_FAIL
            });
        }
    } catch (error) {
        yield put({
            type:types.GET_INVOICES_FAIL
        });
    }
}

export function* watchCheckUser() {
    yield takeEvery(types.CHECK_USER, checkUserSaga);
}

export function* watchUpdateAddress() {
    yield takeEvery(types.UPDATE_PROFILE, updateProfileSaga);
}

export function* watchUpdateGeneralProfile() {
    yield takeEvery(types.UPDATE_GENERAL_PROFILE, updateGeneralProfileSaga);
}

export function* watchUpdateDBUser() {
  yield takeEvery(types.UPDATE_DB_USER, updateDBUserSaga);
}

export function* watchSubscription() {
    yield takeEvery(types.SUBSCRIPTION, subscriptionSaga);
}
export function* watchSubscriptionSuccess() {
    yield takeEvery(types.SUBSCRIPTION_SUCCESS, subscriptionSuccessSaga);
}
export function* watchDigitalPaying() {
    yield takeEvery(types.DIGITAL_PAYING, digitalPayingSaga)
}
export function* watchExecutePayment() {
    yield takeEvery(types.EXECUTE_PAYMENT, executePaymentSaga)
}
export function* watchGetInvoices() {
    yield takeEvery(types.GET_INVOICES, getInvoicesSaga);
}
export function* saga() {
    yield all([
        fork(watchCheckUser),
        fork(watchUpdateAddress),
        fork(watchUpdateGeneralProfile),
        fork(watchUpdateDBUser),
        fork(watchSubscription),
        fork(watchSubscriptionSuccess),
        fork(watchDigitalPaying),
        fork(watchExecutePayment),
        fork(watchGetInvoices),
    ]);
}

/* END OF SAGA Section */

/* REDUCER Section */

const INIT_STATE = {
    authState: "register",
    isNewUser: true,
    loading: false,
    digitalTransaction: null,
    digitalTransactionDateTime: null,
    invoices: [],
    status: "default",
    error: null,
};

export function reducer(state = INIT_STATE, action) {
    switch (action.type) {
        case types.CHANGE_AUTH_STATE:
            return { ...state, authState: action.payload }
        case types.CHECK_USER_EXISTED:
            return { ...state, isNewUser: false }
        case types.CHECK_USER_NEW:
            return { ...state, isNewUser: true }
        case types.GET_INVOICES:
        case types.EXECUTE_PAYMENT:
        case types.DIGITAL_PAYING:
        case types.SUBSCRIPTION:
            return { ...state, status: "processing", loading: true }
        case types.EXECUTE_PAYMENT_SUCCESS:
          return {
            ...state,
            status: "success",
            loading: false,
            error: null
          }
        case types.EXECUTE_PAYMENT_FAIL:
          return {
            ...state,
            status: "fail",
            loading: false,
            error: action.payload.error
          }
        case types.DIGITAL_PAYING_SUCCESS:
        case types.DIGITAL_PAYING_FAIL:
            return { ...state, loading: false }
        case types.SUBSCRIPTION_SUCCESS:
            return { 
                ...state, 
                status: "success",
                loading: false,
            }
        case types.GET_INVOICES_SUCCESS:
            return {
                ...state,
                status: "success",
                invoices: action.payload
            }
        case types.SUBSCRIPTION_FAIL:
        case types.GET_INVOICES_FAIL:
            return {
                ...state,
                status: "fail",
                loading: false,
            }
        default: 
            return { ...state };
    }
}

/* END OF REDUCER Section */
