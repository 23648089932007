import React from "react";
import { Nav, NavItem, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import IntlMessages from "../../helpers/IntlMessages";

const DaysForTraining = params => {
  const { changeDay, currentDay, weight_program } = params;
  return (
    <div >
        <Nav pills className="nav-fill">
          {weight_program.map((item, index) =>
            <NavItem key={index}>
              <Button
                outline={item.is_play ? false : currentDay !== item.day}
                color={item.is_play ? "success" : "primary"}
                className="default btn-block mb-2"
                id={`day${item.day}`}
                name={`day${item.day}`}
                onClick={() => changeDay(item.day)}
              >
                <div>
                  {item.is_play && <FontAwesomeIcon icon={faCheckCircle} size="1x" color="white" />}
                  {item.is_play && ' '}
                  <IntlMessages id="label.day" /> {item.day}
                </div>
              </Button>
            </NavItem>
          )}
        </Nav>
    </div>
  );
};

export default DaysForTraining;
