import React, {useState, useEffect} from "react";
import { Card, CardBody, CardTitle, Button, Row, Label } from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
} from "availity-reactstrap-validation";

import IntlMessages from "../../helpers/IntlMessages";
import LoaderButton from "../../components/LoaderButton";
import { Colxx } from "../../components/common/CustomBootstrap";

function WeeklyMeasurement({
  cardClass = "h-100",
  sourceMeasurement,
  updateMeasurement,
  loading,
  messages
}) {
  const [isStatic, flipIsStatic] = useState(true);
  const [measurement, setMeasurement] = useState({
    arm: 0,
    chest: 0,
    navel: 0,
    thigh: 0,
    above_navel: 0,
    below_navel: 0
  });
  
  useEffect(() => {
    setMeasurement(sourceMeasurement);
  }, [sourceMeasurement]);

  function changeFormState() {
    flipIsStatic(isStatic => !isStatic);
  }

  function submitData(event, errors) {
    if (errors.length === 0) {
      updateMeasurement(measurement);
    }
    flipIsStatic(isStatic => !isStatic);
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setMeasurement({ ...measurement, [name]: parseFloat(value) });
  }

  function renderFront() {
    const {
      arm,
      chest,
      navel,
      thigh,
      above_navel,
      below_navel
    } = measurement;
    return (
      <div>
        <Card className={cardClass}>
          <CardBody>
            <CardTitle>
              <IntlMessages id="activities.measurement-title" />
            </CardTitle>
            <div>
              <p className="text-one">
                {messages['activities.measurement-arm']} {arm} {messages['activities.measurement-cm']}
              </p>
              <p className="text-one">
                {messages['activities.measurement-chest']} {chest} {messages['activities.measurement-cm']}
              </p>
              <p className="text-one">
                {messages['activities.measurement-above-navel']} {above_navel} {messages['activities.measurement-cm']}
              </p>
              <p className="text-one">
                {messages['activities.measurement-navel']} {navel} {messages['activities.measurement-cm']}
              </p>
              <p className="text-one">
                {messages['activities.measurement-below-navel']} {below_navel} {messages['activities.measurement-cm']}
              </p>
              <p className="text-one">
                {messages['activities.measurement-thigh']} {thigh} {messages['activities.measurement-cm']}
              </p>
            </div>
            <Button block color="primary" onClick={changeFormState}>
              <IntlMessages id="activities.measurement-submit" />
            </Button>
          </CardBody>
        </Card>
      </div>
    );
  }

  function renderForm() {
    const {
      arm,
      chest,
      navel,
      thigh,
      above_navel,
      below_navel
    } = measurement;
    return (
      <div>
        <Card className="mb-5">
          <CardBody>
            <h6 className="mb-4">
              <IntlMessages id="activities.measurement-submit" />
            </h6>

            <AvForm
              className="av-tooltip tooltip-label-right"
              onSubmit={submitData}
            >
              <Row>
                <Colxx lg={12}>
                  <AvGroup>
                    <Label>
                      <IntlMessages id="activities.measurement-arm" />
                    </Label>
                    <AvField
                      name="arm"
                      id="arm"
                      type="number"
                      placeholder="หน่วยเป็น cm."
                      value={arm}
                      onChange={handleChange}
                    />
                  </AvGroup>
                </Colxx>
                <Colxx lg={12}>
                  <AvGroup>
                    <Label>
                      <IntlMessages id="activities.measurement-chest" />
                    </Label>
                    <AvField
                      name="chest"
                      id="chest"
                      type="number"
                      placeholder="หน่วยเป็น cm."
                      value={chest}
                      onChange={handleChange}
                    />
                  </AvGroup>
                </Colxx>
                <Colxx lg={12}>
                  <AvGroup>
                    <Label>
                      <IntlMessages id="activities.measurement-above-navel" />
                    </Label>
                    <AvField
                      name="above_navel"
                      id="above_navel"
                      type="number"
                      placeholder="หน่วยเป็น cm."
                      value={above_navel}
                      onChange={handleChange}
                    />
                  </AvGroup>
                </Colxx>
                <Colxx lg={12}>
                  <AvGroup>
                    <Label>
                      <IntlMessages id="activities.measurement-navel" />
                    </Label>
                    <AvField
                      name="navel"
                      id="navel"
                      type="number"
                      placeholder="หน่วยเป็น cm."
                      value={navel}
                      onChange={handleChange}
                    />
                  </AvGroup>
                </Colxx>
                <Colxx lg={12}>
                  <AvGroup>
                    <Label>
                      <IntlMessages id="activities.measurement-below-navel" />
                    </Label>
                    <AvField
                      name="below_navel"
                      id="below_navel"
                      type="number"
                      placeholder="หน่วยเป็น cm."
                      value={below_navel}
                      onChange={handleChange}
                    />
                  </AvGroup>
                </Colxx>
                <Colxx lg={12}>
                  <AvGroup>
                    <Label>
                      <IntlMessages id="activities.measurement-thigh" />
                    </Label>
                    <AvField
                      name="thigh"
                      id="thigh"
                      type="number"
                      placeholder="หน่วยเป็น cm."
                      value={thigh}
                      onChange={handleChange}
                    />
                  </AvGroup>
                </Colxx>
              </Row>

              <LoaderButton
                color="primary"
                block
                status={loading ? "processing" : "default"}
              >
                <IntlMessages id="activities.submit" />
              </LoaderButton>
            </AvForm>
          </CardBody>
        </Card>
      </div>
    );
  }

  if (isStatic) {
    return renderFront();
  } else {
    return renderForm();
  }
};
export default WeeklyMeasurement;
