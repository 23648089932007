import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { Auth, API } from "aws-amplify";
import moment from "moment";
import { productName } from "../constants/defaultValues";

/* ACTION Section */

export const types = {
  LOGIN_USER: "LOGIN_USER",
  LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
  LOGIN_USER_FAIL: "LOGIN_USER_FAIL",
  REGISTER_USER: "REGISTER_USER",
  REGISTER_USER_SUCCESS: "REGISTER_USER_SUCCESS",
  REGISTER_USER_FAIL: "REGISTER_USER_FAIL",
  ADD_DB_USER: "ADD_DB_USER",
  ADD_DB_USER_SUCCESS: "ADD_DB_USER_SUCCESS",
  ADD_DB_USER_FAIL: "ADD_DB_USER_FAIL",
  LOGOUT_USER: "LOGOUT_USER",
  RETRIEVE_USER: "RETRIEVE_USER",
  RETRIEVE_USER_SUCCESS: "RETRIEVE_USER_SUCCESS",
  RETRIEVE_USER_FAIL: "RETRIEVE_USER_FAIL",
  UPDATE_USER: "UPDATE_USER",
  AUTHENTICATED_SUCCESS: "AUTHENTICATED_SUCCESS",
  AUTHORIZED_SUCCESS: "AUTHORIZED_SUCCESS",
  AUTHORIZED_FAIL: "AUTHORIZED_FAIL",
  GET_PROGRAM: "GET_PROGRAM",
  GET_PROGRAM_SUCCESS: "GET_PROGRAM_SUCCESS",
  GET_PROGRAM_FAIL: "GET_PROGRAM_FAIL",
  GET_SUBSCRIBE: "GET_SUBSCRIBE",
  GET_NEW_PASSWORD: "GET_NEW_PASSWORD",
  GET_NEW_PASSWORD_SUCCESS: "GET_NEW_PASSWORD_SUCCESS",
  GET_NEW_PASSWORD_FAIL: "GET_NEW_PASSWORD_FAIL",
  SUBMIT_NEW_PASSWORD: "SUBMIT_NEW_PASSWORD",
  SUBMIT_NEW_PASSWORD_SUCCESS: "SUBMIT_NEW_PASSWORD_SUCCESS",
  SUBMIT_NEW_PASSWORD_FAIL: "SUBMIT_NEW_PASSWORD_FAIL",
  UPDATE_BASE_INFO: 'UPDATE_BASE_INFO',
  UPDATE_BASE_INFO_SUCCESS: 'UPDATE_BASE_INFO_SUCCESS',
  UPDATE_BASE_INFO_FAIL: 'UPDATE_BASE_INFO_FAIL',
};

export const loginUser = user => ({
  type: types.LOGIN_USER,
  payload: { user }
});
export const loginUserSuccess = user => ({
  type: types.LOGIN_USER_SUCCESS,
  payload: user
});
export const loginUserFail = () => ({
  type: types.LOGIN_USER_FAIL
});

export const registerUser = (user, history, isLoginImmediately = false, extURL) => ({
  type: types.REGISTER_USER,
  payload: { user: {
    ...user,
    email: user.email.toLowerCase()
  }, history, isLoginImmediately, extURL }
});
export const registerUserSuccess = user => ({
  type: types.REGISTER_USER_SUCCESS,
  payload: user
});
export const registerUserFail = errorMessageID => ({
  type: types.REGISTER_USER_FAIL,
  payload: errorMessageID
});

export const logoutUser = () => ({
  type: types.LOGOUT_USER
});

export const retrieveUser = () => ({
  type: types.RETRIEVE_USER
});

export const retrieveUserSuccess = user => ({
  type: types.RETRIEVE_USER_SUCCESS,
  payload: user
});

export const retrieveUserFail = () => ({
  type: types.RETRIEVE_USER_FAIL
});

export const getProgram = (product_id, product_type) => ({
  type: types.GET_PROGRAM,
  payload: {product_id, product_type}
});

export const getNewPassword = email => ({
  type: types.GET_NEW_PASSWORD,
  payload: email
});

export const submitNewPassword = (email, code, password) => ({
  type: types.SUBMIT_NEW_PASSWORD,
  payload: { email, code, password }
});

export const updateBaseInfo = (baseInfo) => (
  {
    type: types.UPDATE_BASE_INFO,
    payload: baseInfo
  }
)

/* END OF ACTION Section */

/* SAGA Section */

const setUserGroup = authUser => {
    const u = ['user'];
    const group = (
        (
            (
              (authUser.signInUserSession || u).idToken || u
            )
            .payload || u
        )['cognito:groups'] || u)[0];
    return { ...authUser, group}
}

const loginWithEmailPasswordAsync = async (email, password) =>
  await Auth.signIn(email, password)
    .then(authUser => authUser)
    .catch(error => error);

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
    if (!loginUser.message) {
      yield put(loginUserSuccess(setUserGroup(loginUser)));
    } else {
      yield put(loginUserFail());
    }
  } catch (error) {
    yield put(loginUserFail());
  }
}

const registerWithEmailPasswordAsync = async (
  given_name,
  family_name,
  email,
  password
) =>
  await Auth.signUp({
    username: email,
    password,
    attributes: {
      given_name,
      family_name
    }
  })
    .then(data => data)
    .catch(error => error);

const registerWithDBAsync = async (
  user_id,
  email,
  first_name,
  last_name
) => {
  try {
    const apiReturn = await API.post("user", "/member", { 
      body: { 
        user_id,
        email,
        first_name,
        last_name
       } 
    });
    return apiReturn;
  } catch (error) {
    return { error, messsage: error.message };
  }
}

function* registerWithEmailPassword({ payload }) {
  const {
    user: { firstname, lastname, email, password },
    history,
    isLoginImmediately,
    extURL
  } = payload;

  try {
    const registerResult = yield call(
      registerWithEmailPasswordAsync,
      firstname,
      lastname,
      email,
      password
    );
    if (!registerResult.code) {
      yield put(registerUserSuccess(registerResult));
      if(registerResult.userSub) {
        yield put({
          type: types.ADD_DB_USER,
          payload: {
            user_id: registerResult.userSub,
            email,
            firstname,
            lastname,
            extURL
          }
        })
      }
      if (isLoginImmediately) {
        yield put(loginUser({ email, password }));
      } else {
        if (!extURL) {
          history.push("/login");
        }
      }
    } else {
      // catch throw
      const errorCode = registerResult.code;
      if (errorCode === "UsernameExistsException") {
        yield put(registerUserFail("error.username-existed"));
      } else if (errorCode === "InvalidPasswordException") {
        yield put(registerUserFail("error.invalid-password"));
      } else {
        yield put(registerUserFail("error.general"));
      }
    }
  } catch (error) {
    // catch throw
    console.log("reason of registerWithEmailPassword error:", error);
    yield put(registerUserFail("error.general"));
  }
}

function* registerWithDB({ payload }) {
  const {
    user_id, firstname, lastname, email, extURL
  } = payload;

  try {
    const result = yield call(
      registerWithDBAsync,
      user_id,
      email,
      firstname,
      lastname
    );
    if (result.code) {
      yield put({
        type: types.ADD_DB_USER_SUCCESS
      });
    } else {
      // catch throw
      yield put({
        type: types.ADD_DB_USER_SUCCESS,
        payload: result
      });
    }
    if (extURL) {
      console.log("going to ", extURL);
      window.location.replace(extURL);
    }
  } catch (error) {
    // catch throw
      yield put({
        type: types.ADD_DB_USER_SUCCESS,
        payload: error
      });
  }
}

const logoutAsync = async () => {
  await Auth.signOut()
    .then(authUser => authUser)
    .catch(error => error);
};

function* logout() {
  try {
    yield call(logoutAsync);
    remLSPref("cognito");
  } catch (error) {}
}

function remLSPref(pref, newName) {
  for (var key in localStorage) {
    if (key.toLowerCase().indexOf(pref.toLowerCase()) >= 0) {
      if (key !== newName) {
        localStorage.removeItem(key);
      }
    }
  }
}

const retrieveAuthUserAsync = async () =>
  await Auth.currentAuthenticatedUser()
    .then(authUser => authUser)
    .catch(error => {
      return { error };
    });

function* retrieveAuthUser() {
  try {
    const authUser = yield call(retrieveAuthUserAsync);
    if (!authUser.error) {
      yield put(retrieveUserSuccess(setUserGroup(authUser)));
    } else {
      // catch throw
      yield put(retrieveUserFail());
    }
  } catch (error) {
    yield put(retrieveUserFail());
  }
}

const getLatestSubscription = subData => {
  if (!subData) {
    return null;
  } else if (subData.length <= 0) {
    return null;
  }

  const latestSub = subData.reduce((acc, cur) =>
    moment(acc.program_expire_date).isAfter(cur.program_expire_date, 'day') ? acc : cur
  );

  return latestSub;
};

const calculateSubscribeStatus = (healthProfile, latestSubscription) => {
  /* subscription status including 
  { "new", "expired", "subscribed-wating", "subscribed"} */
  let subscribeStatus = "new";
  let readyForRenew = false;
  let validForReset = false;
  let validForResetDate = moment();
  if(!latestSubscription) {
    return {subscribeStatus, readyForRenew, validForReset, validForResetDate};
  }
  const { payment_status } = latestSubscription;
  if (healthProfile) {
    const { start_date, expire_date } = healthProfile;
    const convertStartDate = moment(start_date).add(-7, 'hours');
    const convertExpireDate = moment(expire_date).add(-7, 'hours');
    validForResetDate = moment(start_date).add(6, 'days');
    if (convertExpireDate.isSameOrAfter(moment(), 'day')) {
        subscribeStatus = moment().isSameOrAfter(convertStartDate, 'day')
          ? "subscribed"
          : "subscribed-waiting";
    
      if( subscribeStatus === "subscribed" ) {
        readyForRenew = convertExpireDate.diff(moment(), 'days') <= 7;
        validForReset = validForResetDate.isSameOrAfter(moment(), 'day');
      }
    } else {
      subscribeStatus = (payment_status.toLowerCase() === "pending")
                          ? "pending"
                          : "expired";
    }
  } else {
    if (payment_status.toLowerCase() === "pending") {
      subscribeStatus = "pending";
    }
  }

  return {subscribeStatus, readyForRenew, validForReset, validForResetDate};
}

const getSubSagaAsync = async user => {
  try {
    const apiReturn = await API.get("user", "/user/subscriptions", {
      queryStringParameters: {
        user_id: user.username,
        product_id: productName
      }
    });
    return apiReturn;
  } catch (error) {
    return { error, messsage: error.message };
  }
};

const getProfileAsync = async user => {
  try {
    const apiReturn = await API.get("user", "/user/profile", {
      queryStringParameters: {
        user_id: user.username,
        product_id: productName
      }
    });
    return apiReturn;
  } catch (error) {
    return { error, messsage: error.message };
  }
}

function* getSubscriptionSaga({ payload }) {
  try {
    const subData = yield call(getSubSagaAsync, payload);
    const profileData = yield call(getProfileAsync, payload);
    let subscribeStatus = "new";
    let readyForRenew = false;
    let validForReset = false;
    let validForResetDate = moment();
    if (!profileData.results || !subData.results) {
      yield put({
        type: types.AUTHORIZED_FAIL,
        payload: { subscriptions: [], latestSubscription: null, subscribeStatus, readyForRenew, validForReset, validForResetDate }
      });
    }
    /* subscription status including 
    { "new", "expired", "pending", "subscribed-wating", "subscribed"} */
    const calcSubStatus = calculateSubscribeStatus(profileData.results[0], subData.results[0]);
    subscribeStatus = calcSubStatus.subscribeStatus;
    readyForRenew = calcSubStatus.readyForRenew;
    validForReset = calcSubStatus.validForReset;
    validForResetDate = calcSubStatus.validForResetDate;
    let latestSubscription = getLatestSubscription(subData.results);
    //yield put(getProgram(productName));
    
    switch (subscribeStatus) {
      case "subscribed":
      case "subscribed-waiting":
        yield put({
          type: types.AUTHORIZED_SUCCESS,
          payload: {
            subscriptions: subData.results,
            subscribeStatus,
            latestSubscription,
            readyForRenew,
            validForReset,
            validForResetDate,
            healthProfile: profileData.results[0]
          }
        });
        break;
      case "expired":
        yield put({
          type: types.AUTHORIZED_FAIL,
          payload: {
            subscriptions: subData.results,
            subscribeStatus,
            readyForRenew,
            latestSubscription,
            healthProfile: profileData.results[0]
          }
        });
        break;
      case "new":
        yield put({
          type: types.AUTHORIZED_FAIL,
          payload: {
            subscriptions: subData.results,
            subscribeStatus,
            readyForRenew,
            latestSubscription: null,
            healthProfile: null
          }
        });
        break;
      default:
        yield put({
          type: types.AUTHORIZED_FAIL,
          payload: {
            subscriptions: subData.results,
            subscribeStatus,
            latestSubscription: null,
            healthProfile: null
          }
        });
        break;
    }
  } catch (error) {
    yield put({
      type: types.AUTHORIZED_FAIL,
      payload: { subscriptions: [], latestSubscription: null }
    });
  }
}

const getProgramSagaAsync = async ({product_id, product_type}) => {
  try {
    const apiReturn = await API.get("user", "/user/program", {
      queryStringParameters: {
        product_id,
        product_type
      }
    });
    return apiReturn;
  } catch (error) {
    return { error, messsage: error.message };
  }
};

function* getProgramSaga({ payload }) {
  try {
    const apiReturn = yield call(getProgramSagaAsync, payload);
    yield put({
      type: types.GET_PROGRAM_SUCCESS,
      payload: apiReturn.results
    });
  } catch (error) {
    throw error;
  }
}

const getNewPasswordSagaAsync = async email => {
  try {
    const apiReturn = await Auth.forgotPassword(email);
    return apiReturn;
  } catch (error) {
    return error;
  }
};

function* getNewPasswordSaga({ payload }) {
  try {
    yield call(getNewPasswordSagaAsync, payload);
    yield put({
      type: types.GET_NEW_PASSWORD_SUCCESS
    });
  } catch (error) {
    yield put({
      type: types.GET_NEW_PASSWORD_FAIL
    });
  }
}

const submitNewPasswordSagaAsync = async ({ email, code, password }) => {
  try {
    const apiReturn = await Auth.forgotPasswordSubmit(email, code, password);
    return apiReturn;
  } catch (error) {
    return error;
  }
};

function* submitNewPasswordSaga({ payload }) {
  try {
    yield call(submitNewPasswordSagaAsync, payload);
    yield put({
      type: types.SUBMIT_NEW_PASSWORD_SUCCESS
    });
  } catch (error) {
    yield put({
      type: types.SUBMIT_NEW_PASSWORD_FAIL
    });
  }
}

const updateBaseInfoSagaAsync = async (base_info) => {
  try {
    const apiReturn = await API.put("activity", "/base_info", {
      body: {
        ...base_info
      }
    });
    return apiReturn;
  } catch (error) {
    console.log("inside updateBaseInfoSagaAsync error reason:", error)
    return error;
  }
};

function* updateBaseInfoSaga({ payload }) {
  try {
    const {
      product_id,
      user_id,
      base_info: {
        low_carb_first_day,
        low_carb_second_day
      }
    } = payload;
    const newPayload = {
      product_id,
      user_id,
      raw_data: [
        {name: "low_carb_first_day", value: low_carb_first_day},
        {name: "low_carb_second_day", value: low_carb_second_day}
      ]
    }
    yield call(updateBaseInfoSagaAsync, newPayload);
    const newBaseInfo = JSON.stringify(payload.base_info);

    yield put({
      type: types.UPDATE_BASE_INFO_SUCCESS,
      payload: newBaseInfo
    });
  } catch (error) {
    yield put({
      type: types.UPDATE_BASE_INFO_FAIL
    });
    console.log("error in tdeeResetSaga with reason:", error);
  }
}

export function* watchRegisterUser() {
  yield takeEvery(types.REGISTER_USER, registerWithEmailPassword);
}

export function* watchRegisterUserDB() {
  yield takeEvery(types.ADD_DB_USER, registerWithDB);
}

export function* watchLoginUser() {
  yield takeEvery(types.LOGIN_USER, loginWithEmailPassword);
}

export function* watchLogoutUser() {
  yield takeEvery(types.LOGOUT_USER, logout);
}

export function* watchRetrieveUser() {
  yield takeEvery(types.RETRIEVE_USER, retrieveAuthUser);
}

export function* watchLoginSuccess() {
  yield takeEvery(types.LOGIN_USER_SUCCESS, getSubscriptionSaga);
}

export function* watchRetriveSuccess() {
  yield takeEvery(types.RETRIEVE_USER_SUCCESS, getSubscriptionSaga);
}

export function* watchGetSubscribe() {
  yield takeEvery(types.GET_SUBSCRIBE, getSubscriptionSaga);
}

export function* watchGetProgram() {
  yield takeEvery(types.GET_PROGRAM, getProgramSaga);
}

export function* watchGetNewPassword() {
  yield takeEvery(types.GET_NEW_PASSWORD, getNewPasswordSaga);
}

export function* watchSubmitNewPassword() {
  yield takeEvery(types.SUBMIT_NEW_PASSWORD, submitNewPasswordSaga);
}

export function* watchUpdateBaseInfo() {
  yield takeEvery(types.UPDATE_BASE_INFO, updateBaseInfoSaga);
}

export function* saga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchRegisterUserDB),
    fork(watchRetrieveUser),
    fork(watchLoginSuccess),
    fork(watchRetriveSuccess),
    fork(watchGetSubscribe),
    fork(watchGetProgram),
    fork(watchGetNewPassword),
    fork(watchSubmitNewPassword),
    fork(watchUpdateBaseInfo)
  ]);
}

/* END OF SAGA Section */

/* REDUCER Section */

const INIT_STATE = {
  user: null,
  loading: false,
  isAuthenticated: false,
  isAuthorized: false,
  errorMessageID: "",
  currentProgram: null,
  currentPrograms: null,
  status: "default",
};

export function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case types.SUBMIT_NEW_PASSWORD:
    case types.GET_NEW_PASSWORD:
    case types.LOGIN_USER:
    case types.RETRIEVE_USER:
    case types.REGISTER_USER:
    case types.UPDATE_BASE_INFO:
      return {
        ...state,
        loading: true,
        status: "processing",
        errorMessageID: ""
      };
    case types.RETRIEVE_USER_SUCCESS:
    case types.LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        status: "success",
        user: action.payload,
        isAuthenticated: true,
        errorMessageID: ""
      };
    case types.UPDATE_BASE_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        status: "success",
        errorMessageID: "",
        user: {
          ...state.user,
          healthProfile: {
            ...state.user.healthProfile,
            base_info: action.payload
          }
        }
      }
    case types.UPDATE_BASE_INFO_FAIL:
      return {
        ...state,
        loading: false,
        status: "fail",
      }
    case types.LOGIN_USER_FAIL:
      return {
        ...state,
        loading: false,
        status: "fail",
        user: null,
        isAuthenticated: false,
        errorMessageID: "error.login-failed"
      };
    case types.REGISTER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        status: "success",
        user: action.payload,
        errorMessageID: ""
      };
    case types.REGISTER_USER_FAIL:
      return {
        ...state,
        loading: false,
        status: "fail",
        user: null,
        errorMessageID: action.payload
      };
    case types.LOGOUT_USER:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        isAuthorized: false
      };
    case types.RETRIEVE_USER_FAIL:
      return {
        ...state,
        loading: false,
        status: "fail",
        user: null,
        isAuthenticated: false
      };
    case types.UPDATE_USER:
      return { ...state, user: { ...state.user, ...action.payload } };
    case types.AUTHORIZED_SUCCESS:
      return {
        ...state,
        loading: false,
        user: { ...state.user, ...action.payload },
        isAuthenticated: true,
        isAuthorized: true,
        errorMessageID: ""
      };
    case types.AUTHORIZED_FAIL:
      return {
        ...state,
        loading: false,
        user: { ...state.user, ...action.payload },
        isAuthenticated: true,
        isAuthorized: false,
        errorMessageID: ""
      };
    case types.GET_PROGRAM_SUCCESS:
      return {
        ...state,
        currentProgram: action.payload[0],
        currentPrograms: action.payload,
        status: "default"
      };
    case types.GET_PROGRAM_FAIL:
      return {
        ...state,
        errorMessageID: action.payload
      };
    case types.SUBMIT_NEW_PASSWORD_SUCCESS:
    case types.GET_NEW_PASSWORD_SUCCESS:
      return { ...state, status: "success", loading: false };
    case types.SUBMIT_NEW_PASSWORD_FAIL:
    case types.GET_NEW_PASSWORD_FAIL:
      return { ...state, status: "fail", loading: false };
    default:
      return { ...state };
  }
}

/* END OF REDUCER Section */
