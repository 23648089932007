import React, { Component, Fragment } from "react";
import {
  Row,
  Card,
  CardTitle,
  Button,
  Modal,
  ModalBody
} from "reactstrap";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";

import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import TDEECalForm from "../../containers/pages/TDEECalForm";
import Breadcrumb from "../../containers/navs/Breadcrumb";
import { retrieveUser } from "../../redux/auth";

class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewName: "loading",
      messagesID: "",
      buttonLabelID: "",
      shouldGoCart: false,
      modalOpen: false
    }

    this.toggle = this.toggle.bind(this);
    this.renderLoading = this.renderLoading.bind(this);
    this.renderWating = this.renderWating.bind(this);
    this.renderPending = this.renderPending.bind(this);
    this.renderEnroll = this.renderEnroll.bind(this);
    this.renderMain = this.renderMain.bind(this);
  }

  componentDidMount() {
		this.props.retrieveUser();
    if (this.props.user.group === "admin")
      this.props.history.push("/app/admin");
  }

  componentWillReceiveProps(newProps) {
    if (!newProps.user) {
      this.setState({
        viewName: "loading",
        messagesID: "",
        buttonLabelID: "",
        shouldGoCart: false
      });
      return;
    }
    const {
      user: { subscribeStatus, healthProfile },
      currentProgram
    } = newProps;
    let viewName = "";
    let messagesID = "";
    let buttonLabelID = "";

    if(healthProfile) {
      const { base_info } = healthProfile;
      const baseInfo = JSON.parse(base_info);
  
      this.setState({
        modalOpen: Object.keys(baseInfo).length <= 0,
      });
    }

    if(subscribeStatus === "subscribed") {
      console.log("subscribed");
      this.props.history.push("/app/daily-record");
    }

    switch (subscribeStatus) {
      case "subscribed-waiting":
        viewName = "waiting";
        messagesID = "";
        buttonLabelID = "";
        break;
      case "pending":
        viewName = "pending";
        messagesID = "pages.pending";
        buttonLabelID = "";
        break;
      case "expired":
        viewName = "enroll";
        messagesID = "pages.expire";
        buttonLabelID = "pages.expire-button";
        break;
      case "new":
        viewName = "enroll";
        messagesID = "pages.firsttime";
        buttonLabelID = "pages.firsttime-button";
        break;
      default:
        viewName = "loading";
        messagesID = "";
        buttonLabelID = "";
        break;
    }

    if (
      !currentProgram &&
      subscribeStatus === "expired" &&
      subscribeStatus === "new"
    ) {
      viewName = "enroll";
      messagesID = "pages.not-open";
    }
    this.setState({
      viewName,
      shouldGoCart: ["new", "expired"].includes(subscribeStatus),
      messagesID,
      buttonLabelID,
    })
  }

  toggle = () => {
    this.setState(prevState => ({
        modalOpen: !prevState.modalOpen
    }));
  };

  renderLoading() {
    return (
      <main>
        <div className="container">
          <Row className="h-100">
            <Colxx xxs="12" md="10" className="mx-auto my-auto">
              <Card className="auth-card">
                <div className="position-relative image-side "></div>
                <div className="form-side">
                  <div className="loading"></div>
                </div>
              </Card>
            </Colxx>
          </Row>
        </div>
      </main>
    );
  }

  renderWating() {
    return (
      <Row>
        <Colxx xxs="12" className="mb-4">
          <p>
            ขณะนี้โปรแกรมยังไม่เริ่ม ท่านสามารถดูกำหนดการการเริ่มโปรแกรมได้โดย{" "}
            <a
              href="https://planforfit.com/ccr/salepage/"
              className="text-underline text-highlight"
              target="_blank"
              rel="noopener noreferrer"
            >
              คลิกที่นี่
            </a>
            <br />
            หรือหากมีข้อสงสัยสามารถติดต่อทีมงานผ่าน Facebook fan page ได้โดย{" "}
            <a
              href="http://m.me/planforfit.com"
              className="text-underline text-highlight"
              target="_blank"
              rel="noopener noreferrer"
            >
              คลิกที่นี่
            </a>
            <br />
            หรือโทร 089-883-2339 ในเวลาทำการ (วันจันทร์-ศุกร์ 10.30 – 19.00 น.)
          </p>
        </Colxx>
      </Row>
    );
  }
  renderPending() {
    return (
      <Row>
        <Colxx xxs="12" className="mb-4">
          <p>
            เราได้รับข้อมูลของคุณแล้ว โปรดรอการยืนยันเข้าโปรแกรมอีกไม่เกิน 24 ชั่วโมง
            <br />
            หรือหากมีข้อสงสัยสามารถติดต่อทีมงานผ่าน Facebook fan page ได้โดย{" "}
            <a
              href="http://m.me/planforfit.com"
              className="text-underline text-highlight"
              target="_blank"
              rel="noopener noreferrer"
            >
              คลิกที่นี่
            </a>
            <br />
            หรือโทร 089-883-2339 ในเวลาทำการ (วันจันทร์-ศุกร์ 10.30 – 19.00 น.)
          </p>
        </Colxx>
      </Row>
    );
  }

  renderEnroll() {
    const {
      user: { 
        subscribeStatus, 
        healthProfile 
      },
      currentProgram
    } = this.props;
    if (!healthProfile) {
      return null;
    }
    const { expire_date } = healthProfile;

    const { messagesID, buttonLabelID, shouldGoCart } = this.state;

    return (
      <main>
        <div className="container">
          <Row className="h-100">
            <Colxx xxs="12" md="10" className="mx-auto my-auto">
              <Card className="d-flex flex-row mb-4 pt-4 pb-5">
                <div className="position-relative image-side "></div>
                <div className="form-side">
                  <CardTitle className="mb-4">
                    <IntlMessages id={messagesID} />{" "}
                    {subscribeStatus === "expired" &&
                      moment(expire_date).format(
                        "DD/MM/YYYY"
                      )}
                  </CardTitle>
                  {/* {shouldGoCart && currentProgram && (
                    <Button
                      href="/cart"
                      color="primary"
                      className="btn-shadow"
                      size="lg"
                    >
                      <IntlMessages id={buttonLabelID} />
                    </Button>
                  )} */}
                </div>
              </Card>
            </Colxx>
          </Row>
        </div>
      </main>
    );
  }
  renderMain() {
    return (
      <main>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.app" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12">
            <Card className="d-flex flex-row mb-3">
              <img
                alt="Thumbnail"
                src="/assets/img/food.png"
                className="list-main-thumbnail responsive border-0 pt-0 pb-0"
              />
              <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                  <p className="list-item-heading mb-1 w-40 w-sm-100">
                    <IntlMessages id="label.intro" />
                    <span className="text-highlight"><IntlMessages id="label.food_intro" /></span>
                  </p>
                  <div className="w-15 w-sm-100">
                    <Button
                      tag={NavLink}
                      color="primary"
                      to="/app/food-intro"
                      size="lg"
                    >
                      <IntlMessages id="label.click" />
                    </Button>
                  </div>
                </div>
              </div>
            </Card>
          </Colxx>
          <Colxx xxs="12">
            <Card className="d-flex flex-row mb-3">
              <img
                alt="Thumbnail"
                src="/assets/img/exer.png"
                className="list-main-thumbnail responsive border-0"
              />
              <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                  <p className="list-item-heading mb-1 w-40 w-sm-100">
                    <IntlMessages id="label.intro" />
                    <span className="text-highlight"><IntlMessages id="label.exercise_intro" /></span>
                  </p>
                  <div className="w-15 w-sm-100">
                    <Button
                      tag={NavLink}
                      color="primary"
                      to="/app/exercise-intro"
                      size="lg"
                    >
                      <IntlMessages id="label.click" />
                    </Button>
                  </div>
                </div>
              </div>
            </Card>
          </Colxx>
        </Row>
      </main>
    );
  }

  render() {
    const {viewName, modalOpen} = this.state;
    return (
      <Fragment>
        <Modal isOpen={modalOpen}>
          <ModalBody>
            <TDEECalForm/>
          </ModalBody>
        </Modal>
        {
        (viewName === "loading") 
          ? this.renderLoading()
          : (viewName === "main")
            ? this.props.history.push("/app/admin")
            : (viewName === "waiting")
              ? this.renderWating()
              : (viewName === "pending")
                ? this.renderPending()              
                : this.renderEnroll()
        }
      </Fragment>
    )
  }
}

const mapStateToProps = ({ authUser }) => {
  const { user, currentProgram } = authUser;
  return { user, currentProgram };
};
const mapActionsToProps = { retrieveUser };

export default connect(
  mapStateToProps,
  mapActionsToProps
)(Main);
