import React, { Component, Fragment } from "react";
import { Modal, ModalBody } from "reactstrap";
import { connect } from "react-redux";
import moment from "moment";
import { subscription, executePayment } from "../redux/checkout";
import { getUrlVarsObject } from "../helpers/Utils";


class DigitalPaid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paypal_info: null,
      subscribe_number: 0,
      currentProgram: null,
    };

    this.calculateCoursePeriod = this.calculateCoursePeriod.bind(this);
    this.getMondayStartDate = this.getMondayStartDate.bind(this);
  }
  componentDidMount() {
    const paypal_info = JSON.parse(localStorage.getItem('paypal_info'));
    const { paymentId, PayerID } = getUrlVarsObject();

    if (paymentId && PayerID) {
      const transactions = [{
        amount: paypal_info.transactions[0].amount
      }]
      const execute_json = {
        payer_id: PayerID,
        transactions
      }
      this.props.executePayment(paymentId, execute_json)
    }
    const currentProgram = localStorage.getItem("currentProgram");
    this.setState({
      paypal_info,
      currentProgram: JSON.parse(currentProgram)
    });
  }
  componentWillReceiveProps(newProps) {
    if(newProps.status === "success") {
      const { user } = newProps;
      const { currentProgram } = this.state;
      const { transactions, create_time } = this.state.paypal_info;
      const payment_date = moment(create_time).format('YYYY-MM-DD HH:mm');
      const { start_date, expire_date } = this.calculateCoursePeriod(currentProgram, user.healthProfile);
      const submitParams = {
          user_id: user.username,
          program_id: currentProgram.program_id,
          payment_type: "paypal",
          amount: transactions[0].amount.total,
          payment_date,
          payment_status: "approved",
          product_id: currentProgram.product_id,
          create_time: payment_date,
          transaction: transactions[0],
          start_date,
          expire_date,
      }
      
      if(this.state.subscribe_number === 0) {
        this.setState(prevState => ({
          subscribe_number: prevState.subscribe_number + 1
        }));
        this.props.subscription(submitParams);

      }
      if(newProps.isAuthenticated && newProps.isAuthorized) {
        newProps.history.push("/app/invoice");
      }
    }
    
  }

  getMondayStartDate() {
    const dateFormat = 'YYYY-MM-DD HH:mm';
    //if today is monday, system will use it. If not, system will use next monday
    return moment().day(1).isSame(moment(), 'day') ? moment().format(dateFormat) : moment().day(8).format(dateFormat);
  }

  calculateCoursePeriod(currentProgram, healthProfile) {
    const { period, program_start_date, program_expire_date } = currentProgram;
    const dateFormat = 'YYYY-MM-DD HH:mm';
    let start_date, expire_date;
    if (healthProfile) {
      start_date = moment(healthProfile.start_date).format(dateFormat);
      expire_date = moment(healthProfile.expire_date).isSameOrAfter(moment(), 'day')
                      ? moment(healthProfile.expire_date).add(period || 60, 'days').format(dateFormat)
                      : moment().add(period || 60, 'days').format(dateFormat);
    } else {
      if (period) {
        //if today is monday, system will use it. If not, system will use next monday
        start_date = this.getMondayStartDate();
        expire_date = moment().add(period, "days").format(dateFormat);
      } else if (program_start_date && program_expire_date) {
        start_date = moment(program_start_date).format(dateFormat);
        expire_date = moment(program_expire_date).isSameOrAfter(moment()) 
                        ? moment(program_expire_date).format(dateFormat)
                        : moment().add(60, "days").format(dateFormat);
      } else if (program_start_date) {
        start_date = moment(program_start_date).format(dateFormat);
        expire_date = moment(program_start_date).add(period || 60, "days").format(dateFormat);
      } else {
        //if today is monday, system will use it. If not, system will use next monday
        start_date = this.getMondayStartDate();
        expire_date = moment().add(60, "days").format(dateFormat);
      }
    }
    return { start_date, expire_date };
  }

  render() {
    return (
      <Fragment>
        <Modal isOpen={true}>
          <ModalBody>
            <div className="loading"></div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <div className="text-center text-medium">
              กำลังจัดเตรียมข้อมูล<br/>โปรดอย่าปิดเบราว์เซอร์
            </div>
          </ModalBody>
        </Modal>      
      </Fragment>
    );
  }
}

const mapStateToProps = ({ authUser, checkout }) => {
  const {
    isAuthenticated, 
    isAuthorized, 
    user
  } = authUser
  const { status } = checkout
  return {
    isAuthenticated, 
    isAuthorized, 
    user, 
    status
  };
};

const mapActionsToProps = { subscription, executePayment };

export default connect(
  mapStateToProps,
  mapActionsToProps
)(DigitalPaid);
