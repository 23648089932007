/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Error Page
05.Form Message
06.Error Message
07.activities
08.Pages
09.Modal
*/


module.exports = {
  /* 01.General */
  "general.copyright": "Fit Together © 2019 All Rights Reserved.",
  "general.company-name": "FIT TOGETHER CO., LTD.",
  "general.company-address": "429/129 Songprapa Road, Donmuang, Donmuang, Bangkok 10210",
  "general.company-tel": "TEL : +662-029-9341",
  "general.company-taxnumber": "tax 0-1055-59179-48-4",

  /* 02.User Login, Logout, Register */
  "user.register": "Register",
  "user.forgot-password": "Forgot Password",
  "user.email": "E-mail",
  "user.password": "Password",
  "user.address": "Address",
  "user.confirm-password": "Confirm Password",
  "user.new-password": "พาสเวิร์ดใหม่",
  "user.confirm-new-password": "ยืนยันพาสเวิร์ดใหม่",
  "user.forgot-password-question": "Forget password?",
  "user.fullname": "Full Name",
  "user.firstname": "Name",
  "user.lastname": "Lastname",
  "user.login-button": "LOGIN",
  "user.logout-button": "Sign Out",
  "user.register-button": "REGISTER",
  "user.continue-button": "Save and continue",
  "user.transfer-button": "Notify us money transfer",
  "user.reset-password-button": "RESET",
  "user.confirmation-code": "Reset Password",
  "user.password-changed": "Your password has been reset.",
  "user.password-changed-button": "Click here to login",
  "user.confirm-button": "CONFIRM",
  "user.buy": "BUY",
  "user.renew": "RENEW",
  "user.line1": "Address",
  "user.line2": "Address line 2",
  "user.tambol": "Tambol",
  "user.city": "City",
  "user.state": "State/Province",
  "user.zip": "Zip/Postal Code",
  "user.phone": "Mobile Phone",
  "user.user_link": "Facebook Name",
  "user.payment_type": "Payment Type",
  "user.weight": "Weight (kg.)",
  "user.height": "Height (cm.)",
  "user.age": "Age",
  "user.gender": "Gender",
  "user.work_active_level": "Working Style",
  "user.workout_day": "No. of Workout day/week",
  "user.reset_profile": "Reset Data",
  "user.update_time": "Update Time",
  "user.goal": "Goal",
  "user.expire_date": "Expire Date",

  /* 03.Menu */
  "menu.app": "Main",
  "menu.daily-record": "Daily record",
  "menu.main": "Daily Activity",
  "menu.dashboards": "Dashboards",
  "menu.gogo": "Gogo",
  "menu.start": "Start",
  "menu.second-menu": "Second Menu",
  "menu.second": "Second",
  "menu.ui": "UI",
  "menu.charts": "Charts",
  "menu.chat": "Chat",
  "menu.survey": "Survey",
  "menu.todo": "Todo",
  "menu.search": "Search",
  "menu.activities": "Daily Activities",
  "menu.video": "video",
  "menu.exercise-video": "Fat burning",
  "menu.weight-program": "Weight Program",
  "menu.waiting": "Please Wait",
  "menu.approve": "Approving Payment",
  "menu.admin": "Admin",
  "menu.videodetail": "Video detail",
  "menu.homework": "Weekly Homework",
  "menu.food-intro": "Suggestions for Food",
  "menu.exercise-intro": "Suggestions for Exercise",
  "menu.coming-soon": "Coming Soon",
  "menu.weekly-submit": "Weekly Progress",
  "menu.history": "History",
  "menu.userHistory": "History",

  /* 04.Error Page */
  "layouts.error-title": "Ooops... looks like an error occurred!",
  "layouts.error-code": "Error code",
  "layouts.go-back-home": "GO BACK HOME",

  /* 05.Form Message */
  "field.required": "This field is required!",
  "label.transfer-datetime": "Transfer Date & Time",
  "label.transfer-month": "Transfer Month",
  "label.register-date": "Register Date",
  "label.today": "Today",
  "label.transfer-details": "Saving Account: Siam Commercial Bank 4075206407",
  "label.transfer-details2": "Fit Together .Co.,Ltd. Chamchuri Square Branch",
  "label.transfer-amount": "Amount ",
  "label.transfer-note1": " eg. ",
  "label.transfer-note2": " to make it easier to approve",
  "label.transfer-title": "Transfer Details",
  "label.total": "Total",
  "label.have-account": "Already have an account?",
  "label.haveno-account": "New here? Create and account",
  "label.existing-user-warning": "Yes, that's me! Take me to sign in",
  "label.reset-password-success": "We have sent you an email with password reset instructions.",
  "label.invoice_no": "Invoice",
  "label.item_name": "ITEM NAME",
  "label.quantity": "QUANTITY",
  "label.price": "PRICE",
  "label.subtotal": "Subtotal :",
  "label.tax": "Tax :",
  "label.Total": "Total :",
  "label.pending": "Pending",
  "label.approved": "Approved",
  "label.status": "Status",
  "label.batch-no": "Batch",
  "label.approve-button": "Approve",
  "label.reject-button": "Disaprove",
  "label.unsubcribers-title": "Unsubscribers List",
  "label.pending-title": "Pending Items",
  "label.rejected-title": "Disaprove Items",
  "label.approved-title": "Approved Items",
  "label.video-file": "Video File",
  "label.video-title": "Title",
  "label.video-description": "Description",
  "label.video-level": "Level",
  "label.video-level-elementary": "Elementary",
  "label.video-level-intermediate": "Intermediate",
  "label.video-level-advance": "Advance",
  "label.video-score": "Score",
  "label.new-upload": "New Upload",
  "label.existing-add": "Adding from Vimeo",
  "label.entirely-add": "Upload New Video",
  "label.save": "Submit",
  "label.id": "ID",
  "label.init-title": "Waiting Homework",
  "label.checking-title": "Checking Homework",
  "label.complete-title": "Completed Homework",
  "label.sent_number": "Sent Number",
  "label.check_homework": "Check Homework",
  "label.recheck_homework": "Re-Check Homework",
  "label.watch_homework": "Just Watch!",
  "label.coach_name": "Coach Name",
  "label.nutrition_note": "Note from Coach",
  "label.homework": "Past Week Homework",
  "label.homework_history": "Program History",
  "label.plan": "Past Week Program",
  "label.nextplan": "Next Week Program",
  "label.weeklyscore": "Weekly Score",
  "label.success-and-return": "<< Success Return to Previous",
  "label.click": "Click",
  "label.intro": "Suggestions for ",
  "label.food_intro": "Food",
  "label.exercise_intro": "Exercise",
  "label.user_detail": "User Details",
  "label.week_no": "Week No.",
  "label.male": "Male",
  "label.female": "Female",
  "label.passive-work": "Passive Style",
  "label.active-work": "Active Style",
  "label.day": "Day",
  "label.day-unit": " day(s)",
  "label.reset-tdee-success": "You have reset your data and program successfully.",
  "label.reset-tdee-until": "You can reset your data and program until:",
  "label.diet": "Diet",
  "label.bulk": "Bulk",
  "label.reverse": "Reverse Diet",
  "label.what_low_carb": "What is low-carb day?",
  "label.low_carb_day": "Low-Carb Day",
  "label.monday": "monday",
  "label.tuesday": "tuesday",
  "label.wednesday": "wednesday",
  "label.thursday": "thursday",
  "label.friday": "friday",
  "label.saturday": "saturday",
  "label.sunday": "sunday",
  "label.and": "and",
  "label.choose_days": "Choose other days",
  "label.choose_lcarb_days": "Choose Low-Carb Day",
  "label.cancel": "Cancel",
  "label.ok": "OK",
  "label.empty": " ",
  "label.change": "Change",
  "label.begin": "Begin",

  /* 06. Error Message */
  "error.login-failed": "email or password is invalid",
  "error.username-existed": "This email is already registered",
  "error.newuser": "This email is not registered",
  "error.invalid-password": "Your password is invalid-format (at least 6 characters)",
  "error.general": "There is something wrong",
  "error.address": "Please Enter Your Address",
  "error.tambol": "Please Enter Your Tambol",
  "error.city": "Please Enter Your city",
  "error.state": "Please Enter Your state",
  "error.zip": "Please Enter Your zip",
  "error.transfer-amount": "Please Enter Your Amount",
  "error.tooless-amount": "Your amount is less than the product price",
  "error.phone": "Please Enter Your Phone",
  "error.user_link": "Please Enter Your Facebook Link",
  "error.weight": "Please Enter Your Weight",
  "error.height": "Please Enter Your Height",
  "error.age": "Please Enter Your Age",
  "error.gender": "Please Enter Your Gender",
  "error.work_active_level": "Please Enter Your Working Style",
  "error.workout_day": "Please Enter Your No. of Workout Day",
  "error.goal": "Please Enter Your Goal",

  /* 07. activities */
  "activities.daily-info": "Daily Info.",
  "activities.weekly-score": "Score",
  "activities.score-description": "For the entire week",
  "activities.nutrition-plan": "Nutrition Plan",
  "activities.plan-description": "Current Plan",
  "activities.monday": "Mon",
  "activities.tuesday": "Tue",
  "activities.wednesday": "Wed",
  "activities.thursday": "Thu",
  "activities.friday": "Fri",
  "activities.saturday": "Sat",
  "activities.sunday": "Sun",
  "activities.note": "Why You Can't?",
  "activities.submit": "Submit today data",
  "activities.can": "can",
  "activities.cannot": "can't",
  "activities.breakfast": "breakfast",
  "activities.snack": "snack",
  "activities.lunch": "lunch",
  "activities.supper": "supper",
  "activities.veggie": "veggie",
  "activities.meat": "meat",
  "activities.carb": "carb",
  "activities.feeling": "How You Feel?",
  "activities.feel-level1": "Still Hungry",
  "activities.feel-level2": "Full",
  "activities.feel-level3": "So Full",
  "activities.feel-level4": "Too Full",
  "activities.feedback-from-coach": "Feedback From Coach",
  "activities.food-title": "Nutrient",
  "activities.food-name": "Name",
  "activities.food-carb": "Carb",
  "activities.food-protein": "Protein",
  "activities.food-fat": "Fat",
  "activities.food-submit": "Add Food",
  "activities.weight-submit": "Enter Today Weight",
  "activities.weight-title": "Body Weight",
  "activities.weight-today": "Today Weight",
  "activities.weight-kg": "Kilogram",
  "activities.cardio-title": "Cardio",
  "activities.cardio-minute": "min.",
  "activities.cardio-submit": "Enter Cardio",
  "activities.cardio-name": "Name",
  "activities.cardio-today": "Cardio for Today",
  "activities.cardio-weekly": "Cardio in This Week",
  "activities.front-image": "Your Front Image",
  "activities.side-image": "Your Side Image",
  "activities.measurement-title": "Measurement",
  "activities.measurement-submit": "Enter Measurement",
  "activities.measurement-chest": "Chest",
  "activities.measurement-arm": "Arm (main)",
  "activities.measurement-thigh": "Thigh (main)",
  "activities.measurement-navel": "Belly",
  "activities.measurement-above-navel": "Above Belly 2 inches",
  "activities.measurement-below-navel": "Below Belly 2 inches",
  "activities.measurement-cm": "cm.",
  "activities.weight-program": "Weight Training Program",
  "activities.weight-name": "Exercise",
  "activities.weight-rep": "Reps",
  "activities.weight-set": "Sets",
  "activities.weight-rest": "Rest",
  "activities.weight-training": "Weight Training",
  "activities.weight-example": "Training Example",
  "activities.weight-played": "(played)",
  "activities.weight-click": "Finished? Click!!",
  "activities.weight-day": "day",
  "activities.week_in_program": "Period",
  "activities.current_week": "Current Week",
  "activities.last_week": "Last Week",
  "activities.last_day": "Last Day:",
  "activities.homework_status": "Homework Status",
  "activities.complete": "Complete",
  "activities.in-progress": "In-progress",

  /* 8.Pages */
  "pages.orderby": "Order By : ",
  "pages.filterby-score": "Score Filter : ",
  "pages.filterby-duration": "Length Filter : ",
  "pages.add-new": "ADD NEW",
  "pages.add-new-modal-title": "Add New Video",
  "pages.product-name": "Video Name",
  "pages.category": "Category",
  "pages.description": "Description",
  "pages.delete": "Delete",
  "pages.another-action": "Another action",
  "pages.display-options": "Display Options",
  "pages.score": "Score : ",
  "pages.video-length": "length : ",
  "pages.name-label": "Name",
  "pages.duration-label": "Duration",
  "pages.score-label": "Score",
  "pages.score-label-all": "All Score",
  "pages.score-label-0-2": "0-2 Points",
  "pages.score-label-3-5": "3-5 Points",
  "pages.score-label-6-more": "> 6 Points",
  "pages.duration-label-all" : "All",
  "pages.duration-label-0-2" : "0-2 min.",
  "pages.duration-label-2-4" : "1-2 min.",
  "pages.duration-label-4-more" : "> 3 min.",
  "pages.error-title": "Ooops... looks like an error occurred!",
  "pages.error-code": "Error code",
  "pages.go-back-home": "GO BACK HOME",
  "pages.expire": "Your CCR exipred on ",
  "pages.expire-button": "Upgrade your subscription",
  "pages.firsttime": "Only one step away for your great health.",
  "pages.firsttime-button": "Buy you subscription",
  "pages.pending": "Please waiting for our approval soon and you can enter CCR circle.",
  "pages.cart-title": "Shopping Cart",
  "pages.product-title": "CCR course batch ",
  "pages.not-open": "Sorry, we're about to open the new batch of this course. Please stay tune!",
  "pages.registration-from": "Open for registration from  ",
  "pages.registration-to": " to ",
  "pages.registration-period": "Course Period: ",
  "pages.total-cart": "Your Cart Total",
  "pages.secure-checkout": "Secure Checkout",
  "pages.checkout-description": "Price displayed excludes sales tax.",
  "pages.checkout-create-account": "Create Account",
  "pages.checkout-create-account-subtitle": "Sign up to CCR to get started.",
  "pages.checkout-billing-detail": "Billing Details",
  "pages.checkout-billing-disclaimer": "We do not sell your details or share them without your permission.",
  "pages.checkout-payment-method": "Select Payment Method",
  "pages.paypal-payment": "Checkout with Paypal",
  "pages.transfer-payment": "Checkout with Bank Transfer",
  "menu.invoice": "Invoice",
  "pages.wating-content": "Please wait, the program has not started yet.",
  "pages.upload": "Upload",
  "pages.close": "Close",

  /* 9.Modal */
  "modal.finish-exercise-title" : "Congratulation!",
  "modal.finish-confirm" : "I Made It!",
  "modal.finish-cancel" : "Cancel",
  "modal.finish-body-message" : "You Got:",
  "modal.finish-delete-message" : "Are U Sure (Vimeo will be remove)",
  "madal.delete": "Delete",
  "madal.deldbonly": "Delete from App Only",
  "madal.tdee": "Nutrients Calculation",
  "modal.tdee-title": "Enter Info. for Program Generation"
};
