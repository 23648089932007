import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { 
  Row,
  Button,
  Modal,
  ModalBody, 
} from "reactstrap";
import { injectIntl } from 'react-intl';

import Spinner from "../../components/common/Spinner";
import { getActivities, updateWeeklyProgress } from "../../redux/activities";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import Breadcrumb from "../../containers/navs/Breadcrumb";
import IntlMessages from "../../helpers/IntlMessages";

import ImageUpload from "../../containers/pages/ImageUpload";
import WeeklyMeasurement from "../../containers/pages/WeeklyMeasurement";
import WeeklyComment from "../../containers/pages/WeeklyComment";
import { s3Download } from "../../helpers/awsLib";

class WeeklySubmit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      side: { public_id: "", secure_url: "" },
      front: { public_id: "", secure_url: "" },
      unentryList: []
    };

    this.getUnentryList = this.getUnentryList.bind(this);
    this.updateImage = this.updateImage.bind(this);
    this.updateMeasurement = this.updateMeasurement.bind(this);
    this.updateWeeklyProgress = this.updateWeeklyProgress.bind(this);
    this.toggle = this.toggle.bind(this);
    this.renderForm = this.renderForm.bind(this);
  }

  componentDidMount() {
    this.props.getActivities(this.props.user.username);
  }

  async componentWillReceiveProps(newProps) {
    if (newProps.weeklyProgress) {
      const { front, side } = newProps.weeklyProgress.images;
      const front_url = front.public_id ? await s3Download(front.public_id) : "";
      const side_url = side.public_id ? await s3Download(side.public_id) : "";
      this.setState({
        side: { 
          public_id: side.public_id,
          secure_url: side_url
        },
        front: {
          public_id: front.public_id,
          secure_url: front_url
        }
      })
    }
  }

  isMeasurementComplete(measurement) {
    if (
      measurement.above_navel &&
      measurement.arm &&
      measurement.below_navel &&
      measurement.navel &&
      measurement.chest &&
      measurement.thigh
    ) {
      return true;
    }

    return false;
  }

  getUnentryList(newWeeklyProgress) {
    let returnList = [];
    const { images, measurement } = newWeeklyProgress;
    if (!images.front || !images.front.public_id) {
      returnList.push("รูปด้านหน้า");
    }
    if (!images.side || !images.side.public_id) {
      returnList.push("รูปด้านช้าง");
    }
    if (!this.isMeasurementComplete(measurement)) {
      returnList.push("สัดส่วน");
    }
    return returnList;
  }

  updateImage(image, side) {
    const { weeklyProgress, entireData, user} = this.props;
    const newWeeklyProgress = {
      ...weeklyProgress,
      images: {
        ...weeklyProgress.images,
        [side]: image
      }
    }
    const unentryList = this.getUnentryList(newWeeklyProgress);
    this.setState({
      unentryList
    });
    this.props.updateWeeklyProgress(newWeeklyProgress, entireData, user);
  }

  updateMeasurement(measurement) {
    const { weeklyProgress, entireData, user } = this.props;
    const newWeeklyProgress = {
      ...weeklyProgress,
      measurement
    }
    const unentryList = this.getUnentryList(newWeeklyProgress);
    this.setState({
      unentryList
    });
    this.props.updateWeeklyProgress(newWeeklyProgress, entireData, user);
  }

  updateWeeklyProgress(name, value) {
    const { weeklyProgress, entireData, user } = this.props;
    const newWeeklyProgress = {
      ...weeklyProgress,
      [name]: value
    }
    this.getUnentryList(newWeeklyProgress);
    this.props.updateWeeklyProgress(newWeeklyProgress, entireData, user);
  }

  toggle() {
    this.setState({
      unentryList: []
    })
  }

  renderForm() {
    const { messages } = this.props.intl;
    const { loading, weeklyProgress } =  this.props;
    const { front, side, unentryList } = this.state;
    let incompleteText = unentryList.reduce((acc, curr) => acc + curr + '/', "");
    incompleteText = incompleteText ? incompleteText.substr(0, incompleteText.length -1) : incompleteText;
    return (
      <Fragment>
        <Modal isOpen={unentryList.length > 0}>
          <ModalBody>
            <p>
              ส่งประเมิน"ไม่ผ่าน" ข้อมูลการส่งไม่สมบูรณ์
            </p>
            <p>
              ( {incompleteText} )
            </p>
            <div className="d-flex justify-content-center align-items-center">
              <Button 
                className="btn-shadow btn-secondary" 
                size="sm"
                onClick={this.toggle}
              >
                <span className="text-small text-white">
                  <IntlMessages id="label.ok" />
                </span>
              </Button>
            </div>
          </ModalBody>
        </Modal>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.weekly-submit" match={this.props.match}/>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row className="mb-4">
          <Colxx xs="12" lg="6" className="mb-4">
            <ImageUpload
              user={this.props.user}
              side="front"
              label={messages["activities.front-image"]}
              updateImage={this.updateImage}
              sourceImage={front.secure_url ? [front] : []}
            />
          </Colxx>
          <Colxx xs="12" lg="6" className="mb-4">
            <ImageUpload
              user={this.props.user}
              side="side"
              label={messages["activities.side-image"]}
              updateImage={this.updateImage}
              sourceImage={side.secure_url ? [side] : []}
            />
          </Colxx>
        </Row>
        <Row>
          <Colxx xs="12" lg="6" className="mb-4">
            <WeeklyMeasurement
              sourceMeasurement={weeklyProgress.measurement}
              updateMeasurement={this.updateMeasurement}
              loading={loading}
              messages={messages}
            />
          </Colxx>
          <Colxx xs="12" lg="6" className="mb-4">
            <WeeklyComment
              weeklyComment={weeklyProgress.weekly_comment}
              updateWeeklyProgress={this.updateWeeklyProgress}
              loading={loading}
            />
          </Colxx>
        </Row>
      </Fragment>
    );
  }

  render() {
    return (
      <div>
          {(this.props.user.week_in_program && this.props.weeklyProgress)
            ? this.renderForm()
            : <div className='imgup-buttons'><Spinner /></div>
          }
      </div>
    )
  }
}

const mapStateToProps = ({ authUser, activities }) => {
  const { user } = authUser;
  const { weeklyProgress, entireData, loading } = activities;
  return { user, weeklyProgress, entireData, loading };
};
const mapActionsToProps = { getActivities, updateWeeklyProgress };

export default connect(
  mapStateToProps,
  mapActionsToProps
)(injectIntl(WeeklySubmit));
