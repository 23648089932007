import React, { Component } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { IntlProvider } from "react-intl";
import "./helpers/Amplify";
import AppLocale from "./lang";
import ColorSwitcher from "./components/common/ColorSwitcher";
import NotificationContainer from "./components/common/react-notifications/NotificationContainer";
import { AuthenticatedRoute, UnauthenticatedRoute } from "./components/Router";
import { isMultiColorActive } from "./constants/defaultValues";
import { retrieveUser } from "./redux/auth";
import main from "./views";
import app from "./views/app";
import error from "./views/error";
import login from "./views/login";
import register from "./views/register";
import saleRegister from "./views/saleRegister";
import forgotPassword from "./views/forgot-password";
import enroll from "./views/enroll";
import cart from "./views/cart";
import batchCart from "./views/batch_cart";
import checkout from "./views/checkout";
import digitalPaid from "./views/ditigal_paid";
import transferNotice from "./views/transfer_notice";
import front from "./views/front";
import salepage from "./views/salepage";
import review from "./views/review";
import moment from "moment";
import 'moment/min/locales';

class App extends Component {
	componentDidMount() {
		this.props.retrieveUser();
  }
  
  render() {
    const { locale, isAuthenticated, isAuthorized, user } = this.props;
    const currentAppLocale = AppLocale[locale];
    const group = (user || "user").group || "user";
    const isAdmin = group === "admin";
    moment.locale(locale);

    return (
      <div className="h-100">
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <React.Fragment>
            <NotificationContainer />
            {isMultiColorActive && <ColorSwitcher />}
            <Router>
              <Switch>
                <AuthenticatedRoute 
                  path="/app" 
                  isAuthenticated={isAuthenticated} 
                  isAuthorized={isAuthorized}
                  isAdmin={isAdmin}
                  component={app} 
                />
                <AuthenticatedRoute 
                  path="/" 
                  isAuthenticated={isAuthenticated} 
                  isAuthorized={isAuthorized}
                  isAdmin={isAdmin}
                  exact 
                  component={main} />
                <UnauthenticatedRoute 
                  path="/login"
                  isAuth={isAuthenticated} 
                  component={login} 
                />
                <UnauthenticatedRoute 
                  path="/enroll" 
                  isAuth={isAuthorized}
                  component={enroll} 
                />
                <UnauthenticatedRoute 
                  path="/front"
                  isAuth={isAuthenticated}  
                  component={front} 
                />
                <UnauthenticatedRoute 
                  path="/salepage"
                  isAuth={isAuthenticated}  
                  component={salepage} 
                />
                <Route path="/digital_paid" component={digitalPaid} />
                <Route path="/review" component={review} />
                <Route path="/transfer_notice" component={transferNotice} />
                {/* <Route path="/checkout" component={checkout} />
                <Route path="/cart" component={cart} />
                <Route path="/batch_cart" component={batchCart} />
                <Route path="/register" component={register} />
                <Route path="/sale_register" component={saleRegister} /> */}
                <Route path="/forgot-password" component={forgotPassword} />
                <Route path="/error" exact component={error} />
                <Redirect to="/error" />
              </Switch>
            </Router>
          </React.Fragment>
        </IntlProvider>
      </div>
    );
  }
}

const mapStateToProps = ({ authUser, settings }) => {
  const { isAuthenticated, isAuthorized, loading, user } = authUser;
  const { locale } = settings;
  return { locale, isAuthenticated, isAuthorized, loading, user };
};
const mapActionsToProps = { retrieveUser };

export default connect(
  mapStateToProps,
  mapActionsToProps
)(App);
