import React, { Component, Fragment } from "react";
import {
  Row,
  Card,
  CardBody,
  CardHeader,
  CardImg,
  CardTitle,
  CardText,
  Button,
  Collapse,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import { connect } from "react-redux";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import IntlMessages from "../helpers/IntlMessages";
import { Colxx, Separator } from "../components/common/CustomBootstrap";
import { retrieveUser } from "../redux/auth";
import { changeAuthState, digitalPaying } from "../redux/checkout";
import RegisterForm from "../containers/pages/registerForm";
import LoginForm from "../containers/pages/loginForm";
import BillingDetailForm from "../containers/pages/billingDetailForm";
import TransferForm from "../containers/pages/transferForm";
import { productName } from "../constants/defaultValues";

class Checkout extends Component {
  constructor(props) {
    super(props);

    this.toggleFirstTab = this.toggleFirstTab.bind(this);
    this.toggleSecondTab = this.toggleSecondTab.bind(this);
    this.toggleSupportButton = this.toggleSupportButton.bind(this);
    this.digitalPaying = this.digitalPaying.bind(this);
    this.renderCreateAccount = this.renderCreateAccount.bind(this);
    this.renderBillingDetails = this.renderBillingDetails.bind(this);
    this.renderPaymentMethods = this.renderPaymentMethods.bind(this);
    this.renderAll = this.renderAll.bind(this);
    this.renderTotalAmount = this.renderTotalAmount.bind(this);
    this.state = {
      activeFirstTab: "1",
      activeSecondTab: "1",
      price: 3290,
      product: `${productName.charAt(0).toUpperCase()} Course`,
      isCreateAccountOpen: false,
      isBillingDetailsOpen: false,
      isPaymentMethodsOpen: false,
      isPreparingModalOpen: false,
      currentProgram: null
    };
  }

  componentDidMount() {
    this.props.retrieveUser();
    const currentProgram = localStorage.getItem("currentProgram");
    if (!currentProgram) this.props.history.push("/cart");
    this.setState({
      currentProgram: JSON.parse(currentProgram)
    });
  }

  componentWillReceiveProps(newProps) {
    let isCreateAccountOpen = true;
    let isBillingDetailsOpen = false;
    let isPaymentMethodsOpen = false;
    const { user, isAuthenticated, status } = newProps;

    const { currentProgram, activeFirstTab } = this.state;

    if (isAuthenticated) {
      if (isAuthenticated) {
        isCreateAccountOpen = false;
        if (activeFirstTab === "1" && status === "processing") {
          this.props.history.push("/transfer_notice");
        }
      }
      isBillingDetailsOpen = user.attributes.address ? false : true;

      isPaymentMethodsOpen = !isCreateAccountOpen && !isBillingDetailsOpen;

      if (currentProgram) {
        const { price, description } = currentProgram;
        this.setState({
          price,
          product: description
        });
      }
    }

    this.setState({
      isCreateAccountOpen,
      isBillingDetailsOpen,
      isPaymentMethodsOpen
    });
  }

  toggleFirstTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeFirstTab: tab
      });
    }
  }
  toggleSecondTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeSecondTab: tab
      });
    }
  }

  toggleSupportButton() {
    const authState =
      this.props.authState === "register" ? "login" : "register";
    this.props.changeAuthState(authState);
  }

  digitalPaying() {
    //this.props.history.push("/payment_preparing");
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq("trackCustom", "<CCR_Purchase>");
      }
      if (window._lt) {
        window._lt('send', 'cv', {
          type: 'CCR_Purchase'
          },['10be62e6-0bb3-49b8-a9c3-404eac3aae64']);
      }
    }
    this.setState({
      isPreparingModalOpen: true
    });
    const { currentProgram } = this.state;
    if (currentProgram) {
      const { price, description, program_id } = currentProgram;
      const items = [
        {
          name: description,
          sku: program_id,
          price,
          currency: "THB",
          quantity: 1
        }
      ];
      this.props.digitalPaying(items, description);
    }
  }

  renderCreateAccount() {
    const { isCreateAccountOpen } = this.state;
    const { authState } = this.props;
    if (isCreateAccountOpen) {
      return (
        <div>
          <Row>
            <Colxx xxs="12">
              <Card className="mb-3">
                <CardBody>
                  <CardTitle>
                    <div className="d-flex flex-grow-1 justify-content-between">
                      <div className="min-width-zero">
                        <p className="text-medium">
                          {authState === "register" ? (
                            <IntlMessages id="pages.checkout-create-account" />
                          ) : (
                            <IntlMessages id="user.login-button" />
                          )}
                        </p>
                      </div>
                    </div>
                  </CardTitle>
                  <Separator className="mb-3" />
                  <CardText className="text-muted text-one mb-4">
                    <span className="text-one text-muted">
                      <IntlMessages id="pages.checkout-create-account-subtitle" />
                      {" "}
                      <IntlMessages
                        id={
                          authState === "register"
                            ? "label.have-account"
                            : "label.haveno-account"
                        }
                      />
                    </span>{" "}
                    <Button
                      id="btn_support_login"
                      color="light"
                      className="btn-shadow"
                      size="sm"
                      onClick={this.toggleSupportButton}
                    >
                      <IntlMessages
                        id={
                          authState === "register"
                            ? "user.login-button"
                            : "user.register-button"
                        }
                      />
                    </Button>
                  </CardText>
                  <Colxx lg="12" xxs="12">
                    {authState === "register" ? (
                      <RegisterForm />
                    ) : (
                      <LoginForm />
                    )}
                  </Colxx>
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        </div>
      );
    } else {
      const { user } = this.props;
      const shouldRenderName =  user && 
                                user.attributes && 
                                user.attributes.email && 
                                user.attributes.given_name && 
                                user.attributes.family_name;
      return (
        <div>
          <Row>
            <Colxx xxs="12">
              <Card className="mb-3 pt-2">
                <CardBody>
                  {
                    shouldRenderName &&
                    <div className="float-right">
                      <FontAwesomeIcon icon={faCheckCircle} size="2x" color="green" />
                    </div>
                  }
                  <p className="text-medium">
                    <IntlMessages id="pages.checkout-create-account" />
                  </p>
                  {
                    shouldRenderName &&
                    <p className="text-one text-muted">
                      {user.attributes.given_name} {user.attributes.family_name}
                      <br/>
                      {user.attributes.email}
                    </p>
                  }
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        </div>
      );
    }
  }

  renderBillingDetails() {
    const { isBillingDetailsOpen } = this.state;
    if (isBillingDetailsOpen) {
      return (
        <div>
          <Row>
            <Colxx xxs="12">
              <Card className="mb-3">
                <CardBody>
                  <CardTitle className="mb-1">
                    <p className="text-medium">
                      <IntlMessages id="pages.checkout-billing-detail" />
                    </p>
                  </CardTitle>
                  <CardText className="text-muted text-one mb-3">
                    <IntlMessages id="pages.checkout-billing-disclaimer" />
                  </CardText>
                  <Separator className="mb-3" />
                  <Colxx lg="12" xxs="12">
                    <BillingDetailForm />
                  </Colxx>
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        </div>
      );
    } else {
      const { user } = this.props;
      const shouldRenderAddress = user && 
                                  user.attributes && 
                                  user.attributes.address;
      let addressLine1 = "";
      let addressLine2 = "";
      let addressLine3 = "";
      if (shouldRenderAddress) {
        const address = JSON.parse(user.attributes.address);
        addressLine1 = `${address.address_line1} ${address.address_line2 ? address.address_line2 : ""}`;
        addressLine2 = `${address.tambol} ${address.city} ${address.state}`;
        addressLine3 = address.zip;
      }
      return (
        <div>
          <Row>
            <Colxx xxs="12">
              <Card className="mb-3 pt-2">
                <CardBody>
                  {
                    shouldRenderAddress &&
                    <div className="float-right">
                      <FontAwesomeIcon icon={faCheckCircle} size="2x" color="green" />
                    </div>
                  }
                  <p className="text-medium">
                    <IntlMessages id="pages.checkout-billing-detail" />
                  </p>
                  {
                    shouldRenderAddress &&
                    <p className="text-one text-muted">
                      {addressLine1}
                      <br/>
                      {addressLine2}
                      <br/>
                      {addressLine3}
                    </p>
                  }
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        </div>
      );
    }
  }

  renderPaymentMethods() {
    const { isPaymentMethodsOpen, price } = this.state;
    if (isPaymentMethodsOpen) {
      return (
        <div>
          <Row>
            <Colxx xxs="12">
              <Card className="mb-4">
                <CardHeader className="mb-0 pt-4">
                  <h2>
                    <IntlMessages id="pages.checkout-payment-method" />
                  </h2>
                  <Separator className="mb-3" />
                </CardHeader>

                <Collapse
                  isOpen={true}
                  className="d-md-block"
                  id="displayOptions"
                >
                  <CardHeader>
                    <Nav tabs className="nav-fill">
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeFirstTab === "1",
                            "nav-link": true
                          })}
                          onClick={() => {
                            this.toggleFirstTab("1");
                          }}
                          to="#"
                        >
                          <CardImg
                            top
                            src="/assets/img/banks-logo.png"
                            alt="Paypal Payment"
                            className="img-thumbnail border-0 list-thumbnail"
                          />
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeFirstTab === "2",
                            "nav-link": true
                          })}
                          onClick={() => {
                            this.toggleFirstTab("2");
                          }}
                          to="#"
                        >
                          <CardImg
                            top
                            src="/assets/img/paypal-logo.png"
                            alt="Paypal Payment"
                            className="img-thumbnail border-0 list-thumbnail"
                          />
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </CardHeader>

                  <TabContent activeTab={this.state.activeFirstTab}>
                    <TabPane tabId="1">
                      <Row>
                        <Colxx sm="12">
                          <CardBody>
                            {this.renderTotalAmount()}
                            <p>
                              <IntlMessages id="label.transfer-details" />
                            </p>
                            <p>
                              <IntlMessages id="label.transfer-details2" />
                            </p>
                            <p className="text-muted text-small mb-4">
                              <IntlMessages id="label.transfer-amount" />$
                              {price}.xx
                              <IntlMessages id="label.transfer-note1" />${price}
                              .89
                              <IntlMessages id="label.transfer-note2" />
                            </p>
                            <Separator className="mb-4" />
                            <p>
                              <IntlMessages id="label.transfer-title" />
                            </p>
                            <Colxx lg="12" xxs="12">
                              <TransferForm />
                            </Colxx>
                          </CardBody>
                        </Colxx>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Colxx lg="12" xxs="12">
                          <CardBody>
                            {this.renderTotalAmount()}
                            <div className="d-flex justify-content-end align-items-center">
                              <Button
                                id="btn_credit_payment_ccr"
                                size="lg"
                                color="primary"
                                onClick={this.digitalPaying}
                              >
                                <span className="text-one text-white">
                                  <IntlMessages id="pages.paypal-payment" />
                                </span>
                              </Button>
                            </div>
                          </CardBody>
                        </Colxx>
                      </Row>
                    </TabPane>
                  </TabContent>
                </Collapse>
              </Card>
            </Colxx>
          </Row>
        </div>
      );
    } else {
      return (
        <div>
          <Row>
            <Colxx xxs="12">
              <Card className="mb-3 pt-2">
                <CardBody>
                  <p className="text-medium text-muted">
                    <IntlMessages id="pages.checkout-payment-method" />
                  </p>
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        </div>
      );
    }
  }

  renderTotalAmount() {
    const { price, product } = this.state;
    return (
      <div>
        <div className="d-flex flex-grow-1 justify-content-between">
          <div className="min-width-zero">
            <h2 className="text-highlight text-bold font-weight-semibold">
              {product}
            </h2>
          </div>
          <div>
            <h2 className="text-bold font-weight-semibold">฿{price}.-</h2>
          </div>
        </div>
        <Separator className="mb-4" />
        <div className="d-flex flex-grow-1 justify-content-between">
          <div className="min-width-zero"></div>
          <div>
            <h2 className="text-bold font-weight-semibold">
              <IntlMessages id="label.total" />
              :&emsp;฿{price}.-
            </h2>
          </div>
        </div>
        <Separator className="mb-4" />
      </div>
    );
  }

  renderAll() {
    return (
      <Fragment>
        <div className="unauth-nav-container">
          <img
            src="/assets/img/p4flogo.svg"
            className="unauth-nav-logo"
            alt="logo"
          />
          <p className="inner-text">CYCLICAL CALORIC RESTRICTION (CCR)</p>
        </div>
        <div className="container mt-4">
          <Row className="h-100">
            <Colxx xxs="12" md="10" className="mx-auto my-auto">
              {/* page Header */}
              <Row>
                <Colxx xxs="12">
                  <CardTitle className="lead font-weight-bold mb-5">
                    <IntlMessages id="pages.secure-checkout" />
                  </CardTitle>
                </Colxx>
              </Row>

              {/* Create Account */}
              {this.renderCreateAccount()}

              {/* Billing Detail */}
              {this.renderBillingDetails()}

              {/* Payment Method */}
              {this.renderPaymentMethods()}

              {/* Footer */}
              <Row>
                <Colxx xxs="12 mb-5">
                  <IntlMessages id="general.copyright" />
                </Colxx>
              </Row>
            </Colxx>
          </Row>
        </div>
      </Fragment>
    );
  }

  render() {
    return (
      <Fragment>
        <Modal isOpen={this.state.isPreparingModalOpen}>
          <ModalBody>
            <div className="text-center">
              <img
                src="/assets/img/animated-paypal-loading.gif"
                alt="paypal preparing"
              />
            </div>
          </ModalBody>
        </Modal>
        {this.renderAll()}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ authUser, checkout }) => {
  const { isAuthenticated, isAuthorized, user, currentProgram } = authUser;
  const { authState, status } = checkout;
  return {
    user,
    isAuthenticated,
    isAuthorized,
    currentProgram,
    authState,
    status
  };
};

const mapActionsToProps = {
  retrieveUser,
  changeAuthState,
  digitalPaying
};

export default connect(mapStateToProps, mapActionsToProps)(Checkout);
