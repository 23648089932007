import React from "react";
import {
  Row,
  Card,
  CardBody,
  CardSubtitle,
  CardImg,
  CardText,
  Badge,
  Button
} from "reactstrap";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import { ContextMenuTrigger } from "react-contextmenu";
import { Colxx } from "../../components/common/CustomBootstrap";
import IntlMessages from "../../helpers/IntlMessages";
//import { strPadLeft } from "../../helpers/Utils";

const ImageListView = ({ video, isSelect, collect, onCheckItem, isAdmin, onDelete }) => {
  //const hours = strPadLeft(Math.floor(video.duration / 3600), '0', 2);
  const minutes = Math.floor(video.duration / 60);//strPadLeft(Math.floor(video.duration / 60), '0', 2);
  const seconds = video.duration - minutes * 60;//strPadLeft(video.duration - minutes * 60, '0', 2);

  const videoLength = `${minutes} นาที ${seconds} วิ`;
  return (
    <Colxx sm="6" lg="4" xl="3" className="mb-3" key={video.id}>
      <ContextMenuTrigger id="menu_id" data={video.id} collect={collect}>
        <Card
          onClick={event => onCheckItem(event, video)}
          className={classnames({
            active: isSelect
          })}
        >
          <div className="position-relative">
            <NavLink to={`?p=${video.id}`} className="w-40 w-sm-100">
              <CardImg top alt={video.title} src={video.thumbnail} />
            </NavLink>
            <Badge
              color={video.level_color}
              pill
              className="position-absolute badge-top-left"
            >
              {video.level}
            </Badge>
            <Badge
              color="primary"
              pill
              className="position-absolute badge-top-left-2"
            >
              <IntlMessages id="pages.score" />{video.score}
            </Badge>
          </div>
          <CardBody>
            <Row>
              <Colxx xxs="10" className="mb-3">
                <CardSubtitle>{video.title}</CardSubtitle>
                <CardText className="text-muted text-small mb-0 font-weight-light">
                  <IntlMessages id="pages.video-length" />{videoLength}
                </CardText>
              </Colxx>
              <Colxx xxs="2">
                {isAdmin && 
                <Button color="empty" size="xs" onClick={() => onDelete(video.id)}>
                  <i
                    className={"simple-icon-trash text-highlight align-text-center text-one"}
                  />
                </Button> }
              </Colxx>
            </Row>
          </CardBody>
        </Card>
      </ContextMenuTrigger>
    </Colxx>
  );
};

/* React.memo detail : https://reactjs.org/docs/react-api.html#reactpurecomponent  */
export default React.memo(ImageListView);
