import React, { Component, Fragment } from "react";
import {
  Row,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Button
} from "reactstrap";
import { connect } from "react-redux";
import moment from "moment";

import IntlMessages from "../helpers/IntlMessages";
import { Colxx } from "../components/common/CustomBootstrap";
import ThumbnailImage from "../components/cards/ThumbnailImage";
import { getProgram } from "../redux/auth";
import { productName } from "../constants/defaultValues";

class BatchCart extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.renderCart = this.renderCart.bind(this);
    this.renderNewBatch = this.renderNewBatch.bind(this);
    this.renderSuspense = this.renderSuspense.bind(this);
  }
  componentDidMount() {
    this.props.getProgram(productName, "batch");
  }
  onClick(currentProgram) {
    if (typeof window !== "undefined") {
      if (window.fbq != null) { 
        window.fbq('trackCustom', '<CCR_AddtoCart>')
      }
      if (window._lt) {
        window._lt('send', 'cv', {
          type: 'CCR_AddtoCart'
          },['10be62e6-0bb3-49b8-a9c3-404eac3aae64']);
      }
    }
    localStorage.setItem("currentProgram", JSON.stringify(currentProgram));
    this.props.history.push("/checkout");
  }
  renderNewBatch() {
    return (
      <div className="container mt-4">
        <Row className="h-100">
          <Colxx xxs="12" md="10" className="mx-auto my-auto">
            <Row>
              <Colxx lg={{ size: 10 }} xxs="12">
                <CardTitle className="lead font-weight-bold mb-5">
                  <IntlMessages id="pages.cart-title" />
                </CardTitle>
              </Colxx>
            </Row>
            {this.props.currentPrograms.map(
              (
                currProgram,
                index
              ) => {
                const {
                  batch_no,
                  price,
                  period,
                  registration_start_date,
                  registration_expire_date
                } = currProgram;
                return (
                  <Row key={index}>
                    <Colxx lg={{ size: 8 }} xxs="12">
                      <Card className="d-flex flex-row mb-4 pt-4 pb-5">
                        <ThumbnailImage
                          rounded
                          src="/assets/img/logo-black.svg"
                          alt="Moove Course"
                          className="m-4"
                        />
                        <div className=" d-flex flex-grow-1 min-width-zero">
                          <CardBody className=" pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                            <div className="min-width-zero">
                              <CardSubtitle className="mb-1">
                                <IntlMessages id="pages.product-title" />
                                {batch_no > 0 ? batch_no : "standard"}
                              </CardSubtitle>
                              <CardText className="text-muted text-small mb-2">
                                {
                                  (registration_start_date && registration_expire_date) &&
                                  <IntlMessages id="pages.registration-from"/>
                                }
                                
                              </CardText>
                              {
                                registration_start_date && registration_expire_date
                                  ? 
                                  (
                                    <CardText className="text-muted text-small mb-2">
                                      {moment(registration_start_date).format(
                                            "DD/MM/YYYY"
                                          )}
                                      <IntlMessages id="pages.registration-to" />
                                      {moment(registration_expire_date).format(
                                            "DD/MM/YYYY"
                                          )}
                                    </CardText>
                                  )
                                  :
                                  (
                                    <CardText>
                                      <IntlMessages id="pages.registration-period"/> {period} <IntlMessages id="label.day-unit"/>
                                    </CardText>
                                  )
                              }
                            </div>
                            <div>
                              <p className="lead pt-3">฿{price}.-</p>
                            </div>
                          </CardBody>
                        </div>
                      </Card>
                    </Colxx>
  
                    <Colxx lg={{ size: 4 }} xxs="12">
                      <Card className="mb-2">
                        <CardBody>
                          <div className="text-center">
                            <p className="lead mb-3">
                              <IntlMessages id="pages.total-cart" />
                            </p>
                            <div>
                              <p className="lead font-weight-bold">฿{price}.-</p>
                            </div>
                            <Button
                              size="lb"
                              block
                              color="primary mb-2"
                              onClick={() => this.onClick(currProgram)}
                            >
                              <span className="text-one">
                                <IntlMessages id="pages.secure-checkout" />
                              </span>
                            </Button>
                            <CardText className="text-muted text-small">
                              <IntlMessages id="pages.checkout-description" />
                            </CardText>
                          </div>
                        </CardBody>
                      </Card>
                    </Colxx>
                  </Row>
                )
              }
              
            )}
          </Colxx>
        </Row>
      </div>
    );
  }
  renderSuspense() {
    return (
      <div className="container mt-4">
        <Row className="h-100">
          <Colxx xxs="12" md="10" className="mx-auto my-auto">
            <Row>
              <Colxx lg={{ size: 10 }} xxs="12">
                <CardTitle className="lead font-weight-bold mb-5">
                  <IntlMessages id="pages.cart-title" />
                </CardTitle>
              </Colxx>
            </Row>
            <Row>
              <Colxx xxs="12">
                <Card className="d-flex flex-row mb-4">
                  <ThumbnailImage
                    rounded
                    src="/assets/img/logo-black.svg"
                    alt="Moove Course"
                    className="m-4"
                  />
                  <div className=" d-flex flex-grow-1 min-width-zero">
                    <CardBody className=" pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                      <div className="min-width-zero">
                        <CardSubtitle className="mb-1">
                          <IntlMessages id="pages.not-open" />
                        </CardSubtitle>
                      </div>
                    </CardBody>
                  </div>
                </Card>
              </Colxx>
            </Row>
          </Colxx>
        </Row>
      </div>
    );
  }
  renderCart() {
    if (this.props.currentProgram) {
      return this.renderNewBatch();
    } else {
      return this.renderSuspense();
    }
  }
  render() {
    return (
      <Fragment>
        {this.props.currentProgram !== null ? (
          this.renderCart()
        ) : (
          <div className="loading" />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ authUser }) => {
  const { currentProgram, currentPrograms } = authUser;
  return { currentProgram, currentPrograms };
};

const mapActionsToProps = { getProgram };

export default connect(mapStateToProps, mapActionsToProps)(BatchCart);
