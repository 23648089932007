import React, { Fragment, useState, useEffect } from "react";

import Spinner from "../../components/common/Spinner";
import Images from "../../components/common/Images";
import Buttons from "../../components/common/Buttons";
import { s3Upload, s3Remove } from "../../helpers/awsLib";
import { strPadLeft } from "../../helpers/Utils";

function ImageUpload({ user, side, label, updateImage, sourceImage }) {
  const [loading, flipLoading] = useState(false);
  const [image, setImage] = useState([]);

  useEffect(() => {
    setImage(sourceImage);
  }, [sourceImage])

  async function onChange(e, side) {
    const file = e.target.files[0];
    flipLoading(true);
    const fileType = file.name.substring(file.name.lastIndexOf('.'));

    try {
      const { username = "", week_in_program = 1 } = user;
      const customPrefixName = `images/${username}/week${strPadLeft(week_in_program, '0', 3)}${side}${fileType}`
      const attachment = file ? await s3Upload(file, customPrefixName) : null;

      flipLoading(false);
      setImage([attachment]);
      updateImage({public_id: attachment.public_id}, side);
    } catch (error) {
      alert(error);
      flipLoading(false);
    }
  }

  async function removeImage(id) {
    flipLoading(true);
    try {
      if(id) await s3Remove(id);
      flipLoading(false);
      setImage([]);
      updateImage({public_id: ""}, side);
    } catch (error) {
      alert(error);
      flipLoading(false);
    }
  }

  function onError(error) {
    console.log("in ImageUpload, the reason of error", error);
  }


  function renderComponent() {
    return (
      <Fragment>
        <div>
          {(image.length > 0) 
            ? <Images images={image} removeImage={removeImage} onError={onError} />
            : <Buttons label={label} onChange={(event) => onChange(event, side)} />
          }
        </div>
      </Fragment>
    );
  }

  function content() {
    switch(true) {
      case loading:
        return <Spinner />
      default:
        return renderComponent();
    }
  }

  return (
    <div>
      <div className='imgup-buttons'>
        {content()}
      </div>
    </div>
  )
}

export default ImageUpload;