import React, { Fragment } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Row,
} from "reactstrap";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";

import IntlMessages from "../../helpers/IntlMessages";
import GradientWithRadialProgressCard from "../../components/cards/GradientWithRadialProgressCard";
import { Colxx } from "../../components/common/CustomBootstrap";

const AddNewVideoModal = ({
  modalOpen,
  toggleModal,
  onSubmitVideo,
  onVideoFileChange,
  onVideoFieldsChange,
  resetUploadModal,
  status,
  percentage,
  customLevelValidation,
  isVideoUploadMode,
  toggleVideoMode
}) => {
  return (
    <Modal
      isOpen={modalOpen}
      toggle={toggleModal}
      wrapClassName="modal-right"
      backdrop="static"
    >
      {status === "default" ? (
        <AvForm onSubmit={onSubmitVideo}>
          <ModalHeader toggle={toggleModal}>
            <IntlMessages id="pages.add-new-modal-title" />
          </ModalHeader>
          <ModalBody>
            <Button color="success" outline onClick={toggleVideoMode}>
                {
                  isVideoUploadMode
                    ? <IntlMessages id="label.existing-add" />
                    : <IntlMessages id="label.entirely-add" />
                }
            </Button>
            {isVideoUploadMode 
              ? <Fragment>
                  <AvGroup>
                    <Label for="file">
                      <IntlMessages id="label.video-file" />
                    </Label>
                    <AvField
                      type="file"
                      name="file"
                      id="file"
                      onChange={onVideoFileChange}
                    />
                  </AvGroup>
                  <AvGroup>
                    <Label for="title">
                      <IntlMessages id="label.video-title" />
                    </Label>
                    <AvField 
                      name="title" 
                      id="title" 
                      onChange={onVideoFieldsChange} 
                      validate={{
                          required: { value: true, errorMessage: 'อย่าลืมกรอกชื่อวิดีโอ' }
                      }}
                    />
                  </AvGroup>
                  <AvGroup>
                    <Label for="description">
                      <IntlMessages id="label.video-description" />
                    </Label>
                    <AvField
                      name="description"
                      id="description"
                      onChange={onVideoFieldsChange}
                      validate={{
                          required: { value: true, errorMessage: 'อย่าลืมกรอกคำอธิบาย' }
                      }}
                    />
                  </AvGroup>
                </Fragment>
              :
                <AvGroup>
                  <Label for="id">
                    <IntlMessages id="label.video-file" />
                  </Label>
                  <AvField
                    name="id"
                    id="id"
                    onChange={onVideoFieldsChange}
                  />
                </AvGroup>
            }
            <AvGroup>
              <Label for="level">
                <IntlMessages id="label.video-level" />
              </Label>
              <AvField
                type="select"
                name="level"
                id="level"
                onChange={onVideoFieldsChange}
                validate={{
                    custom: customLevelValidation
                }}
              >
                <option value="" />
                <option value="ELEMENTARY">ELEMENTARY</option>
                <option value="INTERMEDIATE">INTERMEDIATE</option>
                <option value="ADVANCE">ADVANCE</option>
              </AvField>
            </AvGroup>
            <AvGroup>
              <Label for="score">
                <IntlMessages id="label.video-score" />
              </Label>
              <AvField
                type="number"
                name="score"
                id="score"
                onChange={onVideoFieldsChange}
                validate={{
                  required: { value: true, errorMessage: 'อย่าลืมกรอกคะแนน' },
                  min: { value: 1, errorMessage: 'คะแนนขั้นต่ำต้องเท่ากับ 1' }
                }}
              />
            </AvGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" outline onClick={toggleModal}>
              <IntlMessages id="pages.close" />
            </Button>
            <Button color="primary">
              <IntlMessages id="pages.upload" />
            </Button>{" "}
          </ModalFooter>
        </AvForm>
      ) : (
          <Row>
            <Colxx xxs="12">
              <ModalHeader>
                <IntlMessages id="pages.add-new-modal-title" />
              </ModalHeader>
              <ModalBody>
                <GradientWithRadialProgressCard
                  icon={
                    status==="preparing"
                      ? "iconsminds-loading-2"
                      : status==="uploading"
                        ? "simple-icon-cloud-upload"
                        : status==="processing"
                          ? "iconsminds-gears"
                          : status==="error"
                            ? "simple-icon-close"
                            : "simple-icon-check"
                  }
                  title={status === "success" ? "Completed" : status.toUpperCase()}
                  detail=""
                  percent={isVideoUploadMode ? percentage : 100}
                  progressText={`${isVideoUploadMode ? percentage: 100}%`}
                />
              </ModalBody>
              {(status === "success" || status === "error") && (
                <ModalFooter>
                  <Button color="secondary" outline onClick={toggleModal}>
                    <IntlMessages id="pages.close" />
                  </Button>
                  <Button color="primary" onClick={resetUploadModal}>
                    <IntlMessages id="label.new-upload" />
                  </Button>{" "}
                </ModalFooter>
              )}
            </Colxx>
          </Row>
      )}
    </Modal>
  );
};

export default AddNewVideoModal;
