import React from "react";
import { 
    Card,
    Button
  } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import IntlMessages from "../../helpers/IntlMessages";

const TrainingRow = (params) => {
    const { 
        exercise, 
        reps, 
        sets, 
        rest,
        videoId,
        onClickedItem,
        is_play
    } = params;

    return (
        <Card className="wp-row">
          <div className={`wp-large-item ${is_play && "wp-green-item"}`}>
            {is_play && <FontAwesomeIcon icon={faCheck} size="1x" color="#32CD32" />} {` ${exercise} `}
            {is_play && <IntlMessages id="activities.weight-played" />} 
          </div>
          <div className={`wp-center-item  ${is_play && "wp-green-item"}`}>
            {reps}
          </div>
          <div className={`wp-center-item  ${is_play && "wp-green-item"}`}>
            {sets}
          </div>
          <div className={`wp-center-item  ${is_play && "wp-green-item"}`}>
            {rest}
          </div>
          {
            videoId 
            ?
            (
              <div className="wp-center-item">
                <Button
                  onClick={event => onClickedItem(event, videoId)}
                  color="primary"
                  className="btn-shadow"
                  size="xs"
                  target="_blank"
                >
                  <IntlMessages id="activities.weight-example" />
                </Button>
              </div>
            )
            :
            (
              <div className="wp-center-item">
                <Button
                  color="dark"
                  size="xs"
                  disabled
                >
                  <IntlMessages id="activities.weight-example" />
                </Button>
              </div>
            )
          }
        </Card>
    )
}

export default TrainingRow;