import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, CardTitle } from "reactstrap";
import ReactTable from "react-table";
import { connect } from "react-redux";
import moment from "moment";
import { injectIntl } from 'react-intl';
import { CSVLink } from 'react-csv';

import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import DataTablePagination from "../../../components/DatatablePagination";
import { getAllSubscriptions, getAllUnsubscriptions, updateSubscription } from "../../../redux/admin";
import { formatNumber } from "../../../helpers/Utils";
import LoaderButton from "../../../components/LoaderButton";
import IntlMessages from "../../../helpers/IntlMessages";

class approve extends Component {
  constructor(props) {
    super(props);

    this.handleUpdateSubscription = this.handleUpdateSubscription.bind(this);
  }
  componentDidMount() {
    this.props.getAllSubscriptions();
    this.props.getAllUnsubscriptions();
  }

  handleUpdateSubscription(data, payment_status) {
    const { user_id, program_id } = data;
    this.props.updateSubscription({ user_id, program_id, payment_status });
  }

  render() {
    const { pendings, approves, rejects, unsubscribers, intl: { messages } } = this.props;
    const unsubFileHeaders = [
      { label: "User ID", key: "user_id" },
      { label: "E-Mail", key: "email" },
      { label: "fullname", key: "name" },
      { label: "FB Profile", key: "facebookLink" },
      { label: "phone", key: "phone" },
      { label: "address", key: "address" },
      { label: "Register Date", key: "register_date"}
    ];
    const normalFileHeaders = [
      { label: "User ID", key: "user_id" },
      { label: "E-Mail", key: "email" },
      { label: "fullname", key: "name" },
      { label: "FB Profile", key: "facebookLink" },
      { label: "phone", key: "phone" },
      { label: "address", key: "address" },
      { label: "Batch No.", key: "batch_no"},
      { label: "Payment Type", key: "payment_type"},
      { label: "Payment Date", key: "payment_date"},
      { label: "Payment Amount", key: "amount"},
      { label: "Payment Status", key: "payment_status"},
    ];
    const unsubTableColumns = [
      {
        Header: messages["user.email"],
        accessor: "email",
        width: 200,
        Cell: props => <p className="list-item-heading">{props.value}</p>
      },
      {
        Header: messages["user.fullname"],
        accessor: "name",
        maxWidth: 200,
        Cell: props => <p className="text-muted">{props.value}</p>
      },
      {
        Header: "FB Profile",
        accessor: "facebookLink",
        style: { 'whiteSpace': 'unset'},
        maxWidth: 250,
        Cell: props => <p className="text-muted">{props.value}</p>
      },
      {
        Header: messages["user.phone"],
        accessor: "phone",
        Filter: () => <div></div>,
        maxWidth: 130,
        Cell: props => <p className="text-muted">{props.value}</p>
      },
      {
        Header: messages["user.address"],
        accessor: "address",
        Filter: () => <div></div>,
        maxWidth: 250,
        Cell: props => {
          if(props.value && props.value.length > 0) {
            return (
              <div className="text-muted">
              {
                props.value.map((address, index) => 
                  <p key={index}>{address}</p>
                )
              }
            </div>
            )
          } else {
            return (
              <div className="text-muted">
              {
                props.value
              }
            </div>
            )
          }
        }
      },
      {
        Header: messages["label.register-date"],
        accessor: "register_date",
        sortMethod: (a, b) => {
          return moment(a).isBefore(moment(b), 'day') ? -1 : 1;
        },
        width: 130,
        Cell: props => <p className="text-muted text-center">{props.value}</p>
      }
    ]
    const dataTableColumns = [
      {
        Header: messages["user.email"],
        accessor: "email",
        width: 200,
        Cell: props => <p className="list-item-heading">{props.value}</p>
      },
      {
        Header: messages["user.fullname"],
        accessor: "name",
        maxWidth: 150,
        Cell: props => <p className="text-muted">{props.value}</p>
      },
      {
        Header: "FB Profile",
        accessor: "facebookLink",
        style: { 'whiteSpace': 'unset'},
        maxWidth: 100,
        Cell: props => <p className="text-muted">{props.value}</p>
      },
      {
        Header: messages["user.phone"],
        accessor: "phone",
        Filter: () => <div></div>,
        maxWidth: 130,
        Cell: props => <p className="text-muted">{props.value}</p>
      },
      {
        Header: messages["user.address"],
        accessor: "address",
        Filter: () => <div></div>,
        maxWidth: 450,
        Cell: props => {
          if(props.value && props.value.length > 0) {
            return (
              <div className="text-muted">
              {
                props.value.map((address, index) => 
                  <p key={index}>{address}</p>
                )
              }
            </div>
            )
          } else {
            return (
              <div className="text-muted">
              {
                props.value
              }
            </div>
            )
          }
        }
      },
      {
        Header: messages["label.batch-no"],
        accessor: "batch_no",
        width: 50,
        Cell: props => <p className="text-muted text-center">{props.value}</p>
      },
      {
        Header: messages["user.payment_type"],
        accessor: "payment_type",
        width: 100,
        Cell: props => <p className="text-muted text-center">{props.value}</p>
      },
      {
        Header: messages["label.transfer-datetime"],
        accessor: "payment_date",
        width: 130,
        Cell: props => <p className="text-muted text-center">{props.value}</p>
      },
      {
        Header: messages["label.transfer-month"],
        accessor: "payment_date",
        width: 130,
        Cell: props => <p className="text-muted text-center">{parseInt(props.value.substr(3,2))}</p>
      },
      {
        Header: () => (
          <div style={{textAlign: "right"}}>
            {messages["label.transfer-amount"]}
          </div>
        ),
        accessor: "amount",
        Filter: () => <div></div>,
        width: 130,
        Cell: props => (
          <p className="text-muted text-right">{formatNumber(props.value)}</p>
        )
      },
      {
        Header: () => (
          <div style={{textAlign: "center"}}>
            {messages["label.status"]}
          </div>
        ),
        accessor: "payment_status",
        Filter: () => <div></div>,
        width: 200,
        Cell: props => (props.value === "pending" || props.value === "rejected" ?
          <div className="text-center align-text-bottom">
            <LoaderButton
              status="default"
              id="approving-button"
              color="primary"
              className="btn-shadow mr-1"
              size="sm"
              onClick={() => this.handleUpdateSubscription(props.original, "approved")}
            >
              <IntlMessages id="label.approve-button"/>
            </LoaderButton>
            { props.value === "pending" &&
              <LoaderButton
                status="default"
                id="approving-button"
                color="danger"
                className="btn-shadow"
                size="sm"
                onClick={() => this.handleUpdateSubscription(props.original, "rejected")}
              >
                <IntlMessages id="label.reject-button"/>
              </LoaderButton>
            }
          </div>
          :
          <p className="list-item-heading text-center">{props.value}</p>
        )
      }
    ];
    
    const sortedColumns = [
      {
        id: "batch_no",
        desc: true
      },
      {
        id: "payment_date",
        desc: false
      }
    ]

    const unsubSortedColumns = [
      {
        id: "register_date",
        desc: true
      }
    ]

    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.start" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card className="mb-4">
              <CardBody>
                <CardTitle>
                  <IntlMessages id="label.unsubcribers-title"/>
                  <span className="float-right">
                    <CSVLink 
                      data={unsubscribers}
                      filename="unscribers.csv"
                      headers={unsubFileHeaders}
                      className="btn btn-primary btn-sm">
                        <span className="text-one">Export File</span>
                    </CSVLink>
                  </span>
                </CardTitle>
                <ReactTable
                  data={unsubscribers}
                  columns={unsubTableColumns}
                  defaultPageSize={10}
                  filterable={true}
                  showPageJump={true}
                  PaginationComponent={DataTablePagination}
                  showPageSizeOptions={true}
                  defaultSorted={unsubSortedColumns}
                />
              </CardBody>
            </Card>
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card className="mb-4">
              <CardBody>
                <CardTitle>
                  <IntlMessages id="label.pending-title"/>
                  <span className="float-right">
                    <CSVLink 
                      data={pendings}
                      filename="pendings.csv"
                      headers={normalFileHeaders}
                      className="btn btn-primary btn-sm">
                        <span className="text-one">Export File</span>
                    </CSVLink>
                  </span>
                </CardTitle>
                <ReactTable
                  data={pendings}
                  columns={dataTableColumns}
                  defaultPageSize={10}
                  filterable={true}
                  showPageJump={true}
                  PaginationComponent={DataTablePagination}
                  showPageSizeOptions={true}
                  defaultSorted={sortedColumns}
                />
              </CardBody>
            </Card>
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card className="mb-4">
              <CardBody>
                <CardTitle>
                  <IntlMessages id="label.rejected-title"/>
                  <span className="float-right">
                    <CSVLink 
                      data={rejects}
                      filename="rejects.csv"
                      headers={normalFileHeaders}
                      className="btn btn-primary btn-sm">
                        <span className="text-one">Export File</span>
                    </CSVLink>
                  </span>
                </CardTitle>
                <ReactTable
                  data={rejects}
                  columns={dataTableColumns}
                  defaultPageSize={10}
                  filterable={true}
                  showPageJump={true}
                  PaginationComponent={DataTablePagination}
                  showPageSizeOptions={true}
                  defaultSorted={sortedColumns}
                />
              </CardBody>
            </Card>
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card className="mb-4">
              <CardBody>
                <CardTitle>
                  <IntlMessages id="label.approved-title"/>
                  <span className="float-right">
                    <CSVLink 
                      data={approves}
                      filename="approves.csv"
                      headers={normalFileHeaders}
                      className="btn btn-primary btn-sm">
                        <span className="text-one">Export File</span>
                    </CSVLink>
                  </span>
                </CardTitle>
                <ReactTable
                  data={approves}
                  columns={dataTableColumns}
                  defaultPageSize={10}
                  filterable={true}
                  showPageJump={true}
                  PaginationComponent={DataTablePagination}
                  showPageSizeOptions={true}
                  defaultSorted={sortedColumns}
                />
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ admin }) => {
  const { status, pendings, approves, rejects, unsubscribers } = admin;
  return { status, pendings, approves, rejects, unsubscribers };
};
const mapActionsToProps = { getAllSubscriptions, getAllUnsubscriptions, updateSubscription };

export default connect(
  mapStateToProps,
  mapActionsToProps
)(injectIntl(approve));
