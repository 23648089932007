import React, { Component, Fragment } from "react";
import { Row, Label, Button, Alert } from "reactstrap";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { connect } from "react-redux";
import { loginUser } from "../../redux/auth";

import IntlMessages from "../../helpers/IntlMessages";
import { Colxx } from "../../components/common/CustomBootstrap";

class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
          email: "",
          password: ""
        };
        
        this.onUserLogin = this.onUserLogin.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.renderErrorMessage = this.renderErrorMessage.bind(this);
    }
    onUserLogin(event, errors) {
        if (errors.length === 0) {
          this.props.loginUser(this.state);
        }
    }
    
    handleChange(event) {
        this.setState({
          [event.target.id]: event.target.value
        })
    }
    
    renderErrorMessage(errorMessageID) {
        if (!errorMessageID) {
          return null;
        }
        return (
          <Colxx xxs={12} md={12}>
            <Alert color="danger">
              <IntlMessages id={errorMessageID}/>
            </Alert>
          </Colxx>
        );
    }
    
    render() {
        const { 
            email,
            password
        } = this.state;

        return (
            <Fragment>
                <AvForm onSubmit={this.onUserLogin}>
                    <Row>
                        <Colxx xxs={12} md={12}>
                            <AvGroup>
                                <Label><IntlMessages id="user.email" /></Label>
                                <AvField 
                                    type="email" 
                                    name="email" 
                                    id="email" 
                                    value={email} 
                                    onChange={(event) => this.handleChange(event)} 
                                    validate={{
                                        required: { value: true, errorMessage: 'อย่าลืมกรอกอีเมล์' },
                                        email: { value: true, errorMessage: 'รูปแบบอีเมล์ไม่ถูกต้อง' },
                                    }}
                                />
                            </AvGroup>
                        </Colxx>
                        <Colxx xxs={12} md={12}>
                            <AvGroup>
                                <Label><IntlMessages id="user.password"/></Label>
                                <AvField 
                                    type="password" 
                                    name="password" 
                                    id="password" 
                                    value={password} 
                                    onChange={(event) => this.handleChange(event)} 
                                    validate={{
                                        required: { value: true, errorMessage: 'อย่าลืมกรอกพาสเวิร์ด' },
                                        minLength: { value: 6, errorMessage: 'ขั้นต่ำ 6 ตัวอักษร' }
                                    }}
                                />
                            </AvGroup>
                        </Colxx>
                        {this.renderErrorMessage(this.props.errorMessageID)}
                    </Row>
                    <div className="d-flex justify-content-end align-items-center">
                        <Button
                            color="primary"
                            className="btn-shadow"
                            size="lg"
                        >
                            <span className="text-one text-white"><IntlMessages id="user.login-button" /></span>
                        </Button>
                    </div>
                </AvForm>
            </Fragment>
        );
    }
}
const mapStateToProps = ({ authUser }) => {
    const { user, loading, errorMessageID } = authUser;
    return { user, loading, errorMessageID };
};
  
const mapActionsToProps = { loginUser };
  
export default connect(
    mapStateToProps,
    mapActionsToProps
)(LoginForm);
