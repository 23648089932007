import appLocaleData from 'react-intl/locale-data/th';
import thMessages from '../locales/th-TH';

const ThLang = {
    messages: {
        ...thMessages
    },
    locale: 'th-TH',
    data: appLocaleData
};
export default ThLang;