import React, { Component } from "react";
import {
  Row,
  Button,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Collapse
} from "reactstrap";
import { injectIntl } from "react-intl";

import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import Breadcrumb from "../navs/Breadcrumb";
import IntlMessages from "../../helpers/IntlMessages";

class ListPageHeading extends Component {
  constructor(props) {
    super();
    const { messages } = props.intl;
    
    const orderData = [
      { column: "title", label: messages["pages.name-label"] },
      { column: "duration", label: messages["pages.duration-label"] }
    ];

    const scoreData = [
      { value: [0, 999], label: messages["pages.score-label-all"] },
      { value: [0, 2], label: messages["pages.score-label-0-2"] },
      { value: [3, 5], label: messages["pages.score-label-3-5"] },
      { value: [6, 999], label: messages["pages.score-label-6-more"] },
    ];

    const durationData = [
      { value: [0, 7200], label: messages["pages.duration-label-all"] },
      { value: [0, 120], label: messages["pages.duration-label-0-2"] },
      { value: [121, 240], label: messages["pages.duration-label-2-4"] },
      { value: [241, 7200], label: messages["pages.duration-label-4-more"] },
    ];

    this.state = {
      displayOptionsIsOpen: false,
      search: "",
      orderOptions: orderData,
      selectedOrderOption: orderData[0],
      scoreOptions: scoreData,
      selectedScoreOption: scoreData[0],
      durationOptions: durationData,
      selectedDurationOption: durationData[0],
    };

    this.onSearchChange = this.onSearchChange.bind(this);
    this.onScoreOptionChange = this.onScoreOptionChange.bind(this);
    this.onDurationOptionChange = this.onDurationOptionChange.bind(this);
  }
  
  toggleDisplayOptions = () => {
    this.setState(prevState => ({
      displayOptionsIsOpen: !prevState.displayOptionsIsOpen
    }));
  };

  onSearchChange(event) {
    this.setState({ search: event.target.value.toLowerCase() },
      () => this.props.onSearchKey(this.state.search)
    );
  }

  onScoreOptionChange(selectedScoreOption) {
    this.setState({ selectedScoreOption },
      () => this.props.onRangeFilter(selectedScoreOption.value, "score"))
  }

  onDurationOptionChange(selectedDurationOption) {
    this.setState({ selectedDurationOption },
      () => this.props.onRangeFilter(selectedDurationOption.value, "duration"));
  }

  render() {
    const { messages } = this.props.intl;
    const {
      heading,
      changeOrderBy,
      changePageSize,
      selectedPageSize,
      totalItemCount,
      match,
      startIndex,
      endIndex,
      pageSizes,
      toggleModal,
      isAdmin,
    } = this.props;

    const { 
      displayOptionsIsOpen,
      search, 
      scoreOptions, 
      orderOptions, 
      selectedOrderOption,
      durationOptions,
      selectedDurationOption,
    } = this.state;
    return (
      <Row>
        <Colxx xxs="12">
          <div className="mb-2">
            <h1>
              <IntlMessages id={heading} />
            </h1>
            {
              isAdmin &&
              <div className="float-sm-right">
                <Button
                  color="primary"
                  size="lg"
                  className="top-right-button"
                  onClick={()=>toggleModal()}
                >
                  <IntlMessages id="pages.add-new" />
                </Button>
              </div>
            }
            <Breadcrumb match={match} />
          </div>

          <div className="mb-2">
            <Button
              color="empty"
              className="pt-0 pl-0 d-inline-block d-md-none"
              onClick={this.toggleDisplayOptions}
            >
              <IntlMessages id="pages.display-options" />{" "}
              <i className={`${displayOptionsIsOpen ? "simple-icon-arrow-up" : "simple-icon-arrow-down"} align-middle`} />
            </Button>
            <Collapse
              isOpen={displayOptionsIsOpen}
              className="d-md-block"
              id="displayOptions"
            >
              <div className="d-block d-md-inline-block">
                <UncontrolledDropdown className="mr-1 float-md-left btn-group mb-1">
                  <DropdownMenu>
                    {scoreOptions.map((score, index) => {
                      return (
                        <DropdownItem
                          key={index}
                          onClick={() => this.onScoreOptionChange(score)}
                        >
                          {score.label}
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown className="mr-1 float-md-left btn-group mb-1">
                  <DropdownToggle caret color="outline-dark" size="xs">
                    <IntlMessages id="pages.filterby-duration" />
                    {selectedDurationOption.label}
                  </DropdownToggle>
                  <DropdownMenu>
                    {durationOptions.map((duration, index) => {
                      return (
                        <DropdownItem
                          key={index}
                          onClick={() => this.onDurationOptionChange(duration)}
                        >
                          {duration.label}
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown className="mr-1 float-md-left btn-group mb-1">
                  <DropdownToggle caret color="outline-dark" size="xs">
                    <IntlMessages id="pages.orderby" />
                    {selectedOrderOption.label}
                  </DropdownToggle>
                  <DropdownMenu>
                    {orderOptions.map((order, index) => {
                      return (
                        <DropdownItem
                          key={index}
                          onClick={() => changeOrderBy(order.column)}
                        >
                          {order.label}
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </UncontrolledDropdown>
                <div className="search-sm d-inline-block float-md-left mr-1 mb-1 align-top">
                  <input
                    type="text"
                    name="keyword"
                    id="search"
                    placeholder={messages["menu.search"]}
                    onChange={e => this.onSearchChange(e)}
                    value={search}
                  />
                </div>
              </div>
              <div className="float-md-right">
                <span className="text-muted text-small mr-1">{`${startIndex}-${endIndex} of ${totalItemCount} `}</span>
                <UncontrolledDropdown className="d-inline-block">
                  <DropdownToggle caret color="outline-dark" size="xs">
                    {selectedPageSize}
                  </DropdownToggle>
                  <DropdownMenu right>
                    {pageSizes.map((size, index) => {
                      return (
                        <DropdownItem
                          key={index}
                          onClick={() => changePageSize(size)}
                        >
                          {size}
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </Collapse>
          </div>
          <Separator className="mb-5" />
        </Colxx>
      </Row>
    );
  }
}

export default injectIntl(ListPageHeading);
