import moment from "moment";
import { ThemeColors } from './ThemeColors'
import { defaultDirection } from '../constants/defaultValues';

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    var A = a[key], B = b[key];
    if (order.indexOf(A + "") > order.indexOf(B + "")) {
      return 1;
    } else {
      return -1;
    }
  });
  return array;
};


export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!

  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return dd + '.' + mm + '.' + yyyy;
}

export const getCurrentTime=()=>{
  const now = new Date();
  return now.getHours() + ":" + now.getMinutes()
}

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getDirection -> error", error)
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};
export const getNutritionChartData = ( chartData ) => {
  const colors = ThemeColors()
  const { veggieLabel, veggieValue, meatLabel, meatValue, carbLabel, carbValue } = chartData;
  return {
    labels: [veggieLabel, meatLabel, carbLabel],
    datasets: [
      {
        label: '',
        borderColor: [colors.veggieColor, colors.meatColor, colors.carbColor],
        backgroundColor: [
          colors.veggieColor,
          colors.meatColor,
          colors.carbColor
        ],
        borderWidth: 2,
        data: [veggieValue, meatValue, carbValue]
      }
    ]
  }
}

export const strPadLeft = (string,pad,length) => {
  return (new Array(length+1).join(pad)+string).slice(-length);
}

export const sortGeneralArray = (list, fieldName, asc=true, isString=true) => {
  const mapped = list.map(function(el, i) {
    return { index: i , value: isString ? el[fieldName].toLowerCase() : el[fieldName] }
  });

  mapped.sort(function(a, b) {
    if (a.value > b.value) {
      return asc ? 1 : -1;
    }
    if (a.value < b.value) {
      return asc ? -1 : 1;
    }
    return 0;
  });

  const result = mapped.map(function(el) {
    return list[el.index];
  });

  return result;
}

export function getUrlVars() {
  let vars = [];
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
      vars.push(decodeURIComponent(value));
  });
  return vars;
}

export function getUrlVarsObject() {
  let vars = {};
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
      vars[key] = decodeURIComponent(value);
  });
  return vars;
}

export function formatNumber(num) {
  if (!num) return "";
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function calculatePhase(weekNo, phaseRange=4) {
  return Math.ceil(weekNo/phaseRange);
}

export function calculatePhaseStep(weekNo, phaseRange=4) {
  const phaseStep = weekNo % phaseRange === 0 ? "maintenance" : (weekNo % phaseRange).toString();
  return phaseStep;
}

export function calculatePreviousResult(weekly_plan, daily_activities) {
  let food = {
    fat: 0,
    protein: 0,
    carbohydrate: 0,
    low_fat: 0,
    low_carb: 0
  }
  let cardio = 0;
  const {
    plan,
    weight_program
  } = weekly_plan;

  if (plan) {
    food = plan.food;
    cardio = plan.cardio;
  }

  const {
    fat,
    protein,
    carbohydrate,
    low_carb = 0,
    low_fat = 0
  } = food;
  
  const foodGoal = {
    energy: ((fat*9) + (protein*4) + (carbohydrate*4)),
    protein: protein,
    fat: fat,
    carbohydrate: carbohydrate,
    low_carb,
    low_fat
  };
  let foodActual = daily_activities.reduce((acc, curr) => {
    const {
      dailyEnergy,
      dailyProtein,
      dailyFat,
      dailyCarb
    } = (curr.foods)
        ?  curr.foods.reduce(
            (inacc, food) => ({
              dailyEnergy: inacc.dailyEnergy + ((food.fat*9) + (food.protein*4) + (food.carbohydrate*4)),
              dailyProtein: inacc.dailyProtein + food.protein,
              dailyFat: inacc.dailyFat + food.fat,
              dailyCarb: inacc.dailyCarb + food.carbohydrate
            }), {
              dailyEnergy: 0,
              dailyProtein: 0,
              dailyFat: 0,
              dailyCarb: 0
            })
        : {
          dailyEnergy: 0,
          dailyProtein: 0,
          dailyFat: 0,
          dailyCarb: 0
        };
    return {
      energy: acc.energy + dailyEnergy,
      protein: acc.protein + dailyProtein,
      fat: acc.fat + dailyFat,
      carbohydrate: acc.carbohydrate + dailyCarb
    };
  }, {
    energy: 0,
    protein: 0,
    fat: 0,
    carbohydrate: 0
  });

  foodActual = {
    energy: parseInt(foodActual.energy/7),
    protein: parseInt(foodActual.protein/7),
    fat: parseInt(foodActual.fat/7),
    carbohydrate: parseInt(foodActual.carbohydrate/7)
  }

  const cardioActual = daily_activities.reduce((acc, curr) => {
    const accum = curr.cardio
                    ? curr.cardio.reduce((inacc, incurr) => inacc + incurr.time, 0)
                    : 0;
    return acc + accum;
  }, 0);
  
  const trainGoal = weight_program ? weight_program.length : 0;

  const trainActual = weight_program 
                        ? weight_program.reduce((acc, curr) => (
                          acc + (curr.is_play ? 1 : 0)
                        ), 0)
                        : 0;

  return {
    foodGoal, 
    foodActual,
    cardioGoal: cardio,
    cardioActual,
    trainGoal,
    trainActual
  };
}

export function  calculateCoursePeriod(currentProgram, healthProfile) {
  const { period, program_start_date, program_expire_date } = currentProgram;
  const dateFormat = 'YYYY-MM-DD HH:mm';
  let start_date, expire_date;
  if (healthProfile) {
    start_date = moment(healthProfile.start_date).format(dateFormat);
    expire_date = moment(healthProfile.expire_date).isSameOrAfter(moment(), 'day')
                    ? moment(healthProfile.expire_date).add(period || 60, 'days').format(dateFormat)
                    : moment().add(period || 60, 'days').format(dateFormat);
  } else {
    if (period) {
      start_date = moment().format(dateFormat);
      expire_date = moment().add(period, "days").format(dateFormat);
    } else if (program_start_date && program_expire_date) {
      start_date = moment(program_start_date).format(dateFormat);
      expire_date = moment(program_expire_date).isSameOrAfter(moment()) 
                      ? moment(program_expire_date).format(dateFormat)
                      : moment().add(60, "days").format(dateFormat);
    } else if (program_start_date) {
      start_date = moment(program_start_date).format(dateFormat);
      expire_date = moment(program_start_date).add(period || 60, "days").format(dateFormat);
    } else {
      start_date = moment().format(dateFormat);
      expire_date = moment().add(60, "days").format(dateFormat);
    }
  }
  return { start_date, expire_date };
}
