import React, { Component, Fragment } from "react";
import { Modal, ModalBody } from "reactstrap";
import { connect } from "react-redux";


class TransferNotice extends Component {
  constructor(props) {
    super(props);

    this.checkStatus = this.checkStatus.bind(this);
  }
  componentDidMount() {
    this.checkStatus(this.props);
  }
  componentWillReceiveProps(newProps) {
    this.checkStatus(newProps);
  }

  checkStatus(props) {
    const {
      status,
      isAuthenticated,
      history,
    } = props;

    if(isAuthenticated) {
        
        // in case of pending payment redirect him to enroll
        if (status === "success" || status === "default") {
            history.push("/");
        }
      
    }
  }

  render() {
    return (
      <Fragment>
        <Modal isOpen={true}>
          <ModalBody>
            <div className="loading"></div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <div className="text-center text-medium">
              กำลังจัดเตรียมข้อมูล<br/>โปรดอย่าปิดเบราว์เซอร์
            </div>
          </ModalBody>
        </Modal>      
      </Fragment>
    );
  }
}

const mapStateToProps = ({ authUser, checkout }) => {
  const {
    isAuthenticated
  } = authUser
  const { status } = checkout
  
  return {
    isAuthenticated, 
    status
  };
};

const mapActionsToProps = {  };

export default connect(
  mapStateToProps,
  mapActionsToProps
)(TransferNotice);
