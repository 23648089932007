export const userMenu = [
  /* {
    id: "main",
    icon: "iconsminds-aerobics",
    label: "menu.app",
    to: "/app/main"
  }, */
  {
    id: "dailyrecord",
    icon: "iconsminds-check",
    label: "menu.daily-record",
    to: "/app/daily-record"
  },
  {
    id: "weightprogram",
    icon: "iconsminds-dumbbell",
    label: "menu.weight-program",
    to: "/app/weight-program"
  },
  {
    id: "weeklyprogress",
    icon: "iconsminds-calendar-4",
    label: "menu.weekly-submit",
    to: "/app/weekly-submit"
  }
];

export const adminMenu = [
  {
    id: "homework",
    icon: "simple-icon-book-open",
    label: "menu.homework",
    to: "/app/admin/homework"
  },
  {
    id: "approve",
    icon: "iconsminds-handshake",
    label: "menu.approve",
    to: "/app/admin/approve"
  },
  {
    id: "history",
    icon: "simple-icon-calendar",
    label: "menu.history",
    to: "/app/admin/userHistory"
  }
];

export const pendingMenu = [
  {
    id: "waiting",
    icon: "iconsminds-sand-watch-2",
    label: "menu.coming-soon",
    to: "/app/coming-soon"
  }
]

