import React, {Fragment, Component} from 'react';
import { Row } from "reactstrap";
import { connect } from "react-redux";
import ModalVideo from "react-modal-video";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import Breadcrumb from "../../containers/navs/Breadcrumb";
import DaysForTraining from "../../containers/navs/DaysForTraining";
import TrainingRow from "../../components/cards/TrainingRow";
import { getActivities, updateWeeklyPlan } from "../../redux/activities";
import Spinner from "../../components/common/Spinner";
import IntlMessages from "../../helpers/IntlMessages";
import LoaderButton from '../../components/LoaderButton';

class weightProgram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVideoModalOpen: false,
      selectedVideo: null,
      currentDay: 1
    }

    this.onClosedVideoModal = this.onClosedVideoModal.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onClickedItem = this.onClickedItem.bind(this);
    this.changeDay = this.changeDay.bind(this);
    this.submitData = this.submitData.bind(this);
    this.renderList = this.renderList.bind(this);
  }

  componentDidMount() {
    const { weeklyPlan, user } = this.props;
    if (weeklyPlan.weight_program.length === 0) {
      this.props.getActivities(user.username);
    }
  }

  componentWillReceiveProps(newProps) {
    if(newProps.weeklyPlan.weight_program.length > 0){
      const currentProgram = [...newProps.weeklyPlan.weight_program].reverse().find(element => element.is_play);
      let currentDay = currentProgram ? currentProgram.day : 1;
      this.changeDay(currentDay);
    }
  }

  onClosedVideoModal = () => {
    this.setState({
        isVideoModalOpen: false
    });
  }

  onClickedItem = (event, selectedVideo) => {
    this.setState(prevState => ({
        selectedVideo,
        isVideoModalOpen: !prevState.isVideoModalOpen
    }));
  }

  toggle = () => {
    this.setState(prevState => ({
      isVideoModalOpen: !prevState.isVideoModalOpen
    }))
  }

  changeDay(currentDay) {
    this.setState({
      currentDay
    }, () => this.state);
  }

  submitData(is_play) {
    const { currentDay } = this.state;
    const { weeklyPlan, entireData, user } = this.props;
    const newWeightProgram = weeklyPlan.weight_program.map(elem => (
      elem.day === currentDay 
        ? {...elem, is_play}
        : elem
    ));
    const newWeeklyPlan = {
      ...weeklyPlan,
      weight_program: newWeightProgram
    }
    this.props.updateWeeklyPlan(newWeeklyPlan, entireData, user);
  }

  renderList() {
    const {
      isVideoModalOpen,
      selectedVideo,
      currentDay
    } = this.state;

    const {
      weeklyPlan: {weight_program},
      loading
    } = this.props;

    const { is_play } = weight_program[currentDay - 1];
    console.log("inside renderList:", isVideoModalOpen);

    return (
      <Fragment>
        <ModalVideo 
            channel='youtube' 
            isOpen={isVideoModalOpen} 
            videoId={selectedVideo} 
            onClose={this.onClosedVideoModal} 
        />
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.weight-program" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row className="wp-page">
          <div className="wp-section wp-content mb-4">
            <DaysForTraining
              changeDay={this.changeDay}
              currentDay={currentDay}
              weight_program={weight_program}
            />
            <div className="wp-table-wrapper">
              <div className={`wp-table-title ${is_play && "wp-table-title-alt"}`}>
                {is_play && <FontAwesomeIcon icon={faCheckCircle} size="1x" color="white" />}
                {' '}
                <IntlMessages id="label.day" /> {currentDay} 
                {' '}
                {is_play && <IntlMessages id="activities.weight-played"/> }
                {is_play && " " }
                {is_play && <FontAwesomeIcon icon={faCheckCircle} size="1x" color="white" />}
              </div>
              <div className="wp-row nomargin big">
                <div className="wp-large-item">
                  <IntlMessages id="activities.weight-name" />
                </div>
                <div className="wp-center-item">
                  <IntlMessages id="activities.weight-rep" />
                </div>
                <div className="wp-center-item">
                  <IntlMessages id="activities.weight-set" />
                </div>
                <div className="wp-center-item">
                  <IntlMessages id="activities.weight-rest" />
                </div>
                <div className="wp-center-item">
                  {""}
                </div>
              </div>
              {
                weight_program[currentDay-1].program.map(item => (
                  <TrainingRow 
                    key={item.order}
                    exercise={item.name}
                    reps={item.rep}
                    sets={item.set}
                    rest={item.rest}
                    videoId={item.resource}
                    onClickedItem={this.onClickedItem}
                    is_play={is_play}
                  />
                ))
              }
              {
                !is_play &&
                <div className="wp-content-title">
                  <LoaderButton 
                    color="primary"
                    className="text-medium vertical-button"
                    status={loading ? "processing" : "default"}
                    onClick={() => this.submitData(true)}
                  >
                    <FontAwesomeIcon icon={faArrowRight} size="1x" color="white" />
                    &nbsp;<IntlMessages id="activities.weight-click" />&nbsp;
                    <FontAwesomeIcon icon={faArrowLeft} size="1x" color="white" />
                  </LoaderButton>
                </div>
              }
            </div>
          </div>
          <div className="wp-section wp-header">
            <p className="wp-title">
              วิธีการดูโปรแกรม<br/>
              <span className="wp-text-main">
                <IntlMessages id="activities.weight-training" />
              </span>
            </p>
          </div>
          <div className="wp-section wp-hl">
            <p className="wp-hl-text">
              <IntlMessages id="label.day" /> = จำนวนวันที่ฝึกในแต่ละสัปดาห์<br/>
              <IntlMessages id="activities.weight-name" /> = ท่าฝึก<br/>
              <IntlMessages id="activities.weight-rep" /> = จำนวนครั้งที่ทำ<br/>
              <IntlMessages id="activities.weight-set" /> = จำนวนรอบที่ทำ<br/>
              <IntlMessages id="activities.weight-rest" /> = จำนวนการพัก<br/>
              <span className="demo-button"><IntlMessages id="activities.weight-example" /></span> = คลิกเพื่อเข้าดูวิธีการทำท่านั้นๆ
              <br/>
            </p>
          </div>
          <div className="wp-section wp-hr">
            <p className="wp-hr-text">
              <span className="wp-hr-title">วิธีใช้งาน</span><br/>
              1.เริ่มจากท่าฝึกแรกก่อนโดยดูจำนวนครั้ง (<IntlMessages id="activities.weight-rep" />) แล้วทำให้ครบตามที่กำหนด<br/> 
              2.เมื่อทำครบจำนวนครั้งตามที่กำหนดนับจะเป็น 1 รอบ (<IntlMessages id="activities.weight-set" />)<br/>
              3.ให้ดูที่ช่องพัก (<IntlMessages id="activities.weight-rest" />) คือการพักระหว่างเซตว่าต้องพักกี่วินาที เมื่อพักตามเวลาที่กำหนดครบให้กลับทำแบบเดิมซ้ำอีกรอบเป็นเซตที่สอง<br/>
              4.ทำไปเรื่อยๆ ให้ครบตามเซตที่กำหนดแล้วค่อยเปลี่ยนท่า
            </p>
          </div>
        </Row>
      </Fragment>
    );
  }

  render() {
    return (
      <div>
          {this.props.weeklyPlan.weight_program.length > 0
            ? this.renderList()
            : <div className='imgup-buttons'><Spinner /></div>
          }
      </div>
    )
  }
}

const mapStateToProps = ({ authUser, activities }) => {
  const { weeklyPlan, entireData, loading } = activities;
  const { user } = authUser;
  return { weeklyPlan, entireData, loading, user };
};

const mapActionsToProps = { getActivities, updateWeeklyPlan };

export default connect(
  mapStateToProps,
  mapActionsToProps
)(weightProgram);


