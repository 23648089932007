import React, { Component, Fragment } from "react";
import { Row, Card, CardTitle, Label, Button, Alert } from "reactstrap";
import { AvForm, AvGroup, AvFeedback, AvField } from "availity-reactstrap-validation";
import { connect } from "react-redux";
import { registerUser } from "../redux/auth";

import IntlMessages from "../helpers/IntlMessages";
import { Colxx, Separator } from "../components/common/CustomBootstrap";
import LoaderButton from "../components/LoaderButton";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      firstname: "",
      lastname: "",
    };
    
    this.onUserRegister = this.onUserRegister.bind(this);
  }
  onUserRegister(event, errors) {
    if (errors.length === 0) {
      this.props.registerUser(this.state, this.props.history);
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.id]: event.target.value
    })
  }

  renderErrorMessage(errorMessageID) {
    if (!errorMessageID) {
      return null;
    }
    return (
      <Colxx xxs={12} md={12}>
        <Alert color="danger">
          <IntlMessages id={errorMessageID}/>
        </Alert>
      </Colxx>
    );
  }

  render() {
    const {loading} = this.props;
    return (
      <Fragment>
        <div className='unauth-nav-container'>
          <img src="/assets/img/p4flogo.svg" className="unauth-nav-logo" alt="logo"/>
          <p className="inner-text">
            CYCLICAL CALORIC RESTRICTION (CCR)
          </p>
        </div>
        <Row className="h-100 login-body">
          <Colxx xxs="12" md="10" className="mx-auto my-auto pr-0">
            <Card className="auth-card">
              <div className="form-side">
                <img className="login-logo" src="/assets/img/login_logo.svg" alt="logo"/>
                <CardTitle className="mb-4">
                  <IntlMessages id="user.register" />
                </CardTitle>
                <AvForm onSubmit={this.onUserRegister} className="login-form">
                  <Row>
                    <Colxx xxs={6} md={6}>
                      <AvGroup>
                        <Label><IntlMessages id="user.firstname" /></Label>
                        <AvField 
                          name="firstname" 
                          id="firstname" 
                          value={this.state.firstname} 
                          onChange={(event) => this.handleChange(event)} 
                          validate={{
                            required: { value: true, errorMessage: 'อย่าลืมกรอกชื่อ' }
                          }}
                        />
                      </AvGroup>
                    </Colxx>
                    <Colxx xxs={6} md={6}>
                      <AvGroup>
                        <Label><IntlMessages id="user.lastname" /></Label>
                        <AvField 
                          name="lastname" 
                          id="lastname" 
                          value={this.state.lastname} 
                          onChange={(event) => this.handleChange(event)} 
                          validate={{
                            required: { value: true, errorMessage: 'อย่าลืมกรอกนามสกุล' }
                          }}
                        />
                        
                      </AvGroup>
                    </Colxx>
                    <Colxx xxs={12} md={12}>
                      <AvGroup>
                        <Label><IntlMessages id="user.email" /></Label>
                        <AvField 
                          type="email" 
                          name="email" 
                          id="email" 
                          value={this.state.email} 
                          onChange={(event) => this.handleChange(event)} 
                          validate={{
                            required: { value: true, errorMessage: 'อย่าลืมกรอกอีเมล์' },
                            email: { value: true, errorMessage: 'รูปแบบอีเมล์ไม่ถูกต้อง' },
                          }}
                        />
                      </AvGroup>
                    </Colxx>
                    <Colxx xxs={12} md={12}>
                      <AvGroup>
                        <Label><IntlMessages
                            id="user.password"
                            defaultValue={this.state.password}/></Label>
                        <AvField 
                          type="password" 
                          name="password" 
                          id="password" 
                          value={this.state.password} 
                          onChange={(event) => this.handleChange(event)} 
                          validate={{
                            required: { value: true, errorMessage: 'อย่าลืมกรอกพาสเวิร์ด' },
                            minLength: { value: 6, errorMessage: 'ขั้นต่ำ 6 ตัวอักษร' }
                          }}
                        />
                      </AvGroup>
                    </Colxx>
                    <Colxx xxs={12} md={12}>
                      <AvGroup>
                        <Label><IntlMessages id="user.confirm-password"/></Label>
                        <AvField 
                          type="password" 
                          name="confirm-password" required 
                          id="comfirm-password" 
                          validate={{
                            required: { value: true, errorMessage: 'อย่าลืมกรอกยืนยันพาสเวิร์ด' },
                            match:{value:'password', errorMessage: 'พาสเวิร์ดไม่ตรงกัน'}
                          }}
                        />
                        <AvFeedback><IntlMessages id="field.required"/></AvFeedback>
                      </AvGroup>
                    </Colxx>
                    {this.renderErrorMessage(this.props.errorMessageID)}
                  </Row>
                  <div className="d-flex justify-content-end align-items-center mb-3">
                    <LoaderButton
                      color="primary"
                      className="btn-shadow"
                      size="lg"
                      block
                      status={loading ? "processing" : "default"}
                    >
                    <span className="text-one">
                      <IntlMessages id="user.register-button" />
                      </span>
                    </LoaderButton>
                  </div>
                  <Separator className="mb-3" />
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <Button
                      color="light"
                      className="btn-shadow"
                      size="lg"
                      href="/login"
                      block
                    >
                      <IntlMessages id="user.login-button" />
                    </Button>
                  </div>
                </AvForm>
              </div>
              <div className="position-relative image-side ">
                <img className="login-image" src="/assets/img/welcome.jpg" alt="login"/>
              </div>  
            </Card>
          </Colxx>
        </Row>
        <div className='unauth-footer-container'>
          <p className="text-one mb-0">
            ติดต่อสอบถาม
          </p>
          <div className="inner-wrapper">
            <img src="/assets/img/footer-tel.svg" className="logo" alt="tel"/>
            <img src="/assets/img/footer-fb.svg" className="logo" alt="fb"/>
            <img src="/assets/img/footer-line.svg" className="logo" alt="line"/>
            <img src="/assets/img/footer-msg.svg" className="logo" alt="msg"/>
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = ({ authUser }) => {
  const { user, loading, errorMessageID } = authUser;
  return { user, loading, errorMessageID };
};

const mapActionsToProps = { registerUser };

export default connect(
  mapStateToProps,
  mapActionsToProps
)(Register);
