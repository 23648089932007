import React, { Fragment } from "react";
import { Card, CardBody } from "reactstrap";

const MessageCard = ({ item, currentCoachId }) => {
  return (
    <Fragment>
      <Card
        className={`d-inline-block mb-3 float-${
          item.coach_id !== currentCoachId ? "left" : "right"
        }`}
      >
        <div className="position-absolute  pt-1 pr-2 r-0">
          <span className="text-extra-small text-muted">{item.datetime}</span>
        </div>
        <CardBody>
          <div className="chat-text-left mb-2">
            <p className="mb-0 truncate list-item-heading">{item.coach_name}</p>
          </div>
          <div className="chat-text-left">
            <p className="mb-0 text-semi-muted">{unescape(item.message)}</p>
          </div>
        </CardBody>
      </Card>
      <div className="clearfix" />
    </Fragment>
  );
};

export default MessageCard;
