import React, { Component } from "react";
import { Route, withRouter, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import AppLayout from "../../layout/AppLayout";
import { AdminRoute } from "../../components/Router";
import main from "./main";
import dailyRecord from "./daily-record";
import exerciseVideoPage from "./exercise-video";
import invoice from "./invoice";
import admin from "./admin";
import foodIntro from "./food_intro";
import exerciseIntro from "./exercise_intro";
import weightProgram from "./weight-program";
import comingSoon from "./coming-soon";
import weeklySubmit from "./weekly_submit";
import resetProfile from "./reset_profile";

class App extends Component {
  render() {
    const { match, user } = this.props;
    const group = (user || "user").group || "user";
    const redirectPath = group === "admin" ? `${match.url}/admin` : `${match.url}/main`;
    return (
      <AppLayout>
        <Switch>
          <Redirect exact from={`${match.url}/`} to={redirectPath} />
          <Route path={`${match.url}/main`} component={main} />
          <Route path={`${match.url}/daily-record`} component={dailyRecord} />
          <AdminRoute path={`${match.url}/admin`} isAdmin={user.group === "admin"} component={admin} />
          <Route path={`${match.url}/exercise-video`} component={exerciseVideoPage} />
          <Route path={`${match.url}/invoice`} component={invoice} />
          <Route path={`${match.url}/food-intro`} component={foodIntro} />
          <Route path={`${match.url}/exercise-intro`} component={exerciseIntro} />
          <Route path={`${match.url}/weight-program`} component={weightProgram} />
          <Route path={`${match.url}/coming-soon`} component={comingSoon} />
          <Route path={`${match.url}/weekly-submit`} component={weeklySubmit} />
          <Route path={`${match.url}/reset-profile`} component={resetProfile} />
          <Redirect to="/error" />
        </Switch>
      </AppLayout>
    );
  }
}
const mapStateToProps = ({ menu, authUser }) => {
  const { containerClassnames } = menu;
  const { user } = authUser;
  return { containerClassnames, user };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(App)
);
