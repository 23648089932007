import React from "react";
import ChartComponent, { Chart } from "react-chartjs-2";
import { connect } from "react-redux";

import { areaChartFlexOptions } from "./config";

class WeightArea extends React.Component {
  componentWillMount() {
    if (this.props.shadow) {
      Chart.defaults.lineWithShadow = Chart.defaults.line;
      Chart.controllers.lineWithShadow = Chart.controllers.line.extend({
        draw: function(ease) {
          Chart.controllers.line.prototype.draw.call(this, ease);
          var ctx = this.chart.ctx;
          ctx.save();
          ctx.shadowColor = "rgba(0,0,0,0.15)";
          ctx.shadowBlur = 10;
          ctx.shadowOffsetX = 0;
          ctx.shadowOffsetY = 10;
          ctx.responsive = true;
          ctx.stroke();
          Chart.controllers.line.prototype.draw.apply(this, arguments);
          ctx.restore();
        }
      });
    }
  }

  render() {
    const { data, shadow } = this.props;

    let max, min;
    const filterredWeights = data.datasets[0].data.filter(item => item > 0);

    if (filterredWeights.length > 0) {
      max = parseInt(Math.max(...filterredWeights)) + 4;
      min = parseInt(Math.min(...filterredWeights)) - 4;
    } else {
      max = 70;
      min = 50;
    }
    
    return (
      <ChartComponent
        ref={ref => (this.chart_instance = ref && ref.chart_instance)}
        type={shadow ? "lineWithShadow" : "line"}
        options={{
          ...areaChartFlexOptions(min, max, 2)
        }}
        data={data}
      />
    );
  }
}

const mapStateToProps = ({ activities }) => {
  const { weeklyWeights } = activities;
  return { weeklyWeights };
};
const mapActionsToProps = {};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(WeightArea);
