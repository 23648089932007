import React, {Component} from "react";

class Review extends Component {
  
  fbTrack(trackString) {
    if (typeof window !== "undefined") {
      if (window.fbq != null) { 
        window.fbq('trackCustom', trackString)
      }
      if (window._lt) {
        window._lt('send', 'cv', {
          type: trackString
          },['10be62e6-0bb3-49b8-a9c3-404eac3aae64']);
      }
    }
  }
  render() {
    return (
      <div id="front">
        <nav className="menubar flex-container fixed-top">
          <a href="/">
            <img alt="logo" className="main-logo" src="/assets/img/logo-black.svg"></img> 
          </a>
          <div className="space"></div>
          <a className="btn menu-button float-right" href="/front">
            <span className="text-one">หน้าแรก</span>
          </a> 
          <div className="mr-2"></div>
          <a className="btn menu-button float-right" href="/login">
            <span className="text-one">Login</span>
          </a>
        </nav>
        <div className="image-container first-child">
          <picture>
            <source media="(max-width: 320px)" srcSet="/assets/img/front/hw_head_320.jpg" />
            <source media="(max-width: 480px)" srcSet="/assets/img/front/hw_head_480.jpg" />
            <img src="/assets/img/front/hw_head.jpg" alt="" />
          </picture>
        </div>
        <div className="image-container">
          <picture>
            <source media="(max-width: 320px)" srcSet="/assets/img/front/proof1_320.jpg" />
            <source media="(max-width: 480px)" srcSet="/assets/img/front/proof1_480.jpg" />
            <img src="/assets/img/front/proof1.jpg" alt="" />
          </picture>
        </div>
        <div className="image-container">
          <picture>
            <source media="(max-width: 320px)" srcSet="/assets/img/front/proof2_320.jpg" />
            <source media="(max-width: 480px)" srcSet="/assets/img/front/proof2_480.jpg" />
            <img src="/assets/img/front/proof2.jpg" alt="" />
          </picture>
        </div>
        <div className="image-container">
          <picture>
            <source media="(max-width: 320px)" srcSet="/assets/img/front/proof3_320.jpg" />
            <source media="(max-width: 480px)" srcSet="/assets/img/front/proof3_480.jpg" />
            <img src="/assets/img/front/proof3.jpg" alt="" />
          </picture>
        </div>
        <div className="image-container">
          <picture>
            <source media="(max-width: 320px)" srcSet="/assets/img/front/proof4_320.jpg" />
            <source media="(max-width: 480px)" srcSet="/assets/img/front/proof4_480.jpg" />
            <img src="/assets/img/front/proof4.jpg" alt="" />
          </picture>
        </div>
        {/* <div className="cta">
          <a className="btn cta-button" href="https://sale.ccrdiet.co/" onClick={() => this.fbTrack('CCR_AddtoCart')}>สนใจสมัคร</a>
        </div> */}
      </div>
    );
  }
};

export default Review;
