import React, {Fragment, Component} from 'react';
import { Row } from "reactstrap";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import Breadcrumb from "../../containers/navs/Breadcrumb";

class exerciseIntro extends Component {
  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.exercise-intro" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <div className="w-100">
            <div>
              <img src="/assets/img/exercise_intro01.jpeg" alt="part 1" className="w-100"/>
            </div>
            <div>
              <img src="/assets/img/exercise_intro02.jpeg" alt="part 2" className="w-100"/>
            </div>
          </div>
        </Row>
      </Fragment>
    );
  }
}

export default exerciseIntro;

