import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { API } from 'aws-amplify';
import moment from "moment";
import { UPDATE_USER } from "./actions";
import { types as checkoutTypes } from "./checkout";
import { types as authTypes } from "./auth";

import { productName } from "../constants/defaultValues";

/* ACTION Section */
export const types = {
    GET_ACTIVITIES: 'GET_ACTIVITIES',
    GET_ACTIVITIES_SUCCESS: 'GET_ACTIVITIES_SUCCESS',
    GET_ACTIVITIES_FAIL: 'GET_ACTIVITIES_FAIL',
    GET_MISSING_ACTIVITIES: 'GET_MISSING_ACTIVITIES',
    GET_MISSING_ACTIVITIES_SUCCESS: 'GET_MISSING_ACTIVITIES_SUCCESS',
    GET_MISSING_ACTIVITIES_FAIL: 'GET_MISSING_ACTIVITIES_FAIL',
    GET_HISTORY: 'GET_HISTORY',
    GET_HISTORY_SUCCESS: 'GET_HISTORY_SUCCESS',
    GET_HISTORY_FAIL: 'GET_HISTORY_FAIL',
    UPDATE_ACTIVITY: 'UPDATE_ACTIVITY',
    UPDATE_ACTIVITY_SUCCESS: 'UPDATE_ACTIVITY_SUCCESS',
    UPDATE_ACTIVITY_FAIL: 'UPDATE_ACTIVITY_FAIL',
    UPDATE_WEEKLY_PROGRESS: 'UPDATE_WEEKLY_PROGRESS',
    UPDATE_WEEKLY_PROGRESS_SUCCESS: 'UPDATE_WEEKLY_PROGRESS_SUCCESS',
    UPDATE_WEEKLY_PROGRESS_FAIL: 'UPDATE_WEEKLY_PROGRESS_FAIL',
    UPDATE_WEEKLY_PLAN: 'UPDATE_WEEKLY_PLAN',
    UPDATE_WEEKLY_PLAN_SUCCESS: 'UPDATE_WEEKLY_PLAN_SUCCESS',
    UPDATE_WEEKLY_PLAN_FAIL: 'UPDATE_WEEKLY_PLAN_FAIL',
    UPDATE_C_TO_C: 'UPDATE_C_TO_C',
    UPDATE_C_TO_C_SUCCESS: 'UPDATE_C_TO_C_SUCCESS',
    UPDATE_C_TO_C_FAIL: 'UPDATE_C_TO_C_FAIL',
    CHANGE_CURRENT_ACTIVITY: 'CHANGE_CURRENT_ACTIVITY',
    CHANGE_BASE_INFO: 'CHANGE_BASE_INFO',
    TDEE_CALCULATE: 'TDEE_CALCULATE',
    TDEE_RESET: 'TDEE_RESET',
    TDEE_RESET_SUCCESS: 'TDEE_RESET_SUCCESS',
    RESET_STATUS: 'RESET_STATUS',
}

export const getActivities = (user_id) => ({
    type: types.GET_ACTIVITIES,
    payload: { user_id }
});

export const getHistory = (user_id) => ({
  type: types.GET_HISTORY,
  payload: user_id
})

export const updateActivity = (newState, entireData, user) => ({
    type: types.UPDATE_ACTIVITY,
    payload: { newState, entireData, user }
});

export const updateWeeklyProgress = (newState, entireData, user) => ({
  type: types.UPDATE_WEEKLY_PROGRESS,
  payload: { newState, entireData, user }
});

export const updateWeeklyPlan = (newState, entireData, user) => ({
  type: types.UPDATE_WEEKLY_PLAN,
  payload: { newState, entireData, user }
});

export const updateCoach2Coach = (user_id, coach_id, coach_name, message, datetime) => ({
  type: types.UPDATE_C_TO_C,
  payload: {
    user_id,
    coach_id,
    coach_name,
    message,
    datetime
  }
})

export const updateScore = (exercise_score, new_exercise_videos, entireData, user) => {
    const currentDateActivity = entireData.daily_activities.filter(isCurrentDate)[0];
    const newState = { 
        ...currentDateActivity, 
        exercise_score: currentDateActivity.exercise_score + exercise_score,
        exercise_videos: currentDateActivity.exercise_videos.concat(new_exercise_videos)
    };
    return ({
        type: types.UPDATE_ACTIVITY,
        payload: { newState, entireData, user }
    });
}

export const changeCurrentActivity = (day_in_week) => (
    {
        type: types.CHANGE_CURRENT_ACTIVITY,
        payload: day_in_week,
    }
)

export const changeBaseInfo = (fieldName, value) => (
  {
    type: types.CHANGE_BASE_INFO,
    payload: {
      fieldName,
      value
    }
  }
)

export const tdeeCalculate = (profile) => (
  {
    type: types.TDEE_CALCULATE,
    payload: profile
  }
)

export const tdeeReset = (profile) => (
  {
    type: types.TDEE_RESET,
    payload: profile
  }
)

export const resetStatus = () => (
  {
    type: types.RESET_STATUS
  }
)

/* END OF ACTION Section */

function isCurrentDate(value) {
    return moment().isSame(value.date, "day");
}

function calculateWeeklyTotalCardio(activities) {
  const totalCardio = activities
    .reduce((accumulator, current) => accumulator.concat(current.cardio), [])
    .reduce((accumulator, current) => accumulator + current.time, 0);
  return totalCardio;
}

function calculateTodayCardio(accumulator, current) {
  return accumulator + current.time;
}

function calculateTodayNutrient(accumulator, current) {
  return {
    protein: accumulator.protein + current.protein,
    carbohydrate: accumulator.carbohydrate + current.carbohydrate,
    fat: accumulator.fat + current.fat
  };
}

function assignCurrentActivityStates(currentActivity) {
  const currentNutrients = currentActivity.foods.reduce(
    calculateTodayNutrient,
    {
      protein: 0,
      carbohydrate: 0,
      fat: 0
    }
  );
  const currentCardio = currentActivity.cardio.reduce(calculateTodayCardio, 0);
  return {
    currentNutrients,
    currentCardio
  }
}

function unescapeAllNote(activities) {
  const { weekly_plan, weekly_progress, daily_activities, note } = activities;
  const unescapedWeeklyPlan = {
    ...weekly_plan,
    note: unescape(note)
  }
  const unescapedWeeklyProgress = {
    ...weekly_progress,
    "weekly-comment": unescape(weekly_progress["weekly-comment"])
  }
  const unescapedDailyAct = daily_activities.map(act => ({
    ...act,
    note: unescape(act.note)
  }))
  return {
    weekly_plan: unescapedWeeklyPlan,
    weekly_progress: unescapedWeeklyProgress,
    daily_activities: unescapedDailyAct
  }
}

function escapeWeeklyPlan(weekly_plan) {
  return  {
            ...weekly_plan,
            note: escape(unescape(weekly_plan.note))
          }
}

function escapeWeeklyProgress(weekly_progress) {
  return  {
            ...weekly_progress,
            "weekly-comment": escape(unescape(weekly_progress["weekly-comment"]))
          }
}

function escapeDailyActivities(daily_activities) {
  return daily_activities.map(act => ({
          ...act,
          note: escape(unescape(act.note))
        }));
}

function escapeAllNote(activities) {
  const { weekly_plan, weekly_progress, daily_activities } = activities;
  const escapedWeeklyPlan = escapeWeeklyPlan(weekly_plan);
  const escapedWeeklyProgress = escapeWeeklyProgress(weekly_progress);
  const escapedDailyAct = escapeDailyActivities(daily_activities);
  return {
    weekly_plan: escapedWeeklyPlan,
    weekly_progress: escapedWeeklyProgress,
    daily_activities: escapedDailyAct
  }
}

function assignActivityStates(raw_activities, selectedCurrentDay = 0) {
  const activities = unescapeAllNote(raw_activities);
  const currentActivity = 
    selectedCurrentDay === 0 
      ? activities.daily_activities.filter(isCurrentDate)[0] 
      : activities.daily_activities[selectedCurrentDay - 1];
  const weeklyActivities = activities.daily_activities;

  const weeklyPlan = activities.weekly_plan;
  const weeklyProgress = activities.weekly_progress;
  const weeklyWeights = activities.daily_activities.map(
    activity => activity.weight || 0
  );
  const weeklyTotalCardio = calculateWeeklyTotalCardio(weeklyActivities);

  return {
    currentActivity,
    ...assignCurrentActivityStates(currentActivity),
    weeklyActivities,
    weeklyPlan,
    weeklyProgress,
    weeklyWeights,
    weeklyTotalCardio,
  };
}

/* SAGA Section */

const getActivitiesSagaAsync = async (user_id) => {
    try {
        const apiReturn = await API.post("activity", "/activity/read", {
                            body:   { 
                                        user_id, 
                                        product_id: productName
                                    }
                            });
        return apiReturn;
    } catch (e) {
        return e;
    }
}

const getMissingActivitiesSagaAsync = async (user_id) => {
    try {
        const apiReturn = await API.post("activity", "/missing_activity", {
                            body:   { 
                                        user_id, 
                                        product_id: productName
                                    }
                            });
        return apiReturn;
    } catch (e) {
        return e;
    }
}

function* getActivitiesSaga({ payload }) {
    try {
        let apiReturn;
        const { missing, user_id } = payload;
        if ( missing ) {
          apiReturn = yield call(getMissingActivitiesSagaAsync, user_id);
        } else {
          apiReturn = yield call(getActivitiesSagaAsync, user_id);
        }
        if (apiReturn.results && apiReturn.results.length > 0) {
            const result = apiReturn.results[0];
            let entireData = JSON.parse(result.activities);
            entireData = {
              ...entireData,
              note: result.note
            }
            const activities = assignActivityStates(entireData);
            yield put({ 
                type: types.GET_ACTIVITIES_SUCCESS,
                payload: {
                    entireData,
                    ...activities
                } 
            });
            const { 
                user_id,
                program_id, 
                week_in_program, 
                payment_type, amount, 
                payment_date, 
                product_id, 
                program_start_date, 
                program_exire_date
            } = result;
            yield put({
                type: UPDATE_USER,
                payload: {
                    user_id,
                    program_id, 
                    week_in_program, 
                    payment_type, amount, 
                    payment_date, 
                    product_id, 
                    program_start_date, 
                    program_exire_date
                } 
            });
        } else {
          if ( !missing ) {
            yield put({ 
                type: types.GET_MISSING_ACTIVITIES,
                payload: {
                  user_id,
                  missing: true
                }
            });
          }
        }
    } catch (error) {
        yield put({ 
            type: types.GET_ACTIVITIES_FAIL,
            payload: error.message
        });
    }
}

const getHistorySagaAsync = async (user_id) => {
  try {
      const apiReturn = await API.get("activity", "/history", {
        queryStringParameters: {
          user_id,
          product_id: productName
        }
      });
      return apiReturn;
  } catch (e) {
      return e;
  }
}

function* getHistorySaga({payload}) {
  try {
    const apiReturn = yield call(getHistorySagaAsync, payload);
    if (apiReturn.results) {
      yield put({
        type: types.GET_HISTORY_SUCCESS,
        payload: apiReturn.results,
      });
    } else {
      yield put({
        type: types.GET_HISTORY_FAIL,
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_HISTORY_FAIL,
      payload: error,
    });
  }
}

function assignNewActivitiy(newState, entireData) {
    const new_daily_activities = entireData.daily_activities.map(activity => 
      activity.day === newState.day ? newState : activity
    );

    const newEntireData = { ...entireData, daily_activities: new_daily_activities };
    return escapeAllNote(newEntireData);
}

function assignNewOthersPart(newState, entireData, field) {
  const newEntireData = { ...entireData, [field]: newState };
  return escapeAllNote(newEntireData);
}

const updateActivitySagaAsync = async ({
  newState,
  entireData,
  user,
  type = "daily"
}) => {
  try {
    let activities;
    if (type === "daily") {
      activities = assignNewActivitiy(newState, entireData);
    } else {
      activities = assignNewOthersPart(newState, entireData, type);
    }
    const { user_id, program_id, week_in_program } = user;
    await API.put("activity", "/activity", {
      body: {
        user_id,
        program_id,
        week_in_program,
        activities
      }
    });
    return activities;
  } catch (error) {
    return error;
  }
}

const updateCoach2CoachSagaAsync = async (payload) => {
  try {
    const apiReturn = await API.put("activity", "/c_to_c", {
      body: {
        ...payload,
        product_id: productName
      }
    });
    return apiReturn;
  } catch (error) {
    console.log("inside updateCoach2CoachSagaAsync error reason:", error)
    return error;
  }
}

const tdeeCalculateSagaAsync = async (profile) => {
  try {
    const apiReturn = await API.post("activity", "/health_profile", {
      body: {
        ...profile
      }
    });
    return apiReturn;
  } catch (error) {
    console.log("inside tdeeCalculateSagaAsync error reason:", error)
    return error;
  }
};

const tdeeResetSagaAsync = async (profile) => {
  try {
    const apiReturn = await API.post("activity", "/reset_health_profile", {
      body: {
        ...profile
      }
    });
    return apiReturn;
  } catch (error) {
    console.log("inside tdeeResetSagaAsync error reason:", error)
    return error;
  }
};

function* updateActivitySaga({ payload }) {
    try {
        const apiReturn = yield call(updateActivitySagaAsync, payload);
        if(!apiReturn.message) {
            const activities = assignActivityStates(apiReturn, payload.newState.day);
            yield put({ 
                type: types.UPDATE_ACTIVITY_SUCCESS,
                payload: {
                    entireData: apiReturn,
                    ...activities
                } 
            });
        } else {
            yield put({
                type: types.UPDATE_ACTIVITY_FAIL,
                payload: apiReturn.message
            });
        }
    } catch (error) {
        yield put({
            type: types.UPDATE_ACTIVITY_FAIL,
            payload: error.message
        });
    }
}

function* updateWeeklyProgressSaga({ payload }) {
  try {
    const apiReturn = yield call(updateActivitySagaAsync, {
      ...payload,
      type: "weekly_progress"
    });
    if (!apiReturn.message) {
      const activities = assignActivityStates(apiReturn);
      yield put({
        type: types.UPDATE_WEEKLY_PROGRESS_SUCCESS,
        payload: {
          entireData: apiReturn,
          ...activities
        }
      });
    } else {
      yield put({
        type: types.UPDATE_WEEKLY_PROGRESS_FAIL,
        payload: apiReturn.message
      });
    }
  } catch (error) {
    yield put({
      type: types.UPDATE_WEEKLY_PROGRESS_FAIL,
      payload: error.message
    });
  }
}

function* updateWeeklyPlanSaga({ payload }) {
  try {
    const apiReturn = yield call(updateActivitySagaAsync, {
      ...payload,
      type: "weekly_plan"
    });
    if (!apiReturn.message) {
      const activities = assignActivityStates(apiReturn);
      yield put({
        type: types.UPDATE_WEEKLY_PLAN_SUCCESS,
        payload: {
          entireData: apiReturn,
          ...activities
        }
      });
    } else {
      yield put({
        type: types.UPDATE_WEEKLY_PLAN_FAIL,
        payload: apiReturn.message
      });
    }
  } catch (error) {
    yield put({
      type: types.UPDATE_WEEKLY_PLAN_FAIL,
      payload: error.message
    });
  }
}

function* updateCoach2CoachSaga({ payload }) {
  try {
    const apiReturn = yield call(updateCoach2CoachSagaAsync, {
      ...payload
    });
    if (apiReturn.results) {
      yield put({
        type: types.UPDATE_C_TO_C_SUCCESS,
        payload
      })
    }
    console.log("inside updateCoach2CoachSaga:", apiReturn);
  } catch (error) {
    yield put({
      type: types.UPDATE_C_TO_C_FAIL,
      payload: error.message
    });
  }
}

function* tdeeCalculateSaga({ payload }) {
  try {
    const apiReturn = yield call(tdeeCalculateSagaAsync, payload);

    yield put({
      type: types.GET_ACTIVITIES,
      payload: {user_id: payload.user_id}
    });

    yield put({
      type: checkoutTypes.UPDATE_GENERAL_PROFILE,
      payload: {profile: JSON.stringify(apiReturn)}
    });

    yield put({
      type: authTypes.GET_SUBSCRIBE,
      payload: { 
        username: payload.user_id
      }
    })
  } catch (error) {
    console.log("error in tdeeCalculateSaga with reason:", error);
  }
}

function* tdeeResetSaga({ payload }) {
  try {
    const apiReturn = yield call(tdeeResetSagaAsync, payload);

    yield put({
      type: types.TDEE_RESET_SUCCESS
    })
    yield put({
      type: types.GET_ACTIVITIES,
      payload: {user_id: payload.user_id}
    });

    yield put({
      type: checkoutTypes.UPDATE_GENERAL_PROFILE,
      payload: {profile: JSON.stringify(apiReturn)}
    });

    yield put({
      type: authTypes.GET_SUBSCRIBE,
      payload: { 
        username: payload.user_id
      }
    })
  } catch (error) {
    console.log("error in tdeeResetSaga with reason:", error);
  }
}

export function* watchGetActivities() {
    yield takeEvery(types.GET_ACTIVITIES, getActivitiesSaga)
}

export function* watchGetHistory() {
    yield takeEvery(types.GET_HISTORY, getHistorySaga)
}

export function* watchGetMissingActivities() {
    yield takeEvery(types.GET_MISSING_ACTIVITIES, getActivitiesSaga)
}

export function* watchUpdateActivity() {
    yield takeEvery(types.UPDATE_ACTIVITY, updateActivitySaga)
}
export function* watchUpdateWeeklyProgress() {
  yield takeEvery(types.UPDATE_WEEKLY_PROGRESS, updateWeeklyProgressSaga)
}

export function* watchUpdateWeeklyPlan() {
    yield takeEvery(types.UPDATE_WEEKLY_PLAN, updateWeeklyPlanSaga)
}

export function* watchupdateCoach2Coach() {
    yield takeEvery(types.UPDATE_C_TO_C, updateCoach2CoachSaga)
}

export function* watchTDEECalculate() {
  yield takeEvery(types.TDEE_CALCULATE, tdeeCalculateSaga)
}

export function* watchTDEEReset() {
  yield takeEvery(types.TDEE_RESET, tdeeResetSaga)
}

export function* saga() {
    yield all([
        fork(watchGetActivities),
        fork(watchGetHistory),
        fork(watchGetMissingActivities),
        fork(watchUpdateActivity),
        fork(watchUpdateWeeklyProgress),
        fork(watchUpdateWeeklyPlan),
        fork(watchupdateCoach2Coach),
        fork(watchTDEECalculate),
        fork(watchTDEEReset),
    ]);
}

/* END OF SAGA Section */

/* REDUCER Section */

const INIT_STATE = {
  entireData: null,
  weeklyActivities: null,
  currentActivity: {
    day: 0,
    date: "",
    note: "",
    foods: [],
    cardio: [],
    weight: 0
  },
  currentNutrients: {
    protein: 0,
    carbohydrate: 0,
    fat: 0
  },
  currentCardio: 0,
  weeklyPlan: {
    note: "",
    coach_id: "",
    coach_name: "",
    plan: {
      food: {
        fat: 0,
        protein: 0,
        carbohydrate: 0
      },
      mode: "reduce",
      cadio_time: 0,
      tdee_percentage: "80%"
    },
    exercise_plan: {
      weekly_exercise_score: 20
    },
    nutrition_plan: {
      carb: 25,
      meat: 25,
      veggie: 50
    },
    weight_program: []
  },
  weeklyProgress: null,
  weeklyWeights: [0, 0, 0, 0, 0, 0, 0],
  weeklyTotalCardio: 0,
  loading: false,
  error: null,
  errorMessageID: "",
  updateActStatus: "default",
  status: "default",
  reset_status: "default",
  historyStatus: "default",
  personalHistory: {
    profile: {
      base_info: {
        age: 0,
        goal: "diet",
        mode: "auto",
        gender: "",
        height: 0,
        weight: 0,
        workout_day: 0,
        work_active_level: "",
        low_carb_first_day: 0,
        low_carb_second_day: 3
      },
      c_to_c: []
    },
    history: []
  },
};

export function reducer(state = INIT_STATE, action) {
    switch (action.type) {
      case types.RESET_STATUS:
        return {
          ...state,
          loading: false,
          updateActStatus: "default",
          status: "default",
          reset_status: "default"
        }
      case types.GET_ACTIVITIES:
      case types.GET_MISSING_ACTIVITIES:
      case types.UPDATE_ACTIVITY:
      case types.UPDATE_WEEKLY_PROGRESS:
      case types.UPDATE_WEEKLY_PLAN:
        return {
          ...state,
          loading: true,
          errorMessageID: "",
          updateActStatus: "default",
          status: "default"
        };
      case types.GET_HISTORY:
        return {
          ...state,
          loading: true,
          historyStatus: "processing"
        };
      case types.GET_HISTORY_SUCCESS:
        return {
          ...state,
          loading: false,
          historyStatus: "success",
          personalHistory: action.payload
        };
      case types.GET_HISTORY_FAIL:
        return {
          ...state,
          loading: false,
          historyStatus: "fail",
          error: action.payload
        };
      case types.GET_ACTIVITIES_SUCCESS:
      case types.GET_MISSING_ACTIVITIES_SUCCESS:
      case types.UPDATE_ACTIVITY_SUCCESS:
      case types.UPDATE_WEEKLY_PROGRESS_SUCCESS:
      case types.UPDATE_WEEKLY_PLAN_SUCCESS:
        return {
          ...state,
          loading: false,
          ...action.payload,
          error: null,
          errorMessageID: "",
          status: "success"
        };
      case types.UPDATE_C_TO_C_SUCCESS:
        return {
          ...state,
          personalHistory: {
            ...state.personalHistory,
            profile: {
              ...state.personalHistory.profile,
              c_to_c: state.personalHistory.profile.c_to_c.concat(action.payload)
            }
          }
        }
      case types.TDEE_RESET_SUCCESS:
        return {
          ...state,
          loading: false,
          reset_status: "success"
        }
      case types.GET_ACTIVITIES_FAIL:
      case types.GET_MISSING_ACTIVITIES_FAIL:
      case types.UPDATE_ACTIVITY_FAIL:
      case types.UPDATE_WEEKLY_PROGRESS_FAIL:
      case types.UPDATE_WEEKLY_PLAN_FAIL:
      case types.UPDATE_C_TO_C_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
          status: "fail"
        };
      case types.CHANGE_CURRENT_ACTIVITY:
        return {
          ...state,
          currentActivity: state.weeklyActivities[action.payload - 1],
          ...assignCurrentActivityStates(
            state.weeklyActivities[action.payload - 1]
          )
        };
      case types.CHANGE_BASE_INFO:
        return {
          ...state,
          personalHistory: {
            ...state.personalHistory,
            profile: {
              ...state.personalHistory.profile,
              base_info: {
                ...state.personalHistory.profile.base_info,
                [action.payload.fieldName]: action.payload.value
              }
            }
          }
        }
      default:
        return { ...state };
  }
}

/* END OF REDUCER Section */
