import React, { Component, Fragment } from "react";
import { Row, Card, CardBody } from "reactstrap";
import { connect } from "react-redux";
import moment from "moment";
import Breadcrumb from "../../containers/navs/Breadcrumb";
import { Separator, Colxx } from "../../components/common/CustomBootstrap";
import InvoiceCard from "../../components/cards/InvoiceCard";
import { getInvoices } from "../../redux/checkout";

class InvoicePages extends Component {
  constructor(props) {
    super(props);
    this.renderInvoice = this.renderInvoice.bind(this);
  }

  componentDidMount() {
    if(this.props.invoices.length <= 0) {
      this.props.getInvoices(this.props.user.username);
    }
  }

  renderInvoice() {
    const { invoices, status, user } = this.props;
    if(status === "default" || this.props.status === "processing") {
      return (<div className="loading" />);
    } else if (status === "fail") {
      return (
        <Card className="mb-5 invoice-contents"> 
          <CardBody className="d-flex flex-column justify-content-between">
            <div className="d-flex flex-column">
              <div className="d-flex flex-row justify-content-between pt-2 pb-2">
                <div className="d-flex align-self-center">
                  <img src="/assets/img/logo-black.svg" alt="Logo" />
                </div>
                <div className="d-flex w-30 text-right align-self-center">
                  <p className="text-one text-semi-muted mb-0">
                    There is not invoices data
                  </p>
                </div>
              </div>
              <div className="border-bottom pt-4 mb-5" />
            </div>
          </CardBody>
        </Card>
      )
    } else {
      const { attributes: { address, given_name, family_name } } = user;
      const {
        address_line1,
        address_line2,
        tambol,
        city,
        state,
        zip,
      } = JSON.parse(address);
      const addressString = `${address_line1} ${address_line2}, ${tambol}, ${city}, ${state} ${zip}`;
      return (
        <div>
          {
            invoices.map(({master, details}) => (
              <InvoiceCard 
                name={`${given_name} ${family_name}`}
                address={addressString}
                invoice_id={master.invoice_id}
                transaction_date={moment(master.transaction_date).format("DD.MM.YYYY")}
                details={details}
                total={master.total}
                vat_rate={master.vat_rate}
                key={master.invoice_id}
              />
            ))
          }
          
        </div>
      )
    }
  }
  
  render() {
    
      return (
        <Fragment>
          <Row>
            <Colxx xxs="12">
              <Breadcrumb heading="menu.invoice" match={this.props.match} />
              <Separator className="mb-5" />
            </Colxx>
          </Row>
          <Row className="invoice-react">
            <Colxx xxs="12" className="mb-4">
              {this.renderInvoice()}
            </Colxx>
          </Row>
        </Fragment>
      );
  }
}
const mapStateToProps = ({ authUser, checkout }) => {
  const { user } = authUser;
  const { invoices, status } = checkout;
  return { user, invoices, status };
};
const mapActionsToProps = { getInvoices };

export default connect(
  mapStateToProps,
  mapActionsToProps
)(InvoicePages);
