import React, { Component, Fragment } from "react";
import { Row, Card, CardTitle, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { Colxx } from "../../components/common/CustomBootstrap";
import IntlMessages from "../../helpers/IntlMessages";
import TDEECalForm from "../../containers/pages/TDEECalForm";

class ComingSoon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false
    };

    this.toggle = this.toggle.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (!newProps.user.healthProfile) {
      return;
    }
    const {
      healthProfile: { 
        base_info 
      }
    } = newProps.user;
    const baseInfo = JSON.parse(base_info);

    this.setState({
      modalOpen: Object.keys(baseInfo).length <= 0,
    });

  }

  toggle = () => {
    this.setState(prevState => ({
        modalOpen: !prevState.modalOpen
    }));
  };

  renderLoading() {
    return (
      <main>
        <div className="container">
          <Row className="h-100">
            <Colxx xxs="12" md="10" className="mx-auto my-auto">
              <Card className="auth-card">
                <div className="position-relative image-side "></div>
                <div className="form-side">
                  <div className="loading"></div>
                </div>
              </Card>
            </Colxx>
          </Row>
        </div>
      </main>
    );
  }

  renderWating() {
    return (
      <Row>
        <Colxx xxs="12" className="mb-4">
          <p>
            ขณะนี้โปรแกรมยังไม่เริ่ม ท่านสามารถดูกำหนดการการเริ่มโปรแกรมได้โดย{" "}
            <a
              href="https://planforfit.com/ccr/salepage/"
              className="text-underline text-highlight"
              target="_blank"
              rel="noopener noreferrer"
            >
              คลิกที่นี่
            </a>
            <br />
            หรือหากมีข้อสงสัยสามารถติดต่อทีมงานผ่าน Facebook fan page ได้โดย{" "}
            <a
              href="http://m.me/planforfit.com"
              className="text-underline text-highlight"
              target="_blank"
              rel="noopener noreferrer"
            >
              คลิกที่นี่
            </a>
            <br />
            หรือโทร 089-883-2339 ในเวลาทำการ (วันจันทร์-ศุกร์ 10.30 – 19.00 น.)
          </p>
        </Colxx>
      </Row>
    );
  }

  renderEnroll() {
    const {
      user: { latestSubscription, subscribeStatus, group },
      currentProgram
    } = this.props;
    if(group && group === "admin") {
      this.props.history.push("/app/admin");
    }
    let messagesID = "";
    let buttonLabelID = "";
    const shouldGoCart = ["new", "expire"].includes(subscribeStatus);

    switch (subscribeStatus) {
      case "subscribed":
        this.props.history.push("/app/daily-record");
        break;
      case "subscribed-waiting":
        return this.renderWating();
      case "pending":
        messagesID = "pages.pending";
        buttonLabelID = "";
        break;
      case "expired":
        messagesID = "pages.expire";
        buttonLabelID = "pages.expire-button";
        break;
      case "new":
        messagesID = "pages.firsttime";
        buttonLabelID = "pages.firsttime-button";
        break;
      default:
        messagesID = "pages.firsttime";
        buttonLabelID = "pages.firsttime-button";
        break;
    }

    if (!currentProgram) {
      messagesID = "pages.not-open"
    }
    return (
      <main>
        <div className="container">
          <Row className="h-100">
            <Colxx xxs="12" md="10" className="mx-auto my-auto">
              <Card className="d-flex flex-row mb-4 pt-4 pb-5">
                <div className="position-relative image-side "></div>
                <div className="form-side">
                  <NavLink to={`/`} className="white">
                    <span className="logo-single" />
                  </NavLink>
                  <CardTitle className="mb-4">
                    <IntlMessages id={messagesID} />{" "}
                    {subscribeStatus === "expired" &&
                      moment(latestSubscription.program_expire_date).format(
                        "DD/MM/YYYY"
                      )}
                  </CardTitle>
                  {/* {( shouldGoCart && currentProgram ) && (
                    <Button
                      href="/cart"
                      color="primary"
                      className="btn-shadow"
                      size="lg"
                    >
                      <IntlMessages id={buttonLabelID} />
                    </Button>
                  )} */}
                </div>
              </Card>
            </Colxx>
          </Row>
        </div>
      </main>
    );
  }
  render() {
    const {modalOpen} = this.state;
    return (
      <Fragment>
        <Modal isOpen={modalOpen}>
          <ModalHeader>
            <span className="text-medium"><IntlMessages id="modal.tdee-title" /></span>
          </ModalHeader>
          <ModalBody>
            <TDEECalForm/>
          </ModalBody>
        </Modal>
        {this.props.user
          ? this.renderEnroll()
          : this.renderLoading()}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ authUser }) => {
  const { user, isLoading, currentProgram, status } = authUser;
  return { user, isLoading, currentProgram, status };
};

const mapActionsToProps = {};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ComingSoon);
