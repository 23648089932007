import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { API } from 'aws-amplify';


/* ACTION Section */
export const types = {
    GET_VIDEOS: 'GET_VIDEOS',
    GET_VIDEOS_SUCCESS: 'GET_VIDEOS_SUCCESS',
    GET_VIDEOS_FAIL: 'GET_VIDEOS_FAIL',
    ADD_VIDEO: "ADD_VIDEO",
    ADD_VIDEO_SUCCESS: "ADD_VIDEO_SUCCESS",
    ADD_VIDEO_FAIL: "ADD_VIDEO_FAIL",
    DELETE_VIDEO: "DELETE_VIDEO",
    DELETE_VIDEO_SUCCESS: "DELETE_VIDEO_SUCCESS",
    DELETE_VIDEO_FAIL: "DELETE_VIDEO_FAIL",
    SELECT_VIDEO: "SELECT_VIDEO",
    UPDATE_VIDEO: "UPDATE_VIDEO",
    UPDATE_VIDEO_SUCCESS: "UPDATE_VIDEO_SUCCESS",
    UPDATE_VIDEO_FAIL: "UPDATE_VIDEO_FAIL",
    UPDATE_VIDEO_THUMBNAIL: "UPDATE_VIDEO_THUMBNAIL",
    UPDATE_VIDEO_THUMBNAIL_SUCCESS: "UPDATE_VIDEO_THUMBNAIL_SUCCESS",
    UPDATE_VIDEO_THUMBNAIL_FAIL: "UPDATE_VIDEO_FAIL",
    PROCESSING: "PROCESSING",
}

export const getVideos = () => ({
    type: types.GET_VIDEOS
});

export const addVideo = (video) => ({
  type: types.ADD_VIDEO,
  payload: video
});

export const deleteVideo = (id, deleteEntirely) => ({
  type: types.DELETE_VIDEO,
  payload: { id, deleteEntirely }
});

export const selectVideo = (selectedVideo) => ({
  type: types.SELECT_VIDEO,
  payload: selectedVideo
});

export const updateVideo = (video) => ({
  type: types.UPDATE_VIDEO,
  payload: video
});

export const updateVideoThumbnail = (video) => ({
  type: types.UPDATE_VIDEO_THUMBNAIL,
  payload: video
});

export const processVideo = () => ({
  type: types.PROCESSING
})

/* END OF ACTION Section */


/* SAGA Section */

const getVideosSagaAsync = async () => {
    try {
        const apiReturn = await API.get("activity", "/videos");
        return apiReturn;
    } catch (e) {
        return e;
    }
}

const addVideoSagaAsync = async (video) => {
    try {
      const apiReturn = await API.post("activity", "/video", {
        body: {
          ...video
        }
      });
      return apiReturn
    } catch (error) {
      return error;
    }
  }

const deleteVideoSagaAsync = async (id, deleteEntirely) => {
  try {
    const apiReturn = await API.del("activity", "/video", {
      body: {
        id,
        deleteEntirely
      }
    });
    return apiReturn;
  } catch (error) {
    console.log("on error", error);
    return error;
  }
}

const updateVideoSagaAsync = async (video) => {
  try {
    const apiReturn = await API.put("activity", "/video", {
      body: {
        ...video
      }
    });
    return apiReturn;
  } catch (error) {
    console.log("error because", error);
    return error;
  }
}
  

const updateVideoThumbnailSagaAsync = async (video) => {
  try {
    const {id, thumbnail} = video;
    const thumbnail_id = thumbnail.substring(thumbnail.lastIndexOf("/") + 1, thumbnail.indexOf("_"));
    const apiReturn = await API.put("activity", "/video_thumbnail", {
      body: {
        video_id: id,
        thumbnail_id
      }
    });
    return apiReturn;
  } catch (error) {
    console.log("error because", error);
    return error;
  }
}
  
function* getVideosSaga() {
    try {
        const apiReturn = yield call(getVideosSagaAsync);

        if (apiReturn.status) {
            const data = apiReturn.data;
            yield put({ 
                type: types.GET_VIDEOS_SUCCESS,
                payload: {
                    allItems: data,
                    totalItemCount: data.length,
                } 
            });
        } else {
            // catch throw
            yield put({ type: types.GET_VIDEOS_FAIL });
        }
    } catch (error) {
        yield put({ type: types.GET_VIDEOS_FAIL });
    }
}

function* addVideoSaga({ payload }) {
    const video = {
      id: payload.id,
      category: payload.category,
      level: payload.level,
      score: payload.score,
    };
    try {
      const apiReturn = yield call(addVideoSagaAsync, video);
      if(apiReturn.results) {
        yield put({
          type: types.ADD_VIDEO_SUCCESS
        })
      } else {
        yield put({
          type: types.ADD_VIDEO_FAIL
        })
      }
    } catch (error) {
      yield put({
        type: types.ADD_VIDEO_FAIL
      });
    }
  }

function* deleteVideoSaga({ payload }) {
  const { id, deleteEntirely } = payload
  const apiReturn = yield call(deleteVideoSagaAsync, id, deleteEntirely);
  try {
    if(apiReturn.results) {
      yield put({
        type: types.DELETE_VIDEO_SUCCESS,
        payload: id
      })
    } else {
      yield put({
        type: types.DELETE_VIDEO_FAIL
      });
    }
  } catch (error) {
    yield put({
      type: types.DELETE_VIDEO_FAIL
    });
  }

}

function* updateVideoSaga({ payload }) {
  const apiReturn = yield call(updateVideoSagaAsync, payload);
  try {
    if(apiReturn.results) {
      yield put({
        type: types.UPDATE_VIDEO_SUCCESS,
        payload
      })
    } else {
      yield put({
        type: types.UPDATE_VIDEO_FAIL,
        payload: null
      });
    }
  } catch (error) {
    yield put({
      type: types.UPDATE_VIDEO_FAIL,
      payload: error
    });
  }
}

function* updateVideoThumbnailSaga({ payload }) {
  yield call(updateVideoThumbnailSagaAsync, payload);
  try {
    yield put({
      type: types.UPDATE_VIDEO_THUMBNAIL_SUCCESS,
      payload
    });
  } catch (error) {
    yield put({
      type: types.UPDATE_VIDEO_THUMBNAIL_FAIL,
      payload: error
    });
  }
}
  
export function* watchGetVideos() {
    yield takeEvery(types.GET_VIDEOS, getVideosSaga)
}

export function* watchAddVideo() {
  yield takeEvery(types.ADD_VIDEO, addVideoSaga);
}

export function* watchDeleteVideo() {
  yield takeEvery(types.DELETE_VIDEO, deleteVideoSaga);
}

export function* watchUpdateVideo() {
  yield takeEvery(types.UPDATE_VIDEO, updateVideoSaga);
}

export function* watchUpdateVideoThumbnail() {
  yield takeEvery(types.UPDATE_VIDEO_THUMBNAIL, updateVideoThumbnailSaga);
}

export function* saga() {
    yield all([
        fork(watchGetVideos),
        fork(watchAddVideo),
        fork(watchDeleteVideo),
        fork(watchUpdateVideo),
        fork(watchUpdateVideoThumbnail),
    ]);
}

/* END OF SAGA Section */

/* REDUCER Section */

const INIT_STATE = {
    allItems: [],
    loading: false,
    status: "default",
    selectedVideo: null,
    error: {},
    thumbnailStatus: "default",
};

export function reducer(state = INIT_STATE, action) {
    switch (action.type) {
        case types.SELECT_VIDEO:
          return { ...state, selectedVideo: action.payload }
        case types.UPDATE_VIDEO_THUMBNAIL:
          return { ...state, thumbnailStatus: "processing" };
        case types.UPDATE_VIDEO:
        case types.ADD_VIDEO:
          return { ...state, status: "processing" };
        case types.ADD_VIDEO_SUCCESS:
            return {
                ...state,
                status: "success",
            }
        case types.ADD_VIDEO_FAIL:
            return {
                ...state,
                status: "fail",
            };
        case types.GET_VIDEOS:
            return { ...state, loading: true };
        case types.GET_VIDEOS_SUCCESS:
            return { 
                ...state, 
                loading: false, 
                allItems: action.payload.allItems, 
            };
        case types.GET_VIDEOS_FAIL:
            return { 
                ...state, 
                loading: false, 
                error: "",
            };
        case types.DELETE_VIDEO:
            return {
              ...state,
              status: "deleting"
            }
        case types.DELETE_VIDEO_SUCCESS:
            return {
              ...state,
              status: "success",
              allItems: state.allItems.filter(item => item.id !== action.payload)
        }
        case types.UPDATE_VIDEO_SUCCESS:
          return {
            ...state,
            status: "success",
            allItems: state.allItems.map(item => (
              (item.id === action.payload.id)
                ? action.payload
                : item
            ))
          }
        case types.UPDATE_VIDEO_THUMBNAIL_SUCCESS:
          return {
            ...state,
            thumbnailStatus: "success",
            allItems: state.allItems.map(item => (
              (item.id === action.payload.id)
                ? action.payload
                : item
            ))
          }
        case types.UPDATE_VIDEO_FAIL:
          return {
            ...state, 
            status: "fail",
            error: action.payload
          }
        case types.UPDATE_VIDEO_THUMBNAIL_FAIL:
        return {
          ...state, 
          thumbnailStatus: "fail",
          error: action.payload
        }
        case types.PROCESSING:
          return {
            ...state,
            thumbnailStatus: "processing"
          }
        default: 
            return { ...state };
    }
}

/* END OF REDUCER Section */
