import React, { Component, Fragment } from "react";
import {
  Row,
  Card,
  CardBody,
  CardTitle,
  Label,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import ReactTable from "react-table";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import ExifOrientationImg from 'react-fix-image-orientation';
import { AvForm, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";

import Spinner from "../../../components/common/Spinner";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import DataTablePagination from "../../../components/DatatablePagination";
import HistoryView from "../../../components/HistoryView";
import MessageCard from "../../../components/cards/MessageCard";
import SaySomething from "../../../components/SaySomething";
import {
  getHomeworks,
  updateHomework,
  filterCheckHomework,
  resetStatus,
  updateGoal,
  updateWorkoutDay
} from "../../../redux/admin";
import {
  getHistory,
  updateCoach2Coach
} from "../../../redux/activities";
import IntlMessages from "../../../helpers/IntlMessages";
import { wsURL } from "../../../constants/defaultValues";
import { formatNumber, calculatePreviousResult } from "../../../helpers/Utils";
import {
  initTableColumns,
  processedDataTableColumns
} from "../../../data/homeworksColumns";
import { s3Download } from "../../../helpers/awsLib";

class homeworks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showList: true,
      showSuccessButton: false,
      selectedHomework: {},
      newNote: "",
      newScore: 0,
      frontUrl: "",
      sideUrl: "",
      newCardio: 0,
      newProtein: 0,
      newCarb: 0,
      newFat: 0,
      modalOpen: false,
      modalPage: 1,
      modalType: "goal",
      goal: "diet",
      workout_day: "3",
      nutrients_week: 1,
      mode: "auto",
      auto_plans: [],
      messageInput: ""
    };

    this.handleUpdateHomeworkStatus = this.handleUpdateHomeworkStatus.bind(this);
    this.onCoach2CoachInputPress = this.onCoach2CoachInputPress.bind(this);
    this.onCoach2CoachInputChange = this.onCoach2CoachInputChange.bind(this);
    this.onCoach2CoachSendClick = this.onCoach2CoachSendClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onNumberTypeChange = this.onNumberTypeChange.bind(this);
    this.onOptionChange = this.onOptionChange.bind(this);
    this.onAutoPlanChange = this.onAutoPlanChange.bind(this);
    this.onCoachSubmit = this.onCoachSubmit.bind(this);
    this.successReturn = this.successReturn.bind(this);
    this.onUpdateGoal = this.onUpdateGoal.bind(this);
    this.onUpdateWorkoutDay = this.onUpdateWorkoutDay.bind(this);
    this.openGoalModal = this.openGoalModal.bind(this);
    this.openWorkoutDayModal = this.openWorkoutDayModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.changeGenericState = this.changeGenericState.bind(this);
    this.goalModalBodyRender = this.goalModalBodyRender.bind(this);
    this.workoutModalBodyRender = this.workoutModalBodyRender.bind(this);
    this.loadingModalBodyRender = this.loadingModalBodyRender.bind(this);
    this.toggle = this.toggle.bind(this);
    this.renderList = this.renderList.bind(this);
    this.renderForm = this.renderForm.bind(this);
  }
  componentDidMount() {
    this.ws = new WebSocket(wsURL);
    this.ws.onopen = () => {
      // on connecting, do nothing but log it to the console
      console.log("connected");
    };

    this.ws.onmessage = evt => {
      // on receiving a message, add it to the list of messages
      const { action, Items } = JSON.parse(evt.data);
      if (action === "tasks") {
        this.props.filterCheckHomework(
          this.props.init,
          this.props.checking,
          Items
        );
      } else {
        this.props.getHomeworks();
        this.setState({
          showSuccessButton: true
        });
      }
    };

    this.ws.addEventListener("close", event => {
      console.log("disconnected");
      this.ws = new WebSocket(wsURL);
    });

    window.addEventListener("beforeunload", ev => {
      ev.preventDefault();
      this.ws.removeEventListener("close");
      this.ws.close(1000);
    });

    this.props.getHomeworks();
  }

  componentWillReceiveProps(newProps) {
    const { status } = newProps;
    if (status === "update homework success") {
      const message = {
        action: "removeTask",
        data: {
          coachId: newProps.user.username
        }
      };
      this.ws.send(JSON.stringify(message));
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props.personalHistory.profile.base_info.mode !== prevProps.personalHistory.profile.base_info.mode) {
      this.setState({
        mode: this.props.personalHistory.profile.base_info.mode
      });
    }
  }

  componentWillUnmount() {
    this.ws.removeEventListener("close", evt => {
      console.log("remove myself from close event");
    });
    this.ws.close(1000);
  }

  calculateCurrentScore(accumulator, currentActivity) {
    return accumulator + currentActivity.exercise_score;
  }

  onCoach2CoachInputPress(e) {
    if (e.key === "Enter") {
      this.onCoach2CoachSendClick();
    }
  };

  onCoach2CoachInputChange(e) {
    this.setState({
      messageInput: e.target.value
    });
  };

  onCoach2CoachSendClick() {
    if (this.state.messageInput.length > 0) {
      const {
        selectedHomework,
        messageInput
      } = this.state;
      const { user } = this.props;
      const { user_id } = selectedHomework;
      const coach_id = user.username;
      const coach_name = user.attributes.given_name;
      const message = escape(unescape(messageInput));
      const datetime = moment().format("DD/MM/YY HH:mm");
      this.props.updateCoach2Coach(user_id, coach_id, coach_name, message, datetime);
      this.setState({
        messageInput: "",
      });
    }
  };

  onChange(event) {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  onNumberTypeChange(event) {
    const value = parseInt(event.target.value);
    this.setState({
      [event.target.id]: value
    });
  }

  onOptionChange(event) {
    event.persist();
    const {value, name} = event.target;
    this.setState({
      [name]: value
    });
  }

  onAutoPlanChange(plan_name) {
    const { auto_plans } = this.state;
    const newAutoPlans = auto_plans.map(plan => {
      if (plan.plan_name === plan_name) {
        return {...plan, choosen: true }
      } else {
        return {...plan, choosen: false}
      }
    });
    this.setState({
      auto_plans: newAutoPlans,
    })
  }

  onCoachSubmit(event) {
    const {
      selectedHomework: { act_id, next_activities },
      newCardio,
      newCarb,
      newFat,
      newNote,
      newProtein,
      auto_plans,
      mode
    } = this.state;

    const choosenPlan = auto_plans.find(plan => plan.choosen);
    const nextActivities = JSON.parse(next_activities);
    const { nutrients_week } = nextActivities.weekly_plan.plan;

    const {
      username,
      attributes: { given_name, family_name }
    } = this.props.user;

    const change_mode = this.props.personalHistory.profile.base_info.mode !== this.state.mode;
    let newGoal = "same";
    if (choosenPlan.goal === 'diet') {
      newGoal = choosenPlan.goal;
    } else {
      newGoal = mode === "manual" ? "same" : choosenPlan.goal;
    }
    const params = {
      act_id,
      weekly_plan: {
        note: escape(unescape(newNote)),
        coach_id: username,
        coach_name: `${given_name} ${family_name}`,
        plan: {
          food: {
            fat: mode === "manual" ? newFat : choosenPlan.fat,
            protein: mode === "manual" ? newProtein : choosenPlan.protein,
            carbohydrate: mode === "manual" ? newCarb : choosenPlan.carb
          },
          cardio: mode === "manual" ? newCardio : choosenPlan.cardio,
          nutrients_week: mode === "manual" ? nutrients_week : choosenPlan.nutrients_week,
          auto_plans: mode === "manual" ? nextActivities.weekly_plan.plan.auto_plans : auto_plans
        }
      },
      change_mode,
      goal: newGoal
    };
    this.props.updateHomework(params);
    this.openModal("loading");
  }

  successReturn() {
    this.props.resetStatus();
    this.setState({
      showSuccessButton: false,
      showList: true,
      selectedHomework: {},
      newNote: "",
      newScore: 0,
      modalOpen: false,
      modalPage: 1,
      goal: "diet",
      nutrients_week: 1,
      mode: "auto",
      auto_plans: []
    })
  }

  onUpdateGoal() {
    const {
      selectedHomework,
      goal
    } = this.state;
    const { 
      user_id, 
      next_week_in_program: week_in_program 
    } = selectedHomework;
    const params = {
      user_id,
      week_in_program,
      goal
    }
    this.props.updateGoal(params);
    this.setState({
      modalPage: 3
    });

    setTimeout(() => {
      this.successReturn();
    }, 2500);
  }

  onUpdateWorkoutDay() {
    const {
      selectedHomework,
      workout_day
    } = this.state;
    const {
      user_id
    } = selectedHomework;
    const params = {
      user_id,
      amount: parseInt(workout_day)
    }
    this.props.updateWorkoutDay(params);
    this.setState({
      modalPage: 3
    });

    setTimeout(() => {
      this.successReturn();
    }, 2500);
  }

  async handleUpdateHomeworkStatus(data, status) {
    const { user_id } = data;
    this.props.getHistory(user_id);
    this.props.resetStatus();

    if (status === "init") {
      const {
        username,
        attributes: { given_name, family_name }
      } = this.props.user;
      const message = {
        action: "tasks",
        data: {
          coachId: username,
          coachName: `${given_name} ${family_name}`,
          userId: user_id
        }
      };
      this.ws.send(JSON.stringify(message));
    } 
    const { weekly_progress: {
      images: {
        side, 
        front
      }
    } } = JSON.parse(data.activities);
    const frontUrl = front.public_id ? await s3Download(front.public_id) : "";
    const sideUrl = side.public_id ? await s3Download(side.public_id) : "";

    const {
      weekly_plan: next_weekly_plan
    } = JSON.parse(data.next_activities);
    
    const {plan: { food: next_food, cardio: next_cardio, auto_plans} } = next_weekly_plan;

    this.setState(prevState => ({
      showList: !prevState.showList,
      selectedHomework: data,
      newNote: unescape(next_weekly_plan.note),
      frontUrl,
      sideUrl,
      newCardio: next_cardio,
      newFat: next_food.fat,
      newProtein: next_food.protein,
      newCarb: next_food.carbohydrate,
      auto_plans
    }));
  }

  toggle() {
    this.setState(prevState => ({
        modalOpen: !prevState.modalOpen
    }));
  };

  openGoalModal() {
    this.openModal("goal");
  }

  openWorkoutDayModal() {
    this.openModal("workout_day");
  }

  openModal(type) {
    const {base_info} = this.props.personalHistory.profile;
    this.setState({
      modalOpen: true,
      modalPage: 1,
      modalType: type,
      goal: base_info.goal,
      workout_day: base_info.workout_day.toString()
    })
  }

  changeGenericState(modalPageName, page) {
    this.setState({
      [modalPageName]: page
    })
  }

  workoutModalBodyRender() {
    const {base_info} = this.props.personalHistory.profile;
    if (this.state.modalPage === 1) {
      return (
        <ModalBody>
          <AvForm onSubmit={() => this.changeGenericState('modalPage', 2)}>
            <AvRadioGroup
              name="workout_day"
              id="workout_day"
              label=""
              value={this.state.workout_day}
              required
              onChange={this.onOptionChange}
            >
              <AvRadio
                label="3 วัน"
                value="3"
              />
              <hr/>
              <AvRadio
                label="4 วัน"
                value="4"
              />
              <hr/>
              <AvRadio
                label="5 วัน"
                value="5"
              />
              <hr/>
            </AvRadioGroup>
            <div className="d-flex justify-content-center align-items-center">
              <Button 
                className="btn-shadow btn-secondary" 
                size="sm"
                onClick={this.toggle}
              >
                <span className="text-small text-white">
                  <IntlMessages id="label.cancel" />
                </span>
              </Button>
              <div className="mr-2 ml-2"></div>
              <Button 
                color="primary" 
                className="btn-shadow" 
                size="sm"
                type="submit"
                disabled={base_info.workout_day === parseInt(this.state.workout_day)}
              >
                <span className="text-small text-white">
                  <IntlMessages id="label.ok" />
                </span>
              </Button>
            </div>
          </AvForm>
        </ModalBody>
      );
    } else if (this.state.modalPage === 2) {
      return (
        <ModalBody>
          <p>
            คุณยืนยันการเปลี่ยนแปลงจำนวนวันออกกำลังกายจาก&nbsp;
            <span className="font-weight-semibold">{base_info.workout_day}</span> 
            &nbsp;เป็น&nbsp;
            <span className="font-weight-semibold">{this.state.workout_day}</span> 
            &nbsp;หรือไม่?
          </p>
          <div className="d-flex justify-content-center align-items-center">
            <Button 
              className="btn-shadow btn-secondary" 
              size="sm"
              onClick={this.toggle}
            >
              <span className="text-small text-white">
                <IntlMessages id="label.cancel" />
              </span>
            </Button>
            <div className="mr-2 ml-2"></div>
            <Button 
              color="primary" 
              className="btn-shadow" 
              size="sm"
              onClick={this.onUpdateWorkoutDay}
            >
              <span className="text-small text-white">
                <IntlMessages id="label.approve-button" />
              </span>
            </Button>
          </div>
        </ModalBody>
      )
    } else {
      return (
        <ModalBody>
          <div className="d-flex flex-column justify-content-between align-items-center">
            <FontAwesomeIcon icon={faCheck} size="6x" color="#32CD32" />
            <br/>
            <p className="text-medium">
              ปรับจำนวนวันออกกำลังกายเรียบร้อยแล้ว
            </p>
          </div>
        </ModalBody>
      );
    }
  }

  goalModalBodyRender() {
    const {base_info} = this.props.personalHistory.profile;
    if (this.state.modalPage === 1) {
      return (
        <ModalBody>
          <AvForm onSubmit={() => this.changeGenericState('modalPage', 2)}>
            <AvRadioGroup
              name="goal"
              id="goal"
              label=""
              value={this.state.goal}
              required
              onChange={this.onOptionChange}
            >
              <AvRadio
                label="Diet"
                value="diet"
              />
              <hr/>
              <AvRadio
                label="Reverse Diet"
                value="reverse"
              />
              <hr/>
              <AvRadio
                label="Bulk"
                value="bulk"
              />
              <hr/>
            </AvRadioGroup>
            <div className="d-flex justify-content-center align-items-center">
              <Button 
                className="btn-shadow btn-secondary" 
                size="sm"
                onClick={this.toggle}
              >
                <span className="text-small text-white">
                  <IntlMessages id="label.cancel" />
                </span>
              </Button>
              <div className="mr-2 ml-2"></div>
              <Button 
                color="primary" 
                className="btn-shadow" 
                size="sm"
                type="submit"
                disabled={base_info.goal === this.state.goal}
              >
                <span className="text-small text-white">
                  <IntlMessages id="label.ok" />
                </span>
              </Button>
            </div>
          </AvForm>
        </ModalBody>
      );
    } else if (this.state.modalPage === 2) {
      return (
        <ModalBody>
          <p>
            คุณยืนยันการเปลี่ยนแปลงเป้าหมายจาก&nbsp;
            <span className="font-weight-semibold">{base_info.goal.toUpperCase()}</span> 
            &nbsp;เป็น&nbsp;
            <span className="font-weight-semibold">{this.state.goal.toUpperCase()}</span> 
            &nbsp;หรือไม่?
          </p>
          <div className="d-flex justify-content-center align-items-center">
            <Button 
              className="btn-shadow btn-secondary" 
              size="sm"
              onClick={this.toggle}
            >
              <span className="text-small text-white">
                <IntlMessages id="label.cancel" />
              </span>
            </Button>
            <div className="mr-2 ml-2"></div>
            <Button 
              color="primary" 
              className="btn-shadow" 
              size="sm"
              onClick={this.onUpdateGoal}
            >
              <span className="text-small text-white">
                <IntlMessages id="label.approve-button" />
              </span>
            </Button>
          </div>
        </ModalBody>
      )
    } else {
      return (
        <ModalBody>
          <div className="d-flex flex-column justify-content-between align-items-center">
            <FontAwesomeIcon icon={faCheck} size="6x" color="#32CD32" />
            <br/>
            <p className="text-medium">
              ปรับเป้าหมายเรียบร้อยแล้ว
            </p>
          </div>
        </ModalBody>
      );
    }
  }

  loadingModalBodyRender() {
    return (
      <ModalBody>
          {  
            this.props.status === "update homework processing"
            ? <div className='imgup-buttons'>
                <Spinner/>
              </div>
            : <div className="d-flex flex-column justify-content-between align-items-center">
                <FontAwesomeIcon icon={faCheck} size="6x" color="#32CD32" />
                <br/>
                <p className="text-medium">
                  บันทึกข้อมูลเรียบร้อยแล้ว
                </p>
                <div className="d-flex justify-content-center align-items-center">
                  <Button 
                    color="success" 
                    onClick={this.successReturn}
                  >
                    <IntlMessages id="label.success-and-return" />
                  </Button>
                  <div className="mr-2 ml-2"></div>
                  <Button 
                    className="btn-shadow" 
                    onClick={this.toggle}
                  >
                    <span className="text-small text-white">
                      <IntlMessages id="pages.close" />
                    </span>
                  </Button>
                </div>
              </div>
          }
      </ModalBody>
    );
  }


  renderForm() {
    const {
      newNote, 
      sideUrl,
      frontUrl,
      showSuccessButton,
      selectedHomework: {
        activities,
        email,
        name,
        week_in_program,
        next_week_in_program,
      },
      newCardio,
      newProtein,
      newCarb,
      newFat,
      mode,
      auto_plans,
      messageInput
    } = this.state;
    const {
      weekly_progress: {
        measurement: {
          arm,
          chest, 
          navel,
          thigh,
          above_navel,
          below_navel
        }, 
        weekly_comment: user_comment
      },
      weekly_plan,
      daily_activities
    } = JSON.parse(activities);
    
    const {
      foodGoal,
      foodActual,
      cardioGoal,
      cardioActual,
      trainGoal,
      trainActual
    } = calculatePreviousResult(weekly_plan, daily_activities);
    let totalWeight = 0;
    let totalDay = 0;
    let daily_weights = [];
    daily_activities.forEach((act) => {
      if (act.weight > 0) {
        totalWeight += act.weight;
        totalDay += 1;
      }
      daily_weights.push(act.weight);
    });

    const { personalHistory } = this.props;
    const { c_to_c } = personalHistory.profile;
    return (
      <Row>
        {/* โปรแกรมสัปดาห์หน้า */}
        <Colxx xxs="12" lg="12">
          {/* ข้อมูลประวัติ */}
          <HistoryView 
            personalData={{
              email, 
              name
            }}
            personalHistory={personalHistory}
            onGoalClick={this.openGoalModal}
            onWorkoutClick={this.openWorkoutDayModal}
          />
            <Card className="mb-2">
              <CardBody>
                <CardTitle className="text-center">
                  Coach to Coach messages
                </CardTitle>
                {
                  (this.props.user && c_to_c && c_to_c.length > 0) &&
                  <PerfectScrollbar
                    ref={ref => {
                      this._scrollBarRef = ref;
                    }}
                    containerRef={ref => {}}
                    options={{ suppressScrollX: true, wheelPropagation: false }}
                  >
                    {
                      c_to_c.map((item, index) => {
                        return (
                          <MessageCard
                            key={index}
                            item={item}
                            currentCoachId={this.props.user.username}
                          />
                        );
                      })
                    }
                  </PerfectScrollbar>
                }
                <SaySomething
                  placeholder={"ส่งข้อความให้โค้ชคนอื่น"}
                  messageInput={messageInput}
                  onCoach2CoachInputPress={this.onCoach2CoachInputPress}
                  onCoach2CoachInputChange={this.onCoach2CoachInputChange}
                  onCoach2CoachSendClick={this.onCoach2CoachSendClick}
                />
              </CardBody>
            </Card>
          <Card className="mb-2">
            <CardBody>
              <CardTitle className="text-center">
                ปรับโปรแกรมสำหรับสัปดาห์ที่ {next_week_in_program} เป็นต้นไป
              </CardTitle>
              <div className="justify-content-center d-flex flex-row align-items-center">
                <p>โหมดการปรับโปรแกรม:&nbsp;&nbsp;</p>
                <AvForm>
                  <AvRadioGroup
                    name="mode"
                    id="mode"
                    label=""
                    value={this.state.mode}
                    required
                    onChange={this.onOptionChange}
                  >
                    <div className="justify-content-center d-flex flex-row align-items-center">
                      <AvRadio
                        label="ระบบแนะนำ"
                        value="auto"
                      />
                      <div className="mr-2"></div>
                      <AvRadio
                        label="ตั้งค่าเอง"
                        value="manual"
                      />
                    </div>
                  </AvRadioGroup>
                </AvForm>
              </div>
              <div>
                <span className="text-small">{user_comment && "ลูกค้าบอกมาว่า "}</span>
                <span className="text-one mb-3">{unescape(user_comment)} </span>
              </div>
              <div className="horizontal-align">
                <ExifOrientationImg 
                  className="imgup-img"
                  src={frontUrl} 
                  alt='' 
                />
                <ExifOrientationImg 
                  className="imgup-img"
                  src={sideUrl} 
                  alt='' 
                />
              </div>

              <div className="horizontal-align mb-4">
                <table className="table-measurement">
                  <tbody>
                    <tr>
                      <td rowSpan="2" className="rheader">สัดส่วน</td>
                      <td>รอบอก</td>
                      <td>แขน</td>
                      <td>เหนือสะดือ</td>
                      <td>สะดือ</td>
                      <td>ใต้สะดือ</td>
                      <td>ต้นขา</td>
                    </tr>
                    <tr>
                      <td>{chest} cm.</td>
                      <td>{arm} cm.</td>
                      <td>{above_navel} cm.</td>
                      <td>{navel} cm.</td>
                      <td>{below_navel} cm.</td>
                      <td>{thigh} cm.</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="horizontal-align mb-4">
                <table className="table-measurement">
                  <tbody>
                    <tr>
                      <td rowSpan="2" className="rheader">น้ำหนัก</td>
                      <td>จันทร์</td>
                      <td>อังคาร</td>
                      <td>พุธ</td>
                      <td>พฤหัส</td>
                      <td>ศุกร์</td>
                      <td>เสาร์</td>
                      <td>อาทิตย์</td>
                      <td>เฉลี่ย</td>
                    </tr>
                    <tr>
                      <td>{daily_weights[0]}</td>
                      <td>{daily_weights[1]}</td>
                      <td>{daily_weights[2]}</td>
                      <td>{daily_weights[3]}</td>
                      <td>{daily_weights[4]}</td>
                      <td>{daily_weights[5]}</td>
                      <td>{daily_weights[6]}</td>
                      <td>{totalWeight/(totalDay || 1)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="horizontal-align mb-4">
                <table className="table-program">
                  <thead>
                    <tr>
                      <th></th>
                      <th colSpan="3">สรุปผลสัปดาห์ที่ผ่านมา ({week_in_program})</th>
                      {mode === "manual" && <th>สัปดาห์ {next_week_in_program}</th>}
                    </tr>
                    <tr>
                      <th></th>
                      <th>เป้าหมาย</th>
                      <th>ทำได้ (เฉลี่ย)</th>
                      <th>ส่วนต่าง</th>
                      {/* <th>โดยการคำนวน</th>
                      <th>ตามระบบ</th> */}
                      {mode === "manual" && <th>กรอกด้วยมือ</th>}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="rheader">แคลอรี่สุทธิ</td>
                      <td>{formatNumber(foodGoal.energy)}</td>
                      <td>{formatNumber(foodActual.energy)}</td>
                      <td>{formatNumber(foodGoal.energy - foodActual.energy)}</td>
                      {/* <td>{formatNumber(next_energy)}</td>
                      <td>{formatNumber(next_tdee_energy)}</td> */}
                      {mode === "manual" && <td></td>}
                    </tr>
                    <tr>
                      <td className="rheader">เวลาคาร์ดิโอ</td>
                      <td>{formatNumber(cardioGoal)}</td>
                      <td>{formatNumber(cardioActual)}</td>
                      <td>{formatNumber(cardioGoal - cardioActual)}</td>
                      {/* <td>{formatNumber(next_weekly_plan.plan.cardio)}</td>
                      <td>{formatNumber(next_tdee.cardio)}</td> */}
                      {mode === "manual" && 
                      <td>
                        <Input
                          type="number"
                          name="newCardio"
                          id="newCardio"
                          value={newCardio}
                          onChange={this.onNumberTypeChange}/>
                      </td>
                      }
                    </tr>
                    <tr>
                      <td className="rheader">โปรตีน</td>
                      <td>{formatNumber(foodGoal.protein)}</td>
                      <td>{formatNumber(foodActual.protein)}</td>
                      <td>{formatNumber(foodGoal.protein - foodActual.protein)}</td>
                      {/* <td>{formatNumber(next_food_plan.protein)}</td>
                      <td>{formatNumber(next_tdee.protein)}</td> */}
                      {mode === "manual" && 
                      <td>
                        <Input
                            type="number"
                            name="newProtein"
                            id="newProtein"
                            value={newProtein}
                            onChange={this.onNumberTypeChange}/>
                      </td>
                      }
                    </tr>
                    <tr>
                      <td className="rheader">คาร์โบไฮเดรต</td>
                      <td>{formatNumber(foodGoal.carbohydrate)}</td>
                      <td>{formatNumber(foodActual.carbohydrate)}</td>
                      <td>{formatNumber(foodGoal.carbohydrate - foodActual.carbohydrate)}</td>
                      {/* <td>{formatNumber(next_food_plan.carbohydrate)}</td>
                      <td>{formatNumber(next_tdee.carb)}</td> */}
                      {mode === "manual" && <td>
                        <Input
                            type="number"
                            name="newCarb"
                            id="newCarb"
                            value={newCarb}
                            onChange={this.onNumberTypeChange}/>
                      </td>}
                    </tr>
                    <tr>
                      <td className="rheader">ไขมัน</td>
                      <td>{formatNumber(foodGoal.fat)}</td>
                      <td>{formatNumber(foodActual.fat)}</td>
                      <td>{formatNumber(foodGoal.fat - foodActual.fat)}</td>
                      {/* <td>{formatNumber(next_food_plan.fat)}</td>
                      <td>{formatNumber(next_tdee.fat)}</td> */}
                      {mode === "manual" && <td>
                        <Input
                          type="number"
                          name="newFat"
                          id="newFat"
                          value={newFat}
                          onChange={this.onNumberTypeChange}/>
                      </td>}
                    </tr>
                    <tr>
                      <td className="rheader">จำนวนวันที่เล่นเวทในสัปดาห์</td>
                      <td>{trainActual}/{trainGoal} วัน</td>
                      <td></td>
                      <td></td>
                      {/* <td></td>
                      <td></td> */}
                      {mode === "manual" && <td></td>}
                    </tr>
                  </tbody>
                </table>
                <div className="mr-3"></div>
                {
                  mode === "auto" &&
                  auto_plans.map(autoPlan => 
                    <table className="table-program" key={autoPlan.plan_name}>
                      <thead>
                        <tr>
                          <th></th>
                        </tr>
                        <tr>
                          <th>{autoPlan.plan_name}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="small-col text-small text-center">
                            {autoPlan.goal === "same" ? "" : `เป้า ${autoPlan.goal}`}
                          </td>
                        </tr>
                        <tr>
                          <td className="small-col normal-col">{formatNumber(autoPlan.cardio)}</td>
                        </tr>
                        <tr>
                          <td className="small-col normal-col">{formatNumber(autoPlan.protein)}</td>
                        </tr>
                        <tr>
                          <td className="small-col normal-col">{formatNumber(autoPlan.carb)}</td>
                        </tr>
                        <tr>
                          <td className="small-col normal-col">{formatNumber(autoPlan.fat)}</td>
                        </tr>
                        <tr className="big-row">
                          <td className="small-col">
                            <div className="d-flex justify-content-center align-items-center">
                            {autoPlan.choosen 
                              ? <FontAwesomeIcon icon={faCheck} size="2x" color="#32CD32" />
                              : <Button 
                                  outline 
                                  color="primary" 
                                  size="sm"
                                  onClick={() => this.onAutoPlanChange(autoPlan.plan_name)}
                                >เลือก</Button>
                            }
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      
                    </table>
                  )
                }
              </div>
              <div className="vertical-align mb-4 pl-4 pr-4">
                <Label>
                  คอมเมนต์สำหรับสัปดาห์ต่อไป
                </Label>
                <Input 
                  type="textarea"
                  name="newNote"
                  id="newNote"
                  value={newNote}
                  onChange={this.onChange}/>
              </div>
              <div className="horizontal-align">
                <Button color="primary" className="mr-1 ml-1" onClick={this.onCoachSubmit}>
                  <IntlMessages id="label.save" />
                </Button>
                <Button color="warning" className="mr-1 ml-1" onClick={this.successReturn}>
                  <IntlMessages id="modal.finish-cancel"/>
                </Button>
                {
                  showSuccessButton &&
                  <Button color="success" className="mr-1 ml-1" onClick={this.successReturn}>
                    <IntlMessages id="label.success-and-return" />
                  </Button>
                }
              </div>
            </CardBody>
          </Card>
        </Colxx>

      </Row>
    );
  }

  renderList() {
    const {
      user,
      init,
      checking,
      complete,
      status,
      intl: { messages }
    } = this.props;
    const dataTableColumns = initTableColumns(
      status,
      messages,
      this.handleUpdateHomeworkStatus
    );
    const coachDataTableColumns = processedDataTableColumns(
      status,
      messages,
      this.handleUpdateHomeworkStatus,
      user.username
    );

    const sortedColumns = [
      {
        id: "sent_number",
        desc: true
      },
      {
        id: "email",
        desc: false
      }
    ];

    return (
      <div>
        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card className="mb-4">
              <CardBody>
                <CardTitle>
                  <IntlMessages id="label.init-title" />
                </CardTitle>
                <ReactTable
                  data={init}
                  columns={dataTableColumns}
                  defaultPageSize={10}
                  filterable={true}
                  showPageJump={true}
                  PaginationComponent={DataTablePagination}
                  showPageSizeOptions={true}
                  defaultSorted={sortedColumns}
                />
              </CardBody>
            </Card>
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card className="mb-4">
              <CardBody>
                <CardTitle>
                  <IntlMessages id="label.checking-title" />
                </CardTitle>
                <ReactTable
                  data={checking}
                  columns={coachDataTableColumns}
                  defaultPageSize={10}
                  filterable={true}
                  showPageJump={true}
                  PaginationComponent={DataTablePagination}
                  showPageSizeOptions={true}
                  defaultSorted={sortedColumns}
                />
              </CardBody>
            </Card>
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card className="mb-4">
              <CardBody>
                <CardTitle>
                  <IntlMessages id="label.complete-title" />
                </CardTitle>
                <ReactTable
                  data={complete}
                  columns={coachDataTableColumns}
                  defaultPageSize={10}
                  filterable={true}
                  showPageJump={true}
                  PaginationComponent={DataTablePagination}
                  showPageSizeOptions={true}
                  defaultSorted={sortedColumns}
                />
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </div>
    );
  }

  render() {
    const { showList, modalOpen, modalType } = this.state;
    return (
      <Fragment>
        <Modal isOpen={modalOpen}>
          {
            modalType !== "loading" &&
            <ModalHeader toggle={this.toggle}>
              <span className="text-medium">
                <IntlMessages id="user.goal" />
              </span>
            </ModalHeader>
          }
          {
            modalType === "goal"
              ? this.goalModalBodyRender()
              : modalType === "workout_day"
                ? this.workoutModalBodyRender()
                : this.loadingModalBodyRender()
          }
        </Modal>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.homework" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {showList 
          ? this.renderList() 
          : this.renderForm()
        }
      </Fragment>
    );
  }
}

const mapStateToProps = ({ admin, authUser, activities }) => {
  const { init, checking, complete, status } = admin;
  const { user } = authUser;
  const { historyStatus, personalHistory } = activities
  return { init, checking, complete, status, user, historyStatus, personalHistory };
};
const mapActionsToProps = {
  getHomeworks,
  updateHomework,
  filterCheckHomework,
  resetStatus,
  getHistory,
  updateGoal,
  updateWorkoutDay,
  updateCoach2Coach
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(injectIntl(homeworks));
