import React, { useState, useEffect } from "react";
import { Card, CardBody, Row } from "reactstrap";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";

import IntlMessages from "../../helpers/IntlMessages";
import LoaderButton from "../../components/LoaderButton";
import { Colxx } from "../../components/common/CustomBootstrap";

function WeeklyComment({ weeklyComment, updateWeeklyProgress, loading }) {

  const [note, setNote] = useState(weeklyComment);
  useEffect(() => {
    setNote(weeklyComment);
  }, [weeklyComment]);

  function submitData(event, errors) {
    if (errors.length === 0) {
      updateWeeklyProgress("weekly_comment", escape(unescape(note)));
    }
  }

  function handleChange(event) {
    setNote(event.target.value);
  }

  return (
    <div>
      <Card>
        <CardBody>
          <h6 className="mb-4">
            <IntlMessages id="activities.note" />
          </h6>

          <AvForm 
            className="av-tooltip tooltip-label-right"
            onSubmit={submitData}
          >
            <Row>
              <Colxx lg={12}>
                <AvGroup>
                  <AvField
                    name="note"
                    id="note"
                    type="textarea"
                    value={unescape(note)}
                    onChange={handleChange}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "อย่าลืมกรอกข้อความ"
                      }
                    }}
                  />
                </AvGroup>
              </Colxx>
            </Row>

            <LoaderButton
                color="primary"
                block
                status={loading ? "processing" : "default"}
              >
              <IntlMessages id="activities.submit" />
            </LoaderButton>
          </AvForm>
        </CardBody>
      </Card>
    </div>
  );
}
export default WeeklyComment;
