import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';

export default props => 
  <div className='imgup-buttons imgup-fadein'>
    
    <div className='imgup-button'>
      <label htmlFor='single'>
        <FontAwesomeIcon icon={faPlusSquare} color='#3B5998' size='6x' />
      </label>
      <input type='file' accept="image/*" className="imgup-hidden" id='single' onChange={props.onChange} /> 
    </div>
    <p className="text-one text-center">{props.label}</p>

  </div>