import React, { useState, useEffect } from 'react';
import { Card, CardTitle } from "reactstrap";
import Img from 'react-fix-image-orientation';

import { formatNumber, calculatePreviousResult } from "../helpers/Utils";
import { s3DownloadPromise } from "../helpers/awsLib";

const HistoryItem = ({ week_in_program, activities }) => {
  const [frontUrl, setFrontUrl] = useState("");
  const [sideUrl, setSideUrl] = useState("");

  const {
    weekly_plan,
    weekly_progress,
    daily_activities
  } = activities;
  const { images: {
    front, 
    side
  } } = weekly_progress;

  useEffect(() => {
    async function fetchImageUrl() {
      try {
        const secureFrontUrl = (front && front.public_id) ? await s3DownloadPromise(front.public_id) : "";
        const secureSideUrl = (side && side.public_id) ? await s3DownloadPromise(side.public_id) : "";
        setFrontUrl(secureFrontUrl);
        setSideUrl(secureSideUrl);
      } catch (error) {
        console.log("error when fetching image url in week ", week_in_program, error);
        setFrontUrl("");
        setSideUrl("");
      }
    }
    fetchImageUrl();
  }, [ front, side, week_in_program])

  const {
    arm,
    chest,
    above_navel,
    navel,
    below_navel,
    thigh,
  } = weekly_progress.measurement;
  const {
    foodGoal,
    foodActual,
    cardioGoal,
    cardioActual,
    trainGoal,
    trainActual
  } = calculatePreviousResult(weekly_plan, daily_activities);

  return (
    <Card className="history-item">
      <div className="history-item-content">
        <CardTitle className="text-center">
          ย้อนหลังสัปดาห์ที่ {week_in_program}
        </CardTitle>
        <div className="history-image-wrapper">
          <Img 
            className="history-img"
            src={frontUrl} 
            alt='' 
          />
          <Img 
            className="history-img"
            src={sideUrl} 
            alt='' 
          />
        </div>
        <div className="history-table-wrapper">
          <table className="table-program mb-4">
            <thead>
              <tr>
                <th></th>
                <th>เป้าหมาย</th>
                <th>ทำได้ (เฉลี่ย)</th>
                <th>ส่วนต่าง</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="rheader">แคลอรี่สุทธิ</td>
                <td>{formatNumber(foodGoal.energy)}</td>
                <td>{formatNumber(foodActual.energy)}</td>
                <td className="normal-col">{formatNumber(foodGoal.energy - foodActual.energy)}</td>
              </tr>
              <tr>
                <td className="rheader">เวลาคาร์ดิโอ</td>
                <td>{formatNumber(cardioGoal)}</td>
                <td>{formatNumber(cardioActual)}</td>
                <td className="normal-col">{formatNumber(cardioGoal - cardioActual)}</td>
              </tr>
              <tr>
                <td className="rheader">จำนวนวันเล่นเวท</td>
                <td>{formatNumber(trainGoal)}</td>
                <td>{formatNumber(trainActual)}</td>
                <td className="normal-col">{formatNumber(trainGoal - trainActual)}</td>
              </tr>
              <tr>
                <td className="rheader">โปรตีน</td>
                <td>{formatNumber(foodGoal.protein)}</td>
                <td>{formatNumber(foodActual.protein)}</td>
                <td className="normal-col">{formatNumber(foodGoal.protein - foodActual.protein)}</td>
              </tr>
              <tr>
                <td className="rheader">คาร์โบไฮเดรต</td>
                <td>{formatNumber(foodGoal.carbohydrate)}</td>
                <td>{formatNumber(foodActual.carbohydrate)}</td>
                <td className="normal-col">{formatNumber(foodGoal.carbohydrate - foodActual.carbohydrate)}</td>
              </tr>
              <tr>
                <td className="rheader">low carb</td>
                <td >{formatNumber(foodGoal.low_carb)}</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td className="rheader">ไขมัน</td>
                <td>{formatNumber(foodGoal.fat)}</td>
                <td>{formatNumber(foodActual.fat)}</td>
                <td className="normal-col">{formatNumber(foodGoal.fat - foodActual.fat)}</td>
              </tr>
              <tr>
                <td className="rheader">low fat</td>
                <td >{formatNumber(foodGoal.low_fat)}</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td className="rheader">สัดส่วน</td>
                <td colSpan="3" className="normal-col">
                  <div>
                    <p>อก: {chest} ซม.</p>
                    <p>ต้นแขน: {arm} ซม.</p>
                    <p>เหนือสะดือ: {above_navel} ซม.</p>
                    <p>สะดือ: {navel} ซม.</p>
                    <p>ใต้สะดือ: {below_navel} ซม.</p>
                    <p>ต้นขา: {thigh} ซม.</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <p className="text-one text-muted mb-1">
            Customer Comment
          </p>
          <p className="text-one">
            {unescape(weekly_progress.weekly_comment)}
          </p>
          <p className="text-one text-muted mb-1">
            Coach Comment
          </p>
          <p className="text-one">
            {unescape(weekly_plan.note)}
          </p>
          <p className="text-one text-muted mb-1">
            Coach Name
          </p>
          <p className="text-one">
            {unescape(weekly_plan.coach_name)}
          </p>
        </div>
      </div>
    </Card>
  );
};

export default HistoryItem;