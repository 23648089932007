import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import { reducer as authUser} from './auth';
import { reducer as activities } from './activities';
import { reducer as exerciseVideos } from './exerciseVideos';
import { reducer as checkout } from './checkout';
import { reducer as admin } from './admin';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  activities,
  exerciseVideos,
  checkout,
  admin,
});

export default reducers;