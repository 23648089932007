import React, { Component, Fragment } from "react";
import { 
  Row, 
  Card, 
  CardTitle, 
  Form, 
  Label, 
  Input, 
  Button, 
  Alert
} from "reactstrap";
import { connect } from "react-redux";

import { loginUser } from "../redux/auth";
import { Colxx, Separator } from "../components/common/CustomBootstrap";
import IntlMessages from "../helpers/IntlMessages";
import LoaderButton from "../components/LoaderButton";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: ""
    };
  }
  onUserLogin() {
    if (this.state.email !== "" && this.state.password !== "") {
      this.props.loginUser(this.state, this.props.history);
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.id]: event.target.value
    })
  }

  renderErrorMessage(errorMessageID) {
    if (!errorMessageID) {
      return null;
    }
    return (
      <Alert color="danger">
        <IntlMessages id={errorMessageID}/>
      </Alert>
    );
  }

  render() {
    const {loading} = this.props;
    return (
      <Fragment>
        <div className='unauth-nav-container'>
          <img src="/assets/img/p4flogo.svg" className="unauth-nav-logo" alt="logo"/>
          <p className="inner-text">
            CYCLICAL CALORIC RESTRICTION (CCR)
          </p>
        </div>
        <Row className="h-100 login-body">
          <Colxx xxs="12" md="10" className="mx-auto my-auto pr-0">
            <Card className="auth-card">
              <div className="form-side">
                <img className="login-logo" src="/assets/img/login_logo.svg" alt="logo"/>
                <CardTitle className="mb-4">
                  <IntlMessages id="user.login-button" />
                </CardTitle>
                <Form className="login-form">
                  <Label className="form-group has-float-label mb-4">
                  <Input 
                    type="email" 
                    id="email" 
                    value={this.state.email} onChange={(event) => this.handleChange(event)} 
                  />
                    <IntlMessages id="user.email" />
                  </Label>
                  <Label className="form-group has-float-label mb-4">
                  <Input 
                    type="password" 
                    id="password" 
                    value={this.state.password} onChange={(event) => this.handleChange(event)} 
                  />
                    <IntlMessages
                      id="user.password"
                      defaultValue={this.state.password}
                    />
                  </Label>
                  {this.renderErrorMessage(this.props.errorMessageID)}
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <LoaderButton
                      color="primary"
                      className="btn-shadow"
                      size="lg"
                      onClick={() => this.onUserLogin()}
                      block
                      status={loading ? "processing" : "default"}
                    >
                      <span className="text-one">
                        <IntlMessages id="user.login-button" />
                      </span>
                    </LoaderButton>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <Button 
                      className="btn-link" 
                      href="/forgot-password" 
                      color="empty"
                      block
                    >
                      <span className="text-one">
                        <IntlMessages id="user.forgot-password-question" />
                      </span>
                    </Button>
                  </div>
                  <Separator className="mb-3" />
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <Button
                      color="light"
                      className="btn-shadow"
                      size="lg"
                      href="/register"
                      block
                    >
                      <IntlMessages id="user.register-button" />
                    </Button>
                  </div>
                </Form>
              </div>
              
              <div className="position-relative image-side ">
                <img className="login-image" src="/assets/img/welcome.jpg" alt="login"/>
              </div>          
            </Card>
          </Colxx>
        </Row>
        <div className='unauth-footer-container'>
          <p className="text-one mb-0">
            ติดต่อสอบถาม
          </p>
          <div className="inner-wrapper">
            <img src="/assets/img/footer-tel.svg" className="logo" alt="tel"/>
            <img src="/assets/img/footer-fb.svg" className="logo" alt="fb"/>
            <img src="/assets/img/footer-line.svg" className="logo" alt="line"/>
            <img src="/assets/img/footer-msg.svg" className="logo" alt="msg"/>
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = ({ authUser }) => {
  const { user, loading, errorMessageID } = authUser;
  return { user, loading, errorMessageID };
};

const mapActionsToProps = { loginUser };

export default connect(
  mapStateToProps,
  mapActionsToProps
)(Login);
