import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import approve from "./approve";
import videoDetail from "./video-detail";
import homework from "./homeworks";
import userHistory from "./userHistory";

const admin = ({ match }) => (
  <div className="dashboard-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/homework`} />
      <Route path={`${match.url}/approve`} component={approve} />
      <Route path={`${match.url}/videodetail`} component={videoDetail} />
      <Route path={`${match.url}/homework`} component={homework} />
      <Route path={`${match.url}/userHistory`} component={userHistory} />
      <Redirect to="/error" />
    </Switch>
  </div>
);
export default admin;
