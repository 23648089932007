/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register 
03.Menu
04.Error 
05.Form
06.Error Message
07.activities
08.Pages
09.Modal
*/


module.exports = {
  /* 01.General */
  "general.copyright": "Fit Together © 2019 All Rights Reserved.",
  "general.company-name": "บริษัท ฟิต ทูเกทเธอร์ จำกัด (สำนักงานใหญ่)",
  "general.company-address": "เลขที่ 429/129 ถนนสรงประภา แขวงดอนเมือง เขตดอนเมือง กรุงเทพมหานคร 10210",
  "general.company-tel": "โทร. 02-029-9341",
  "general.company-taxnumber": "เลขประจำตัวผู้เสียภาษี 0-1055-59179-48-4",

  /* 02.User Login, Logout, Register */
  "user.register": "ลงทะเบียน",
  "user.forgot-password": "ลืมพาสเวิร์ด",
  "user.email": "อีเมล์",
  "user.password": "พาสเวิร์ด",
  "user.address": "ที่อยู่",
  "user.confirm-password": "ยืนยันพาสเวิร์ด",
  "user.new-password": "พาสเวิร์ดใหม่",
  "user.confirm-new-password": "ยืนยันพาสเวิร์ดใหม่",
  "user.forgot-password-question": "ลืมพาสเวิร์ด?",
  "user.fullname": "ชื่อ-นามสกุล",
  "user.firstname": "ชื่อ",
  "user.lastname": "นามสกุล",
  "user.login-button": "เข้าใช้งาน",
  "user.logout-button": "ออกจากระบบ",
  "user.register-button": "สมัครใหม่",
  "user.continue-button": "บันทึกแล้วไปขั้นต่อไป",
  "user.transfer-button": "แจ้งการโอนเงิน",
  "user.reset-password-button": "เปลี่ยนรหัสผ่านใหม่",
  "user.confirmation-code": "แก้ไขรหัสผ่าน",
  "user.password-changed": "รหัสผ่านของคุณถูกแก้ไขแล้ว",
  "user.password-changed-button": "คลิกปุ่มนี้เพื่อเข้าสู่ระบบ",
  "user.confirm-button": "ยืนยัน",
  "user.buy": "สั่งซื้อ",
  "user.renew": "ต่ออายุ",
  "user.line1": "ที่อยู่",
  "user.line2": "ที่อยู่บรรทัดสอง",
  "user.tambol": "แขวง/ตำบล",
  "user.city": "เขต/อำเภอ",
  "user.state": "จังหวัด",
  "user.zip": "รหัสไปรษณีย์",
  "user.phone": "โทรศัพท์มือถือ",
  "user.user_link": "Facebook Name",
  "user.payment_type": "วิธีชำระ",
  "user.weight": "น้ำหนัก (กก.)",
  "user.height": "ส่วนสูง (ซม.)",
  "user.age": "อายุ",
  "user.gender": "เพศ",
  "user.work_active_level": "ลักษณะงาน",
  "user.workout_day": "จำนวนวันออกกำลังกาย/สัปดาห์",
  "user.reset_profile": "รีเซ็ตข้อมูลตั้งต้น",
  "user.update_time": "วัน-เวลา",
  "user.goal": "เป้าหมายการออกกำลังกาย",
  "user.expire_date": "วันหมดอายุ",

  /* 03.Menu */
  "menu.app": "หน้าหลัก",
  "menu.daily-record": "บันทึกผล",
  "menu.main": "คำแนะนำ",
  "menu.dashboards": "หน้ารายงานรวม",
  "menu.gogo": "Gogo",
  "menu.start": "เริ่ม",
  "menu.second-menu": "เมนูที่สอง",
  "menu.second": "ที่สอง",
  "menu.ui": "UI",
  "menu.charts": "กราฟ",
  "menu.chat": "แชท",
  "menu.survey": "สำรวจ",
  "menu.todo": "รายการที่ต้องทำ",
  "menu.search": "ค้นหา",
  "menu.activities": "กิจกรรมประจำวัน",
  "menu.video": "วิดีโอ",
  "menu.exercise-video": "เบิร์นไขมัน",
  "menu.weight-program": "โปรแกรมเวท",
  "menu.waiting": "รออีกไม่นาน",
  "menu.approve": "ยืนยันการชำระ",
  "menu.admin": "แอดมิน",
  "menu.videodetail": "รายละเอียดวิดีโอ",
  "menu.homework": "ตรวจการบ้าน",
  "menu.food-intro": "คำแนะนำเรื่องการทานอาหาร",
  "menu.exercise-intro": "คำแนะนำเรื่องการออกกำลังกาย",
  "menu.coming-soon": "Coming Soon",
  "menu.weekly-submit": "ประเมินผลรายสัปดาห์",
  "menu.history": "ประวัติ",
  "menu.userHistory": "ประวัติ",

  /* 04.Error Page */
  "layouts.error-title": "โอ้ว... เหมือนจะมีอะไรผิดพลาดซักอย่าง!",
  "layouts.error-code": "รหัส Error",
  "layouts.go-back-home": "กลับไปที่หน้าแรก",

  /* 05.Form Message */
  "field.required": "ช่องนี้ต้องกรอก!",
  "label.transfer-datetime": "วัน-เวลา โอนเงิน",
  "label.transfer-month": "เดือนที่โอน",
  "label.register-date": "วันที่ลงทะเบียน",
  "label.today": "วันนี้",
  "label.transfer-details": "เลขที่บัญชี: ธนาคารไทยพาณิชย์ เลขที่บัญชี 4075206407",
  "label.transfer-details2": "บริษัท ฟิต ทูเกทเธอร์ จำกัด ออมทรัพย์ สาขาจตุรัสจามจุรี",
  "label.transfer-amount": "ยอดเงิน ",
  "label.transfer-note1": " เช่น ",
  "label.transfer-note2": " เพื่อให้ง่ายต่อการตรวจสอบ",
  "label.transfer-title": "รายละเอียดการโอนเงิน",
  "label.total": "ยอดรวม",
  "label.have-account": "เป็นสมาชิกอยู่แล้วใช่มั๊ย?",
  "label.haveno-account": "ยังไม่ได้เป็นสมาชิกใช่มั๊ย?",
  "label.existing-user-warning": "อีเมล์นี่เป็นของฉันเอง ไปหน้าเข้าระบบ",
  "label.reset-password-success": "ทีมงานได้ส่งอีเมล์แสดงวิธีการ เปลี่ยนแปลงรหัสผ่าน ให้คุณแล้ว โปรดตรวจอีเมล์ของคุณ",
  "label.invoice_no": "ใบเสร็จเลขที่",
  "label.item_name": "ชื่อสินค้า",
  "label.quantity": "จำนวน",
  "label.price": "ราคา",
  "label.subtotal": "มูลค่ารวม :",
  "label.tax": "ภาษี :",
  "label.Total": "มูลค่าสุทธิ :",
  "label.pending": "รอตรวจสอบ",
  "label.approved": "ตรวจสอบแล้ว",
  "label.status": "สถานะ",
  "label.batch-no": "รุ่นที่",
  "label.approve-button": "ยืนยัน",
  "label.reject-button": "ปฏิเสธ",
  "label.unsubcribers-title": "รายชื่อสมาชิกที่ยังไม่สั่งซื้อ",
  "label.pending-title": "รายการที่รอยืนยันการชำระ",
  "label.rejected-title": "รายการที่ถูกปฏิเสธ",
  "label.approved-title": "รายการที่ได้รับการยืนยันเรียบร้อยแล้ว",
  "label.video-file": "ไฟล์วิดีโอ",
  "label.video-title": "ชื่อ",
  "label.video-description": "คำอธิบาย",
  "label.video-level": "ระดับความยาก",
  "label.elementary": "ขั้นพื้นฐาน",
  "label.intermediate": "ขั้นกลาง",
  "label.advance": "ขั้นสูง",
  "label.video-score": "คะแนน",
  "label.new-upload": "อัพโหลดวิดีโอต่อ",
  "label.existing-add": "เพิ่มวิดีโอจาก Vimeo",
  "label.entirely-add": "อัพโหลดวิดีโอใหม่",
  "label.save": "บันทึก",
  "label.id": "ID",
  "label.init-title": "การบ้านที่ยังไม่ตรวจ",
  "label.checking-title": "การบ้านที่กำลังตรวจอยู่",
  "label.complete-title": "การบ้านที่ตรวจเสร็จแล้ว",
  "label.sent_number": "วันที่ส่งการบ้าน",
  "label.check_homework": "ตรวจการบ้าน",
  "label.recheck_homework": "กลับไปตรวจการบ้าน",
  "label.watch_homework": "แอบเข้าไปดูการบ้าน",
  "label.coach_name": "โค้ชผู้รับผิดชอบ",
  "label.nutrition_note": "ข้อความจากโค้ช",
  "label.homework": "การบ้านสัปดาห์ที่ผ่านมา",
  "label.homework_history": "ประวัติโปรแกรมที่ผ่านมา",
  "label.plan": "แผนการประจำสัปดาห์ที่ผ่านมา",
  "label.nextplan": "โปรแกรมสัปดาห์หน้า",
  "label.weeklyscore": "คะแนนทั้งสัปดาห์",
  "label.success-and-return": "<< บันทึกสำเร็จ กลับสู่หน้าแรก",
  "label.click": "คลิก",
  "label.intro": "คำแนะนำเรื่อง",
  "label.food_intro": "การทานอาหาร",
  "label.exercise_intro": "การออกกำลังกาย",
  "label.user_detail": "รายละเอียดผู้ใช้",
  "label.week_no": "สัปดาห์ที่",
  "label.male": "ผู้ชาย",
  "label.female": "ผู้หญิง",
  "label.passive-work": "นั่งส่วนใหญ่",
  "label.active-work": "ยืนและเดินมาก",
  "label.day": "วันที่",
  "label.day-unit": " วัน",
  "label.reset-tdee-success": "คุณได้ทำการแก้ไขข้อมูลตั้งต้น และโปรแกรมของคุณในระบบทั้งหมดแล้ว",
  "label.reset-tdee-until": "คุณสามารถแก้ไขข้อมูลดังต่อไปนี้ได้จนถึงวันที่:",
  "label.diet": "ลดไขมัน",
  "label.bulk": "เพิ่มกล้ามเนื้อ",
  "label.reverse": "Reverse Diet",
  "label.what_low_carb": "Low-Carb Day คืออะไร",
  "label.low_carb_day": "Low-Carb Day",
  "label.monday": "วันจันทร์",
  "label.tuesday": "วันอังคาร",
  "label.wednesday": "วันพุธ",
  "label.thursday": "วันพฤหัส",
  "label.friday": "วันศุกร์",
  "label.saturday": "วันเสาร์",
  "label.sunday": "วันอาทิตย์",
  "label.and": "และ",
  "label.choose_days": "เลือกวันอื่น",
  "label.choose_lcarb_days": "เลือก Low-Carb Day",
  "label.cancel": "ยกเลิก",
  "label.ok": "ตกลง",
  "label.empty": " ",
  "label.change": "เปลี่ยน",
  "label.begin": "เริ่มต้น",
  
  /* 06. Error Message */
  "error.login-failed": "อีเมล์ หรือพาสเวิร์ดของคุณไม่ถูกต้อง",
  "error.username-existed": "อีเมล์นี้มีการลงทะเบียนไว้แล้ว",
  "error.newuser": "อีเมล์นี้ยังไม่มีการลงทะเบียนไว้",
  "error.invalid-password": "พาสเวิร์ดผิดรูปแบบ (ความยาวอย่างน้อยต้อง 6 ตัวอักษร)",
  "error.general": "มีบางอย่างผิดพลาด",
  "error.address": "อย่าลืมกรอกที่อยู่",
  "error.tambol": "อย่าลืมกรอก แขวง/ตำบล",
  "error.city": "อย่าลืมกรอกอำเภอ",
  "error.state": "อย่าลืมกรอกจังหวัด",
  "error.zip": "อย่าลืมกรอกรหัสไปรษณีย์",
  "error.transfer-amount": "อย่าลืมกรอกจำนวนเงิน",
  "error.tooless-amount": "จำนวนที่คุณกรอกน้อยกว่าราคาสินค้า",
  "error.phone": "อย่าลืมกรอกเบอร์โทรศัพท์",
  "error.user_link": "อย่าลืมอกรอกลิงค์ facebook",
  "error.weight": "อย่าลืมกรอกน้ำหนัก",
  "error.height": "อย่าลืมกรอกส่วนสูง",
  "error.age": "อย่าลืมกรอกอายุ",
  "error.gender": "อย่าลืมกรอกเพศ",
  "error.work_active_level": "อย่าลืมกรอกลักษณะงาน",
  "error.workout_day": "อย่าลืมกรอกจำนวนวันออกกำลังกาย",
  "error.goal": "อย่าลืมกรอกเป้าหมายของคุณ",
  
  /* 07. activities */
  "activities.daily-info": "ข้อมูลรายวัน",
  "activities.weekly-score": "คะแนน",
  "activities.score-description": "คำนวนจากทั้งสัปดาห์",
  "activities.nutrition-plan": "โปรแกรมอาหาร",
  "activities.plan-description": "สำหรับสัปดาห์นี้",
  "activities.monday": "จ.",
  "activities.tuesday": "อ.",
  "activities.wednesday": "พ.",
  "activities.thursday": "พฤ.",
  "activities.friday": "ศ.",
  "activities.saturday": "ส.",
  "activities.sunday": "อา.",
  "activities.note": "ข้อความถึงโค้ช",
  "activities.submit": "บันทึก",
  "activities.can": "ได้",
  "activities.cannot": "ไม่ได้",
  "activities.breakfast": "มื้อเช้า",
  "activities.snack": "ของว่าง",
  "activities.lunch": "มื้อกลางวัน",
  "activities.supper": "มื้อเย็น",
  "activities.veggie": "ผัก",
  "activities.meat": "เนื้อ",
  "activities.carb": "ข้าว",
  "activities.feeling": "รู้สึกยังไง?",
  "activities.feel-level1": "ยังรู้สึกหิว",
  "activities.feel-level2": "อิ่มพอดี",
  "activities.feel-level3": "อิ่มมาก",
  "activities.feel-level4": "อิ่มจนจุก",
  "activities.feedback-from-coach": "ความเห็นจากโค้ชในสัปดาห์นี้",
  "activities.food-title": "รายการอาหาร",
  "activities.food-name": "ชื่ออาหาร",
  "activities.food-carb": "คาร์บ",
  "activities.food-protein": "โปรตีน",
  "activities.food-fat": "ไขมัน",
  "activities.food-submit": "กรอกอาหาร",
  "activities.weight-submit": "กรอกน้ำหนักวันนี้",
  "activities.weight-title": "น้ำหนักตัว",
  "activities.weight-today": "น้ำหนักตัววันนี้",
  "activities.weight-kg": "กิโลกรัม",
  "activities.cardio-title": "คาร์ดิโอ",
  "activities.cardio-minute": "นาที",
  "activities.cardio-submit": "กรอกคาร์ดิโอ",
  "activities.cardio-name": "ชื่อกิจกรรม",
  "activities.cardio-today": "คาร์ดิโอสำหรับวันนี้",
  "activities.cardio-weekly": "คาร์ดิโอในสัปดาห์นี้",
  "activities.front-image": "ภาพถ่ายด้านหน้า",
  "activities.side-image": "ภาพถ่ายด้านข้าง",
  "activities.measurement-title": "วัดสัดส่วนประจำสัปดาห์",
  "activities.measurement-submit": "กรอกสัดส่วน",
  "activities.measurement-chest": "หน้าอก",
  "activities.measurement-arm": "แขน (ข้างถนัด)",
  "activities.measurement-thigh": "ต้นขา (ข้างถนัด)",
  "activities.measurement-navel": "เอวตรงสะดือ",
  "activities.measurement-above-navel": "เหนือสะดือ 2 นิ้ว",
  "activities.measurement-below-navel": "ใต้สะดือ 2 นิ้ว",
  "activities.measurement-cm": "ซม.",
  "activities.weight-program": "โปรแกรมเวท",
  "activities.weight-name": "ชื่อท่า",
  "activities.weight-rep": "จำนวนครั้ง",
  "activities.weight-set": "เซต",
  "activities.weight-rest": "เวลาพัก",
  "activities.weight-training": "เวทเทรนนิ่ง",
  "activities.weight-example": "ตัวอย่างท่าฝึก",
  "activities.weight-played": "(เล่นแล้ว)",
  "activities.weight-click": "เล่นแล้วคลิก!!",
  "activities.weight-day": "วัน",
  "activities.week_in_program": "ระยะเวลาในโปรแกรม",
  "activities.current_week": "สัปดาห์ปัจจุบัน",
  "activities.last_week": "สัปดาห์สุดท้าย",
  "activities.last_day": "วันสุดท้าย:",
  "activities.homework_status": "สถานะการบ้าน",
  "activities.complete": "คุณทำได้!",
  "activities.in-progress": "ไม่ผ่านเกณฑ์",

  /* 8.Pages */
  "pages.orderby": "เรียงตาม : ",
  "pages.filterby-score": "กรองตามคะแนน : ",
  "pages.filterby-duration": "กรองตามความยาว : ",
  "pages.add-new": "เพิ่มวิดีโอ",
  "pages.add-new-modal-title": "เพิ่มวิดีโอใหม่",
  "pages.product-name": "ชื่อวิดีโอ",
  "pages.category": "หมวดวิดีโอ",
  "pages.description": "คำอธิบาย",
  "pages.delete": "ลบ",
  "pages.another-action": "กิจกรรมอื่น",
  "pages.display-options": "แสดงทางเลือก",
  "pages.score": "คะแนน : ",
  "pages.video-length": "ความยาว : ",
  "pages.name-label": "ชื่อวิดีโอ",
  "pages.duration-label": "ความยาว",
  "pages.score-label": "คะแนน",
  "pages.score-label-all": "ทุกช่วงคะแนน",
  "pages.score-label-0-2": "0-2 คะแนน",
  "pages.score-label-3-5": "3-5 คะแนน",
  "pages.score-label-6-more": "6 คะแนนขึ้นไป",
  "pages.duration-label-all" : "ทั้งหมด",
  "pages.duration-label-0-2" : "0-2 นาที",
  "pages.duration-label-2-4" : "2-4 นาที",
  "pages.duration-label-4-more" : "4 นาทีขึ้นไป",
  "pages.error-title": "โอ้ เสียใจด้วยค่ะ มีข้อผิดพลาดเกิดขึ้น!",
  "pages.error-code": "รายละเอียดข้อผิดพลาด",
  "pages.go-back-home": "กลับไปหน้าแรก",
  "pages.expire": "สมาชิกของคุณหมดอายุแล้วตั้งแต่ ",
  "pages.expire-button": "ต่ออายุสมาชิก",
  "pages.firsttime": "คุณยังไม่ได้สมัครเข้าเป็นสมาชิกของ CCR",
  "pages.firsttime-button": "สมัครเป็นสมาชิก CCR",
  "pages.pending": "คุณได้ชำระค่าสมาชิก CCR เรียบร้อยแล้ว โปรดรออีกไม่เกิน 24 ชั่วโมง",
  "pages.cart-title": "ตะกร้าสินค้า",
  "pages.product-title": "คอร์ส CCR รุ่นที่ ",
  "pages.not-open": "ตอนนี้เรายังไม่ได้เปิดรับสมัครคอร์ส CCR รุ่นใหม่ โปรดรออีกไม่นาน!",
  "pages.registration-from": "เปิดรับสมัครดั้งแต่วันที่  ",
  "pages.registration-to": " ถึง ",
  "pages.registration-period": "ระยะเวลา: ",
  "pages.total-cart": "มูลค่ารวม",
  "pages.secure-checkout": "สั่งซื้อ",
  "pages.checkout-description": "ราคารวมภาษีไว้แล้ว",
  "pages.checkout-create-account": "สมัครสมาชิก",
  "pages.checkout-create-account-subtitle": "สมัครสมาชิก CCR เพื่อเริ่มต้น",
  "pages.checkout-billing-detail": "ที่อยู่ออกใบเสร็จ",
  "pages.checkout-billing-disclaimer": "เราจะไม่นำข้อมูลของคุณไปให้ผู้อื่นโดยไม่ได้รับความยินยอมจากคุณเสียก่อน",
  "pages.checkout-payment-method": "เลือกวิธีชำระเงิน",
  "pages.paypal-payment": "ชำระด้วย Paypal",
  "pages.transfer-payment": "ชำระด้วยการโอนเงิน",
  "menu.invoice": "ใบเสร็จรับเงิน",
  "pages.wating-content": "ในขณะนี้โปรแกรมยังไม่เริ่มต้น รบกวนรออีกไม่นานค่ะ",
  "pages.upload": "อัพโหลด",
  "pages.close": "ปิด",

  /* 9.Modal */
  "modal.finish-exercise-title" : " ",
  "modal.finish-confirm" : "เล่นแล้ว!",
  "modal.finish-cancel" : "ย้อนกลับ",
  "modal.finish-body-message" : "คะแนนที่จะได้:",
  "modal.finish-delete-message" : "แน่ใจนะ (Vimeo จะถูกลบด้วย)",
  "madal.delete": "ลบวิดีโอทั้งหมด",
  "madal.deldbonly": "ลบจากแอพเท่านั้น",
  "madal.tdee": "คำนวณสารอาหาร",
  "modal.tdee-title": "กรอกข้อมูลสำหรับออกแบบโปรแกรม"
};
