import React, { Component, Fragment } from "react";
import { injectIntl } from 'react-intl';
import {
  Row,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  Progress,
  ModalBody,
  Button,
} from "reactstrap";
import { connect } from "react-redux";
import moment from "moment";
import { AvForm, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import DayInWeekNav from "../../containers/navs/DayInWeekNav";
import Breadcrumb from "../../containers/navs/Breadcrumb";
import { getActivities, updateActivity, changeCurrentActivity } from "../../redux/activities";
import { updateBaseInfo } from "../../redux/auth";

import IconCard from "../../components/cards/IconCard";
import HomeworkFood from "../../containers/pages/HomeworkFood";
import HomeworkWeight from "../../containers/pages/HomeworkWeight";
import HomeworkCardio from "../../containers/pages/HomeworkCardio";
import HomeworkStatus from "../../containers/pages/HomeworkStatus";
//import HomeworkNote from "../../containers/pages/HomeworkNote";
import TDEECalForm from "../../containers/pages/TDEECalForm";
import IntlMessages from "../../helpers/IntlMessages";
import GradientWithRadialProgressCard from "../../components/cards/GradientWithRadialProgressCard";
import { productName } from "../../constants/defaultValues";

class DailyRecord extends Component {
  constructor(props) {
    super(props);

    this.lowCarbDaysChoices = [
      [0, 3],
      [0, 4],
      [1, 4],
      [1, 5],
      [2, 5],
      [2, 6]
    ]

    this.state = {
      modalOpen: false,
      lowCarbModalOpen: false,
      lowCarbModalPage: 1,
      lowCarbDays: 0
    }

    this.toggle = this.toggle.bind(this);
    this.toggleLowCarbModal = this.toggleLowCarbModal.bind(this);
    this.openLowCarbModal = this.openLowCarbModal.bind(this);
    this.goToPage2 = this.goToPage2.bind(this);
    this.getLowCarbDaysIndex = this.getLowCarbDaysIndex.bind(this);
    this.lowCarbModalBodyRender = this.lowCarbModalBodyRender.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleLowCarbDaysChange = this.handleLowCarbDaysChange.bind(this);
    this.handleFeelingChange = this.handleFeelingChange.bind(this);
    this.updateActivity = this.updateActivity.bind(this);
    this.changeDay = this.changeDay.bind(this);
  }
  componentDidMount() {
    if (this.props.user.group && this.props.user.group === "admin") {
      this.props.history.push("/app/admin");
    } else {
      this.props.getActivities(this.props.user.username);
    }
  }

  componentWillReceiveProps(newProps) {
    if (!newProps.user.healthProfile) {
      return;
    }
    const {
      healthProfile: { 
        base_info 
      }
    } = newProps.user;
    const baseInfo = JSON.parse(base_info);
    const { low_carb_first_day, low_carb_second_day } = baseInfo;

    this.setState({
      modalOpen: Object.keys(baseInfo).length <= 0,
      lowCarbDays: this.getLowCarbDaysIndex(low_carb_first_day, low_carb_second_day),
    });

  }

  toggle = () => {
    this.setState(prevState => ({
        modalOpen: !prevState.modalOpen
    }));
  };

  toggleLowCarbModal = () => {
    const { base_info } = this.props.user.healthProfile;
    const baseInfo = JSON.parse(base_info);
    const { low_carb_first_day, low_carb_second_day } = baseInfo;
    this.setState(prevState => ({
      lowCarbModalOpen: !prevState.lowCarbModalOpen,
      lowCarbDays: this.getLowCarbDaysIndex(low_carb_first_day, low_carb_second_day),
    }));
  };

  openLowCarbModal = () => {
    this.setState({
      lowCarbModalOpen: true,
      lowCarbModalPage: 1
    });
  };

  goToPage2 = () => {
    this.setState({
      lowCarbModalPage: 2
    });
  };

  getLowCarbDaysIndex = (firstDay, secondDay) => {
    for (let index = 0; index < this.lowCarbDaysChoices.length; index++) {
      const element = this.lowCarbDaysChoices[index];
      if (element[0] === firstDay && element[1] === secondDay) {
        return index;
      }
    }
    return 0;
  }

  onUpdateBaseInfo = () => {
    const {username} = this.props.user;
    const lowCarbDays = this.lowCarbDaysChoices[this.state.lowCarbDays];
    const prev_base_info = JSON.parse(this.props.user.healthProfile.base_info);
    const base_info = {
      ...prev_base_info,
      low_carb_first_day: lowCarbDays[0],
      low_carb_second_day: lowCarbDays[1]
    }
    const newBaseInfo = {
      user_id: username,
      product_id: productName,
      base_info 
    };
    this.props.updateBaseInfo(newBaseInfo);
    this.setState({
      lowCarbModalPage: 3
    })
  }

  lowCarbModalBodyRender = () => {
    const {messages} = this.props.intl;
    const { healthProfile } = this.props.user;
    const weekDay = [
      messages["label.monday"],
      messages["label.tuesday"],
      messages["label.wednesday"],
      messages["label.thursday"],
      messages["label.friday"],
      messages["label.saturday"],
      messages["label.sunday"]
    ];
    const baseInfo = JSON.parse(healthProfile.base_info) 
                      || 
                    {
                      goal: 'diet',
                      low_carb_first_day: 0,
                      low_carb_second_day: 3
                    };
    const { low_carb_first_day, low_carb_second_day } = baseInfo;
    if (this.state.lowCarbModalPage === 1) {
      return (
        <ModalBody>
          <p className="font-weight-semibold">
            สำคัญอย่างไร?
          </p>
          <p>
            เนื่องจากการเพิ่มกล้ามเนื้อมักจะมีไขมันสะสมเพิ่ม แต่ด้วยการทำ Low-Carb Day (วันที่คุณจะต้องกินคาร์บน้อยกว่าปรกติ)
            จะช่วยให้คุณลดอัตราการสะสมไขมันโดยไม่กระทบต่อเป้าหมาย โดยในหนึ่งสัปดาห์จะต้องมี Low-Carb Day 2 วัน
          </p>
          <p className="color-theme-1">
            แนะนำให้เลือก Low-Carb Day ในวันที่ไม่มีเวทเทรนนิ่ง
          </p>
          <hr/>
          <p className="font-weight-semibold">
            Low-Carb Day ปัจจุบันของคุณ
          </p>
          <div className="d-flex justify-content-between align-items-center">
            <div className="h-100">
              {`${weekDay[low_carb_first_day]} ${messages["label.and"]} ${weekDay[low_carb_second_day]}`}
            </div>
            <div>
              <Button 
                className="link-btn-right"
                onClick={this.goToPage2}
              >
                <IntlMessages id="label.choose_days" /> 
              </Button>
            </div>
          </div>
        </ModalBody>
      )
    } else if (this.state.lowCarbModalPage === 2) {
      return (
        <ModalBody>
          <AvForm onSubmit={this.onUpdateBaseInfo}>
            <AvRadioGroup
              name="lowCarbDays"
              id="lowCarbDays"
              label=""
              value={this.state.lowCarbDays.toString()}
              required
              onChange={this.handleLowCarbDaysChange}
            >
              <AvRadio
                label={`${messages["label.monday"]} ${messages["label.and"]} ${messages["label.thursday"]}`}
                value="0"
              />
              <hr/>
              <AvRadio
                label={`${messages["label.monday"]} ${messages["label.and"]} ${messages["label.friday"]}`}
                value="1"
              />
              <hr/>
              <AvRadio
                label={`${messages["label.tuesday"]} ${messages["label.and"]} ${messages["label.friday"]}`}
                value="2"
              />
              <hr/>
              <AvRadio
                label={`${messages["label.tuesday"]} ${messages["label.and"]} ${messages["label.saturday"]}`}
                value="3"
              />
              <hr/>
              <AvRadio
                label={`${messages["label.wednesday"]} ${messages["label.and"]} ${messages["label.saturday"]}`}
                value="4"
              />
              <hr/>
              <AvRadio
                label={`${messages["label.wednesday"]} ${messages["label.and"]} ${messages["label.sunday"]}`}
                value="5"
              />
              <hr/>
            </AvRadioGroup>
            <div className="d-flex justify-content-center align-items-center">
              <Button 
                className="btn-shadow btn-secondary" 
                size="sm"
                onClick={this.toggleLowCarbModal}
              >
                <span className="text-small text-white">
                  <IntlMessages id="label.cancel" />
                </span>
              </Button>
              <div className="mr-2 ml-2"></div>
              <Button 
                color="primary" 
                className="btn-shadow" 
                size="sm"
                type="submit"
              >
                <span className="text-small text-white">
                  <IntlMessages id="label.save" />
                </span>
              </Button>
            </div>
          </AvForm>
        </ModalBody>
      );
    } else {
      const { lowCarbDays } = this.state;
      const selectedChoice = this.lowCarbDaysChoices[lowCarbDays];
      return (
        <ModalBody>
          <div className="d-flex flex-column justify-content-between align-items-center">
            <FontAwesomeIcon icon={faCheck} size="6x" color="#32CD32" />
            <br/>
            <p className="text-medium">
              บันทึกสำเร็จแล้ว
            </p>
            <p className="mb-0">
              Low-Carb Day ที่เลือกคือ
            </p>
            <p className="font-weight-semibold">
              {`${weekDay[selectedChoice[0]]} ${messages["label.and"]} ${weekDay[selectedChoice[1]]}`}
            </p>
            <Button 
              color="primary" 
              className="btn-shadow" 
              size="sm"
              onClick={this.toggleLowCarbModal}
            >
              <span className="text-one text-white">
                <IntlMessages id="label.ok" />
              </span>
            </Button>
          </div>
        </ModalBody>
      );
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleOptionChange(event) {
    const name = event.target.name;
    const value = event.target.id === "can";
    this.setState(prevState => ({
      meal: { ...prevState.meal, [name]: value } 
    }));
  }

  handleLowCarbDaysChange(event) {
    event.persist();
    const {value} = event.target;
    this.setState({
      lowCarbDays: parseInt(value)
    });
  }

  handleFeelingChange(selectedObject, name) {
    this.setState(prevState => ({
      meal: { ...prevState.meal, [name]: selectedObject.value } 
    }));
  }

  updateActivity(value, fieldName) {
    const newCurrentActivity = {
      ...this.props.currentActivity,
      [fieldName]: value
    }
    this.props.updateActivity(newCurrentActivity, this.props.entireData, this.props.user);
  }

  changeDay(day) {
    if (day !== this.props.currentActivity.day) this.props.changeCurrentActivity(day);
  }

  render() {
    const {messages} = this.props.intl;
    const {modalOpen, lowCarbModalOpen, lowCarbModalPage} = this.state;
    const {
      loading,
      weeklyPlan,
      currentActivity,
      currentNutrients,
      weeklyWeights,
      weeklyTotalCardio
    } = this.props;
    const { healthProfile } = this.props.user;
    const baseInfo = JSON.parse(healthProfile.base_info) 
                      || 
                    {
                      goal: 'diet',
                      low_carb_first_day: 0,
                      low_carb_second_day: 3
                    };
    const { 
      day, 
    } = currentActivity;
    const weightAllDay = ( weeklyPlan.weight_program && weeklyPlan.weight_program.length > 0) 
                          ? weeklyPlan.weight_program.length
                          : 1;
    const weightPlayedDay = ( weeklyPlan.weight_program && weeklyPlan.weight_program.length > 0)
                              ? weeklyPlan.weight_program.filter(item => item.is_play).length
                              : 0;
    const weekProgress = (healthProfile.current_week / healthProfile.last_week) * 100;
    return (
      <Fragment>
        <Modal isOpen={modalOpen}>
          <ModalHeader>
            <span className="text-medium"><IntlMessages id="modal.tdee-title" /></span>
          </ModalHeader>
          <ModalBody>
            <TDEECalForm/>
          </ModalBody>
        </Modal>
        <Modal isOpen={lowCarbModalOpen}>
          <ModalHeader toggle={this.toggleLowCarbModal}>
            <span className="text-medium">
              <IntlMessages id={lowCarbModalPage === 1 
                                  ? "label.low_carb_day" 
                                  : lowCarbModalPage === 2 ? "label.choose_lcarb_days" : "label.empty"
                                } 
              />
            </span>
          </ModalHeader>
          {this.lowCarbModalBodyRender()}
        </Modal>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.daily-record" match={this.props.match}/>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <Colxx lg="12">
            {
              healthProfile &&
              <Row className="mb-4">
                <Colxx xxs="12">
                  <Card>
                    <CardBody>
                      <CardTitle>
                        {/* <IntlMessages id="activities.week_in_program" />  */}
                        <span className="goal-badge ml-0">
                          {`${messages["user.goal"]}: ${messages["label."+baseInfo.goal]}`}
                        </span>
                      </CardTitle>  
                      <div style={{position: "relative", marginTop: "40px"}}>  
                        <div
                          style={{ 
                            top: "-20px",
                            left: `${weekProgress}%`,
                            position: "absolute",
                            transform: `translate(${weekProgress>=5 ? "-100%" : "0%"})`,
                          }}
                        >
                          <IntlMessages id="label.week_no"/> {healthProfile.current_week}
                        </div>     
                        <Progress value={weekProgress} />
                      </div>
                      <div
                        className="justify-content-between d-flex flex-row align-items-center"
                      >              
                        <div>
                          <IntlMessages id="label.begin"/>
                        </div>                       
                        <div>
                          <IntlMessages id="activities.last_week"/> {healthProfile.last_week}
                        </div>
                      </div>
                      <div
                        className="justify-content-end d-flex flex-row align-items-center"
                      >                              
                        <div>
                          <IntlMessages id="activities.last_day"/> 
                          {` ${moment(healthProfile.expire_date).format("DD-MM-YYYY")}`}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Colxx>
              </Row>
            }
            {/* MON-FRI button group */}
            <Row>
              <Colxx xxs="12">
                {this.props.weeklyActivities &&
                  <DayInWeekNav
                    changeDay={this.changeDay}
                    day={day}
                    weeklyActivities={this.props.weeklyActivities}
                    firstLowCarb={baseInfo.low_carb_first_day}
                    secondLowCarb={baseInfo.low_carb_second_day}
                    goal={baseInfo.goal}
                    openLowCarbModal={this.openLowCarbModal}
                  />
                }
              </Colxx>
            </Row>
            {/* END OF MON-FRI button group */}
            <Row>

              <Colxx 
                xs="12" 
              >
                <IconCard
                  icon="simple-icon-bubble"
                  title="activities.feedback-from-coach"
                  value={unescape(weeklyPlan.note)}
                />
              </Colxx>
            </Row>

            {/* Activities Form */}
            <Row>
              <Colxx 
                xs="12" 
                lg="6"
                className="mb-4"
              >
                <HomeworkFood
                  foodList={currentActivity.foods}
                  weeklyPlan={weeklyPlan}
                  currentNutrients={currentNutrients}
                  updateActivity={this.updateActivity}
                  loading={loading}
                />
              </Colxx>
              <Colxx 
                xs="12" 
                lg="6"
                className="mb-4"
              >
                <HomeworkWeight
                  weeklyWeights={weeklyWeights}
                  currentWeight={currentActivity.weight}
                  messages={this.props.intl.messages}
                  updateActivity={this.updateActivity}
                  loading={loading}
                />
              </Colxx>
              <Colxx 
                xs="12" 
                lg="6"
                className="mb-4"
              >
                <HomeworkCardio
                  cardioList={currentActivity.cardio}
                  weeklyPlan={weeklyPlan}
                  weeklyTotalCardio={weeklyTotalCardio}
                  messages={this.props.intl.messages}
                  updateActivity={this.updateActivity}
                  loading={loading}
                />
              </Colxx>
              <Colxx 
                xs="12" 
                lg="6"
                className="mb-4"
              >
                <GradientWithRadialProgressCard
                  icon = "iconsminds-dumbbell"
                  title = {`${messages["activities.weight-played"]} ${weightPlayedDay} ${messages["activities.weight-day"]}`}
                  detail = {messages["activities.weight-training"]}
                  percent = {weightPlayedDay*100/weightAllDay}
                  progressText ={`${weightPlayedDay}/${weightAllDay}`}
                  onClick={() => this.props.history.push("/app/weight-program")}
                />
              </Colxx>
              {
                weeklyPlan.completeness &&
                <Colxx 
                  xs="12" 
                  lg="6"
                  className="mb-4"
                >
                  <HomeworkStatus
                    completeNess={weeklyPlan.completeness}
                  />
                </Colxx>
              }
              {/* <Colxx 
                xs="12" 
                lg="6"
                className="mb-4"
              >
                <HomeworkNote
                  currentNote={currentActivity.note}
                  updateActivity={this.updateActivity}
                  loading={loading}
                />
              </Colxx> */}
            </Row>
            {/* END OF Activities Form */}
          
          </Colxx>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ authUser, activities }) => {
  const { user } = authUser;
  const {
    loading,
    currentActivity,
    weeklyPlan,
    currentNutrients,
    entireData,
    weeklyActivities,
    weeklyWeights,
    weeklyTotalCardio
  } = activities;
  return {
    user,
    loading,
    currentActivity,
    weeklyPlan,
    currentNutrients,
    entireData,
    weeklyActivities,
    weeklyWeights,
    weeklyTotalCardio
  };
};
const mapActionsToProps = {
  getActivities,
  updateActivity,
  changeCurrentActivity,
  updateBaseInfo
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(injectIntl(DailyRecord));