import React, { useState, Fragment } from 'react';
import {
  Row,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Button,
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import { Colxx } from "./common/CustomBootstrap";
import IntlMessages from "../helpers/IntlMessages";
import GlideComponent from './carousel/GlideComponent';
import HistoryItem from "./HistoryItem";
import { WeightAreaChart } from "./charts";
import { ThemeColors } from '../helpers/ThemeColors';
import { strPadLeft, calculatePhase, calculatePhaseStep } from "../helpers/Utils";

const HistoryView = ({ personalData, personalHistory, onGoalClick, onWorkoutClick }) => {
  const [activeFirstTab, setActiveFirstTab] = useState('1');

  const {
    email,
    name
  } = personalData;
  const { history, profile } = personalHistory
  const {base_info, expire_date} = profile;
  let weightData = [];
  let labels = [];
  let weeklyWeights = [];
  let cpfData = [];
  for (let index = 0; index < history.length; index++) {
    const element = history[index];
    const {
      week_in_program, 
      activities: {
        weekly_plan,
        daily_activities
      }
    } = element;
    let food = {
      carbohydrate: 0,
      protein: 0,
      fat: 0
    };
    let nutrients_week = 1;
    let is_pass = false;
    if (weekly_plan.plan) {
      food = weekly_plan.plan.food;
      nutrients_week = weekly_plan.plan.nutrients_week;
    }
    if (weekly_plan.completeness) {
      is_pass = weekly_plan.completeness.is_pass === 1;
    }
    let totalWeight = 0;
    let totalDay = 0;
    let daily_weights = [];
    let daily_nutrients = [];
    daily_activities.forEach((act) => {
      if (act.weight > 0) {
        totalWeight += act.weight;
        totalDay += 1;
      }
      let c = 0;
      let p = 0;
      let f = 0;
      if (act.foods && act.foods.length > 0) {
        act.foods.forEach(food => {
          c += parseInt(food.carbohydrate);
          p += parseInt(food.protein);
          f += parseInt(food.fat);
        });
      }
      daily_nutrients.push(`${c}/ ${p}/ ${f}`);
      daily_weights.push(act.weight);
    });
    const average_weight = parseFloat((totalWeight/(totalDay || 1)).toFixed(2));
    weightData.push({
      week_in_program,
      nutrients_week,
      daily_weights,
      average_weight
    });
    labels.push(`w${strPadLeft(week_in_program, '0', 3)}`);
    weeklyWeights.push(average_weight);

    cpfData.push({
      week_in_program,
      nutrients_week,
      is_pass,
      daily_nutrients,
      goal_nutrients: `${food.carbohydrate}/ ${food.protein}/ ${food.fat}`
    });
  }
  labels.reverse();
  weeklyWeights.reverse();

  const colors = ThemeColors();
  const weightChartData = {
    labels,
    datasets: [
      {
        label: "",
        data: weeklyWeights,
        borderColor: colors.themeColor2,
        pointBackgroundColor: colors.foregroundColor,
        pointBorderColor: colors.themeColor2,
        pointHoverBackgroundColor: colors.themeColor2,
        pointHoverBorderColor: colors.foregroundColor,
        pointRadius: 4,
        pointBorderWidth: 2,
        pointHoverRadius: 5,
        fill: true,
        borderWidth: 2,
        backgroundColor: colors.themeColor2_10
      }
    ]
  };

  const remainDate = expire_date ? moment(expire_date).diff(moment(), 'days'): 0;

  return (
    <Fragment>
      {/* รายละเอียดผู้ใช้ */}
      <Card className="mb-2">
        <CardBody>
          <CardTitle className="text-center">
            ข้อมูลส่วนบุคคล
          </CardTitle>
          <Row>
            <Colxx xxs="12" lg="6">
              <Row>
                <Colxx xxs="6">
                  <div className="text-one text-muted"><IntlMessages id="user.email" />:{' '}</div>
                </Colxx>
                <Colxx xxs="6">
                  <div className="text-one mb-3">{email}</div>
                </Colxx>
              </Row>
              <Row>
                <Colxx xxs="6">
                  <div className="text-one text-muted"><IntlMessages id="user.fullname" />:{' '}</div>
                </Colxx>
                <Colxx xxs="6">
                  <div className="text-one mb-3">{name}</div>
                </Colxx>
              </Row>
              { 
                expire_date &&
                <Row>
                  <Colxx xxs="6">
                    <div className="text-one text-muted"><IntlMessages id="user.expire_date" />:{' '}</div>
                  </Colxx>
                  <Colxx xxs="6">
                    <div className={`text-one ${remainDate <= 7 ? "text-primary": ""}  mb-3`}>
                      {moment(expire_date).format("DD/MM/YYYY")} (เหลืออีก {remainDate} วัน)
                    </div>
                  </Colxx>
                </Row>
              }
              {
                onGoalClick &&
                <Row>
                  <Colxx xxs="6">
                    <div className="text-one text-muted"><IntlMessages id="user.goal" />:{' '}</div>
                  </Colxx>
                  <Colxx xxs="6">
                    <div className="text-one mb-3 d-flex flex-row justify-content-start align-items-start">
                      {base_info.goal || ""}
                      <span className="mr-1 ml-1"></span>
                      <Button
                        className="link-btn pt-0 text-one"
                        onClick={onGoalClick}
                      >
                        <IntlMessages id="label.change" />
                      </Button>
                    </div>
                  </Colxx>
                </Row>
              }
            </Colxx>
            <Colxx xxs="12" lg="6">
              <Row>
                <Colxx xxs="6">
                  <div className="text-one text-muted">อายุ</div>
                </Colxx>
                <Colxx xxs="6">
                  <div className="text-one mb-3">{base_info.age || ""}</div>
                </Colxx>
              </Row>
              <Row>
                <Colxx xxs="6">
                  <div className="text-one text-muted">เพศ</div>
                </Colxx>
                <Colxx xxs="6">
                  <div className="text-one mb-3">{base_info.gender || ""}</div>
                </Colxx>
              </Row>
              <Row>
                <Colxx xxs="6">
                  <div className="text-one text-muted">ส่วนสูง</div>
                </Colxx>
                <Colxx xxs="6">
                  <div className="text-one mb-3">{base_info.height || ""}</div>
                </Colxx>
              </Row>
              <Row>
                <Colxx xxs="6">
                  <div className="text-one text-muted">จำนวนวันออกกำลังกาย</div>
                </Colxx>
                <Colxx xxs="6">
                  <div className="text-one mb-3 d-flex flex-row justify-content-start align-items-start">
                    {base_info.workout_day || ""}
                    <span className="mr-1 ml-1"></span>
                    {
                      onWorkoutClick &&
                      <Button
                        className="link-btn pt-0 text-one"
                        onClick={onWorkoutClick}
                      >
                        <IntlMessages id="label.change" />
                      </Button>
                    }
                  </div>
                </Colxx>
              </Row>
              <Row>
                <Colxx xxs="6">
                  <div className="text-one text-muted">ลักษณะการทำงาน</div>
                </Colxx>
                <Colxx xxs="6">
                  <div className="text-one mb-3">{base_info.work_active_level || ""}</div>
                </Colxx>
              </Row>
            </Colxx>
          </Row>
        
        </CardBody>
      </Card>

      {
        (history && history.length > 0) &&
        <Row style={{padding: "15px"}}>
          <Colxx xxs="12" className="pl-0 pr-0 mb-5">
            <GlideComponent
              settings={{
                gap: 10,
                perView: 3,
                type: 'carousel',
                breakpoints: {
                  600: { perView: 1 },
                  1400: { perView: 2 },
                },
              }}
            >
              {history.map((item, index) => {
                return (
                  <div key={index}>
                    <HistoryItem {...item} />
                  </div>
                );
              })}
            </GlideComponent>
          </Colxx>
        </Row>
      }
      <Row>
        <Colxx xxs="12">
          <CardTitle className="mb-4">
            ประวัติด้านอื่น ๆ
          </CardTitle>
          <Row>
            <Colxx xxs="12">
              <Card className="mb-4">
                <CardHeader>
                  <Nav tabs className="card-header-tabs ">
                    <NavItem>
                      <NavLink
                        to="#"
                        location={{}}
                        className={classnames({
                          active: activeFirstTab === '1',
                          'nav-link': true,
                        })}
                        onClick={() => {
                          setActiveFirstTab('1');
                        }}
                      >
                        น้ำหนัก
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to="#"
                        location={{}}
                        className={classnames({
                          active: activeFirstTab === '2',
                          'nav-link': true,
                        })}
                        onClick={() => {
                          setActiveFirstTab('2');
                        }}
                      >
                        Nutrient
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>

                <TabContent activeTab={activeFirstTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Colxx sm="12">
                        <CardBody>
                          <CardTitle className="mb-4 text-center">
                            ประวัติน้ำหนัก 12 สัปดาห์ย้อนหลัง
                          </CardTitle>
                          <div className="chart card-body pt-0">
                            <WeightAreaChart shadow data={weightChartData} weeklyWeights={weeklyWeights} />
                          </div>
                          <div className="horizontal-align mb-4">
                            <table className="table-measurement">
                              <tbody>
                                <tr>
                                  <td></td>
                                  <td>phase</td>
                                  <td>phase step</td>
                                  <td>จันทร์</td>
                                  <td>อังคาร</td>
                                  <td>พุธ</td>
                                  <td>พฤหัส</td>
                                  <td>ศุกร์</td>
                                  <td>เสาร์</td>
                                  <td>อาทิตย์</td>
                                  <td>เฉลี่ย</td>
                                </tr>
                                {
                                  weightData.map((data, index) => (
                                    <tr key={index}>
                                      <td className="rheader">สัปดาห์ {data.week_in_program}</td>
                                      <td className="rheader">{calculatePhase(data.nutrients_week)}</td>
                                      <td className="rheader">{calculatePhaseStep(data.nutrients_week)}</td>
                                      <td>{data.daily_weights[0]}</td>
                                      <td>{data.daily_weights[1]}</td>
                                      <td>{data.daily_weights[2]}</td>
                                      <td>{data.daily_weights[3]}</td>
                                      <td>{data.daily_weights[4]}</td>
                                      <td>{data.daily_weights[5]}</td>
                                      <td>{data.daily_weights[6]}</td>
                                      <td>{data.average_weight}</td>
                                    </tr>
                                  ))
                                }
                              </tbody>
                            </table>
                          </div>
                        </CardBody>
                      </Colxx>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Colxx sm="12">
                        <CardBody>
                          <CardTitle className="mb-4 text-center">
                            ประวัติสารอาหารย้อนหลัง (หน่วย carb/protein/fat)
                          </CardTitle>
                          <div className="horizontal-align mb-4">
                            <table className="table-measurement">
                              <tbody>
                                <tr>
                                  <td></td>
                                  <td>phase</td>
                                  <td>phase step</td>
                                  <td>เป้าหมาย (c/p/f)</td>
                                  <td>จันทร์</td>
                                  <td>อังคาร</td>
                                  <td>พุธ</td>
                                  <td>พฤหัส</td>
                                  <td>ศุกร์</td>
                                  <td>เสาร์</td>
                                  <td>อาทิตย์</td>
                                  <td>ทำได้?</td>
                                </tr>
                                {
                                  cpfData.map((data, index) => (
                                    <tr key={index}>
                                      <td className="rheader">สัปดาห์ {data.week_in_program}</td>
                                      <td className="rheader">{calculatePhase(data.nutrients_week)}</td>
                                      <td className="rheader">{calculatePhaseStep(data.nutrients_week)}</td>
                                      <td>{data.goal_nutrients}</td>
                                      <td>{data.daily_nutrients[0]}</td>
                                      <td>{data.daily_nutrients[1]}</td>
                                      <td>{data.daily_nutrients[2]}</td>
                                      <td>{data.daily_nutrients[3]}</td>
                                      <td>{data.daily_nutrients[4]}</td>
                                      <td>{data.daily_nutrients[5]}</td>
                                      <td>{data.daily_nutrients[6]}</td>
                                      <td>{
                                        data.is_pass 
                                          ? <FontAwesomeIcon icon={faCheck} size="1x" color="#32CD32" />  
                                          : <FontAwesomeIcon icon={faTimes} size="1x" color="#B50000" />  
                                      }</td>
                                    </tr>
                                  ))
                                }
                              </tbody>
                            </table>
                          </div>
                        </CardBody>
                      </Colxx>
                    </Row>
                  </TabPane>
                </TabContent>
              </Card>
            </Colxx>
          </Row>
        </Colxx>
      </Row>
    </Fragment>
  );
};

export default HistoryView;