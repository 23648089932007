import React from "react";
import { Button } from "reactstrap";
import classnames from "classnames";

const LoaderButton = ({
  status = "default",
  id,
  className,
  color,
  handleOnClick,
  ...props
}) => {
  return (
    <Button
      id={id}
      className={`btn-multiple-state  ${className}  ${classnames({
        "show-spinner": status === "processing"
      })}`}
      color={
        status === "processing"
          ? "dark"
          : color
      }
      onClick={handleOnClick}
      {...props}
      disabled={status !== "default"}
    >
      <div>
        <span className="spinner d-inline-block">
          <span className="bounce1" />
          <span className="bounce2" />
          <span className="bounce3" />
        </span>
        <span className="text-one label">
          {props.children}
        </span>
      </div>
    </Button>
  );
};

export default LoaderButton;
