import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, CardTitle } from "reactstrap";
import ReactTable from "react-table";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import DataTablePagination from "../../../components/DatatablePagination";
import HistoryView from "../../../components/HistoryView";
import { getMembers } from "../../../redux/admin";
import { getHistory } from "../../../redux/activities";
import IntlMessages from "../../../helpers/IntlMessages";
import { historyColumns } from "../../../data/homeworksColumns";

class userHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showList: true,
      selectedHomework: {},
    };

    this.handleUpdateHomeworkStatus = this.handleUpdateHomeworkStatus.bind(this);
    this.renderList = this.renderList.bind(this);
    this.renderForm = this.renderForm.bind(this);
  }
  componentDidMount() {
    this.props.getMembers();
  }

  handleUpdateHomeworkStatus(data) {
    const { user_id } = data;
    this.props.getHistory(user_id);

    this.setState(prevState => ({
      showList: !prevState.showList,
      selectedHomework: data
    }));
  }

  renderForm() {
    const {
      selectedHomework: {
        email,
        name
      }
    } = this.state;
    
    const { personalHistory } = this.props;
    return (
      <Row>
        {/* โปรแกรมสัปดาห์หน้า */}
        <Colxx xxs="12" lg="12">
          {/* ข้อมูลประวัติ */}
          <HistoryView 
            personalData={{
              email, 
              name
            }}
            personalHistory={personalHistory}
          />
        </Colxx>

      </Row>
    );
  }

  renderList() {
    const {
      members,
      status,
      intl: { messages }
    } = this.props;
    const dataTableColumns = historyColumns(
      status,
      messages,
      this.handleUpdateHomeworkStatus
    );
    const sortedColumns = [
      {
        id: "email",
        desc: false
      }
    ];

    return (
      <div>
        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card className="mb-4">
              <CardBody>
                <CardTitle>
                  <IntlMessages id="label.init-title" />
                </CardTitle>
                <ReactTable
                  data={members}
                  columns={dataTableColumns}
                  defaultPageSize={10}
                  filterable={true}
                  showPageJump={true}
                  PaginationComponent={DataTablePagination}
                  showPageSizeOptions={true}
                  defaultSorted={sortedColumns}
                />
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </div>
    );
  }

  render() {
    const { showList } = this.state;
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.userHistory" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {showList 
          ? this.renderList() 
          : this.renderForm()
        }
      </Fragment>
    );
  }
}

const mapStateToProps = ({ admin, activities }) => {
  const { members, status } = admin;
  const { historyStatus, personalHistory } = activities
  return { members, status, historyStatus, personalHistory };
};
const mapActionsToProps = {
  getMembers,
  getHistory,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(injectIntl(userHistory));
