import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, Button, Label } from "reactstrap";
import { connect } from "react-redux";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";

import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { Colxx } from "../../../components/common/CustomBootstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { updateVideo } from "../../../redux/exerciseVideos";

class VideoDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: "",
      title: "",
      url: "",
      thumbnail: "",
      category: "",
      level: "",
      description: "",
      score: 0,
    };

    this.onSubmitVideo = this.onSubmitVideo.bind(this);
    this.customLevelValidation = this.customLevelValidation.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    if (!this.props.selectedVideo) {
      this.props.history.push("/app/exercise-video");
    } else {
        const {
            id,
            title,
            url,
            thumbnail,
            category,
            level,
            description,
            score
        } = this.props.selectedVideo;
      
        this.setState({
            id,
            title,
            url,
            thumbnail,
            category,
            level,
            description,
            score
        });
    }
  }

  async onSubmitVideo(event, errors) {
      if(errors.length === 0) {
        this.props.updateVideo(this.state);
      }
  }

  customLevelValidation() {
    if (this.state.level === "") {
      return "อย่าลืมกรอกระดับความยาก";
    } else {
      return true;
    }
  }

  onChange(event) {
    const { id, value } = event.target;

    this.setState({
      [id]: value
    });
  }

  render() {
    const {
        id,
        title,
        thumbnail,
        level,
        description,
        score
    } = this.state;
    const videoThumbnail = `${thumbnail.substring(0, thumbnail.lastIndexOf("_"))}_640.jpg`;
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <h1>{title}</h1>
            <div className="float-sm-right mb-2" />

            <Breadcrumb match={this.props.match} />
            <Row>
              <Colxx xxs="12" lg="6" className="mb-4">
                <Card className="mb-4">
                  {/* <div className="position-absolute card-top-buttons">
                    <Button outline color={"white"} className="icon-button">
                      <i className="simple-icon-pencil" />
                    </Button>
                  </div> */}
                  <img
                    src={videoThumbnail}
                    alt="thumbnail"
                    className="card-img-top"
                  />

                  <CardBody>
                    <p className="text-muted text-small mb-2">
                        <IntlMessages id="label.id" />
                    </p>
                    <div className="mb-3">
                        {id}
                    </div>

                    <p className="text-muted text-small mb-2">
                      <IntlMessages id="pages.description" />
                    </p>
                    <p className="mb-3">
                      {description}
                    </p>
                  </CardBody>
                </Card>
              </Colxx>

              <Colxx xxs="12" lg="6">
                <Card className="mb-4">
                  <CardBody>
                    <AvForm onSubmit={this.onSubmitVideo}>
                      <AvGroup>
                        <Label for="title">
                          <IntlMessages id="label.video-title" />
                        </Label>
                        <AvField 
                          name="title" 
                          id="title"
                          value={title} 
                          onChange={this.onChange} 
                          validate={{
                              required: { value: true, errorMessage: 'อย่าลืมกรอกชื่อวิดีโอ' }
                          }}
                        />
                      </AvGroup>
                      <AvGroup>
                        <Label for="description">
                          <IntlMessages id="label.video-description" />
                        </Label>
                        <AvField
                          name="description"
                          id="description"
                          value={description}
                          onChange={this.onChange}
                          validate={{
                              required: { value: true, errorMessage: 'อย่าลืมกรอกคำอธิบาย' }
                          }}
                        />
                      </AvGroup>
                      <AvGroup>
                        <Label for="level">
                          <IntlMessages id="label.video-level" />
                        </Label>
                        <AvField
                          type="select"
                          name="level"
                          id="level"
                          value={level}
                          onChange={this.onChange}
                          validate={{
                            custom: this.customLevelValidation
                          }}
                        >
                          <option value="" />
                          <option value="ELEMENTARY">ELEMENTARY</option>
                          <option value="INTERMEDIATE">INTERMEDIATE</option>
                          <option value="ADVANCE">ADVANCE</option>
                        </AvField>
                      </AvGroup>
                      <AvGroup>
                        <Label for="score">
                          <IntlMessages id="label.video-score" />
                        </Label>
                        <AvField
                          type="number"
                          name="score"
                          id="score"
                          value={score}
                          onChange={this.onChange}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "อย่าลืมกรอกคะแนน"
                            },
                            min: {
                              value: 1,
                              errorMessage: "คะแนนขั้นต่ำต้องเท่ากับ 1"
                            }
                          }}
                        />
                      </AvGroup>
                      <Button color="primary">
                        <IntlMessages id="label.save" />
                      </Button>{" "}
                    </AvForm>
                  </CardBody>
                </Card>
              </Colxx>
            </Row>
          </Colxx>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ exerciseVideos }) => {
  const { allItems, loading, status, selectedVideo } = exerciseVideos;
  return { allItems, loading, status, selectedVideo };
};
const mapActionsToProps = { updateVideo };

export default connect(
  mapStateToProps,
  mapActionsToProps
)(VideoDetail);
