import React from "react";
import { Button } from "reactstrap";

export const initTableColumns = (status, messages, handleUpdateHomeworkStatus) => [
  {
    Header: messages["user.email"],
    accessor: "email",
    width: 300,
    Cell: props => 
    <Button
      id="approving-link"
      className="btn-link, btn-outline-white"
      size="sm"
      onClick={() => handleUpdateHomeworkStatus(props.original, props.original.status)}
    >
      <span className="text-default">{props.value}</span>
    </Button>
  },
  {
    Header: messages["user.fullname"],
    accessor: "name",
    maxWidth: 500,
    Cell: props => <p className="text-muted">{props.value}</p>
  },
  {
    Header: messages["user.update_time"],
    accessor: "update_time",
    width: 150,
    Cell: props => <p className="text-muted text-center">{props.value}</p>
  },
  {
    Header: () => (
        <div style={{textAlign: "center"}}>
          {messages["label.status"]}
        </div>
      ),
    accessor: "status",
    Filter: () => <div></div>,
    Cell: props => (
      <div className="text-center align-text-bottom">
          <Button
            id="approving-button"
            color="primary"
            className="btn-shadow"
            size="sm"
            onClick={() => handleUpdateHomeworkStatus(props.original, props.value)}
          >
            {messages["label.check_homework"]}
          </Button>
      </div>
    )
  }
];

export const processedDataTableColumns = (status, messages, handleUpdateHomeworkStatus, current_user_id) => [
  {
    Header: messages["user.email"],
    accessor: "email",
    width: 200,
    Cell: props => <p className="list-item-heading">{props.value}</p>
  },
  {
    Header: messages["user.fullname"],
    accessor: "name",
    maxWidth: 400,
    Cell: props => <p className="text-muted">{props.value}</p>
  },
  {
    Header: messages["label.sent_number"],
    accessor: "sent_number",
    width: 100,
    Cell: props => <p className="text-muted text-center">{props.value} วัน</p>
  },
  {
    Header: messages["label.coach_name"],
    accessor: "coach_name",
    maxWidth: 400,
    Cell: props => <p className="text-muted">{props.value}</p>
  },
  {
    Header: () => (
        <div style={{textAlign: "center"}}>
          {messages["label.status"]}
        </div>
      ),
    accessor: "status",
    Filter: () => <div></div>,
    Cell: props => (
      <div className="text-center align-text-bottom">
        <div>
          <Button
            id="approving-button"
            color="primary"
            className="btn-shadow"
            size="sm"
            onClick={() => handleUpdateHomeworkStatus(props.original, props.value)}
          >
            {(props.original.coach_id === current_user_id) ? messages["label.recheck_homework"] : messages["label.watch_homework"]}
          </Button>
        </div>
      </div>
    )
  }
];

export const historyColumns = (status, messages, handleUpdateHomeworkStatus) => [
  {
    Header: messages["user.email"],
    accessor: "email",
    width: 500,
    Cell: props => 
    <Button
      id="approving-link"
      className="btn-link, btn-outline-white"
      size="sm"
      onClick={() => handleUpdateHomeworkStatus(props.original, props.original.status)}
    >
      <span className="text-default">{props.value}</span>
    </Button>
  },
  {
    Header: messages["user.fullname"],
    accessor: "name",
    maxWidth: 600,
    Cell: props => <p className="text-muted">{props.value}</p>
  },
  {
    Header: () => (
        <div style={{textAlign: "center"}}>
        </div>
      ),
    accessor: "status",
    Filter: () => <div></div>,
    Cell: props => (
      <div className="text-center align-text-bottom">
          <Button
            id="approving-button"
            color="primary"
            className="btn-shadow"
            size="sm"
            onClick={() => handleUpdateHomeworkStatus(props.original, props.value)}
          >
            ดูข้อมูล
          </Button>
      </div>
    )
  }
];
