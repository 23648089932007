import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const AuthenticatedRoute = ({ component: Component,  isAuthenticated: isAuth, isAuthorized, isAdmin, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                isAuth 
                  ? <Component {...props} />
                  : <Redirect
                          to={`/front`}
                      />}
        />
    );
}

function querystring(name, url = window.location.href) {
    name = name.replace(/[[]]/g, "\\$&");

    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
    const results = regex.exec(url);

    if (!results) {
        return null;
    }
    if (!results[2]) {
        return "";
    }

    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

const UnauthenticatedRoute = ({ component: Component, isAuth, ...rest }) => {
    const redirect = querystring("redirect");
    return (
        <Route
            {...rest}
            render={props =>
                !isAuth
                    ? <Component {...props} />
                    : <Redirect to={redirect === "" || redirect === null ? "/" : redirect} />}
        />);
}

const AdminRoute = ({ component: Component, isAdmin, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                isAdmin
                    ? <Component {...props} />
                    : <Redirect to="/app" />}
        />);
}

export { AuthenticatedRoute, UnauthenticatedRoute, AdminRoute }
