import React, { Component, Fragment } from "react";
import { injectIntl } from "react-intl";
import {
    Row,
    Button,
    Label,
  } from "reactstrap";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { connect } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { subscription } from "../../redux/checkout"
import IntlMessages from "../../helpers/IntlMessages";
import { calculateCoursePeriod } from "../../helpers/Utils";
import { Colxx } from "../../components/common/CustomBootstrap";

class TransferForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            transferDateTime: null,
            amount: 0,
            currentProgram: null,
        }

        this.onSubmitTransfer = this.onSubmitTransfer.bind(this);
        this.handleChangeDateTime = this.handleChangeDateTime.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount(){
      const currentProgram = localStorage.getItem("currentProgram");
      this.setState({
        currentProgram: JSON.parse(currentProgram)
      });
    }

    onSubmitTransfer(event, errors) {
        if (errors.length === 0 && this.state.transferDateTime !== null) {
          if (typeof window !== "undefined") {
            if (window.fbq != null) { 
              window.fbq('trackCustom', '<CCR_Purchase>')
            }
            if (window._lt) {
              window._lt('send', 'cv', {
                type: 'CCR_Purchase'
                },['10be62e6-0bb3-49b8-a9c3-404eac3aae64']);
            }
          }
            const { transferDateTime, amount } = this.state;
            const { user } = this.props;
            const { currentProgram } = this.state;
            const payment_date = moment(transferDateTime).format('YYYY-MM-DD HH:mm');
            const { start_date, expire_date } = calculateCoursePeriod(currentProgram, user.healthProfile);
            const submitParams = {
                user_id: user.username,
                program_id: currentProgram.program_id,
                payment_type: "transfer",
                amount,
                payment_date,
                payment_status: "pending",
                product_id: currentProgram.productName,
                create_time: payment_date,
                start_date,
                expire_date,
                transaction: {
                    amount: {
                        total: amount,
                        currency: "THB"
                    },
                    description: currentProgram.description,
                    item_list: {
                        items: [
                            {
                                name: currentProgram.description,
                                sku: currentProgram.program_id,
                                price: amount,
                                currency: "THB",
                                quantity: 1
                            }
                        ]
                    },
                    related_resources: []
                }
            }

            this.props.subscription(submitParams);
        }
    }
  
    handleChangeDateTime = date => {
        this.setState({
          transferDateTime: date
        });
    };
    
    handleChange(event) {
        this.setState({
          [event.target.id]: event.target.value
        })
    }
    
    render() {
        const { messages } = this.props.intl;
        const { amount } = this.state;
        return (
            <Fragment>
                <AvForm onSubmit={this.onSubmitTransfer}>
                    <Row>
                        <Colxx xxs={12} md={6}>
                            <AvGroup>
                                <label>
                                    <IntlMessages id="label.transfer-datetime" />
                                </label>
                                <DatePicker
                                    selected={this.state.transferDateTime}
                                    onChange={this.handleChangeDateTime}
                                    placeholderText={messages["label.transfer-datetime"]}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={1}
                                    dateFormat="DD MMMM YYYY  เวลา:HH:mm"
                                    timeCaption="Time"
                                    todayButton={messages["label.today"]}
                                />
                            </AvGroup>
                        </Colxx>
                        <Colxx xxs={12} md={6}>
                            <AvGroup>
                                <Label><IntlMessages id="label.transfer-amount" /></Label>
                                <AvField 
                                    name="amount" 
                                    id="amount" 
                                    value={amount} 
                                    onChange={(event) => this.handleChange(event)} 
                                    validate={{
                                        required: { value: true, errorMessage: messages["error.transfer-amount"] }
                                    }}
                                />
                            </AvGroup>
                        </Colxx>
                    </Row>
                    <div className="d-flex justify-content-end align-items-center">
                        <Button
                            color="primary"
                            className="btn-shadow"
                            size="lg"
                            id="btn_transfer_payment_ccr"
                        >
                            <span className="text-one text-white"><IntlMessages id="user.transfer-button" /></span>
                        </Button>
                    </div>
                </AvForm>
            </Fragment>
        );
    }
}
const mapStateToProps = ({ authUser }) => {
    const { user } = authUser;
    return { user };
};
  
const mapActionsToProps = { subscription };
  
export default connect(
    mapStateToProps,
    mapActionsToProps
)(injectIntl(TransferForm));
