import React, {Component} from "react";

class SalePage extends Component {
  constructor(props) {
    super();
    this.state = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    }
  }
  componentDidMount() {
    this.timeID = setInterval(
      () => this.tick(), 
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.timeID);
  }
  tick () {
    const SECOND = 1000;
    const MINUTE = SECOND * 60;
    const HOUR = MINUTE * 60;
    const DAY = HOUR * 24;
    const now = new Date().getTime();
    let target = new Date("July 31 2020 23:59");
    if (new Date() > target) {
      target = new Date();
      target.setDate(target.getDate() + ((7-target.getDay())%7));
      target.setHours(23,59,59);
    }
    let nextSunday = target.getTime();
    if (now > target) {
      nextSunday = new Date().setHours(23, 59, 59);
    }
    const unixTimeLeft = nextSunday - now;

    this.setState({
      days: Math.floor(unixTimeLeft / DAY),
      hours: Math.floor(unixTimeLeft % DAY / HOUR),
      minutes: Math.floor(unixTimeLeft % HOUR / MINUTE),
      seconds: Math.floor(unixTimeLeft % MINUTE / SECOND)
    });
  }

  fbTrack(trackString) {
    if (typeof window !== "undefined") {
      if (window.fbq != null) { 
        window.fbq('trackCustom', trackString)
      }
      if (window._lt) {
        window._lt('send', 'cv', {
          type: trackString
          },['10be62e6-0bb3-49b8-a9c3-404eac3aae64']);
      }
    }
  }
  render() {
    const {
      days,
      hours,
      minutes,
      seconds
    } = this.state;

    return (
      <div id="front">
        <nav className="menubar flex-container fixed-top">
          <a href="/">
            <img alt="logo" className="main-logo" src="/assets/img/logo-black.svg"></img> 
          </a>
          <div className="space"></div>
          <a className="btn menu-button float-right" href="/review" onClick={() => this.fbTrack("CCR_review")}>
            <span className="text-one">ทำไม CCR ถึงได้ผล</span>
          </a> 
          <div className="mr-2"></div>
          <a className="btn menu-button float-right" href="/login">
            <span className="text-one">Login</span>
          </a>
        </nav>
        <div className="image-container first-child">
          <picture>
            <source media="(max-width: 320px)" srcSet="/assets/img/salepage/head_320.jpg" />
            <source media="(max-width: 480px)" srcSet="/assets/img/salepage/head_480.jpg" />
            <img src="/assets/img/salepage/head.jpg" alt="" />
          </picture>
        </div>
        <div className="image-container">
          <picture>
            <source media="(max-width: 320px)" srcSet="/assets/img/salepage/part2_320.jpg" />
            <source media="(max-width: 480px)" srcSet="/assets/img/salepage/part2_480.jpg" />
            <img src="/assets/img/salepage/part2.jpg" alt="" />
          </picture>
        </div>
        <div className="image-container">
          <picture>
            <source media="(max-width: 320px)" srcSet="/assets/img/salepage/part3_320.jpg" />
            <source media="(max-width: 480px)" srcSet="/assets/img/salepage/part3_480.jpg" />
            <img src="/assets/img/salepage/part3.jpg" alt="" />
          </picture>
        </div>
        <div className="image-container">
          <picture>
            <source media="(max-width: 320px)" srcSet="/assets/img/salepage/part4_320.jpg" />
            <source media="(max-width: 480px)" srcSet="/assets/img/salepage/part4_480.jpg" />
            <img src="/assets/img/salepage/part4.jpg" alt="" />
          </picture>
        </div>
        <div className="image-container">
          <picture>
            <source media="(max-width: 320px)" srcSet="/assets/img/salepage/part5_320.jpg" />
            <source media="(max-width: 480px)" srcSet="/assets/img/salepage/part5_480.jpg" />
            <img src="/assets/img/salepage/part5.jpg" alt="" />
          </picture>
        </div>
        <div className="image-container">
          <picture>
            <source media="(max-width: 320px)" srcSet="/assets/img/salepage/part6_320.jpg" />
            <source media="(max-width: 480px)" srcSet="/assets/img/salepage/part6_480.jpg" />
            <img src="/assets/img/salepage/part6.jpg" alt="" />
          </picture>
        </div>
        <div className="image-container">
          <picture>
            <source media="(max-width: 320px)" srcSet="/assets/img/salepage/part7_320.jpg" />
            <source media="(max-width: 480px)" srcSet="/assets/img/salepage/part7_480.jpg" />
            <img src="/assets/img/salepage/part7.jpg" alt="" />
          </picture>
        </div>
        <div className="register">
          <p>
            ทำไม CCR ถึงได้ผลมากกว่าวิธีลดน้ำหนักโดยทั่วไป
          </p>
          <a className="btn register-button" onClick={() => this.fbTrack('CCR_review')} href="/review">คลิกเพื่อดูรายละเอียด</a>
        </div>
        <div className="image-container">
          <picture>
            <source media="(max-width: 320px)" srcSet="/assets/img/salepage/part8_320.jpg" />
            <source media="(max-width: 480px)" srcSet="/assets/img/salepage/part8_480.jpg" />
            <img src="/assets/img/salepage/part8.jpg" alt="" />
          </picture>
        </div>
        <div className="image-container">
          <picture>
            <source media="(max-width: 320px)" srcSet="/assets/img/salepage/part9_320.jpg" />
            <source media="(max-width: 480px)" srcSet="/assets/img/salepage/part9_480.jpg" />
            <img src="/assets/img/salepage/part9.jpg" alt="" />
          </picture>
        </div>
        <div className="image-container">
          <picture>
            <source media="(max-width: 320px)" srcSet="/assets/img/salepage/part10_320.jpg" />
            <source media="(max-width: 480px)" srcSet="/assets/img/salepage/part10_480.jpg" />
            <img src="/assets/img/salepage/part10.jpg" alt="" />
          </picture>
        </div>
        <div className="image-container">
          <picture>
            <source media="(max-width: 320px)" srcSet="/assets/img/salepage/part11_320.jpg" />
            <source media="(max-width: 480px)" srcSet="/assets/img/salepage/part11_480.jpg" />
            <img src="/assets/img/salepage/part11.jpg" alt="" />
          </picture>
        </div>
        <div className="image-container">
          <picture>
            <source media="(max-width: 320px)" srcSet="/assets/img/salepage/part12_320.jpg" />
            <source media="(max-width: 480px)" srcSet="/assets/img/salepage/part12_480.jpg" />
            <img src="/assets/img/salepage/part12.jpg" alt="" />
          </picture>
        </div>
        <div className="image-container">
          <picture>
            <source media="(max-width: 320px)" srcSet="/assets/img/salepage/part13_320.jpg" />
            <source media="(max-width: 480px)" srcSet="/assets/img/salepage/part13_480.jpg" />
            <img src="/assets/img/salepage/part13.jpg" alt="" />
          </picture>
        </div>
        <div className="image-container">
          <picture>
            <source media="(max-width: 320px)" srcSet="/assets/img/salepage/time_320.jpg" />
            <source media="(max-width: 480px)" srcSet="/assets/img/salepage/time_480.jpg" />
            <img src="/assets/img/salepage/time.jpg" alt="" />
          </picture>
        </div>
        <div className="image-container">
          <picture>
            <source media="(max-width: 320px)" srcSet="/assets/img/salepage/social_320.jpg" />
            <source media="(max-width: 480px)" srcSet="/assets/img/salepage/social_480.jpg" />
            <img src="/assets/img/salepage/social.jpg" alt="" />
          </picture>
          <a target="_blank" rel="noopener noreferrer" href="https://m.me/planforfits" className="inbox"> </a>
          <a target="_blank" rel="noopener noreferrer" href="tel:0938832339" className="tel"> </a>
          <a target="_blank" rel="noopener noreferrer" href="https://line.me/R/ti/p/@wpd9702p" className="line"> </a>
        </div>
        <div className="image-container">
          <picture>
            <source media="(max-width: 320px)" srcSet="/assets/img/salepage/part14_320.jpg" />
            <source media="(max-width: 480px)" srcSet="/assets/img/salepage/part14_480.jpg" />
            <img src="/assets/img/salepage/part14.jpg" alt="" />
          </picture>
        </div>
        <div className="image-container">
          <picture>
            <source media="(max-width: 320px)" srcSet="/assets/img/salepage/price_320.jpg" />
            <source media="(max-width: 480px)" srcSet="/assets/img/salepage/price_480.jpg" />
            <img src="/assets/img/salepage/price.jpg" alt="" />
          </picture>
        </div>
        <div className="countdown-wrapper">
          <h1>เหลือเวลาสมัครคอร์สเพียง</h1>
          <div>
            <div className="countdown"><span>{days}</span>วัน</div>
            <div className="countdown"><span>{hours}</span>ชั่วโมง</div>
            <div className="countdown"><span>{minutes}</span>นาที</div>
            <div className="countdown"><span>{seconds}</span>วินาที</div>
          </div>
        </div>
        {/* <div className="cta">
          <a className="btn cta-button" href="https://sale.ccrdiet.co/" onClick={() => this.fbTrack('CCR_AddtoCart')}>คลิกสมัครเพื่อรับโปรโมชั่น</a>
        </div> */}
      </div>
    );
  }
};

export default SalePage;
