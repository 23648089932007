import React, { Component } from "react";
import { Input, Button } from "reactstrap";

class SaySomething extends Component {
  render() {
    const {
      placeholder,
      messageInput,
      onCoach2CoachInputPress,
      onCoach2CoachInputChange,
      onCoach2CoachSendClick
    } = this.props;
    return (
      <div className="chat-input-container d-flex justify-content-between align-items-center">
        <Input
          className="form-control flex-grow-1"
          type="text"
          placeholder={placeholder}
          value={messageInput}
          onKeyPress={e => onCoach2CoachInputPress(e)}
          onChange={e => onCoach2CoachInputChange(e)}
        />
        <div>
          <Button
            color="primary"
            className="icon-button large ml-1"
            onClick={() => onCoach2CoachSendClick()}
          >
            <i className="simple-icon-arrow-right" />
          </Button>
        </div>
      </div>
    );
  }
}
export default SaySomething;
