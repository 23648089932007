import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { API } from "aws-amplify";
import moment from "moment";
import { productName } from "../constants/defaultValues";
import { types as actTypes } from "./activities";

/* ACTION Section */

export const types = {
  GET_ALL_SUBSCRIPTIONS: "GET_ALL_SUBSCRIPTIONS",
  GET_ALL_SUBSCRIPTIONS_SUCCESS: "GET_ALL_SUBSCRIPTIONS_SUCCESS",
  GET_ALL_SUBSCRIPTIONS_FAIL: "GET_ALL_SUBSCRIPTIONS_FAIL",
  GET_ALL_UNSUBSCRIPTIONS: "GET_ALL_UNSUBSCRIPTIONS",
  GET_ALL_UNSUBSCRIPTIONS_SUCCESS: "GET_ALL_UNSUBSCRIPTIONS_SUCCESS",
  GET_ALL_UNSUBSCRIPTIONS_FAIL: "GET_ALL_UNSUBSCRIPTIONS_FAIL",
  UPDATE_SUBSCRIPTION: "UPDATE_SUBSCRIPTION",
  UPDATE_SUBSCRIPTION_SUCCESS: "UPDATE_SUBSCRIPTION_SUCCESS",
  UPDATE_SUBSCRIPTION_FAIL: "UPDATE_SUBSCRIPTION_FAIL",
  GET_HOMEWORKS: "GET_HOMEWORKS",
  GET_HOMEWORKS_SUCCESS: "GET_HOMEWORKS_SUCCESS",
  GET_HOMEWORKS_FAIL: "GET_HOMEWORKS_FAIL",
  GET_PROGRAMS: "GET_PROGRAMS",
  GET_PROGRAMS_SUCCESS: "GET_PROGRAMS_SUCCESS",
  GET_PROGRAMS_FAIL: "GET_PROGRAMS_FAIL",
  GET_MEMBERS: "GET_MEMBERS",
  GET_MEMBERS_SUCCESS: "GET_MEMBERS_SUCCESS",
  GET_MEMBERS_FAIL: "GET_MEMBERS_FAIL",
  FILTER_CHECKING_HOMEWORK: "FILTER_CHECKING_HOMEWORK",
  UPDATE_HOMEWORK: "UPDATE_HOMEWORK",
  UPDATE_HOMEWORK_SUCCESS: "UPDATE_HOMEWORK_SUCCESS",
  UPDATE_HOMEWORK_FAIL: "UPDATE_HOMEWORK_FAIL",
  UPDATE_PROGRAM: "UPDATE_PROGRAM",
  UPDATE_PROGRAM_SUCCESS: "UPDATE_PROGRAM_SUCCESS",
  UPDATE_PROGRAM_FAIL: "UPDATE_PROGRAM_FAIL",
  UPDATE_GOAL: "UPDATE_GOAL",
  UPDATE_GOAL_SUCCESS: "UPDATE_GOAL_SUCCESS",
  UPDATE_GOAL_FAIL: "UPDATE_GOAL_FAIL",
  UPDATE_WORKOUT_DAY: "UPDATE_WORKOUT_DAY",
  UPDATE_WORKOUT_DAY_SUCCESS: "UPDATE_WORKOUT_DAY_SUCCESS",
  UPDATE_WORKOUT_DAY_FAIL: "UPDATE_WORKOUT_DAY_FAIL",
  RESET_STATUS: "RESET_STATUS",
};

export const getAllSubscriptions = () => ({
  type: types.GET_ALL_SUBSCRIPTIONS
});

export const getAllUnsubscriptions = () => ({
  type: types.GET_ALL_UNSUBSCRIPTIONS
});

export const updateSubscription = params => ({
  type: types.UPDATE_SUBSCRIPTION,
  payload: params
});

export const getHomeworks = () => ({
  type: types.GET_HOMEWORKS
});

export const getPrograms = () => ({
  type: types.GET_PROGRAMS
})

export const getMembers = () => ({
  type: types.GET_MEMBERS
})

export const updateHomework = payload => ({
  type: types.UPDATE_HOMEWORK,
  payload
});

export const updateProgram = payload => ({
  type: types.UPDATE_PROGRAM,
  payload
});

export const updateGoal = payload => ({
  type: types.UPDATE_GOAL,
  payload
});

export const updateWorkoutDay = payload => ({
  type: types.UPDATE_WORKOUT_DAY,
  payload
});

export const resetStatus = () => ({
  type: types.RESET_STATUS
});

export const filterCheckHomework = (init, checking, message) => {
  const initChecking = checking.map(elem => ({ ...elem, status: "init" }));
  const allElement = init.concat(initChecking);
  const tmpChecking = allElement.filter(initElem =>
    message.some(newElem => initElem.user_id === newElem.userId)
  );
  let newChecking = [];
  tmpChecking.forEach(checking => {
    const coachData = message.find(mes => mes.userId === checking.user_id);
    if (coachData) {
      newChecking.push({
        ...checking,
        coach_id: coachData.coachId,
        coach_name: coachData.coachName,
        status: "checking"
      });
    }
  });
  const newInit = allElement.filter(
    initElem => !message.some(newElem => initElem.user_id === newElem.userId)
  );

  return {
    type: types.FILTER_CHECKING_HOMEWORK,
    payload: {
      init: newInit,
      checking: newChecking
    }
  };
};

/* END OF ACTION Section */

/* SAGA Section */

const GetAllSubscriptionsSagaAsync = async () => {
  try {
    const apiReturn = await API.get("user", "/subscriptions", {
      queryStringParameters: {
        product_id: productName
      }
    });
    return apiReturn;
  } catch (error) {
    return error;
  }
};

const GetAllUnsubscriptionsSagaAsync = async () => {
  try {
    const apiReturn = await API.get("user", "/unsubscriptions");
    return apiReturn;
  } catch (error) {
    return error;
  }
};

const updateSubscriptionSagaAsync = async ({
  user_id,
  program_id,
  payment_status
}) => {
  try {
    const apiReturn = await API.put("user", "/subscription", {
      body: {
        user_id,
        program_id,
        payment_status,
        product_id: productName
      }
    });
    return apiReturn;
  } catch (error) {
    return error;
  }
};

const getHomeworksSagaAsync = async () => {
  try {
    const apiReturn = await API.get("user", "/homeworks", {
      queryStringParameters: {
        product_id: productName
      }
    });
    return apiReturn;
  } catch (error) {
    return error;
  }
};

const getProgramsSagaAsync = async () => {
  try {
    const apiReturn = await API.get("user", "/programs", {
      queryStringParameters: {
        product_id: productName
      }
    });
    return apiReturn;
  } catch (error) {
    return error;
  }
};

const getMembersSagaAsync = async () => {
  try {
    const apiReturn = await API.get("user", "/members", {
      queryStringParameters: {
        product_id: productName
      }
    });
    return apiReturn;
  } catch (error) {
    return error;
  }
};

const updateHomeworkSagaAsync = async (payload) => {
  try {
    const apiReturn = await API.put("activity", "/ccr_homeworks", {
      body: {
        ...payload
      }
    });
    return apiReturn;
  } catch (error) {
    return error;
  }
}

const updateProgramSagaAsync = async (payload) => {
  try {
    const apiReturn = await API.post("user", "/program", {
      body: {
        ...payload
      }
    });
    return apiReturn;
  } catch (error) {
    return error;
  }
}

const updateGoalSagaAsync = async (payload) => {
  try {
    const apiReturn = await API.put("activity", "/goal", {
      body: {
        ...payload
      }
    });
    return apiReturn;
  } catch (error) {
    return error;
  }
}

const updateWorkoutDaySagaAsync = async (payload) => {
  try {
    const apiReturn = await API.put("activity", "/ccr_workout", {
      body: {
        ...payload
      }
    });
    return apiReturn;
  } catch (error) {
    return error;
  }
}

function* GetAllSubscriptionsSaga({ payload }) {
  try {
    const apiReturn = yield call(GetAllSubscriptionsSagaAsync, payload);
    if (apiReturn.results) {
      const { pendings, approves, rejects } = apiReturn.results;
      const newPendings = pendings ? pendings.map(ele => ({
        ...ele, 
        payment_date: moment(ele.payment_date).utcOffset(0).format("DD/MM/YYYY HH:mm")
      })) : [];
      const newApproves = approves ? approves.map(ele => ({
        ...ele, 
        payment_date: moment(ele.payment_date).utcOffset(0).format("DD/MM/YYYY HH:mm")
      })) : [];
      const newRejects = rejects ? rejects.map(ele => ({
        ...ele, 
        payment_date: moment(ele.payment_date).utcOffset(0).format("DD/MM/YYYY HH:mm")
      })) : [];
      const newResult = {
        ...apiReturn.results,
        pendings: newPendings,
        approves: newApproves,
        rejects: newRejects
      }
      yield put({
        type: types.GET_ALL_SUBSCRIPTIONS_SUCCESS,
        payload: newResult
      });
    } else {
      yield put({
        type: types.GET_ALL_SUBSCRIPTIONS_FAIL
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_ALL_SUBSCRIPTIONS_FAIL
    });
  }
}

function* GetAllUnsubscriptionsSaga({ payload }) {
  try {
    const apiReturn = yield call(GetAllUnsubscriptionsSagaAsync, payload);
    if (apiReturn.results) {
      const newUnsubs = apiReturn.results.map(ele => ({
        ...ele, 
        register_date: moment(ele.register_date).utcOffset(0).format("DD/MM/YYYY HH:mm")
      }));
      yield put({
        type: types.GET_ALL_UNSUBSCRIPTIONS_SUCCESS,
        payload: newUnsubs
      });
    } else {
      yield put({
        type: types.GET_ALL_UNSUBSCRIPTIONS_FAIL
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_ALL_UNSUBSCRIPTIONS_FAIL
    });
  }
}

function* updateSubscriptionSaga({ payload }) {
  try {
    const apiReturn = yield call(updateSubscriptionSagaAsync, payload);
    if (apiReturn.results) {
      yield put({
        type: types.UPDATE_SUBSCRIPTION_SUCCESS
      });
      yield put({
        type: types.GET_ALL_SUBSCRIPTIONS
      });
    } else {
      yield put({
        type: types.UPDATE_SUBSCRIPTION_FAIL
      });
    }
  } catch (error) {
    yield put({
      type: types.UPDATE_SUBSCRIPTION_FAIL
    });
  }
}

function* getHomeworksSaga() {
  try {
    const apiReturn = yield call(getHomeworksSagaAsync);
    if (apiReturn.results) {
      yield put({
        type: types.GET_HOMEWORKS_SUCCESS,
        payload: apiReturn.results
      });
    } else {
      yield put({
        type: types.GET_HOMEWORKS_FAIL
      });
    }
  } catch (error) {
    console.log("in getHomeworkSaga error:", error);
    yield put({
      type: types.GET_HOMEWORKS_FAIL
    });
  }
}

function* getProgramsSaga() {
  try {
    const apiReturn = yield call(getProgramsSagaAsync);
    if (apiReturn.results) {
      yield put({
        type: types.GET_PROGRAMS_SUCCESS,
        payload: apiReturn.results
      });
    } else {
      yield put({
        type: types.GET_PROGRAMS_FAIL
      });
    }
  } catch (error) {
    console.log("in getProgramSaga error:", error);
    yield put({
      type: types.GET_PROGRAMS_FAIL
    });
  }
}

function* getMembersSaga() {
  try {
    const apiReturn = yield call(getMembersSagaAsync);
    if (apiReturn.results) {
      yield put({
        type: types.GET_MEMBERS_SUCCESS,
        payload: apiReturn.results
      });
    } else {
      yield put({
        type: types.GET_MEMBERS_FAIL
      });
    }
  } catch (error) {
    console.log("in getMembersSaga error:", error);
    yield put({
      type: types.GET_MEMBERS_FAIL
    });
  }
}

function* updateHomeworkSaga({ payload }) {
  try {
    const apiReturn = yield call(updateHomeworkSagaAsync, payload);
    if (apiReturn.results) {
      yield put({
        type: types.UPDATE_HOMEWORK_SUCCESS
      });
      if (payload.goal !== "same") {
        yield put({
          type: actTypes.CHANGE_BASE_INFO,
          payload: {
            fieldName: 'goal',
            value: payload.goal
          }
        })
      }
    } else {
      yield put({
        type: types.UPDATE_HOMEWORK_FAIL
      })
    }
  } catch (error) {
    console.log("in updateHomeworkSaga error:", error);
    yield put({
      type: types.UPDATE_HOMEWORK_FAIL
    })
  }
}

function* updateProgramSaga({ payload }) {
  try {
    const apiReturn = yield call(updateProgramSagaAsync, payload);
    if (apiReturn.results) {
      yield put({
        type: types.UPDATE_PROGRAM_SUCCESS
      });
    } else {
      yield put({
        type: types.UPDATE_PROGRAM_FAIL
      })
    }
  } catch (error) {
    console.log("in updateProgramSaga error:", error);
    yield put({
      type: types.UPDATE_PROGRAM_FAIL
    })
  }
}

function* updateGoalSaga({ payload }) {
  try {
    const apiReturn = yield call(updateGoalSagaAsync, payload);
    if (apiReturn.results) {
      yield put({
        type: types.UPDATE_GOAL_SUCCESS
      });
      yield put({
        type: types.GET_HOMEWORKS
      })
    } else {
      yield put({
        type: types.UPDATE_GOAL_FAIL
      })
    }
  } catch (error) {
    console.log("in updateGoalSaga error:", error);
    yield put({
      type: types.UPDATE_GOAL_FAIL
    })
  }
}

function* updateWorkoutDaySaga({ payload }) {
  try {
    const apiReturn = yield call(updateWorkoutDaySagaAsync, payload);
    if (apiReturn.results) {
      yield put({
        type: types.UPDATE_WORKOUT_DAY_SUCCESS
      });
      yield put({
        type: types.GET_HOMEWORKS
      })
    } else {
      yield put({
        type: types.UPDATE_WORKOUT_DAY_FAIL
      })
    }
  } catch (error) {
    console.log("in updateWorkoutDaySaga error:", error);
    yield put({
      type: types.UPDATE_WORKOUT_DAY_FAIL
    })
  }
}

export function* watchGetAllSubscriptions() {
  yield takeEvery(types.GET_ALL_SUBSCRIPTIONS, GetAllSubscriptionsSaga);
}

export function* watchGetAllUnsubscriptions() {
  yield takeEvery(types.GET_ALL_SUBSCRIPTIONS, GetAllUnsubscriptionsSaga);
}

export function* watchUpdateSubscription() {
  yield takeEvery(types.UPDATE_SUBSCRIPTION, updateSubscriptionSaga);
}

export function* watchGetHomeworks() {
  yield takeEvery(types.GET_HOMEWORKS, getHomeworksSaga);
}

export function* watchGetPrograms() {
  yield takeEvery(types.GET_PROGRAMS, getProgramsSaga);
}

export function* watchGetMembers() {
  yield takeEvery(types.GET_MEMBERS, getMembersSaga);
}

export function* watchUpdateHomework() {
  yield takeEvery(types.UPDATE_HOMEWORK, updateHomeworkSaga);
}

export function* watchUpdateProgram() {
  yield takeEvery(types.UPDATE_PROGRAM, updateProgramSaga);
}

export function* watchUpdateGoal() {
  yield takeEvery(types.UPDATE_GOAL, updateGoalSaga);
}

export function* watchUpdateWorkoutDay() {
  yield takeEvery(types.UPDATE_WORKOUT_DAY, updateWorkoutDaySaga);
}

export function* saga() {
  yield all([
    fork(watchGetAllSubscriptions),
    fork(watchGetAllUnsubscriptions),
    fork(watchUpdateSubscription),
    fork(watchGetHomeworks),
    fork(watchGetPrograms),
    fork(watchGetMembers),
    fork(watchUpdateHomework),
    fork(watchUpdateProgram),
    fork(watchUpdateGoal),
    fork(watchUpdateWorkoutDay),
  ]);
}

/* END OF SAGA Section */

/* REDUCER Section */

const INIT_STATE = {
  pendings: [],
  approves: [],
  rejects: [],
  init: [],
  unsubscribers: [],
  checking: [],
  complete: [],
  batch_programs: [],
  sub_programs: [],
  members: [],
  status: "default"
};

export function reducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case types.GET_HOMEWORKS:
    case types.GET_PROGRAMS:
    case types.GET_MEMBERS:
    case types.GET_ALL_SUBSCRIPTIONS:
    case types.GET_ALL_UNSUBSCRIPTIONS:
    case types.UPDATE_SUBSCRIPTION:
    case types.UPDATE_GOAL:
      return { 
        ...state, 
        status: "processing" 
      };
    case types.GET_HOMEWORKS_SUCCESS:
      return {
        ...state,
        status: "success",
        init: payload.init,
        checking: payload.checking,
        complete: payload.complete
      };
    case types.GET_PROGRAMS_SUCCESS:
      return {
        ...state,
        status: "success",
        init: payload.init,
        batch_programs: payload.batches,
        sub_programs: payload.subscriptions
      };
    case types.GET_MEMBERS_SUCCESS:
      return {
        ...state,
        status: "success",
        members: payload
      }
    case types.GET_ALL_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        status: "success",
        pendings: payload.pendings,
        approves: payload.approves,
        rejects: payload.rejects
      };
    case types.GET_ALL_UNSUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        status: "success",
        unsubscribers: payload
      }
    case types.UPDATE_HOMEWORK:
      return {
        ...state,
        status: "update homework processing"
      };
    case types.UPDATE_HOMEWORK_SUCCESS:
      return {
        ...state,
        status: "update homework success"
      };
    case types.UPDATE_PROGRAM_SUCCESS:
      return {
        ...state,
        status: "update program success"
      }
    case types.UPDATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        status: "success"
      };
    case types.UPDATE_HOMEWORK_FAIL:
    case types.GET_HOMEWORKS_FAIL:
    case types.GET_PROGRAMS_FAIL:
    case types.GET_MEMBERS_FAIL:
    case types.UPDATE_SUBSCRIPTION_FAIL:
    case types.GET_ALL_SUBSCRIPTIONS_FAIL:
    case types.GET_ALL_UNSUBSCRIPTIONS_FAIL:
    case types.UPDATE_GOAL_FAIL:
      return {
        ...state,
        status: "fail"
      };
    case types.FILTER_CHECKING_HOMEWORK:
      return {
        ...state,
        status: "default",
        init: payload.init,
        checking: payload.checking
      };
    case types.RESET_STATUS:
      return {
        ...state,
        status: "default"
      }
    default:
      return { ...state };
  }
}

/* END OF REDUCER Section */
