import React from "react";
import { Card, CardBody, Table } from "reactstrap";

import IntlMessages from "../../helpers/IntlMessages";
import { formatNumber } from "../../helpers/Utils";

const InvoiceCard = ({
    name,
    address,
    invoice_id,
    transaction_date,
    details,
    total,
    vat_rate,
}) => {
    const subtotal = (total / (1 + (vat_rate/100))).toFixed(2);
    const vatAmount = (total - subtotal).toFixed(2);
    return (
        <Card className="mb-5 invoice-contents">
            <CardBody className="d-flex flex-column justify-content-between">
            <div className="d-flex flex-column">
                <div className="d-flex flex-row justify-content-between pt-2 pb-2">
                    <div className="d-flex align-self-center">
                        <img src="/assets/img/logo-black.svg" alt="Logo" />
                    </div>
                    <div className="d-flex w-30 text-right align-self-center">
                        <p className="text-small text-semi-muted mb-0">
                        <IntlMessages id="general.company-name"/>
                        <br />
                        <IntlMessages id="general.company-address"/>
                        <br />
                        <IntlMessages id="general.company-tel"/>
                        <br />
                        <IntlMessages id="general.company-taxnumber"/>
                        </p>
                    </div>
                    </div>
                    <div className="border-bottom pt-4 mb-5" />

                    <div className="d-flex flex-row justify-content-between mb-5">
                    <div className="d-flex flex-column w-70 mr-2 p-4 text-semi-muted bg-semi-muted">
                        <p className="mb-0">{name}</p>
                        <p className="mb-0">
                            {address}
                        </p>
                    </div>
                    <div className="d-flex w-30 flex-column text-right p-4 text-semi-muted bg-semi-muted">
                        <p className="mb-0">
                            <IntlMessages id="label.invoice_no"/> #: {invoice_id}
                        </p>
                        <p className="mb-0">{transaction_date}</p>
                    </div>
                </div>

                <Table borderless>
                    <thead>
                        <tr>
                        <th className="text-muted text-extra-small mb-2">
                            <IntlMessages id="label.item_name"/>
                        </th>
                        <th className="text-muted text-extra-small mb-2">
                            <IntlMessages id="label.quantity"/>
                        </th>
                        <th className="text-right text-muted text-extra-small mb-2">
                            <IntlMessages id="label.price"/>
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            details.map(({item_name, quantity, price}, index) => (
                                <tr key={index}>
                                    <td>{item_name}</td>
                                    <td>{quantity}</td>
                                    <td className="text-right">{formatNumber(price.toFixed(2))}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>
            <div className="d-flex flex-column">
                <div className="border-bottom pt-3 mb-5" />
                <Table borderless className="d-flex justify-content-end">
                <tbody>
                    <tr>
                    <td className="text-semi-muted"><IntlMessages id="label.subtotal"/></td>
                    <td className="text-right">{formatNumber(subtotal)}</td>
                    </tr>
                    <tr>
                    <td className="text-semi-muted"><IntlMessages id="label.tax"/></td>
                    <td className="text-right">{formatNumber(vatAmount)}</td>
                    </tr>
                    <tr className="font-weight-bold">
                    <td className="text-semi-muted"><IntlMessages id="label.total"/></td>
                    <td className="text-right">{formatNumber(total.toFixed(2))}</td>
                    </tr>
                </tbody>
                </Table>
                <div className="border-bottom pt-3 mb-5" />
                <p className="text-muted text-small text-center">
                Invoice was created on a computer and is valid without the
                signature and seal.{" "}
                </p>
            </div>
            </CardBody>
        </Card>
    );
}

export default InvoiceCard;