import React, { Component, Fragment } from "react";
import { Row, Card, CardTitle,Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { Colxx } from "../components/common/CustomBootstrap";
import IntlMessages from "../helpers/IntlMessages";


class Enroll extends Component {
  componentDidMount() {
    document.body.classList.add("background");
  }
  componentWillUnmount() {
    document.body.classList.remove("background");
  }
  componentWillReceiveProps(newProps) {
    const { user, status, history } = newProps;
    if(!user && status === "fail") history.push("/register");
  }
  renderLoading() {
    return (
        <main>
          <div className="container">
            <Row className="h-100">
              <Colxx xxs="12" md="10" className="mx-auto my-auto">
                <Card className="auth-card">
                  <div className="position-relative image-side ">
                  </div>
                  <div className="form-side">
                    <div className="loading"></div>
                  </div>
                </Card>
              </Colxx>
            </Row>
          </div>
        </main>
    );
  }
  renderEnroll() {
    const { user: {latestSubscription}, currentProgram } = this.props;
    // if there is no latestSubscription, I assume this person is the first timer
    let messagesID = "pages.firsttime";
    let buttonLabelID = "pages.firsttime-button";
    let isPending = false;
    let isExpired = false;
    let isSubscribed = false;
    if (latestSubscription && currentProgram && 
        latestSubscription.program_id === currentProgram.program_id) {
          isSubscribed = true
    }
    
    if (isSubscribed) /* user already bought the course */ {
      if (latestSubscription.payment_status.toLowerCase() === "pending") {
        isPending = true;
        messagesID = "pages.pending";
        buttonLabelID = "";
      } 
    } else {
      if (latestSubscription && 
          latestSubscription.hasOwnProperty("program_expire_date") && 
          moment(latestSubscription.program_expire_date).isBefore(moment(), 'day')) 
      {
        isExpired = true;
        messagesID = "pages.expire";
        buttonLabelID = "pages.expire-button";
      }
    }
    return (
      <main>
          <div className="container">
            <Row className="h-100">
              <Colxx xxs="12" md="10" className="mx-auto my-auto">
                <Card className="auth-card">
                  <div className="position-relative image-side ">
                  </div>
                  <div className="form-side">
                    <NavLink to={`/`} className="white">
                      <span className="logo-single" />
                    </NavLink>
                    <CardTitle className="mb-4">
                      <IntlMessages id={messagesID} /> {isExpired && moment(latestSubscription.program_expire_date).format('DD/MM/YYYY')}
                    </CardTitle>
                    {!isPending && 
                      <Button
                        href="/cart"
                        color="primary"
                        className="btn-shadow"
                        size="lg"
                      >
                        <IntlMessages id={buttonLabelID}/>
                      </Button>
                    }
                  </div>
                </Card>
              </Colxx>
            </Row>
          </div>
        </main>
    );
  }
  render() {
    return (
      <Fragment>
        <div className="fixed-background" />
        {this.props.user && this.props.currentProgram ? this.renderEnroll() : this.renderLoading()}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ authUser }) => {
  const { user, isLoading, currentProgram, status } = authUser;
  return { user, isLoading, currentProgram, status };
};

const mapActionsToProps = { };

export default connect(
  mapStateToProps,
  mapActionsToProps
)(Enroll);
