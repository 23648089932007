import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faHistory } from "@fortawesome/free-solid-svg-icons";

import IntlMessages from "../../helpers/IntlMessages";

function HomeworkStatus({
  completeNess
}) {
  function renderList() {
    const cardClass = "h-100"
    return (
      <div>
        <Card className={cardClass}>
          <CardBody>
            <CardTitle>
              <IntlMessages id="activities.homework_status" /> 
            </CardTitle>
            <div className="mb-4 wp-content-title">
              {
                completeNess.is_pass 
                  ? <div>
                      <FontAwesomeIcon icon={faCheck} size="2x" color="#32CD32" />  
                      <h3 className="mt-1">
                        <IntlMessages id="activities.complete" /> 
                      </h3>
                    </div>
                  : <div>
                      <FontAwesomeIcon icon={faHistory} size="2x" color="#B50000" />   
                      <h3 className="mt-1">
                        <IntlMessages id="activities.in-progress" /> 
                      </h3>
                    </div>
              }
            </div>
            <div className="mb-4">
              <p className="mb-2">
                fat
                <span className="float-right text-muted">
                  {
                    completeNess.fat 
                    ? <IntlMessages id="activities.complete" /> 
                    : <IntlMessages id="activities.in-progress" /> 
                  }
                </span>
              </p>
              <hr />
            </div>
            <div className="mb-4">
              <p className="mb-2">
                protein
                <span className="float-right text-muted">
                  {
                    completeNess.protein 
                    ? <IntlMessages id="activities.complete" /> 
                    : <IntlMessages id="activities.in-progress" /> 
                  }
                </span>
              </p>
              <hr />
            </div>
            <div className="mb-4">
              <p className="mb-2">
                carbohydrate
                <span className="float-right text-muted">
                  {
                    completeNess.carb 
                    ? <IntlMessages id="activities.complete" /> 
                    : <IntlMessages id="activities.in-progress" /> 
                  }
                </span>
              </p>
              <hr />
            </div>
            <div className="mb-4">
              <p className="mb-2">
                cardio
                <span className="float-right text-muted">
                  {
                    completeNess.cardio 
                    ? <IntlMessages id="activities.complete" /> 
                    : <IntlMessages id="activities.in-progress" /> 
                  }
                </span>
              </p>
              <hr />
            </div>
            <div className="mb-4">
              <p className="mb-2">
                Weight Training
                <span className="float-right text-muted">
                  {
                    completeNess.workout 
                    ? <IntlMessages id="activities.complete" /> 
                    : <IntlMessages id="activities.in-progress" /> 
                  }
                </span>
              </p>
              <hr />
            </div>
          </CardBody>
        </Card>

      </div>
    );
  }

  return renderList();
};
export default HomeworkStatus;
