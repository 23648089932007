import React, {useState, useEffect} from "react";
import { Card, CardBody, CardTitle, Progress, Button, Row, Label } from "reactstrap";
import ReactTable from "react-table";
import {
  AvForm,
  AvField,
  AvGroup,
} from "availity-reactstrap-validation";
import uuidv4 from "uuid/v4";

import IntlMessages from "../../helpers/IntlMessages";
import LoaderButton from "../../components/LoaderButton";
import Pagination from "../../components/DatatablePagination";
import { Colxx } from "../../components/common/CustomBootstrap";

function HomeworkCardio({
  cardClass = "h-100",
  cardioList,
  weeklyPlan,
  weeklyTotalCardio,
  messages,
  updateActivity,
  loading
}) {
  const [isList, flipIsList] = useState(true);
  const [isNewCardio, flipIsNewCardio] = useState(true);
  const [cardio, setCardio] = useState({
    id: "",
    name: "",
    time: 0
  });
  const [cardios, setCardios] = useState(cardioList);
  useEffect(() => {
    setCardios(cardioList);
  }, [cardioList]);

  const cardioStatus = {
    name: messages['activities.cardio-weekly'],
    plan: weeklyPlan.plan.cardio,
    actual: weeklyTotalCardio
  };

  function changeFormState() {
    flipIsNewCardio(true);
    if (isList) {
      setCardio({
        id: "",
        name: "",
        time: 0
      });
    }
    flipIsList(isList => !isList);
  }

  function submitData(newCardios) {
    setCardios(newCardios);
    updateActivity(newCardios, "cardio");
  }

  function updateCardio(event, errors) {
    if(errors.length === 0) {
      if (isNewCardio) {
        const newCardio = { ...cardio, id: uuidv4()};
        cardios.push(newCardio);
        submitData(cardios);
      } else {
        const editedCardio = cardios.map(item => 
          item.id === cardio.id ? cardio : item
        );
        submitData(editedCardio);
      }
      flipIsList(isList => !isList);
    }
  }

  function editCardio(param) {
    flipIsNewCardio(false);
    setCardio({ ...param });
    flipIsList(isList => !isList);
  }

  function removeCardio(id) {
    const removedCardio = cardios.filter (item => item.id !== id);
    submitData(removedCardio);
  }

  function handleChange(event) {
    const { name, value } = event.target;
    const cardioValue = name === "name" ? value : parseFloat(value);
    setCardio({ ...cardio, [name]: cardioValue });
  }

  const columns = [
    {
      Header: () => <div styles="display:none;"></div>,
      accessor: "name",
      width: 100,
      sortable: false,
      Cell: props => <p className="text-muted">{props.value}</p>
    },
    {
      Header: () => <div styles="display:none;"></div>,
      accessor: "time",
      sortable: false,
      Cell: props => (
        <p className="text-muted">
          {props.value} {messages["activities.cardio-minute"]}
        </p>
      )
    },
    {
      Header: () => <div styles="display:none;"></div>,
      accessor: "id",
      width: 80,
      sortable: false,
      Cell: props => (
        <div className="text-center align-text-bottom">
          <Button
            color="empty"
            size="xs"
            onClick={() => editCardio(props.original)}
          >
            <i className={"simple-icon-note align-text-center"} />
          </Button>
          <Button
            color="empty"
            size="xs"
            onClick={() => removeCardio(props.value)}
          >
            <i
              className={"simple-icon-trash text-highlight align-text-center"}
            />
          </Button>
        </div>
      )
    }
  ];

  function renderList() {
    return (
      <div>
        <Card className={cardClass}>
          <CardBody>
            <CardTitle>
              <IntlMessages id="activities.cardio-title" />
            </CardTitle>
            <div className="mb-4">
              <p className="mb-2">
                {cardioStatus.name}
                <span className="float-right text-muted">
                  {cardioStatus.actual}/{cardioStatus.plan}
                </span>
              </p>
              <Progress value={(cardioStatus.actual / cardioStatus.plan) * 100} />
            </div>
          </CardBody>
        </Card>

        <Card className={cardClass}>
          <CardBody>
            <IntlMessages id="activities.cardio-today" />
            <ReactTable
              NoDataComponent={() => null}
              defaultPageSize={6}
              data={cardios}
              columns={columns}
              minRows={0}
              showPageJump={false}
              showPageSizeOptions={false}
              PaginationComponent={Pagination}
            />
            <Button block color="primary" onClick={changeFormState}>
              <IntlMessages id="activities.cardio-submit" />
            </Button>
          </CardBody>
        </Card>
      </div>
    );
  }

  function renderForm() {
    return (
      <div>
        <Card className="mb-5">
          <CardBody>
            <h6 className="mb-4">
              <IntlMessages id="activities.cardio-submit" />
            </h6>

            <AvForm
              className="av-tooltip tooltip-label-right"
              onSubmit={updateCardio}
            >
              <Row>
                <Colxx lg={12}>
                  <AvGroup>
                    <Label>
                      <IntlMessages id="activities.cardio-name" />
                    </Label>
                    <AvField
                      name="name"
                      id="name"
                      value={cardio.name}
                      onChange={handleChange}
                    />
                  </AvGroup>
                </Colxx>
                <Colxx lg={12}>
                  <AvGroup>
                    <Label>
                      <IntlMessages id="activities.cardio-minute" />
                    </Label>
                    <AvField
                      name="time"
                      id="time"
                      type="number"
                      value={cardio.time}
                      onChange={handleChange}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "โปรดกรอกเวลา"
                        },
                        min: {
                          value: 0,
                          errorMessage: "เวลาขั้นต่ำต้องเท่ากับ 0"
                        }
                      }}
                    />
                  </AvGroup>
                </Colxx>
              </Row>

              <LoaderButton
                color="primary"
                block
                status={loading ? "processing" : "default"}
              >
                <IntlMessages id="activities.submit" />
              </LoaderButton>
            </AvForm>
          </CardBody>
        </Card>
      </div>
    );
  }

  if (isList) {
    return renderList();
  } else {
    return renderForm();
  }
};
export default HomeworkCardio;
